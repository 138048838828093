/* ==============================================================================
  予約ログの 表示　モジュール

DB table R  : visit、tech_slip、goods_slip
		 R  : staff、customer、tech、goods
		 
 group: id,title,<staff>
 staff: id,no+name
 salesax: startDate, rate
 
 item:  id,group,   title,start_time,end_time,<visit>
 visit: id,staff_id,name, start_time,duration,visit_state,customer_id
 
=============================================================================== */

import React, {useState,useEffect} from 'react';

// import ReactDataGrid from 'react-data-grid';
import {FormGroup,InputGroup,InputGroupText,Input,
		Container,Row,Col,Button } from 'reactstrap';
import ReactDataGrid from 'react-data-grid';
import { AlignLeft,AlignRight,YenFormat } from './Formatter';	// React Data Grid

import moment from 'moment';

import Request from 'superagent';
import {JWToken} from './auth/Login';
import { FaArrowLeft,FaArrowRight } from "react-icons/fa";

import ModalHook, {FooterOk} from './ModalHook';
import {changeYen} from './Formatter';

import './Table.css';

const visitState = [ '予約','来店','会計','集計' ];

// マスターリストから、idを検索する
const getMaster = (masterList,id) => {
	let result = masterList.find( (master) => {
		return master.id === id;
	});
	return result;
};

const VisitLog = (props) => {
	const {
		isOpen,
		visitId,
		onClose,
		onCancel,
		customerList,
		staffList,
		techList,
		goodsList,
		taxList,
		creditList,
	} = props;

	const defaultVisitLogList = [{
		tenant_id:   0,     			/* テナントID   */
	    visit_id:    0,     			/* 来店情報ID   */
	    staff_id:    0,     			/* レジ担当ID   */
	    edit_time:   '2021-07-09 10:28',/* 変更日時     */
	    visit:       {visit_date: "", visit_state: 0, visit_time: "", credit_id: 0}, // add_point: 0, credit_id: 0, customer_id: 0, duration: 30, id: 0, memo: "",name: "", ope_staff: 0, pay: 0, regist_date: "", staff_id: 0, tenant_id: 0, use_point: 0, visit_date: "", visit_state: 0, visit_time: ""},				/* 来店情報     */
	    tech_slip:   [],				/* 技術売上     */
	    goods_slip:  []					/* 商品売上     */
	}];

	const [index, setIndex] = useState(0);
	const [visitLogList,setVisitLogList	] = useState(defaultVisitLogList);
	const [visitLog,	setVisitLog		] = useState(visitLogList[index]);

/* -----------------------------------------------------------
	VisitLogの読み込み
------------------------------------------------------------- */
	useEffect(() => {
		if ( isOpen && visitId  ) {
			//ajax通信する
			Request.get("/visit_log/" + visitId)
				.query( { token: JWToken.getToken().token } )
				.then( res => {
					if ( res.body.dbData.length ) {
						setVisitLogList( res.body.dbData );
					} else {
						setVisitLogList( defaultVisitLogList );
					}
//					setIndex(visitLogList.length -1);
				})
				.catch( err => {
					console.log(err,err.status);
					console.error("Visit Log "+visitId+  " を読み込めません。");
					if ( err & err.status === 403 ) JWToken.clearToken();
				});
		} else {
			setVisitLogList( defaultVisitLogList );
			setIndex(0);
			setVisitLog(visitLogList[index]);
		}
	// eslint-disable-next-line
	},[isOpen]);

	useEffect(() => {
		setIndex(visitLogList.length -1);
		setVisitLog(visitLogList[index]);
	// eslint-disable-next-line
	},[visitLogList]);

	useEffect(() => {
		setVisitLog(visitLogList[index]);
	// eslint-disable-next-line
	},[index]);
	
	const onDecIndex = () => {
		if ( index > 0 ) setIndex(index-1);
		setVisitLog(visitLogList[index]);
	};
	
	const onIncIndex = () => {
		if ( index + 1 < visitLogList.length ) setIndex(index+1);
		setVisitLog(visitLogList[index]);
	};
// console.log(visitLog.edit_time);
	const subTitle = 
		<div>
			<Button onClick={onDecIndex} disabled={index===0} autoFocus={true}><FaArrowLeft /></Button>
			{'　修正日時：' + moment(visitLog.edit_time).format('YYYY-MM-DD HH:mm') + '　'}
			<Button onClick={onIncIndex} disabled={index===visitLogList.length-1} ><FaArrowRight/></Button>
		</div>;
	
	let body = <Body visitLog={visitLog} customerList={customerList} staffList={staffList} techList={techList} goodsList={goodsList} taxList={taxList} creditList={creditList} />;

	return (
		<ModalHook isOpen={isOpen} title='予約ログ' subTitle={subTitle} maxWidth = '750px' body={body} onCancel={onCancel} footer={<FooterOk onClose={onClose} />} />
	);
};


const Body = (props) => {
	const {
		visitLog,
		customerList,
		staffList,
		techList,
		goodsList,
		taxList,
		creditList,
	} = props;
	
	let customer = customerList.find( (cust) => {
		return cust.id === visitLog.visit.customer_id;
	});
	if ( !customer ) 	customer = { id: 0, no: 0, name: '', staff_id: 0, point: 0 };

		// 消費税のレート
	const taxRate = ( day ) => {
		let tax = 0;
		for ( let i = 0 ; i < taxList.length ; i++ ) {
			if ( taxList[i].start > day ) break;
			tax = taxList[i].rate;
		}
		return tax/100;
	}
	
	const taxInc = (visitLog) => {
		// 技術合計
		let techTotal = 0;
		let techSlip = visitLog.tech_slip;
		techSlip.forEach ( (s,index) => {
			techTotal +=  Number(s.price);
		});

		// 商品合計
		let goodsTotal = 0;
		let goodsSlip = visitLog.goods_slip;
		goodsSlip.forEach ( (s,index) => {
			goodsTotal +=  Number(s.price);
		});

		let tax = Math.floor( ( (techTotal + goodsTotal) * taxRate(visitLog.visit.visit_date) ) /10) * 10;		// 10円未満を切り捨て

		return { techTotal:techTotal, goodsTotal:goodsTotal, tax:tax, usePoint:visitLog.visit.use_point };
	}

	const {techTotal,goodsTotal,tax,usePoint} = taxInc(visitLog);

	return(
		<Container fluid={true}>
			<Row>
				<Col xs="5">
					<Visit   visit={visitLog.visit} customer={customer} staffList={staffList}   />
					<Account visitLog={visitLog}    customer={customer} creditList={creditList} usePoint={usePoint} total={techTotal+goodsTotal+tax} />
				</Col>
				<Col xs="7">
					<TechSlip  techSlipList ={visitLog.tech_slip}  techList ={techList}  staffList={staffList} total={techTotal} />
					<GoodsSlip goodsSlipList={visitLog.goods_slip} goodsList={goodsList} total={goodsTotal}/>
					<Memo visit={visitLog.visit}/>
				</Col>
			</Row>
		</Container>

	);
};

const Visit = (props) => {
	const {
		visit,
		customer,
		staffList,
	} = props;
	
	
	// 予約日時
	let startDate = visit.visit_date + ' ' + String(visit.visit_time).substr(0, 5);
	
	let opeStaff = getMaster(staffList,visit.ope_staff);
	if ( opeStaff === undefined ) 	opeStaff = { no: '', name: ''};

	let staff =  getMaster(staffList,visit.staff_id);
	if ( staff === undefined ) 	staff = { no: '', name: ''};

	return (
		<div>
			<FormGroup disabled>
				<InputGroup>
					<InputGroupText style={{width:'90px', textAlign:'justify'}}>レジ担当　　</InputGroupText>
					<Input type="text" value={opeStaff.name} styles={"width=20"} readOnly={true}/>
				</InputGroup>
			</FormGroup>
			<FormGroup disabled>
				<InputGroup>
					<InputGroupText style={{width:'90px', textAlign:'justify'}}>状　況　　</InputGroupText>
					<Input type="text"  value={visitState[visit.visit_state]} styles={"width=20"} readOnly={true}/>
				</InputGroup>
				<InputGroup>
					<InputGroupText style={{width:'90px', textAlign:'justify'}}>予約日時　</InputGroupText>
					<Input type="text" value={startDate} styles={"width=20"} readOnly={true}/>
				</InputGroup>
				<InputGroup>
					<InputGroupText style={{width:'90px', textAlign:'justify'}}>担当者　　</InputGroupText>
					<Input type="text" value={staff.name} styles={"width=20"} readOnly={true}/>
				</InputGroup>
				<InputGroup>
					<InputGroupText style={{width:'90px', textAlign:'justify'}}>お客様　　</InputGroupText>
					<Input type="number" value={customer.no} readOnly={true}/>
				</InputGroup>
				<InputGroup>
					<InputGroupText style={{width:'90px', textAlign:'justify'}}>お名前　　</InputGroupText>
					<Input type="text" value={visit.name} readOnly={true}/>
				</InputGroup>
			</FormGroup>
		</div>
	);
}

const Account = (props) => {
	const {
		visitLog,
		customer,
		creditList,
		usePoint,
		total,
	} = props;
	
	let discount = '利用Point　';
	if ( visitLog.visit.customer_id === 0 ) {
		discount = '割 　引　　';
	}

	let creditName = '現金';
	if ( visitLog.visit.credit_id ) {
		let credit = creditList.find( (c) => {			// 
			return c.id === visitLog.visit.credit_id;
		});
		creditName = credit.name;
	}

	return (
		<div>

			<FormGroup disabled>
				<InputGroup >
					<InputGroupText style={{width:'90px', textAlign:'justify'}}>税込 金額　　</InputGroupText>
					<Input type="text" className="text-right" value={'¥'+changeYen(total)+'円' } readOnly={true}/>
				</InputGroup>
				<InputGroup >
					<InputGroupText style={{width:'90px', textAlign:'justify'}}>保有Point　　</InputGroupText>
					<Input type="text" className="text-right" value={changeYen(customer.point)} readOnly={true}/>
				</InputGroup>
				<InputGroup >
					<InputGroupText style={{width:'90px', textAlign:'justify'}}>{discount}</InputGroupText>
					<Input type="number" className="text-right" value={usePoint}  readOnly={true}/>
				</InputGroup>

				<InputGroup >
					<InputGroupText style={{width:'90px', textAlign:'justify'}}>合計 金額　　</InputGroupText>
					<Input type="text" className="text-right" value={'¥'+changeYen(total-usePoint)+'円'} readOnly={true}/>
				</InputGroup>
				<InputGroup>
					<InputGroupText style={{width:'90px', textAlign:'justify'}}>支払方法　　</InputGroupText>
					<Input type="text" value={creditName} readOnly={true}/>
				</InputGroup>
				<InputGroup>
					<InputGroupText style={{width:'90px', textAlign:'justify'}}>お預り　　</InputGroupText>
					<Input type="text" className="text-right" value={'¥'+changeYen(visitLog.visit.pay)+'円'} readOnly={true}/>
				</InputGroup>

				<InputGroup>
					<InputGroupText style={{width:'90px', textAlign:'justify'}}>お釣り　　</InputGroupText>
					<Input type="text" className="text-right" value={'¥' + changeYen(visitLog.visit.pay - total + usePoint) +'円' } readOnly={true}/>
				</InputGroup>
			</FormGroup>
		</div>
	);
}

const TechSlip = (props) => {

	const {
		techSlipList,
		staffList,
		techList,
		total,
		height= 200,

	} = props;

	const columns= [
			 { key: 'tech', name: '技術名', width: 137, editable: false, resizable: true, formatter:AlignLeft },
			 { key: 'staff',name: '担当者', width: 100, editable: false, resizable: true, formatter:AlignLeft },
			 { key: 'price',name: '価格'  ,				editable: false, resizable: true, formatter:YenFormat },
			 { key: 'period',name:'時間',   width:  70,	editable: false, resizable: true, formatter:AlignRight }
	];

	let slipList  = techSlipList.map( (slip) => {
		let tech  = getMaster(techList, slip.tech_id );
		let staff = getMaster(staffList,slip.staff_id);
		slip.tech   = tech.name;
		slip.period = tech.period;
		slip.staff  = staff.name;
		return slip;
	})

/* ReactDataGrid -------------------------------------------- */ 
	const rowGetter = (rowIdx) => {
		return slipList[rowIdx];
	}
/* ReactDataGrid --------------------------------------------  */

	return (
		<div>
		    <div className="d-flex align-items-end">　技術合計：{changeYen(total)}円</div>

			<ReactDataGrid enableCellSelect={true} minHeight={height} // minWidth={420} 
						rowGetter={rowGetter} columns={columns} rowsCount={slipList.length}
						// onRowUpdated={this.handleRowUpdated}
				rowSelection={{
					showCheckbox: false,
					enableShiftSelect: false,
				}}  />
		</div>
	);
}

const GoodsSlip = (props) => {

	const {
		goodsSlipList,
		goodsList,
		total,
		height= 180,

	} = props;

	const columns = [
			 { key: 'goods', name: '商品名', width: 260, editable: false, resizable: true, formatter:AlignLeft },
			 { key: 'price', name: '価格',               editable: false, resizable: true, formatter:YenFormat }
	];

	let slipList  = goodsSlipList.map( (slip) => {
		let goods = getMaster(goodsList,slip.goods_id );
		slip.goods = goods.name;
		return slip;
	});

/* ReactDataGrid -------------------------------------------- */ 
	const rowGetter = (rowIdx) => {
		return slipList[rowIdx];
	};
/* ReactDataGrid --------------------------------------------  */

	return (
		<div>
		    <div className="d-flex align-items-end" style={{marginTop:"7px"}}>　商品合計：{changeYen(total)}円</div>

			<ReactDataGrid enableCellSelect={true} minHeight={height} // minWidth={420} 
						rowGetter={rowGetter} columns={columns} rowsCount={slipList.length}
						// onRowUpdated={this.handleRowUpdated}
				rowSelection={{
					showCheckbox: false,
					enableShiftSelect: false,
				}}  />
		</div>
	);
};

const Memo = (props) => {
	const {
		visit,
	} = props;

	return (
		<InputGroup>
			<InputGroupText style={{width:'55px'}}>メモ</InputGroupText>
			<Input style={{height: '71px'}} type="textarea" value={visit.memo} readOnly={true} />
		</InputGroup>
	);
}
export default VisitLog;