/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useState } from 'react';
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import { FaQuestion } from "react-icons/fa";


import './Table.css';

const HelpPopover = (props) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => {
//    console.log(props.help);
    setPopoverOpen(!popoverOpen);
  };
  
  return (
    <div  style={{ margin: '0 0 0 auto'}} >
{/*			<Button className="float-right" id={props.id} ><FaQuestion/></Button> */}
			<Button  style={{float:'right'}} id={props.id} ><FaQuestion/></Button>
      <Popover placement="auto-start" trigger="legacy" isOpen={popoverOpen} target={props.id} toggle={toggle} >
        <PopoverHeader>ヘルプ</PopoverHeader>
        <PopoverBody>
          <div className="popover-content">
          <ReactMarkdown children={props.help} skipHtml />
          </div>
        </PopoverBody>
      </Popover>
    </div>
  );
};

export default HelpPopover;