export const MAX_DB_LENGTH = 1000000000;

export const MAX_NUMBER_LENGTH = 4;
export const MAX_NAME_LENGTH = 40;
export const MAX_KANA_LENGTH = 40;
export const MAX_NICKNAME_LENGTH = 4;
export const MAX_MONEY_LENGTH = 8;
export const MAX_MAIL_LENGTH = 128;
export const MAX_PHONE_LENGTH = 15;
export const MAX_ZIPCODE_LENGTH = 8;
export const MAX_PREF_LENGTH = 8;
export const MAX_CITY_LENGTH = 30;
export const MAX_SUBURB_LENGTH = 30;
export const MAX_ADDRESS_LENGTH = 100;
export const MAX_ADDRESS1_LENGTH = 60;
export const MAX_ADDRESS2_LENGTH = 60;
export const MAX_PASSWORD_LENGTH = 64;
export const MAX_COMPANY_LENGTH = 40;
export const MAX_ABSTRACT_LENGTH = 60;          // 適用
export const MAX_DESCRIPTION_LENGTH = 100;
export const MAX_COMMENT_LENGTH = 100;          // 仕入先のコメントはvarchar


export const CHECK_NO = 0x01, CHECK_NAME = 0x02, CHECK_DUP = 0x04, CHECK_STAFF = 0x08;

export const STATE_BOOK = 0, STATE_VISIT = 1, STATE_PAY = 2, STATE_SUM = 3, STATE_NET = 4, STATE_CANCEL = 5;

export const COLOR_ERROR = '#fff0c0', COLOR_OK = '#ffffff';

export const EDIT_UNSELECT = 1, EDIT_SELECT = 2, EDIT_ADD = 3, EDIT_REP = 4, EDIT_DEL = 5, EDIT_INI = 6;

export const TIMEZONE = 'Asia/Tokyo';

export const WORK_OTHER   = 0;		// 他店舗 
export const WORK_REQUEST = 1;		// 勤怠届 
export const WORK_CREATE  = 2;		// 作成 未使用
export const WORK_RELEASE = 3;		// シフト確定
export const WORK_ARRIVE  = 4;		// 出勤 work
export const WORK_LEAVE   = 5;		// 退勤 work


// 予約、勤怠、祝日を区別する bookType
export const BOOK_VISIT   = 1;
export const BOOK_WORK    = 2;
export const BOOK_HOLIDAY = 3;
