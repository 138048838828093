/* ==============================================================================
 旧DBと新DBの変換

 履歴-----------------------------------------
 2019/07/23 新規作成
 
=============================================================================== */

import React, { Component } from 'react';
import { Form,Label,FormGroup,Input,InputGroup,InputGroupText,Col,Button } from 'reactstrap';

import Request from 'superagent';
import {JWToken} from './auth/Login';
// import { AlignLeft,AlignCenter } from './Formatter';	// React Data Grid

/* ===========================
const MAX_DB_LENGTH = 100000;
const MAX_NAME_LENGTH = 40;
const MAX_PHONE_LENGTH = 15;
const MAX_MAIL_LENGTH = 128;
const MAX_ADDRESS_LENGTH = 100;
   =========================== */

var initialRow =  {no: 0, name: "", phone: "", address: "", time_unit: 5, break_time: "01:00", stime: "09:00", etime: "19:00", workday: 0, shift_month: null };
var visit_count = 0;
var req_count = 0;
var goods_count = 0;
var cust_count = 0;
var slip_count = 0;

class Convert extends Component {

    // コンストラクター
    constructor(props) {
        super(props);
        
        this.state = { 
            rowData: Object.assign({},initialRow),
            
            salon_id : 0,
            
            salonList: [],
            
			staffFile: "",
            staffList: [],
            newStaffList:[],
            
			techSumFile: "",
            techSumList:[],
            newTechSumList:[],
            
			techFile: "",
            techList:[],
            newTechList:[],

			supplierFile:"",
			supplierList:[],
			newSupplierList:[],			
			
			goodsFile: "",
            goodsList:[],
            newGoodsList:[],

			customerFile: "",
            customerList: [],
			newCustomerList: [],

			creditFile:"",
			creditList:[],
			newCreditList:[],			
            
			visitFile: "",
            visitList: [],
			newVisitList: [],
            
			techSlipFile: "",
            techSlipList: [],
			newTechSlipList: [],

			goodsSlipFile: "",
            goodsSlipList: [],
			newGoodsSlipList: [],

        };
    }

	// 描画が行われる直前に一度だけ呼ばれる
	componentDidMount() {		// componentWillMount から修正した　確認していない
        this.getSalonList();
		// salon_id = this.state.salonList[0].id;
	}

    // 店舗一覧取得
    getSalonList = () => {
        //ajax通信する
        Request.get("/salon" )
            .query({ token: JWToken.getToken().token})
            .end(function(err, res) {
                if (err) {
                    console.log(err);
					console.error("店舗一覧を取得できませんでした。");
                    this.setState({rowData: initialRow });
					return;
                }
 				this.setState( { salonList: res.body.dbData,
 								salon_id : res.body.dbData[0].id
 				} );
            }.bind(this));
    }

// -----------------------------------------------------------------------------
// 担当者取得
	getStaffList = () => {
		//ajax通信する
		Request.get("/staff" )
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.log(err);
					console.log(res);
					console.error("担当者を取得できませんでした。");
					return;
				}
				this.setState({newStaffList: res.body.dbData });
			}.bind(this));
	}

	// 担当者1人追加
	postStaff = (row) => {
		//ajax通信する
		Request.post('/staff')
			.send({ dbData:row, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.error("担当者を追加できませんでした");
					return;
				}
//				console.log(res.body.dbData[0]);
			});
	}

// -----------------------------------------------------------------------------
// 技術集計取得
	getTechSumList = () => {
		//ajax通信する
		Request.get("/tech_sum" )
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.log(err);
					console.log(res);
					console.error("技術集計を取得できませんでした。");
					return;
				}
				this.setState({newTechSumList: res.body.dbData });
			}.bind(this));
	}

	// 技術集計
	postTechSum = (row) => {
		//ajax通信する
		Request.post('/tech_sum')
			.send({ dbData:row, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.error("技術集計を追加できませんでした");
					return;
				}
//				console.log(res.body.dbData[0]);
			});
	}

// -----------------------------------------------------------------------------
// 技術取得
	getTechList = () => {
		//ajax通信する
		Request.get("/tech" )
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.log(err);
					console.log(res);
					console.error("技術を取得できませんでした。");
					return;
				}
				console.log("getTechList length = ", res.body.dbData.length)
				this.setState({newTechList: res.body.dbData });
			}.bind(this));
	}

	// 技術
	postTech = (row) => {
		//ajax通信する
		Request.post('/tech')
			.send({ dbData:row, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.log(row);
					console.error("技術を追加できませんでした");
					return;
				}
//				console.log(res.body.dbData[0]);
			});
	}


// -----------------------------------------------------------------------------
// 仕入先取得
	getSupplierList = () => {
		//ajax通信する
		Request.get("/supplier" )
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.log(err);
					console.log(res);
					console.error("仕入先を取得できませんでした。");
					return;
				}
				this.setState({newSupplierList: res.body.dbData });
			}.bind(this));
	}

	// 仕入先
	postSupplier = (row) => {
		//ajax通信する
		Request.post('/supplier')
			.send({ dbData:row, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.log(row);
					console.error("仕入先を追加できませんでした");
					return;
				}
//				console.log(res.body.dbData[0]);
			});
	}

// -----------------------------------------------------------------------------
// 商品取得
	getGoodsList = () => {
		//ajax通信する
		Request.get("/goods" )
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.log(err);
					console.log(res);
					console.error("商品を取得できませんでした。");
					return;
				}
				this.setState({newGoodsList: res.body.dbData });
			}.bind(this));
	}

	// 商品
	postGoods = (row) => {
		//ajax通信する
		console.log("postGoods + ",++req_count);
		Request.post('/goods')
			.send({ dbData:row, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.log(row);
					console.error("商品を追加できませんでした");
					return;
				}
				console.log("postGoods - ",--req_count);
//				console.log(res.body.dbData[0]);
			});
	}


// -----------------------------------------------------------------------------
// 顧客取得
	getCustomerList = () => {
		//ajax通信する
		Request.get("/customer" )
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.log(err);
					console.log(res);
					console.error("顧客を取得できませんでした。");
					return;
				}
				this.setState({newCustomerList: res.body.dbData });
				console.log("Customer DB. Read End");
			}.bind(this));
	}

	// 顧客追加
	postCustomer = (row) => {
		console.log("postCustomer + ",++req_count);
		//ajax通信する
		Request.post('/customer')
			.send({ dbData:row, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.log(row);
					console.error("顧客を追加できませんでした");
					return;
				}
				console.log("postCustomer - ",--req_count);
//				console.log(res.body.dbData[0]);
			});
	}

	// 顧客アップデート
	putCustomer = (row) => {
		//ajax通信する
		Request.put('/customer')
			.send({ dbData:row, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.log(row);
					console.error("顧客を追加できませんでした");
					return;
				}
//				console.log(res.body.dbData[0]);
			});
	}


// -----------------------------------------------------------------------------
// クレジット代行取得
	getCreditList = () => {
		//ajax通信する
		Request.get("/credit" )
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.log(err);
					console.log(res);
					console.error("クレジットを取得できませんでした。");
					return;
				}
				this.setState({newCreditList: res.body.dbData });
			}.bind(this));
	}

	// クレジット代行
	postCredit = (row) => {
		//ajax通信する
		Request.post('/credit')
			.send({ dbData:row, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.log(row);
					console.error("クレジット代行を追加できませんでした");
					return;
				}
//				console.log(res.body.dbData[0]);
			});
	}

// -----------------------------------------------------------------------------
// 会計情報取得
	getVisitList = () => {
		// 予約データーの読込
		Request.get("/visit/edit/" + this.state.salon_id + "/2015-01-01_2049-12-31" )
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.error("予約データを取得できませんでした。");
					console.log(res);
					return;
				}
				this.setState({newVisitList: res.body.dbData });
				console.log("会計情報 DB Get End.", res.body.dbData.length);
			}.bind(this));
	}

	// 顧客
	postVisit = (row) => {
		//ajax通信する
		Request.post('/visit')
			.send({ dbData:row, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
//					console.log(row);
					console.error("予約データを追加できませんでした");
					return;
				}
//				console.log(res.body.dbData[0]);
			});
	}



/* ================================================================= 
	画面
	
   ================================================================= */

	// 店舗を変更した
	onSalonChange = (event) =>  {
 		console.log('onSalonChange',event.target.value);
		
		this.setState({salon_id : Number(event.target.value) });
		
	}

    onStaffChange = (event) => {
// console.log('Staff', event.target.files );
		let file = event.target.files[0];
		
		this.setState({staffFile: file});
		
    }

    onTechSumChange = (event) => {
// console.log('TechSum', event.target.files );
		let file = event.target.files[0];
		
		this.setState({techSumFile: file});
    }

    onTechChange = (event) => {
// console.log('Tech', event.target.files );
		let file = event.target.files[0];
		
		this.setState({techFile: file});
    }

    onSupplierChange = (event) => {
// console.log('Supplier', event.target.files );
		let file = event.target.files[0];
		
		this.setState({supplierFile: file});
    }

    onGoodsChange = (event) => {
// console.log('Goods', event.target.files );
		let file = event.target.files[0];
		
		this.setState({goodsFile: file});
    }

    onCustChange = (event) => {
// console.log('Customer', event.target.files );
		let file = event.target.files[0];
		
		this.setState({customerFile: file});
    }

    onVisitChange = (event) => {
// console.log('Visit', event.target.files );
		let file = event.target.files[0];
		
		this.setState({visitFile: file});
    }

	onTechSlipChange = (event) => {
// console.log('TechSlip', event.target.files );
		let file = event.target.files[0];
		
		this.setState({techSlipFile: file});
	}

	onGoodsSlipChange = (event) => {
// console.log('GoodsSlip', event.target.files );
		let file = event.target.files[0];
		
		this.setState({goodsSlipFile: file});
	}

	onCreditChange = (event) => {
// console.log('Credit', event.target.files );
		let file = event.target.files[0];
		
		this.setState({creditFile: file});
	}

// -----------------------------------------------------------------------------
// 新しい担当者を作成する
	createNewStaff = ( event ) => {
//		console.log   (this.state.staffFile);
		this.csv2Staff(this.state.staffFile);
	}
	
	// 新しい担当者をDBに登録する
	postNewStaff = ( event ) => {
		let staff = this.state.staffList;
		staff.forEach(function( value ) {
//			console.log(value);
			this.postStaff(value);
		}.bind(this));
	}

	// 
	getStaff = () => {
		this.getStaffList();
		this.csv2Staff(this.state.staffFile);
	}
	
// -----------------------------------------------------------------------------
// 新しい技術集計を作成する
	createNewTechSum = ( event ) => {
//		console.log   (this.state.techSumFile);
		this.csv2TechSum(this.state.techSumFile);
	}

	// 新しい担当者をDBに登録する
	postNewTechSum = ( event ) => {
		let techSum = this.state.techSumList;
		techSum.forEach(function( value ) {
			console.log(value);
			this.postTechSum(value);
		}.bind(this));
	}

	getTechSum = () => {
		this.getTechSumList();
//		this.csv2TechSum(this.state.techSumFile);
	}
	
	
// -----------------------------------------------------------------------------
// 新しい技術を作成する
	createNewTech = ( event ) => {
//		console.log   (this.state.techFile);
		this.csv2Tech(this.state.techFile);
	}

	// 新しい担当者をDBに登録する
	postNewTech = ( event ) => {
		let tech = this.state.techList;
		tech.forEach(function( value ) {
//			console.log(value);
			this.postTech(value);
		}.bind(this));
	}

	// createNewTechの前処理
	getTech = () => {
		this.getTechSumList();						// 最新の技術集計を読み込む
		this.csv2Tech(this.state.techFile);
	}
	

// -----------------------------------------------------------------------------
// 新しい仕入先を作成する
	createNewSupplier = ( event ) => {
//		console.log   (this.state.goodsFile);
		this.csv2Supplier(this.state.supplierFile);
	}

	// 新しい仕入先をDBに登録する
	postNewSupplier = ( event ) => {
		let supplier = this.state.supplierList;
		supplier.forEach(function( value ) {
//			console.log(value);
			this.postSupplier(value);
		}.bind(this));
	}

	// 読み込みの準備
	getSupplier = () => {
		this.getSupplierList();						// 最新の仕入先を読み込む
		this.csv2Supplier(this.state.supplierFile);
	}
	
	
	
// -----------------------------------------------------------------------------
// 新しいクレジット代行を作成する
	createNewCredit = ( event ) => {
//		console.log   (this.state.creditFile);
		this.csv2Credit(this.state.creditFile);
	}

	// 新しい仕入先をDBに登録する
	postNewCredit = ( event ) => {
		let credit = this.state.creditList;
		credit.forEach(function( value ) {
//			console.log(value);
			this.postCredit(value);
		}.bind(this));
	}

	// 読み込みの準備
	getCredit = () => {
		this.getCreditList();						// 最新のクレジット代行を読み込む
		this.csv2Credit(this.state.creditFile);		// 旧クレジット代行を読み込む
	}
	
	
	
// -----------------------------------------------------------------------------
// 新しい商品を作成する
	createNewGoods = ( event ) => {
//		console.log   (this.state.goodsFile);
		this.csv2Goods(this.state.goodsFile);
		goods_count = 0;
	}

	// 新しい商品をDBに登録する
	postNewGoods = ( event ) => {
		req_count = 0;
		let goods = this.state.goodsList;
		
		for ( ; goods_count < goods.length ;  ) {
			this.postGoods(goods[goods_count++]);
			if ( req_count > 100) break;
		}
		console.log( "REST " + goods_count + '/' + req_count );
	}

	// 読み込みの準備
	getGoods = () => {
//		this.getSupplierList();						// 最新の仕入先を読み込む
		this.csv2Goods(this.state.goodsFile);
	}
	
	
// -----------------------------------------------------------------------------
// 新しい顧客を作成する
	createNewCustomer = ( event ) => {
//		console.log   (this.state.staffFile);
		this.csv2Staff(this.state.staffFile);
//		console.log   (this.state.customerFile);
		this.csv2Customer(this.state.custmerFile);
	}

	// 新しい商品をDBに登録する
	postNewCustomer = () => {
		req_count = 0;
		let customer = this.state.customerList;

		for ( ; cust_count < customer.length ;  ) {
			this.postCustomer(customer[cust_count++]);
			if ( req_count > 500) break;
		}
		console.log( "REST " + cust_count + '/' + req_count );

	}

	getCustomer = () => {
		this.getCustomerList();						// 最新の仕入先を読み込む
		this.csv2Customer(this.state.customerFile);
	}
	

// -----------------------------------------------------------------------------
// 会計情報を作成する
	createNewVisit = ( event ) => {
//		console.log   (this.state.visitFile);
		this.csv2Visit(this.state.visitFile);
	}

	// 新しい会計情報をDBに登録する
	postNewVisit = ( event ) => {
		req_count = 0;
		let visit = this.state.visitList;
		for (  ; visit_count < visit.length ;  ) {
			let value = visit[visit_count++];
//		visit.forEach(function( value ) {
//			console.log(value);
			//ajax通信する
			Request.post('/visit')
				.send({ dbData:value, token: JWToken.getToken().token } )
// eslint-disable-next-line
				.end(function(err, res){
					if ( err ) {
						console.log(err);
						console.log(res);
						console.log(value);
						console.error("予約データを追加できませんでした");
						return;
					}
					req_count--;
					console.log('- ' + req_count);			// Requestを遅らせたい
				});
			if ( ++req_count > 1000 ) break;
			console.log('+ ' + req_count);			// Requestを遅らせたい
//		});
		}
		console.log( visit_count + '/' + visit.length )		
		console.log('顧客登録 DataBase INSERT Req');
	}

	getVisit = () => {
		this.getStaffList();
		this.csv2Staff(this.state.staffFile);
		this.getCustomerList();
		this.csv2Customer(this.state.customerFile);
	}


// -----------------------------------------------------------------------------
// 技術会計を作成する
	createNewTechSlip = ( event ) => {
//		console.log   (this.state.techSlipFile);
		this.csv2TechSlip(this.state.techSlipFile);
		slip_count = 0;
	}

	// 新しい会計情報をDBに登録する
	postNewTechSlip = ( event ) => {
		req_count = 0;
		let slip = this.state.newTechSlipList;
		for (  ; slip_count < slip.length ; slip_count+=100) {
			let slip_end = slip_count + 100 < slip.length ?   slip_count + 100 : slip.length;
			let value = slip.slice(slip_count,slip_end);
//			console.log(value);
			//ajax通信する
			Request.post('/tech_slip')
				.send({ dbData:value, token: JWToken.getToken().token } )
// eslint-disable-next-line
				.end(function(err, res){
					if ( err ) {
						console.log(err);
						console.log(res);
						console.log(value);
						console.error("技術会計を追加できませんでした");
						return;
					}
					req_count--;
					console.log('- ' + req_count);			// Requestを遅らせたい
				});
			req_count++;
			if ( req_count > 100 ) break;
			console.log('+ ' + req_count);			// Requestを遅らせたい
		}
		console.log( slip_count + '/' + slip.length )		
		console.log('顧客登録 DataBase INSERT Req');
	}

	getTechSlip = () => {
		this.getStaffList();
		this.csv2Staff(this.state.staffFile);
		this.getTechList();
		this.csv2TechOld(this.state.techFile);
		this.getVisitList();
console.log(this.state.newStaffList,this.state.staffList);
console.log(this.state.newTechList, this.state.techList);
console.log(this.state.newVisitList);
	}

// -----------------------------------------------------------------------------
// 商品会計を作成する
	createNewGoodsSlip = ( event ) => {
//		console.log   (this.state.goodsSlipFile);
		this.csv2GoodsSlip(this.state.goodsSlipFile);
		slip_count = 0;
	}

	// 新しい会計情報をDBに登録する
	postNewGoodsSlip = ( event ) => {
		req_count = 0;
		let slip = this.state.newGoodsSlipList;
		for (  ; slip_count < slip.length ; slip_count+=100) {
			let slip_end = slip_count + 100 < slip.length ?   slip_count + 100 : slip.length;
			let value = slip.slice(slip_count,slip_end);
//			console.log(value);
			//ajax通信する
			Request.post('/goods_slip')
				.send({ dbData:value, token: JWToken.getToken().token } )
// eslint-disable-next-line
				.end((err, res) => {
					if ( err ) {
						console.log(err);
						console.log(res);
						console.log(value);
						console.error("商品会計を追加できませんでした");
						return;
					}
					req_count--;
					console.log('- ' + req_count);			// Requestを遅らせたい
				});
			req_count++;
			if ( req_count > 100 ) break;
			console.log('+ ' + req_count);			// Requestを遅らせたい
		}
		console.log( slip_count + '/' + slip.length )		
		console.log('顧客登録 DataBase INSERT Req');
	}

	getGoodsSlip = () => {
		this.getGoodsList();
		this.csv2GoodsOld(this.state.goodsFile);
		this.getVisitList();
	}


// *****************************************************************************
// 担当者.CSV ファイルを staffList オブジェクトに変換する
// *****************************************************************************
	csv2Staff = (file) => {
		let reader = new FileReader();
		reader.onload = (e) => {
			let content = e.target.result; // ファイルの中身が取れます。

            let rows = content.split('\n');
            var data = [];
            for (var i = 1; i < rows.length; i++) {		// 最初の１行はタイトル行
                let col = rows[i].split(',');

				let staff = {};
				staff.id          = Number(col[0]);		/* キー         */
			    staff.tenant_id   = JWToken.getToken().tenant_id;	/* テナントID   */
			    staff.no          = Number(col[1]);		/* 社員番号     */
			    staff.name        = col[3];				/* 氏名         */
			    staff.mail        = "";					/* メールアドレス */
			    staff.phone       = "";					/* 電話番号     */
			    staff.profile     = "";					/* プロフィール */
			    staff.avatar      = "";         		/* 写真         */          
			    staff.workday     = 0;					/* 出勤日(週) 土:0x64、金:0x32、木:0x16、水:0x08、火:0x04、月:0x02、日:0x01  */
			    staff.password    = "";					/* パスワード   */
			    staff.admin       = false;

				data.push(staff);
            }

			console.log(data);
			this.setState({ staffList: data});
			
			console.log("担当者 file read end.");
		};
		
		reader.readAsText(this.state.staffFile);
	}

// *****************************************************************************
// 技術集計.CSV ファイルを staffList オブジェクトに変換する
// *****************************************************************************
	csv2TechSum = (file) => {
		let reader = new FileReader();
		reader.onload = (e) => {
			let content = e.target.result; // ファイルの中身が取れます。

            let rows = content.split('\n');
            var data = [];
            for (var i = 1; i < rows.length; i++) {		// 最初の１行はタイトル行
                let col = rows[i].split(',');

				let techSum = {};
				techSum.id          = Number(col[0]);		/* キー         */
			    techSum.tenant_id   = JWToken.getToken().tenant_id;		/* テナントID   */
			    techSum.no          = Number(col[1]);		/* 技術集計番号 */
			    techSum.name        = col[2];		/* 氏名         */

				data.push(techSum);
            }

			console.log(data);
			this.setState({ techSumList: data});
			
			console.log("技術集計 file read end.");
		};
		
		reader.readAsText(this.state.techSumFile);
	}


// *****************************************************************************
// 技術.CSV ファイルを staffList オブジェクトに変換する
// *****************************************************************************
	csv2Tech = (file) => {
		console.log(this.state.newTechSumList);
		let reader = new FileReader();
		reader.onload = (e) => {
			let content = e.target.result; // ファイルの中身が取れます

            let rows = content.split('\n');
            var data = [];
            for (var i = 1; i < rows.length; i++) {		// 最初の１行はタイトル行
                let col = rows[i].split(',');

				let tech = {};
				tech.id          = col[0];			/* キー         */
			    tech.tenant_id   = JWToken.getToken().tenant_id;	/* テナントID   */
			    tech.no          = Number(col[1]);	/* 技術番号		*/
			    tech.name        = col[3];			/* 技術名       */
			    tech.nickname    = col[4];			/* 技術略称     */
			    tech.price       = Number(col[5]);	/* 単価         */
			    tech.period      = Number(col[7]);	/* 施術時間     */
			    tech.sum_id      = Number(col[2]);	/* 技術集計番号 */
			    
			    if ( Number(col[1]) >= 1000000 ) continue;

				let id = Number(col[2]);
				let techSum    = this.state.techSumList.find((t) => t.id === id );
				if ( techSum === undefined ) {
					console.log("旧技術集計が見つからない", tech,col[2]);
					console.log(this.state.techSumList);
					continue;
				}
				let	newTechSum = this.state.newTechSumList.find((t) => t.no === techSum.no);
				if ( newTechSum === undefined ) {
					console.log("技術集計が見つからない", tech,techSum.no);
					continue;
				}
				tech.sum_id   = newTechSum.id;
				this.postTech(tech);
				data.push(tech);
            }

			console.log(data);
			this.setState({ techList: data});
			
			console.log("技術 file read end.");
		};
		
		reader.readAsText(this.state.techFile);
	}


// *****************************************************************************
// 仕入先.CSV ファイルを staffList オブジェクトに変換する
// *****************************************************************************
	csv2Supplier = (file) => {
		let reader = new FileReader();
		reader.onload = (e) => {
			let content = e.target.result; // ファイルの中身が取れます。

            let rows = content.split('\n');
        console.log(rows);
            var data = [];
            for (var i = 1; i < rows.length; i++) {		// 最初の１行はタイトル行
                let col = rows[i].split(',');

				let supplier = {};
				supplier.id         = col[0];		/* キー         */
			    supplier.tenant_id  = JWToken.getToken().tenant_id;	/* テナントID   */
			    supplier.no         = col[1];		/* 技術集計番号 */
			    supplier.name       = col[2];		/* 氏名         */
			    supplier.phone		= "0" + col[8] +"-"+ col[9] +"-"+ ('0000' + col[10]).slice( -4 );
				supplier.address	= col[4] + "-" + col[5] + " " + col[6] + col[7];
				supplier.comment    = col[3];

				data.push(supplier);
            }

			console.log(data);
			this.setState({ supplierList: data});
			
			console.log("仕入先 file read end.");
		};
		
		reader.readAsText(this.state.supplierFile);
	}


// *****************************************************************************
// 商品.CSV ファイルを goodsList オブジェクトに変換する
// *****************************************************************************
	csv2Goods = (file) => {
		console.log(this.state.newSupplierList);
		let reader = new FileReader();
		reader.onload = (e) => {
			let content = e.target.result; // ファイルの中身が取れます。

            let rows = content.split('\n');
            var data = [];
            for (var i = 1; i < rows.length; i++) {		// 最初の１行はタイトル行
                let col = rows[i].split(',');

				let goods = {};
				goods.id        = col[0];			/* キー         */
			    goods.tenant_id = JWToken.getToken().tenant_id;	/* テナントID   */
			    goods.no        = Number(col[1]);	/* 技術番号		*/
			    goods.name      = col[2];			/* 氏名         */
			    goods.price     = Number(col[3]);	/* 単価         */
			    goods.cost      = Number(col[4]);	/* 施術時間     */
			    goods.stock		= 0;				/* 在庫数		*/
			    goods.just		= 0;				/* 適正在庫数	*/
			    
			    // 仕入先を見つける
			    // 旧仕入先のidから番号を取得する
				let oldSupplier = this.state.supplierList.find((s) => Number(s.id) === Number(col[7]));
				if ( oldSupplier === undefined ) {
					console.log("Supplierが見つからない", goods,col[7]);
					console.log(this.state.supplierList);
					goods.supplier_id   = 0;
				} else {
					// 番号から新仕入先のidを取得する
					let no = Number(oldSupplier.no);
					let	newSupplier = this.state.newSupplierList.find((s) => s.no === no);
					if ( newSupplier === undefined ) {
						console.log("Supplierが見つからない", goods,oldSupplier);
						goods.supplier_id   = 0;
					} else {
						goods.supplier_id   = newSupplier.id;
					}
				}

//				this.postGoods(goods);
				data.push(goods);
            }

			console.log(data);
			this.setState({ goodsList: data});
			
			console.log("商品 file read end.");
		};
		
		reader.readAsText(this.state.goodsFile);
	}



// *****************************************************************************
// 顧客.CSV ファイルを customerList オブジェクトに変換する
// *****************************************************************************
	csv2Customer = (file) => {

		let reader = new FileReader();
		reader.onload = (e) => {
			let content = e.target.result; // ファイルの中身が取れます。

            let rows = content.split('\n');
            var data = [];
            for (var i = 1; i < rows.length; i++) {		// 最初の１行はタイトル行
                let col = rows[i].split(',');

				if ( col[2] === "" ) continue;

				let cust = {};

				cust.id     	= Number(col[0]);	/* キー         */
				cust.tenant_id  = JWToken.getToken().tenant_id; 	/* テナントID   */
				cust.no         = Number(col[1]);	/* 顧客番号     */
				cust.name       = col[2];			/* 顧客名       */
				cust.kana       = col[3];	    	/* カナ         */
				cust.mail       = col[11];			/* メール       */
				
				// 携帯番号
				if ( col[16] !== "") {
					cust.cellula    = "0" + col[16] +"-"+ ('0000' + col[17]).slice( -4 ) +"-"+ ('0000' + col[18]).slice( -4 );	
				} else {
					cust.cellula    = "";
				}
				
				// 電話番号
				if ( col[13] !== "") {
					cust.phone      = "0" + col[13] +"-"+ col[14] +"-"+ ('0000' + col[15]).slice( -4 );	
				} else {
					cust.phone    = "";
				}
				
				// 郵便番号
				if ( col[5] !== "") {
					cust.zipcode    = col[5]+ '-' +  ( '0000' + col[6]).slice( -4 );	/* 郵便番号	 */
	            } else {
					cust.zipcode    = "";
				}

				cust.address1   = "";				/* 住所1  郵便番号から取得する */
				
				cust.address2   = col[7] + col[8];	/* 住所2        */
				cust.birthday	= col[9]  === "" ? null : col[9];	/* 誕生日	    */
				if ( col[12] === "Y") {				/* 性別			*/
					cust.gender	= true;				/*   女性		*/
				} else {
					cust.gender = false;			/*   男性		*/
				}
				cust.comment    = col[23];			/* コメント 別にすると高速になるのか？ */
				cust.staff_id   = col[20];			/* 担当者ID     */
				cust.kubun		= Number(col[24]);	/* 顧客区分		*/
				cust.point      = 0;				/* 売上の数パーセントをポイントにする */
				cust.password   = "";				/* パスワード */

				// 担当者IDを担当者番号に変換
//				console.log(this.state.staffList,col[20]);		// staffList
				let oldStaff = this.state.staffList.find((s) => s.id === Number(col[20]));
				if ( oldStaff === undefined ) {
					console.log("Staffが見つからない", cust,col[20]);
					console.log(this.state.staffList);
					continue;
				}
//				console.log(this.state.newStaffList, oldStaff);	// newStaffList
				let no = Number(oldStaff.no);
				let	newStaff = this.state.newStaffList.find((s) => s.no === no);
				if ( newStaff === undefined ) {
					console.log("Staffが見つからない", cust,oldStaff);
					continue;
				}
				cust.staff_id   = newStaff.id;

//				this.postCustomer(cust);
				data.push(cust);
            }

			console.log(data);
			this.setState({ customerList: data});

			console.log("顧客 file read end.");

		};
		
		reader.readAsText(this.state.customerFile);
	}


// *****************************************************************************
// カード.CSV ファイルを creditList オブジェクトに変換する
// *****************************************************************************
	csv2Credit = (file) => {
		let reader = new FileReader();
		reader.onload = (e) => {
			let content = e.target.result; // ファイルの中身が取れます。

            let rows = content.split('\n');
        console.log(rows);
            var data = [];
            for (var i = 1; i < rows.length; i++) {		// 最初の１行はタイトル行
                let col = rows[i].split(',');

				let credit = {};
				credit.id			= Number(col[0]);		/* キー         */
			    credit.tenant_id	= JWToken.getToken().tenant_id;	/* テナントID   */
			    credit.no			= Number(col[1]);		/* カード番号 */
			    credit.name			= col[2];				/* 名称       */

				data.push(credit);
            }

			console.log(data);
			this.setState({ creditList: data});
			
			console.log("クレジット file read end.");
		};
		
		reader.readAsText(this.state.creditFile);
	}


// *****************************************************************************
// 会計情報.CSV ファイルを visit オブジェクトに変換する
// *****************************************************************************

	csv2Visit = (file) => {

		console.log(this.state.staffList);			// 旧担当者List
		console.log(this.state.newStaffList);		// 担当者List
		console.log(this.state.customerList);		// 旧顧客List
		console.log(this.state.newCustomerList);	// 顧客List

		let reader = new FileReader();
		reader.onload = (e) => {
			let content = e.target.result; // ファイルの中身が取れます。

            let rows = content.split('\n');
            var data = [];
            for (var i = 1; i < rows.length; i++) {		// 最初の１行はタイトル行
                let col = rows[i].split(',');
//				if ( Number(col[8]) !== 4 ) continue;		// 状態が会計になっていない場合

// if ( col[0] < "2019/01/01") continue;		// テスト中は今年だけとする
// if ( col[0] < "2018/01/01" || col[0] > "2018/12/31" ) continue;		// テスト中は2018年だけとする
// if ( col[0] < "2017/01/01" || col[0] > "2017/12/31" ) continue;		// テスト中は2017年だけとする
// if ( col[0] < "2016/01/01" || col[0] > "2016/12/31" ) continue;		// テスト中は2016年だけとする
// if ( col[0] < "2016/10/29" || col[0] > "2016/10/29" ) continue;		// テスト中は2016年だけとする

				let visit = {};

				visit.id     	 = Number(col[3]);					/* キー         */
				visit.tenant_id  = JWToken.getToken().tenant_id;	/* テナントID   */
				visit.salon_id   = this.state.salon_id;				/* 店舗ID   */
				visit.receipt_no = Number(col[3]);					/* 伝票番号     */
				visit.regist_date= col[0].replace(/\//g, '-');		/* 予約の登録日 */
				visit.visit_date = col[0].replace(/\//g, '-');		/* 来店日時	    */
				visit.visit_time = col[2];							/* 来店時間     */
				visit.duration	 = 45;								/* 施術時間 Default 30分 */

				switch ( Number(col[8]) ) {
					case 1:
						visit.visit_state= 0;        		/* 0:予約、1:来店、2:会計 */
						break;				
					case 2:
						visit.visit_state= 1;        		/* 0:予約、1:来店、2:会計 */
						break;				
					case 4:
						visit.visit_state= 2;        		/* 0:予約、1:来店、2:会計 */
						break;
					default:
						continue;
				}

				// 顧客IDを顧客番号に変換
//				console.log(this.state.customerList,col[1]);			// customerList
				let oldCustomer = this.state.customerList.find((c) => c.id === Number(col[4]));
				if ( oldCustomer === undefined ) {
//					console.log("旧Customer 未登録者の来店", visit,col[4]);
					visit.customer_id = 0;			/* 顧客IDを省略	*/
				} else {
//					console.log(oldCustomer);	// newCustomerList
					let no = Number(oldCustomer.no);
					let	newCustomer = this.state.newCustomerList.find((s) => s.no === no);
					if ( newCustomer === undefined ) {
						console.log("新Customerが見つからない", visit,oldCustomer);
						continue;
					}
					visit.customer_id= newCustomer.id;	/* 顧客ID       */
				}

				visit.name       = col[7];			/* 顧客名       */

				// 担当者IDを担当者番号に変換
//				console.log(this.state.staffList,col[20]);		// staffList
				let oldStaff = this.state.staffList.find((s) => s.id === Number(col[1]));
				if ( oldStaff === undefined ) {
					console.log("Staffが見つからない", visit,col[1]);
					continue;
				}
//				console.log(this.state.newStaffList, oldStaff);	// newStaffList
				let no = Number(oldStaff.no);
				let	newStaff = this.state.newStaffList.find((s) => s.no === no);
				if ( newStaff === undefined ) {
					console.log("Staffが見つからない", visit,oldStaff);
					continue;
				}
				visit.staff_id   = newStaff.id;
				visit.memo  	 = col[14];				/* メモ			*/
				
				visit.add_point  = 0;					/* 追加ポイント */
				visit.use_point  = Number(col[15]);		/* 使用ポイント */

				// 支払い方法をクレジット代行番号に変換
				let oldCredit = this.state.creditList.find((c) => c.id === Number(col[13]));
				if ( oldCredit === undefined ) {
					console.log("支払い方法が見つからない", visit,col[13]);
					continue;
				}
//				console.log(this.state.newCreditList, oldCredit);	// newCreditList
				let creditNo = Number(oldCredit.no);
				if ( creditNo === 1 ) {
					visit.credit_id = 0;
				} else {
					let	newCredit = this.state.newCreditList.find((c) => c.no === creditNo);
					if ( newCredit === undefined ) {
						console.log("クレジット代行が見つからない", visit,oldCredit);
						continue;
					}
					visit.credit_id   = newCredit.id;
				}
				
				visit.pay        = Number(col[12]);		/* お預かり金額 */

//				this.postVisit(visit);
				data.push(visit);
            }

			console.log(data);
			this.setState({ visitList: data});

			console.log("会計情報 file read end.");
		};
		console.log(this.state.visitFile);
		reader.readAsText(this.state.visitFile);
	}

// *****************************************************************************
// 技術.CSV ファイルを staffList オブジェクトに変換する
// *****************************************************************************
	csv2TechOld = (file) => {
		let reader = new FileReader();
		reader.onload = (e) => {
			let content = e.target.result; // ファイルの中身が取れます

            let rows = content.split('\n');
            var data = [];
            for (var i = 1; i < rows.length; i++) {		// 最初の１行はタイトル行
                let col = rows[i].split(',');

				let tech = {};
				tech.id          = Number(col[0]);	/* キー         */
			    tech.tenant_id   = JWToken.getToken().tenant_id;	/* テナントID   */
			    tech.no          = Number(col[1]);	/* 技術番号		*/
			    tech.name        = col[3];			/* 氏名         */
			    tech.price       = Number(col[5]);	/* 単価         */
			    tech.period      = Number(col[6]);	/* 施術時間     */
			    tech.sum_id      = Number(col[2]);	/* 技術集計番号 */
				tech.sum_id   = 0;
				data.push(tech);
            }

			console.log(data);
			this.setState({ techList: data});
			
			console.log("旧技術 file read end.");
		};
		
		reader.readAsText(this.state.techFile);
	}


// *****************************************************************************
// 技術会計.CSV ファイルを visit オブジェクトに変換する
// *****************************************************************************
	csv2TechSlip= (file) => {

		console.log(this.state.staffList);			// 旧担当者List
		console.log(this.state.newStaffList);		// 担当者List
		console.log(this.state.techList);			// 旧技術List
		console.log(this.state.newTechList);		// 技術List
		console.log(this.state.newVisitList);		// 会計情報List

		let reader = new FileReader();
		reader.onload = (e) => {
			let content = e.target.result;	// ファイルの中身が取れます。

            let rows = content.split('\n');
            var data = [];								// アウトプット
            for (var i = 1; i < rows.length; i++) {		// 最初の１行はタイトル行
                let col = rows[i].split(',');

				let slip = {};

				slip.tenant_id  = JWToken.getToken().tenant_id;        	/* テナントID   */

				// 会計番号からVisit.idに変換
//				console.log(this.state.newVisitList,col[0]);			// visitList
				let newVisit = this.state.newVisitList.find((c) => c.receipt_no === Number(col[0]));
//				console.log('Visit',newVisit);	// newVisitList
				if ( newVisit === undefined ) {
					console.log("newVisit 未登録", this.state.newVisitList,col[0]);
					continue;
				} else {
					slip.visit_id = newVisit.id;	/* 顧客ID       */
				}

				// 技術IDを技術番号に変換
//				console.log(this.state.techList,col[2]);		// techList
				let oldTech = this.state.techList.find((t) => t.id === Number(col[2]));
//				console.log('旧技術',oldTech,col[2]);
				if ( oldTech === undefined ) {
					console.log("Tech IDが見つからない", this.state.techList,col[2]);
					continue;
				}
//				console.log(this.state.newTechList, oldTech);	// newTechList
				let no = Number(oldTech.no);
				let	newTech = this.state.newTechList.find((t) => t.no === no);
				if ( newTech === undefined ) {
					console.log("Tech No.が見つからない", this.state.newTechList,no,oldTech);
					continue;
				}
				slip.tech_id   = newTech.id;

				// 担当者IDを担当者番号に変換
//				console.log(this.state.staffList,col[３]);		// staffList
				let oldStaff = this.state.staffList.find((s) => s.id === Number(col[3]));
				if ( oldStaff === undefined ) {
					console.log("Staffが見つからない", this.state.staffList,col[3]);
					continue;
				}
//				console.log(this.state.newStaffList, oldStaff);	// newStaffList
				no = Number(oldStaff.no);
				let	newStaff = this.state.newStaffList.find((s) => s.no === no);
				if ( newStaff === undefined ) {
					console.log("Staffが見つからない", newStaff,oldStaff);
					continue;
				}
				slip.staff_id   = newStaff.id;

				slip.price		= Number(col[5]);	/* 価格     */

				data.push(slip);
            }

			console.log(data);
			this.setState({ newTechSlipList: data});

			console.log("Tech Slip file read end.");
		};
		console.log(file);
		reader.readAsText(this.state.techSlipFile);
	}

// *****************************************************************************
// 商品.CSV ファイルを goodsList オブジェクトに変換する
// *****************************************************************************
	csv2GoodsOld = (file) => {
		let reader = new FileReader();
		reader.onload = (e) => {
			let content = e.target.result; // ファイルの中身が取れます

            let rows = content.split('\n');
            var data = [];
            for (var i = 1; i < rows.length; i++) {		// 最初の１行はタイトル行
                let col = rows[i].split(',');

				let goods = {};
				goods.id          = Number(col[0]);	/* キー         */
			    goods.tenant_id   = JWToken.getToken().tenant_id;	/* テナントID   */
			    goods.no          = Number(col[1]);	/* 商品番号		*/
			    goods.name        = col[3];			/* 氏名         */
			    goods.price       = Number(col[5]);	/* 単価         */
			    goods.cost        = 0;				/* コスト		*/
				goods.just		  = 0;				/* 在庫数		*/
				goods.supplier_id = 0;				/* 仕入先		*/
				data.push(goods);
            }

			console.log(data);
			this.setState({ goodsList: data});
			
			console.log("旧商品 file read end.");
		};
		
		reader.readAsText(this.state.goodsFile);
	}


// *****************************************************************************
// 商品会計.CSV ファイルを visit オブジェクトに変換する
// *****************************************************************************
	csv2GoodsSlip= (file) => {

		console.log(this.state.goodsList);			// 旧商品List
		console.log(this.state.newGoodsList);		// 商品List
		console.log(this.state.newVisitList);		// 会計情報List

		let reader = new FileReader();
		reader.onload = (e) => {
			let content = e.target.result;	// ファイルの中身が取れます。

            let rows = content.split('\n');
            var data = [];								// アウトプット
            for (var i = 1; i < rows.length; i++) {		// 最初の１行はタイトル行
                let col = rows[i].split(',');

				let slip = {};

				slip.tenant_id  = JWToken.getToken().tenant_id;        	/* テナントID   */

				// 会計番号からVisit.idに変換
//				console.log(this.state.newVisitList,col[0]);			// visitList
				let newVisit = this.state.newVisitList.find((c) => c.receipt_no === Number(col[0]));
//				console.log('Visit',newVisit);	// newVisitList
				if ( newVisit === undefined ) {
					console.log("newVisit 未登録", this.state.newVisitList,col[0]);
					continue;
				} else {
					slip.visit_id = newVisit.id;	/* 顧客ID       */
				}

				// 商品IDを商品番号に変換
//				console.log(this.state.goodsList,col[2]);		// goodsList
				let oldGoods = this.state.goodsList.find((t) => t.id === Number(col[2]));
//				console.log('旧商品',oldGoods,col[2]);
				if ( oldGoods === undefined ) {
					console.log("Goods IDが見つからない", this.state.goodsList,col[2]);
					continue;
				}
//				console.log(this.state.newGoodsList, oldGoods);	// newGoodsList
				let no = Number(oldGoods.no);
				let	newGoods = this.state.newGoodsList.find((t) => t.no === no);
				if ( newGoods === undefined ) {
					console.log("Goods No.が見つからない", this.state.newGoodsList,no);
					continue;
				}
				slip.goods_id   = newGoods.id;

				slip.price		= Number(col[5]);	/* 価格     */

				data.push(slip);
            }

			console.log(data);
			this.setState({ newGoodsSlipList: data});

			console.log("Goods Slip file read end.");
		};
		console.log(file);
		reader.readAsText(this.state.goodsSlipFile);
	}

	putCustomerList = () => {
		console.log("psql で実行する")
		console.log("select customer_zip();");
	}

    render = () => {

	    return (
			<Form>
				担当者の作成ーーーーーーーーーーー
				<FormGroup row>
					<Label for="exampleFile" sm={2}>担当者New.CSV</Label>
					<Col sm={10}>
						<Input type="file" ref="file" onChange={this.onStaffChange} />
					</Col>
				</FormGroup>
				<Button onClick={this.createNewStaff}>新担当者の作成</Button>
				<Button onClick={this.postNewStaff}  >新担当者の登録</Button>
				<br/><br/>

				技術集計の作成ーーーーーーーーーーー
				<FormGroup row>
					<Label for="exampleFile" sm={2}>技術集計New.CSV</Label>
					<Col sm={10}>
						<Input type="file" ref="file" onChange={this.onTechSumChange} />
					</Col>
				</FormGroup>
				<Button onClick={this.createNewTechSum}>新技術集計の作成</Button>
				<Button onClick={this.postNewTechSum}  >新技術集計の登録</Button>
				<br/><br/>

				技術の作成ーーーーーーーーーーー
				<FormGroup row>
					<Label for="exampleFile" sm={2}>技術New.CSV</Label>
					<Col sm={10}>
						<Input type="file" ref="file" onChange={this.onTechChange} />
					</Col>
				</FormGroup>
				<Button onClick={this.getTechSum   }>新技術集計の読込</Button>
				<Button onClick={this.createNewTech}>技術の作成登録</Button>
				<br/><br/>

				仕入先の作成ーーーーーーーーーーー
				<FormGroup row>
					<Label for="exampleFile" sm={2}>仕入先New.CSV</Label>
					<Col sm={10}>
						<Input type="file" ref="file" onChange={this.onSupplierChange} />
					</Col>
				</FormGroup>
				<Button onClick={this.createNewSupplier}>新仕入先の作成</Button>
				<Button onClick={this.postNewSupplier}  >新仕入先の登録</Button>
				<br/><br/>

				商品の作成ーーーーーーーーーーー
				<FormGroup row>
					<Label for="exampleFile" sm={2}>仕入先.CSV</Label>
					<Col sm={10}>
						<Input type="file" ref="file" onChange={this.onSupplierChange} />
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for="exampleFile" sm={2}>商品.CSV</Label>
					<Col sm={10}>
						<Input type="file" ref="file" onChange={this.onGoodsChange} />
					</Col>
				</FormGroup>
				<Button onClick={this.getSupplier   }>仕入先の読込</Button>
				<Button onClick={this.createNewGoods}>商品の作成</Button>
				<Button onClick={this.postNewGoods}>商品の登録</Button>
				<br/><br/>

				顧客の作成ーーーーーーーーーーー
				<FormGroup row>
					<Label for="exampleFile" sm={2}>担当者M.CSV</Label>
					<Col sm={10}>
						<Input type="file" ref="file" onChange={this.onStaffChange} />
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for="exampleFile" sm={2}>顧客.CSV</Label>
					<Col sm={10}>
						<Input type="file" ref="file" onChange={this.onCustChange} />
					</Col>
				</FormGroup>
				<Button onClick={this.getStaff         }>担当者の読込</Button>
				<Button onClick={this.createNewCustomer}>顧客の登録</Button>
				<Button onClick={this.postNewCustomer  }>顧客の登録</Button>
				<br/><br/>

				クレジット代行の作成ーーーーーーーーーーー
				<FormGroup row>
					<Label for="exampleFile" sm={2}>カードNew.CSV</Label>
					<Col sm={10}>
						<Input type="file" ref="file" onChange={this.onCreditChange} />
					</Col>
				</FormGroup>
				<Button onClick={this.createNewCredit}>クレジット代行の作成</Button>
				<Button onClick={this.postNewCredit}  >クレジット代行の登録</Button>
				<br/><br/>

				会計情報の作成ーーーーーーーーーーー
				<InputGroup>
					<InputGroupText>店舗　</InputGroupText>
					<Input type="select" placeholder="店舗名" value={this.state.salon_id} onChange={this.onSalonChange}>
					{
						this.state.salonList.map(function (salon) {
							return <option value={salon.id} key={salon.id}>{salon.name}</option>;
						})
					}
					</Input>
				</InputGroup>
				<FormGroup row>
					<Label for="exampleFile" sm={2}>担当者M.CSV</Label>
					<Col sm={10}>
						<Input type="file" ref="file" onChange={this.onStaffChange} />
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for="exampleFile" sm={2}>カードNew.CSV</Label>
					<Col sm={10}>
						<Input type="file" ref="file" onChange={this.onCreditChange} />
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for="exampleFile" sm={2}>顧客.CSV</Label>
					<Col sm={10}>
						<Input type="file" ref="file" onChange={this.onCustChange} />
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for="exampleFile" sm={2}>会計情報.CSV</Label>
					<Col sm={10}>
						<Input type="file" ref="file" onChange={this.onVisitChange} />
					</Col>
				</FormGroup>
				<Button onClick={this.getStaff      }>担当者の読込</Button>
				<Button onClick={this.getCredit     }>クレジット代行の読込</Button>
				<Button onClick={this.getCustomer   }>顧客の読込</Button>
				<Button onClick={this.createNewVisit}>会計情報の変換</Button>
				<Button onClick={this.postNewVisit  }>会計情報の登録</Button>
				<br/><br/>


				技術会計の作成ーーーーーーーーーーー
				<FormGroup row>
					<Label for="exampleFile" sm={2}>担当者M.CSV</Label>
					<Col sm={10}>
						<Input type="file" ref="file" onChange={this.onStaffChange} />
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for="exampleFile" sm={2}>技術M.CSV</Label>
					<Col sm={10}>
						<Input type="file" ref="file" onChange={this.onTechChange} />
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for="exampleFile" sm={2}>会計情報.CSV</Label>
					<Col sm={10}>
						<Input type="file" ref="file" onChange={this.onVisitChange} />
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for="exampleFile" sm={2}>技術会計.CSV</Label>
					<Col sm={10}>
						<Input type="file" ref="file" onChange={this.onTechSlipChange} />
					</Col>
				</FormGroup>
				<Button onClick={this.getTechSlip      }>技術、担当者の読込</Button>
				<Button onClick={this.createNewTechSlip}>技術会計の変換</Button>
				<Button onClick={this.postNewTechSlip  }>技術会計の登録</Button>
				<br/><br/>

				商品会計の作成ーーーーーーーーーーー
				<FormGroup row>
					<Label for="exampleFile" sm={2}>商品.CSV</Label>
					<Col sm={10}>
						<Input type="file" ref="file" onChange={this.onGoodsChange} />
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for="exampleFile" sm={2}>会計情報.CSV</Label>
					<Col sm={10}>
						<Input type="file" ref="file" onChange={this.onVisitChange} />
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for="exampleFile" sm={2}>商品会計.CSV</Label>
					<Col sm={10}>
						<Input type="file" ref="file" onChange={this.onGoodsSlipChange} />
					</Col>
				</FormGroup>
				<Button onClick={this.getGoodsSlip      }>商品の読込</Button>
				<Button onClick={this.createNewGoodsSlip}>商品会計の変換</Button>
				<Button onClick={this.postNewGoodsSlip  }>商品会計の登録</Button>
				<br/><br/>

				顧客住所の作成ーーーーーーーーーーー<br/>
				<Button onClick={this.putCustomerList  }>顧客の読込</Button>
			</Form>
	    );
    };
};

export default Convert;
