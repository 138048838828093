/* ==============================================================================
 予約・来客・会計の編集画面 モーダル モジュール
   個客の検索画面 モーダル
 
DB table R/W: visit、tech_slip、goods_slip
		 R  : staff、customer
		 
 group: id,title,<staff>
 staff: id,no+name
 salesax: startDate, rate
 
 item:  id,group,   title,start_time,end_time,<visit>
 visit: id,staff_id,name, start_time,duration,visit_state,customer_id
 
=============================================================================== */

import React, { Component,useRef,useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button,InputGroup,InputGroupText,Input,FormGroup,Form,
			Modal,ModalHeader,ModalBody,ModalFooter,
			Container,CardGroup,Card,CardBody, Tooltip } from 'reactstrap';
import Select from 'react-select';
import { FaHistory,FaExclamationTriangle } from "react-icons/fa";

import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ja from 'date-fns/locale/ja';

import StarReceipt,{StarDisplay} from './StarWebPrint/StarReceipt';

import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/ja';
// import Request from 'superagent';
import {JWToken,Confirm,Alert} from './auth/Login';
import {changeYen} from './Formatter';

import './Table.css';

import {TechSlipGrid} from './TechSlip';
import {GoodsSlipGrid} from './GoodsSlip';
import {CustomerFilter} from './Customer';
// import ReceiptPrint from './Receipt';
// import Check from './Input';
import CustEdit from './CustEdit';
import Record	   from './Record';
import QRcalendar  from './QRcalendar';
import HelpPopover from './HelpPopover';
// import Check from './Input';
import VisitLog    from './VisitLog';

import {
	/* EDIT_UNSELECT = 1, EDIT_SELECT = 2, EDIT_ADD = 3, */ EDIT_REP, /*, EDIT_DEL = 5*/
	
	STATE_VISIT, STATE_PAY, STATE_SUM, STATE_NET, STATE_BOOK, STATE_CANCEL, // , STATE_SUM; 
	MAX_NAME_LENGTH,
	MAX_DB_LENGTH,
	
	// タイムゾーン
	TIMEZONE
} from './Define';

const itemBgColor = ['lightcyan','SteelBlue','DarkBlue','dimgray','lemonchiffon','lavenderblush'];
const itemColor   = ['black',    'white',    'white',   'white',   'black',      'black'];
var initialCustomer = {id: 0, tenant_id: 0, no: 0, name: "", kana: "", mail: "", cellula: "", phone: "", zipcode:"", address1: "", address2:"", birthday:"", gender:true, kubun:0, staff_id: 0, point:0, comment:"", password:"", staffname:"" };
var edit = false;

// ====================================================================================================
// レジ担当者の項目にフォーカスする
// 
// ====================================================================================================
const SelectStaff = (props) => {
	const inputElement = useRef(null);
	
	useEffect(() => {
		if (inputElement.current) {
			inputElement.current.focus();
		}
	}, []);
	
	return (
		<Select className="form-control react-select-original" placeholder="担当者(必須)" value={props.staffValue} options={props.options} onChange={props.onChange}  ref={inputElement}
			menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
	);
};

// ====================================================================================================
// 予約詳細の入力画面
// 
// ====================================================================================================

class Visitor extends Component {
	static propTypes = {
		showModal:		PropTypes.bool.isRequired,			// Modal画面の表示/非表示
		item:			PropTypes.object.isRequired,		// 予約データ
		salon:			PropTypes.object.isRequired,		// 店舗情報
	    techSlipList:	PropTypes.array.isRequired,			// 技術伝票
	    goodsSlipList:	PropTypes.array.isRequired,			// 商品伝票
	    customerList:	PropTypes.array.isRequired,			// 個客マスター
	    staffList:		PropTypes.array.isRequired,			// 担当者マスター
	    techList:		PropTypes.array.isRequired,			// 技術マスター
	    goodsList:		PropTypes.array.isRequired,			// 商品マスター
	    creditList:		PropTypes.array.isRequired,			// 決済サービス
	    taxList:		PropTypes.array.isRequired,			// 消費税率
	    pointList:		PropTypes.array.isRequired,			// ポイントの還元率
	    onClose:		PropTypes.func.isRequired,			// 来客情報のコールバック関数
	    onCancel:		PropTypes.func.isRequired,			// 来客情報のコールバック関数
	    onDelete:		PropTypes.func.isRequired,			// 来客情報のコールバック関数
	    onPutCustomer:	PropTypes.func.isRequired,			// 顧客Writeのコールバック関数
	};

	// コンストラクター
	constructor(props) {
		super(props);
		let item   = Object.assign({}, props.item);
		item.visit = Object.assign({}, props.item.visit);
		
		this.state = {
			showModal:false,			// Modal 非表示
			showModalCustEdit:false,	// 顧客編集画面の表示 
			showModalVisitLog:false,	// 予約ログ画面の表示
			item:			item,		// Timeline で選択されている item
			techSlipList:	[Object.assign({}, props.techSlipList)],	// 技術売上
			goodsSlipList:	[Object.assign({}, props.goodsSlipList)],	// 商品売上
			payReadOnly:	false,		// 支払い
			custNo: 0,					// 顧客番号
			selectedStaff:	null,		// React-Select で選択したスタッフ
			selectedCust: Object.assign({},initialCustomer),
			disabled:		true,		// OKボタンを無効にする
			tooltipOpen:	false,
		};

		moment.locale('ja');
		moment.tz.setDefault(TIMEZONE);		// timezoneをJSTに変更する

		registerLocale('ja',ja);		// react-datepicker の日本語表記

	}
	
	// コンポーネントが新しいpropsを受け取ると実行されます。 このメソッドは初期描画では呼び出されません。
	UNSAFE_componentWillReceiveProps = (nextProps) => {

		this.setState({ item:			nextProps.item,
						techSlipList:	nextProps.techSlipList,
						goodsSlipList:	nextProps.goodsSlipList,
						showModal:		nextProps.showModal,
//						disabled: disabled,
//						tooltipOpen:false,
//						custNo: 0,
		});
	}


	// 消費税のレート
	taxRate = ( day) => {
		let tax = 0;
		let taxList = this.props.taxList;
		for ( let i = 0 ; i < taxList.length ; i++ ) {
			if ( taxList[i].start > day ) break;
			tax = taxList[i].rate;
		}
		return tax/100;
	}

	amount = (item) => {
		let total = this.taxInc(item) - item.visit.use_point;
	
		return total;
	}
	
	taxInc = (item) => {
		let tax = Math.floor( ( (item.techTotal + item.goodsTotal) * this.taxRate(item.visit.visit_date) ) /10) * 10;		// 10円未満を切り捨て
		let total = item.techTotal + item.goodsTotal + tax;
	
		return total;
	}
	
	// ポイントのレート
	pointRate = (day) => {
		let rate = 0;
		let pointList = this.props.pointList;
		for ( let i = 0 ; i < pointList.length ; i++ ) {
			if ( pointList[i].start > day ) break;
			rate = pointList[i].rate;
		}
		return rate/100;
	}
	
	// 
	addPoint = (item) => {
		let point = (item.techTotal + item.goodsTotal - item.visit.use_point) * this.pointRate(item.visit.visit_date);
// console.log(point);		
		return Math.floor(point);
	}

	
// ======================================================================================
// キー入力 イベント処理関数
//		・rowPropを編集してthis.state.rowDataにデーターを登録する事で表示する
// ======================================================================================

	// InputにFocusを当てると選択状態にする
	focusSelect = (event) => {
		event.target.select();
	}
	
	// レジ担当者を変更した
	opeStaffChange = (option) =>  {
		edit = true;
		let item = Object.assign({}, this.state.item);
		item.visit.ope_staff = Number(option.value);
		this.setState({ 
			item:		item,
			disabled:	false,
		});
	}

	// 予約・来店・会計の切り替え（select）
	stateChange = (event) => {
		let item = this.state.item;
		if ( item.visit.visit_state === STATE_SUM    ) return;
		if ( item.visit.visit_state === STATE_CANCEL ) return;
		// eslint-disable-next-line
		if ( event.target.value     == STATE_SUM ) return;
		// eslint-disable-next-line
		if ( event.target.value == STATE_NET || event.target.value == STATE_CANCEL ) return;
		edit = true;
		item.visit.visit_state = Number(event.target.value);
		this.setState({ item: item });
	}

	// 日時が変更された
	dateChange = (date) => {
		edit = true;
//		console.log("dateChange():"+date);
		let item = Object.assign({}, this.state.item);
		item.visit_time = moment(date).format("YYYY-MM-DD HH:mm");
		item.start_time = moment(date).valueOf();
		item.end_time   = moment(date).add(item.duration, 'minute').valueOf();
		item.visit.visit_date = moment(date).format('YYYY-MM-DD');
		item.visit.visit_time = moment(date).format('HH:mm');
		this.setState({ item:item });
	}

	// 担当者を変更した
	staffChange = (option) =>  {
		edit = true;
		let item = Object.assign({}, this.state.item);
		item.group = Number(option.value);
		item.visit.staff_id = Number(option.value);
		this.setState({ item:item,
						selectedStaff: option
		});
	}

	// 顧客番号の入力	
	noChange = (event) => {
		
		edit = true;
		let item = this.state.item;
		let cust = this.props.customerList.find(function(obj){ return obj.no === Number(event.target.value); });

		item.visit.use_point = 0;				// 顧客を変更したので、使用するポイントは初期化
		if ( cust === undefined ) {
			item.visit.name = "";
//			item.title      = "";				// 顧客名をCalendarTimelineに表示
			item.title	    = ( item.visit.nickname === null ? '' : ' 【' + item.visit.nickname + '】 ' )		// 技術略称を追加する
							+ ( item.visit.memo     === null ? '' : item.visit.memo.replace(/\\n/g, ' ') );		// メモを追加する
			item.visit.customer_id = 0;
			item.point = 0;
		} else {			
			item.visit.name = cust.name;
//			item.title      = cust.name;				// 顧客名をCalendarTimelineに表示
			item.title	    = item.visit.name + ( item.visit.nickname === null ? '' : ' 【' + item.visit.nickname + '】 ' )		// 技術略称を追加する
											  + ( item.visit.memo     === null ? '' : item.visit.memo.replace(/\\n/g, ' ') );	// メモを追加する
			item.visit.customer_id = cust.id;
			item.visit.staff_id    = cust.staff_id;		// 顧客のスタッフに設定
			item.point   = cust.point;					// point は顧客が保有するポイントをitemに設定する
//			this.getPoint(cust.id);
		}
// console.log(event.target.value);
		item.customer_no = event.target.value;
		this.setState({ item:item });
	}

	// 顧客名を入力
	nameChange = (event) => {
		if ( event.target.value.length > MAX_NAME_LENGTH ) return;
		edit = true;
		let item = this.state.item;
		item.visit.name = event.target.value;
//		item.title      = event.target.value;				// 顧客名をCalendarTimelineに表示
		item.title	    = item.visit.name + ( item.visit.nickname === null ? '' : ' 【' + item.visit.nickname + '】 ' )		// 技術略称を追加する
										  + ( item.visit.memo     === null ? '' : item.visit.memo.replace(/\\n/g, ' ') );	// メモを追加する
		this.setState({ item:item });
	}

	// 決済サービス
	creditChange = (event) => {
		edit = true;
		let item = this.state.item;
		item.visit.credit_id = Number(event.target.value);
		if ( item.visit.credit_id === 0 ) {
			// 現金の場合
			this.setState({ payReadOnly: false });				// お預かり金額を入力可にする
			item.visit.pay = 0;									// お預かり金額を0円にする
			item.visit.visit_state = STATE_VISIT;				// 来店にする
		} else {
			// 現金以外の場合
			this.setState({ payReadOnly: true });				// お預かり金額の入力不可にする
			item.visit.pay = this.amount(item);
			item.visit.visit_state = STATE_PAY;					// 会計済みにする
// console.log(item);
		}
		this.setState({ item:item });
	}

	// 預かり金額を入力
	payChange = (event) => {
		edit = true;
		let pay = event.target.value.replace(/,/g, '');
// console.log(pay);
		if ( isNaN(pay)  ) return;
		let item = this.state.item;
		item.visit.pay = Number(pay);

		// 会計終了ならstateを会計に変更してpointを追加する
		let account = this.amount(item);
		if ( item.visit.pay >= account) {
			item.visit.add_point   = this.addPoint(item);
			item.visit.visit_state = STATE_PAY;
		} else {
			item.visit.add_point   = 0;
			item.visit.visit_state = STATE_VISIT;
		}
		this.setState({ item:item });
	}

	// メモの入力
	memoChange = (event) => {
		edit = true;
		let item = this.state.item;
		item.visit.memo = event.target.value;
		item.title	    = item.visit.name + ( item.visit.nickname === null ? '' : ' 【' + item.visit.nickname + '】 ' )		// 技術略称を追加する
										  + ( item.visit.memo     === null ? '' : item.visit.memo.replace(/\\n/g, ' ') );	// メモを追加する
		this.setState({ item:item });
	}

	// ポイントの入力
	pointChange = (event) => {
//		if ( Check.money(event.target.value) === false ) return;
		edit = true;
		let point = this.state.item.point;				// 保有ポイント
		let use   = Number(event.target.value.replace(/,/g, ''));
		if ( isNaN(use) ) return;
		if ( use < 0 ) return;
		let pay   = this.taxInc(this.state.item); // (item.techTotal + item.goodsTotal) * tax;
//		console.log('保有ポイント:',point);
//		console.log('利用ポイント:',use);

		let item = this.state.item;
		
		// 利用ポイントの制限
		if ( item.visit.customer_id !== 0 ) {	// 顧客が登録されている場合
			if ( use > point ) return;			// 保有ポイント以上はポイントを使えない
		}
		if ( use > pay   ) return;			// 支払金額以上はポイントを使えない

		item.visit.use_point = use;			// this.state.item.visit.use_point

		// クレジットなら支払金額を設定
		if ( item.visit.credit_id !== 0 ) {
			item.visit.pay = this.amount(item);
		}

		// 会計終了ならstateを会計に変更してpointを追加する
		let account = this.amount(item);
		if ( item.visit.pay >= account) {
			item.visit.add_point   = this.addPoint(item);
			item.visit.visit_state = STATE_PAY;
		} else {
			item.visit.add_point   = 0;
			item.visit.visit_state = STATE_VISIT;
		}

		this.setState({ item:item });

	}

// ======================================================================================
// ボタンが押された
//		・削除ボタン
//		・キャンセルボタン
//		・登録ボタン
// ======================================================================================
	// 削除ボタンが押された
	onDelete = () => {
		this.props.onDelete(this.state.item);
		// this.setState({showzeModal:false});
	}

	// キャンセルボタンが押された
	onCancel = () => {
//		console.log("onCancel--state--",this.state.item.visit,this.state.techSlipList,this.state.goodsSlipList);
//		console.log("onCancel--props--",this.props.item.visit,this.props.techSlipList,this.props.goodsSlipList);
//		console.log("onCancel--edit--",edit);
		if ( edit ) {
//			var result = window.confirm('予約項目が変更されているのに「キャンセル」ボタンが押されました\n変更を無効にして画面を閉じてもよろしいですか？');
//			if ( !result ) return;
			Confirm(<div>予約項目が変更されています<br/>変更を無効にして画面を閉じてもよろしいですか？</div>).then(result => {
				if ( !result ) return;
				edit = false;
				this.setState({disabled:true});
				this.props.onCancel();
			});
			return;
		}
		edit = false;
		this.setState({disabled:true});
		this.props.onCancel();
		// this.setState({showModal:false});
	}

	// 登録ボタンが押された
	onClose = () => {
//		console.log("onClose---",this.state.item,this.state.techSlipList,this.state.goodsSlipList);
		if ( this.state.disabled === true ) {
			Alert(<div>レジ担当が入力されていません。<br/>入力してから登録してください。</div>);
			return;
		}
		edit = false;
		this.props.onClose(this.state.item,this.state.techSlipList,this.state.goodsSlipList);
		this.setState({disabled:true});
		let item = this.state.item;
		item.id = 0;
		this.setState({item:item});
		// this.setState({showModal:false});
	}

	onEnter = () => {
// 		console.log("onEnter---");
	}

	//	toggle() {
	toggleModal = () => {
		if ( this.state.showModal === true ) {
			this.onCancel();
//			this.setState( { showModal: false } );
		} else {
			edit = false;
			this.setState( { showModal: true } );
		}
	}

	//	toggle() {
	toggleCast = () => {
		if ( this.state.showModalCust === true ) {
			this.cancelCust();
			this.setState( { showModalCust: false } );
		} else {
			this.setState( { showModalCust: true } );
		}
	}

	//	toggle() {
	toggleCastEdit = () => {
		if ( this.state.showModalCustEdit === true ) {
			this.cancelCust();
			this.setState( { showModalCustEdit: false } );
		} else {
			this.setState( { showModalCustEdit: true } );
		}
	}


// ======================================================================================
// 顧客選択画面 Customer
//		・モーダル画面で顧客の選択を行う。
//		・selectedCust:現在選択されている顧客の情報
// ======================================================================================
	// 顧客検索ボタンが押された
	openCust = () => {
		this.setState({ custNo: this.state.item.customer_no });
		let cust = this.props.customerList.find( obj => { return obj.id === this.state.item.visit.customer_id });
		if ( cust === undefined ) cust = null;
		this.setState({ selectedCust: cust });
    	this.setState({ showModalCust: true });
	}

	// 顧客一覧表の顧客が選択された
	onCustSelect = (cust) => {
		this.setState({ selectedCust: Object.assign({},cust) });
	}

	cancelCust = () => {
		this.setState({showModalCust: false });
	}

	closeCust = () => {
		let item = this.state.item;
		let cust = this.state.selectedCust;
		if ( cust !== null ) {
			// 削除された担当者は使用しない
			let staff = this.props.staffList.find( obj => { return obj.id === cust.staff_id });
			if ( staff && staff.no < MAX_DB_LENGTH ) {
				item.visit.staff_id = staff.id;			// 顧客の担当者に変更する
			}
			item.visit.name	= cust.name;
//			item.title      = cust.name;			// 顧客名をCalenderTimelineに表示する
			item.title	    = item.visit.name + ( item.visit.nickname === null ? '' : ' 【' + item.visit.nickname + '】 ' )		// 技術略称を追加する
											  + ( item.visit.memo     === null ? '' : item.visit.memo.replace(/\\n/g, ' ') );	// メモを追加する
			item.visit.customer_id = cust.id;
			item.customer_no       = cust.no;
			item.visit.use_point   = 0;					// 顧客が変わったので、利用ポイントを０にする
			item.point   = cust.point;					// point は顧客にあるポイントを設定する
//			this.getPoint(item.visit.customer_id);		// this.state.item.addPoint を設定する
		}
		this.setState({ item: item });
		this.setState({showModalCust: false });
// console.log('closeCust',item);
	}


// ======================================================================================
// 顧客編集画面 CustEdit
//		・モーダル画面で顧客の選択を行う。
//		・selectedCust:現在選択されている顧客の情報
// ======================================================================================
	// 顧客編集ボタンが押された
	openCustEdit = () => {
		let item = this.state.item;
		let cust = this.props.customerList.find(function(obj){ return obj.id === item.visit.customer_id; });
		if ( cust === undefined ) {
			cust = Object.assign({},initialCustomer);
			return;
		}
//		console.log('openCustEdit',this.state.selectedCust);
    	this.setState({ 
    		selectedCust: Object.assign({},cust),
    		showModalCustEdit: true
    	});
	}

	// お客様変更画面でOKボタンが押された
	closeCustRep = (customer) => {
		let item = this.state.item;
		if ( customer !== null && customer !== undefined ) {
			item.visit.name = customer.name;
//			item.title      = customer.name;			// 顧客名をCalenderTimelineに表示する
			item.title	    = item.visit.name + ( item.visit.nickname === null ? '' : ' 【' + item.visit.nickname + '】 ' )		// 技術略称を追加する
											  + ( item.visit.memo     === null ? '' : item.visit.memo.replace(/\\n/g, ' ') );	// メモを追加する
			item.visit.customer_id = customer.id;
			item.visit.staff_id    = customer.staff_id;		// 顧客の担当者に変更する
			item.customer_no       = customer.no;
			if ( item.visit.use_point > customer.point ) {
				item.visit.use_point   = 0;					// 顧客が変わったので、利用ポイントを０にする
			}
			item.point   = customer.point;			// point は顧客にあるポイントを設定する

			this.setState({ showModalCustEdit: false,
							item: item,
							selectedCust: customer});		
			this.props.onPutCustomer(customer,this.state.techSlipList,this.state.goodsSlipList);
		}
	}

	onCancelCustEdit = () => {
		this.setState({showModalCustEdit: false });
	}


// ======================================================================================
// 顧客履歴画面 Record
//		・モーダル画面で顧客の選択を行う。
//		・selectedCust:現在選択されている顧客の情報
// ======================================================================================
	// 顧客履歴ボタンが押された
	openRecord = () => {
		let item = this.state.item;
		if ( item.visit.customer_id === 0 ) return;

		let cust = this.props.customerList.find(function(obj){ return obj.id === item.visit.customer_id; });
		if ( cust === undefined ) return;
		
    	this.setState({ 
    		selectedCust: cust,
    		showModalRecord: true
    	});
	}

	cancelRecord = () => {
		this.setState({ showModalRecord: false });
		
	}

// 過去の履歴から技術をコピーする  開発中
	onAddTechSlip = (tech_slip) => {
//		console.log("onAddTechSlip",tech_slip);
		this.setState({recordTechSlip: tech_slip});
	}
	
	addTechSlip = () => {
		console.log("addTechSlip",this.state.recordTechSlip);
//		console.log(this.state.techSlipList);
		edit = true;
		let recTechSLip = this.state.recordTechSlip;
		let visit_id    = this.state.item.visit.id; 
		let staff_id    = this.state.item.visit.staff_id;
		let duration    = 0;
		let techSlip = recTechSLip.map( (rec) => {
			duration += rec.period;
			return {
				price:		rec.price,
				staff_id:	staff_id,
				tech_id:	rec.tech_id,
				tenant_id:	rec.tenant_id,
				visit_id:	visit_id,
			};
		});
		let item  = this.state.item;
		item.visit.duration = duration;
		item.end_time	    = item.start_time + (duration * 60000 );

//		let slip = this.state.techSlipList.concat(techSlip);
		this.setState({ techSlipList: techSlip });
		this.setState({ item: item });
		this.setState({ showModalRecord: false });
	}
	
	toggleRecord = () => {
		if ( this.state.showModalRecord === true ) {
			this.cancelRecord();
			this.setState( { showModalRecord: false } );
		} else {
			this.setState( { showModalRecord: true } );
		}
	}

// ======================================================================================
// 予約履歴画面 VisitLog
//		・モーダル画面で予約ログを表示する。
//		・selectedCust:現在選択されている顧客の情報
// ======================================================================================
	onOpenVisitLog = () => {
		let item = this.state.item;
		this.setState({
			visitId: item.visit.id,
			showModalVisitLog: true
		});
	}

	onCancelVisitLog = () => {
		this.setState({ showModalVisitLog: false });
	}

	onCloseVisitLog = () => {
		this.setState({ showModalVisitLog: false });
	}
	


// ======================================================================================
// 請求画面 Bill
//		・モーダル画面で顧客の選択を行う。
//		・selectedCust:現在選択されている顧客の情報
// ======================================================================================
	// 支払い請求ボタンが押された
	openBill = () => {
		let item = this.state.item;
		let cust = this.props.customerList.find(function(obj){ return obj.id === item.visit.customer_id; });

		// スター精密の場合
		this.displayOpen(changeYen(this.amount(this.state.item)));

    	this.setState({ 
    		selectedCust: cust,
    		showModalBill: true
    	});
	}

	cancelBill = () => {

		// スター精密の場合
		this.displayClose();
		
		this.setState({ showModalBill: false });
	}
	
	toggleBill = () => {
		if ( this.state.showModalBill === true ) {
			this.cancelBill();
			this.setState( { showModalBill: false } );
		} else {
			this.setState( { showModalBill: true } );
		}
	}

// ======================================================================================
// コールバック関数
//		・TechSlipGridのコールバック
//		・GoodsSlipGridのコールバック
// ======================================================================================

	// 技術売上一覧に変更があった時のコールバック関数
	onTechRowUpdated = (list,duration,total) => {
		edit = true;
		let slip = list.concat();
		let item = this.state.item;
		if ( duration < 30 ) duration = 30;
		item.visit.duration = duration;
		item.end_time	   = item.start_time + (duration * 60000 );
		item.techTotal	   = total;
		if ( item.visit.credit_id !== 0 ) {
			item.visit.pay = this.amount(item);		// 現金以外の時は支払いを自動計算する
		}

		if ( total && item.visit.visit_state === STATE_NET ) {
			item.visit.visit_state = STATE_BOOK;
		}

		this.setState({ techSlipList: slip,
						item: item });
		return true;
	}

	// 商品売上一覧に変更があった時のコールバック関数
	onGoodsRowUpdated = (list,total) => {
		edit = true;
		let slip = list.concat();
		let item = this.state.item;
		item.goodsTotal	 = total;
		if ( item.visit.credit_id !== 0 ) {
			item.visit.pay = this.amount(item);		// 現金以外の時は支払いを自動計算する
		}

		this.setState({ goodsSlipList: slip,
						item: item });
// console.log('onGoodsRowUpdated',list);
		return true;
	}


// ======================================================================================
// スター精密　レシート印刷
// 
// ======================================================================================
	printReceipt = ( ) => {
		// urlが空の場合は印刷しない
		let salon = this.props.salon;
		if ( !salon.receipt_url ) return;

		let item = this.state.item;

		let receipt = new StarReceipt();
		let url     = salon.receipt_url;
		let width   = salon.receipt_width;
		receipt.connect(url,width);		// レシートプリンターに接続する
console.log('StarReceipt');
		let day     = moment().tz(TIMEZONE).format('YYYY年MM月DD日HH:mm');		// this.state.item.visit.visit_date + ' ' + this.state.item.visit.visit_time.substr(0,5);
		let comment = salon.comment.replace(/\\n/g, '\n');
		let staff   = this.props.staffList.find(  s => { return item.visit.staff_id  === s.id });
		let credit  = this.props.creditList.find( c => { return item.visit.credit_id === c.id });
		let card    = credit ? credit.name : '現金';
		let stylist = staff  ? staff.name  : '';
//		if ( staff !== undefined ) stylist = staff.name;
		let visit   = item.visit;
		if ( visit.credit_id === 0 ) receipt.drawerOpen(); // ドロアーオープン
console.log('StarReceipt.drawerOpen()');
		receipt.createLogo(1);									// ロゴの印刷
		receipt.createText2(salon.name);						// Salon名の印刷
		receipt.createText (salon.address);						// Salon住所の印刷
		receipt.createText ("  電   話   ：" + salon.phone);	// Salon電話番号の印刷
		receipt.createText ("スタイリスト：" + stylist);		// スタイリストの印刷
		receipt.createText ("　日　 時 　：" + day);			// 日時の印刷
		if ( item.customer_no ) receipt.createText ("お客様番号　：" + item.customer_no);	// 日時の印刷
		receipt.createText (" お  名  前 ：" + visit.name + ' 様');		// 顧客名の印刷
		receipt.createText (" 支払い方法 ：" + card);			// クレジットカード？現金
		receipt.createText ("レシート番号：" + visit.id);		// visit.idの印刷
		receipt.createText (" " );								// 改行

		// 技術項目の印刷
		let totalTech = 0;
		this.state.techSlipList.map( (s,index) => {
			totalTech +=  Number(s.price);
			let tech = this.props.techList.find(function(t) { return s.tech_id === t.id });
			let yen  = '\\' + changeYen(s.price);
			receipt.createTextW (tech.name,yen);		// 技術名、価格の印刷
			return s.price;
		});
		let techYen = '\\' + changeYen(totalTech);
		receipt.createTextW ('----技術合計----',techYen);
		receipt.createText (" " );							// 改行

		// 商品項目の印刷
		let totalGoods = 0;
		this.state.goodsSlipList.map( (s,index) => {
			totalGoods +=  Number(s.price);
			let goods = this.props.goodsList.find(function(g) { return s.goods_id === g.id });
			let yen   = '\\' + changeYen(s.price);
			receipt.createTextW (goods.name,yen);		// 商品名、価格の印刷
			return s.price;
		});
		let goodsYen = '\\' + changeYen(totalGoods);
		receipt.createTextW ('----商品合計----',goodsYen);
		receipt.createText (" " );							// 改行

		let tax        = Math.floor((totalTech + totalGoods) * this.taxRate(item.visit.visit_date)/10) * 10;
		let taxInc     = '\\' + changeYen( tax );
		let taxSales   = '\\' + changeYen( totalTech + totalGoods + tax);
		let use_point  = changeYen(item.visit.use_point);
		let totalSales = '\\' + changeYen( totalTech + totalGoods + tax - item.visit.use_point);
		let pay        = '\\' + changeYen(item.visit.pay);
		let change     = '\\' + changeYen(item.visit.pay - (totalTech + totalGoods + tax - item.visit.use_point));

		receipt.createTextW ('消費税',		taxInc);
		receipt.createTextW ('税込金額',	taxSales);

		// ポイントか割引か
		if ( item.visit.customer_id !== 0 ) {
			receipt.createTextW ('使用ポイント',use_point);
		} else {
			receipt.createTextW ('割引',use_point);
		}
		
		// 顧客登録されていない場合は印字しない
		if ( item.visit.customer_id !== 0 && this.pointRate(item.visit.visit_date) !== 0 ) {
			receipt.createText  (' ');							// 改行印刷
			receipt.createTextW ('追加ポイント',changeYen(item.visit.add_point));
			if ( item.visit.visit_state === 3 ) {
				receipt.createTextW ('累計ポイント',changeYen( item.point ));
			} else {
				let point = Number(item.point) + Number(item.visit.add_point) - Number(item.visit.use_point);
				receipt.createTextW ('累計ポイント',changeYen( point ));
			}
		}
		
		receipt.createText  (' ');							// 改行印刷
		receipt.createTextW2('合計金額',	totalSales);
		receipt.createTextW2('お預かり',	pay);
		receipt.createTextW2('お釣り',		change);
		
		receipt.createText  (comment);						// コメント印刷
		
		receipt.createText  (' ');							// 改行印刷

		receipt.createCutPaper();			// feed & Cut paper
console.log('StarReceipt.send()');
		
		receipt.send();						// 印刷する
console.log('StarReceipt.send()end');
	}

// ======================================================================================
// スター精密　領収書印刷
// 
// ======================================================================================
	printVoucher = ( ) => {
		// urlが空の場合は印刷しない
		let salon = this.props.salon;
		if ( !salon.receipt_url ) return;

		let item = this.state.item;

		let receipt = new StarReceipt();
		let url     = salon.receipt_url;
		let width   = salon.receipt_width;
		receipt.connect(url,width);		// レシートプリンターに接続する

		let day     = moment().tz(TIMEZONE).format('YYYY年MM月DD日HH:mm');		// this.state.item.visit.visit_date + ' ' + this.state.item.visit.visit_time.substr(0,5);
		let visit   = item.visit;

		receipt.createLogo(1);									// ロゴの印刷
		receipt.createText2("　　領　収　書");					// タイトルの印刷
		receipt.createText (" " );								// 改行
		receipt.createText ("レシート番号：" + visit.id);		// visit.idの印刷
		receipt.createText ("　日　 時 　：" + day);			// 日時の印刷
		receipt.createText (" " );								// 改行
		receipt.createTextW2("","御中");
		receipt.createText (" " );								// 改行

		// 技術項目の合計
		let totalTech = 0;
		this.state.techSlipList.map( (s,index) => {
			totalTech +=  Number(s.price);
			return s.price;
		});

		// 商品項目の合計
		let totalGoods = 0;
		this.state.goodsSlipList.map( (s,index) => {
			totalGoods +=  Number(s.price);
			return s.price;
		});
		
		// 消費税
		let tax        = Math.floor((totalTech + totalGoods) * this.taxRate(item.visit.visit_date)/10) * 10;

		let totalYen = '\\' + changeYen(totalTech + totalGoods + tax - item.visit.use_point) + '-';
		receipt.createTextW2(' 金 額 ',totalYen);
		receipt.createText (" " );								// 改行
		receipt.createText2("但し、" );							// 但し書き
		receipt.createText (" " );								// 改行
		receipt.createText (" 上記正に領収いたしました。" );	// 改行
		receipt.createText (" " );								// 改行

		receipt.createText2(salon.name);						// Salon名の印刷
		receipt.createText (salon.address);						// Salon住所の印刷
		receipt.createText ("  電   話   ：" + salon.phone);	// Salon電話番号の印刷

		receipt.createText  (' ');							// 改行印刷

		receipt.createCutPaper();			// feed & Cut paper
		
		receipt.send();						// 印刷する
	}

// ======================================================================================
// スター精密  予約印刷
// 
// ======================================================================================
	printReserve = ( ) => {

		let receipt = new StarReceipt();
		receipt.printReserve( this.props.salon, this.state.item.visit, 
								this.props.staffList,	this.props.customerList,
								this.props.techList,	this.props.goodsList,
								this.state.techSlipList,this.state.goodsSlipList,
								this.taxRate(this.state.item.visit.visit_date) );
	}


	drawerOpen = ( url, width ) => {
		// urlが空の場合は印刷しない
		if ( !this.props.salon.receipt_url ) return;

		let receipt = new StarReceipt();
		receipt.connect(this.props.salon.receipt_url,this.props.salon.receipt_width);

		receipt.drawerOpen();
		receipt.send();						// 印刷する
	}

	displayOpen = (total)  => {
		// urlが空の場合は表示しない
		if ( !this.props.salon.receipt_url ) return;

		let display = new StarDisplay();
		display.show( this.props.salon.receipt_url, total );		
	}

	displayChange = (total,change) => {
		// urlが空の場合は表示しない
		if ( !this.props.salon.receipt_url ) return;

		let display = new StarDisplay();
		
		display.show1( this.props.salon.receipt_url, total, change );		
	}

	displayClose = ()  => {
		// urlが空の場合は表示しない
		if ( !this.props.salon.receipt_url ) return;

		let display = new StarDisplay();
		
		display.hide( this.props.salon.receipt_url );		
	}


/* ToolTip -------------------------------------------------- */
	tooltipToggle = () => {
		this.setState({tooltipOpen:!this.state.tooltipOpen});
	}
	
/* ToolTip -------------------------------------------------- */
	
// ======================================================================================
// render()
// 
// ======================================================================================

	render = () => {
// console.log(this.props.item,this.state.item);

		// 予約受付日時
		let visitTitle = '';
		let date = this.state.item.visit.regist_date;
		if ( date !== null && date !== undefined ) {
			visitTitle = "予約受付日:" + date;
		}
		
		// 予約日時
		var startDate = new Date(this.state.item.visit_time); //moment(this.state.item.visit_time).valueOf();

		// スタッフの設定
		let list = this.props.staffList.filter( (staff) => {		// 削除された担当者の番号は選択出来ない
			return staff.no < MAX_DB_LENGTH;
		});
		let options = list.map( (staff) => {						// 担当者のドロップダウンリスト
			return { value: staff.id, label:staff.no + ' ' + staff.name};
		});
		let staff = list.find( (staff) => {							// 予約担当者
			return staff.id === this.state.item.visit.staff_id;
		});
		if ( !staff ) {
			if ( list.length !== 0 ) {
				staff = list[0];
			} else {
				staff = {id: '', name: ''};		// スタッフidとnameだけを設定
			}
		}
		let staffValue = { value: staff.id, label: staff.name};
		let opeStaffValue = { value: '', label:''};

		// 決済サービス
		list = this.props.creditList.filter( (credit) => {			// 削除された番号は表示しない
			return credit.no < MAX_DB_LENGTH;
		});
		let creditList = list.flatMap(function (credit) {
			if ( credit.no >= MAX_DB_LENGTH ) return [];
			return [<option value={credit.id} key={credit.id}>{credit.no + ' ' + credit.name}</option>];
		});

		//	QRコードでスケジュールに登録する技術項目
		let techName = this.state.techSlipList.map( (s,index) => {
			let tech = this.props.techList.find(function(t) { return s.tech_id === t.id });
			if ( !tech ) return 'tech not found';
			return tech.name;
		});

		// 顧客が選択されていない時
		let customerName = "";
		let disabledReplaceCastomer = true;
		if ( this.state.selectedCust !== null && this.state.selectedCust !== undefined) {
			customerName = this.state.selectedCust.name;
			disabledReplaceCastomer = false;
		}
		if ( this.state.item.visit.id === 0 ) {
			disabledReplaceCastomer = true;
		}

		let visit_state = this.state.item.visit.visit_state;
		
		// スター精密の場合 カスタマーディスプレイに表示
		let change = this.state.item.visit.pay - this.amount(this.state.item);
// eslint-disable-next-line
		if ( this.state.showModalBill && ( visit_state == STATE_VISIT || visit_state == STATE_PAY ) ) {
			if ( change < 0 ) {
				this.displayOpen(changeYen(this.amount(this.state.item)));
			} else { 
				this.displayChange(changeYen(this.state.item.visit.pay), changeYen(change) );
			}
		} 
		
		let discount = '利用Point　';
		if ( this.state.item.visit.customer_id === 0 ) {
			discount = '割 　引　　';
		}
// console.log(this.state.item.visit.ope_staff, edit, this.state.disabled);
		return (
			<div >
				<Modal isOpen={this.state.showModal} backdrop={'static'} style={{maxWidth: '750px', width: '99%'}} size={'lg'} autoFocus={false}> 
					<ModalHeader toggle={this.toggleModal}>
					<table width="100%" ><tbody><tr>
						<td>予約項目</td>
						<td className="h6">{ visitTitle }</td>
						<td style={{width:'44px'}}>
							{ this.state.item.visit.visit_date >= moment().format('YYYY-MM-DD') &&
								<Button  onClick={this.onOpenVisitLog} id="BookBefore" align="right" className="float-right" ><FaHistory/></Button>
							}
						</td>
						<td style={{width:'44px'}}>
							<HelpPopover id='booking' help={help} />
						</td>
					</tr></tbody></table>
					</ModalHeader>
					<ModalBody style={{paddingTop:0, paddingLeft:8, paddingRight:8, paddingButtom:0}}>
						<Container fluid={true}>
							<CardGroup>
								<div className="col-sm-5 h-100" style={{padding:1}} >
									<Card style={{border:'none'}}>
										<CardBody style={{padding: 1}}>

{/*							<Row>
								<Col xs="5" >
*/}
								<Form>
									<FormGroup disabled>
    									<InputGroup >
											<InputGroupText style={{width:'90px', textAlign:'justify'}}>レジ担当</InputGroupText>
											<SelectStaff  value={opeStaffValue} options={options} onChange={this.opeStaffChange} autoFocus={true} />
										</InputGroup>
									</FormGroup>
									<FormGroup disabled>
										<InputGroup>
											<InputGroupText style={{width:'90px', textAlign:'justify'}}>状　況</InputGroupText>
											<Input type="select" placeholder="予約状況" bsSize="3" value={this.state.item.visit.visit_state} onChange={this.stateChange} style={{width:20, color: itemColor[visit_state], backgroundColor: itemBgColor[visit_state]}} >
												<option value={4} key={4} style={{color:'lightgray', backgroundColor:'white'}}>{"予約連絡"}</option>
												<option value={5} key={5} style={{color:'lightgray', backgroundColor:'white'}}>{"キャンセル連絡"}</option>
												<option value={0} key={0} style={{color:'dimgray',   backgroundColor:'white'}}>{"予約"}</option>
												<option value={1} key={1} style={{color:'dimgray',   backgroundColor:'white'}}>{"来店"}</option>
												<option value={2} key={2} style={{color:'dimgray',   backgroundColor:'white'}}>{"会計"}</option>
												<option value={3} key={3} style={{color:'lightgray', backgroundColor:'white'}}>{"集計"}</option>
											</Input>
										</InputGroup>
										<InputGroup>
											<InputGroupText style={{width:'90px', textAlign:'justify'}}>予約日時</InputGroupText>
										    <DatePicker className="form-control" selected={startDate} onChange={this.dateChange} style={{flex: '110px'}} preventOpenOnFocus={true}
										    dateFormat="yyyy-MM-dd HH:mm" showTimeSelect timeFormat="HH:mm" timeIntervals={15} fixedHeight />
										</InputGroup>
    									<InputGroup>
											<InputGroupText style={{width:'90px', textAlign:'justify'}}>担 当 者</InputGroupText>
											<Select className="form-control react-select-original" value={staffValue} options={options} onChange={this.staffChange} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
										</InputGroup>
										<InputGroup>
											<InputGroupText style={{width:'90px', textAlign:'justify'}}>お 客 様</InputGroupText>
											<Input type="number" placeholder="お客様番号" value={this.state.item.customer_no} onFocus={this.focusSelect} onChange={this.noChange} />
											<Button onClick={this.openCust}>検索</Button>
										</InputGroup>
										<InputGroup>
											<InputGroupText style={{width:'90px', textAlign:'justify'}}>お 名 前</InputGroupText>
											<Input type="text"   placeholder="お客様名(20文字以内)" value={this.state.item.visit.name} onChange={this.nameChange} />
											<Button onClick={this.openCustEdit} disabled={disabledReplaceCastomer}>変更</Button>
										</InputGroup>
									</FormGroup>

									<FormGroup>  {/* style={{marginBottom: 0}}> */}
										<InputGroup >
											<InputGroupText style={{width:'90px', textAlign:'justify'}}>税込 金額</InputGroupText>
											<Input type="text" className="text-right" value={'¥'+changeYen(this.taxInc(this.state.item))+'円' } readOnly={true}/>
										</InputGroup>
										<InputGroup >
											<InputGroupText style={{width:'90px', textAlign:'justify'}}>保有Point</InputGroupText>
											<Input type="text" className="text-right" placeholder="保有ポイント" value={changeYen(this.state.item.point)} readOnly={true}/>
										</InputGroup>
										<InputGroup >
											<InputGroupText style={{width:'90px', textAlign:'justify'}}>{discount}</InputGroupText>
											<Input type="number" className="text-right" placeholder={discount} value={this.state.item.visit.use_point} onChange={this.pointChange} onFocus={this.focusSelect} />
										</InputGroup>
										<InputGroup >
											<InputGroupText style={{width:'90px', textAlign:'justify'}}>合計 金額</InputGroupText>
											<Input type="text" className="text-right" value={'¥'+changeYen(this.taxInc(this.state.item) - this.state.item.visit.use_point)+'円'} readOnly={true}/>
										</InputGroup>
										<InputGroup>
											<InputGroupText style={{width:'90px', textAlign:'justify'}}>支払方法</InputGroupText>
											<Input type="select" value={this.state.item.visit.credit_id} onChange={this.creditChange}>
												<option value={0} key={0}>{"0 現金"}</option>
												{	creditList	}
											</Input>
										</InputGroup>
										<InputGroup>
											<InputGroupText style={{width:'90px', textAlign:'justify'}}>お 預 り</InputGroupText>
											<Input type="number" className="text-right" placeholder="お預り" value={this.state.item.visit.pay} onChange={this.payChange} onFocus={this.focusSelect} readOnly={this.state.payReadOnly}/>
											<InputGroupText>円</InputGroupText>
										</InputGroup>
										<InputGroup>
											<InputGroupText style={{width:'90px', textAlign:'justify'}}>お 釣 り</InputGroupText>
											<Input type="text" className="text-right" placeholder="お釣り" value={'¥' + changeYen(this.state.item.visit.pay - this.amount(this.state.item)) +'円' } readOnly={true}/>
										</InputGroup>
									</FormGroup>
								</Form>

										</CardBody>
									</Card>
								</div>
							
								<div className="col-sm-7 h-100"  style={{padding:1}} >
									<Card  style={{border:'none'}}>
										<CardBody style={{padding:1}}>


									<TechSlipGrid visit={this.state.item.visit} onRowUpdated={this.onTechRowUpdated}
										slipList={this.state.techSlipList} staffList={this.props.staffList} techList={this.props.techList} height={246} />
									<GoodsSlipGrid visit={this.state.item.visit} onRowUpdated={this.onGoodsRowUpdated}
										slipList={this.state.goodsSlipList} goodsList={this.props.goodsList} height={107} />
									<InputGroup>
										<InputGroupText style={{width:'55px'}}>メモ</InputGroupText>
										<Input style={{height: '72px'}} type="textarea" placeholder="メモ" value={this.state.item.visit.memo} onChange={this.memoChange} />
									</InputGroup>

										</CardBody>
									</Card >
								</div>
							</CardGroup>
						</Container>
					</ModalBody>
					<ModalFooter>
						{
							this.props.salon.receipt_url && this.state.item.visit.pay > 0 &&
								<div>
									<Button color="info"  onClick={this.printReceipt}>レシート印刷</Button>{" "}
									<Button color="info"  onClick={this.printVoucher}>領収書印刷</Button>
								</div>
						}
						{
							this.state.item.visit.visit_state === 0  &&
								<QRcalendar summary= {'ご予約 ' + JWToken.getToken().name} 
											dtstart={moment(this.state.item.start_time).format("YYYYMMDDTHHmmss")} 
											dtend={moment(this.state.item.end_time).format("YYYYMMDDTHHmmss")} 
											location={this.props.salon.address}
											description= { techName.join(', ') } />
						}
						{
							this.props.salon.receipt_url && this.state.item.visit.visit_state === 0 &&
								<Button color="info"  onClick={this.printReserve}>予約表印刷</Button>
						}
						
						<Button color="info"  onClick={this.openBill  }>ご請求</Button>{" "}
						<Button color="info"  onClick={this.openRecord}>来店履歴</Button>
						{"　　　"}
						<Button color="danger"  onClick={this.onDelete}>削除</Button>
						<Button color="warning" onClick={this.onCancel}>キャンセル</Button>
						<Button color="primary" onClick={this.onClose} id="BookButton" >登録</Button>
						{ this.state.disabled === true &&
							<Tooltip placement="right" isOpen={this.state.tooltipOpen} target="BookButton" toggle={this.tooltipToggle} hideArrow={false} ><FaExclamationTriangle/>レジ担当を入力して下さい。</Tooltip>
						}
					</ModalFooter>
				</Modal>

				<Modal isOpen={this.state.showModalCust} backdrop={'static'} style={{maxWidth: '750px', width: '99%'}} size={'lg'} onClose={this.cancelCust}> 
					<ModalHeader toggle={this.toggleCast}>お客様 検索</ModalHeader>
					<ModalBody>
						<CustomerFilter onRowSelect={this.onCustSelect} mstNo={Number(this.state.custNo)} height={250} showCheckbox={false} customerList={this.props.customerList}  canFilter={true} /> {/* canFilter={true} にすると　TypeError: Cannot read properties of undefined (reading 'value')*/}
					</ModalBody>
					<ModalFooter>
						<Button color="warning" onClick={this.cancelCust}>キャンセル</Button>
						<Button color="primary" onClick={this.closeCust} >OK</Button>
					</ModalFooter>
				</Modal>

				<CustEdit showModal={this.state.showModalCustEdit} editStatus={EDIT_REP} customer={this.state.selectedCust} onCancel={this.onCancelCustEdit}
						customerList={this.props.customerList} staffList={this.props.staffList} onCloseRep={this.closeCustRep} />

				<Modal isOpen={this.state.showModalRecord} backdrop={'static'} style={{maxWidth: '750px', width: '99%'}} size={'lg'} onClose={this.cancelRecord}> 
					<ModalHeader toggle={this.toggleRecord} >来店履歴　　{customerName} 様 </ModalHeader>
					<ModalBody>
						<Record customer	= {this.state.selectedCust}			// 選択された顧客
//								salon		= {this.props.salon} 				// 店舗情報
								staffList	= {this.props.staffList}			// 担当者マスター
								techList	= {this.props.techList}				// 技術マスター
								goodsList	= {this.props.goodsList}			// 商品マスター
								taxList		= {this.props.taxList}				// 消費税
								pointList	= {this.props.pointList}			// ポイントの還元率
								creditList  = {this.props.creditList}			// 決済サービス
								onAddTechSlip = {this.onAddTechSlip}			// 表示されている技術項目を返す
						/>
					</ModalBody>
					<ModalFooter>
{/*						<Button color="info"  onClick={this.printReserve}>予約印刷</Button> */}
						<Button color="primary" onClick={this.addTechSlip} >技術コピー</Button>
						<Button color="primary" onClick={this.cancelRecord} >OK</Button>
					</ModalFooter>
				</Modal>

				<Modal isOpen={this.state.showModalBill} backdrop={'static'} style={{maxWidth: '600px', width: '99%'}} size={'xl'} onClose={this.cancelBill} autoFocus={false}> 
					<ModalHeader toggle={this.toggleBill} >{this.state.item.visit.name} 様</ModalHeader>
					<ModalBody>
						<FormGroup disabled>
							<InputGroup size="lg">
								<InputGroupText style={{width:'120px', textAlign:'justify'}} >税込 金額　</InputGroupText>
								<Input type="text" className="text-right" value={'¥'+changeYen(this.taxInc(this.state.item))+'円' } readOnly={true}/>
							</InputGroup>
							<InputGroup size="lg">
								<InputGroupText style={{width:'120px', textAlign:'justify'}}>保有Point　</InputGroupText>
								<Input type="text" className="text-right" placeholder="保有ポイント" value={changeYen(this.state.item.point)} readOnly={true}/>
							</InputGroup>
							<InputGroup size="lg">
								<InputGroupText style={{width:'120px', textAlign:'justify'}}>{discount}</InputGroupText>
								<Input type="number" className="text-right" placeholder={discount} value={this.state.item.visit.use_point} onChange={this.pointChange} onFocus={this.focusSelect} autoFocus={true} />
							</InputGroup>
							<InputGroup size="lg">
								<InputGroupText style={{width:'120px', textAlign:'justify'}}>合計 金額　</InputGroupText>
								<Input type="text" className="text-right" value={'¥'+changeYen(this.taxInc(this.state.item) - this.state.item.visit.use_point)+'円'} readOnly={true}/>
							</InputGroup>
							<InputGroup size="lg">
								<InputGroupText style={{width:'120px', textAlign:'justify'}}>支払方法　　</InputGroupText>
								<Input type="select" value={this.state.item.visit.credit_id} onChange={this.creditChange}>
									<option value={0} key={0}>{"0 現金"}</option>
									{	creditList	}
								</Input>
							</InputGroup>
							<InputGroup size="lg">
								<InputGroupText style={{width:'120px', textAlign:'justify'}}>お預り　　</InputGroupText>
								<Input type="number" className="text-right" placeholder="お預り" value={this.state.item.visit.pay} onChange={this.payChange} onFocus={this.focusSelect} readOnly={this.state.payReadOnly}/>
								<InputGroupText>円</InputGroupText>
							</InputGroup>
							<InputGroup size="lg">
								<InputGroupText style={{width:'120px', textAlign:'justify'}}>お釣り　　</InputGroupText>
								<Input type="text" className="text-right" placeholder="お釣り" value={'¥' + changeYen(this.state.item.visit.pay - this.amount(this.state.item)) +'円' } readOnly={true}/>
							</InputGroup>
						</FormGroup>
					</ModalBody>
					<ModalFooter>
						{
							this.props.salon.receipt_url && this.state.item.visit.pay > 0 &&
							<Button color="info"  onClick={this.printReceipt}>レシート印刷</Button>
						}
						<Button color="primary" onClick={this.cancelBill} >OK</Button>
					</ModalFooter>
				</Modal>
				
				<VisitLog isOpen={this.state.showModalVisitLog} visitId={this.state.visitId} onClose={ this.onCloseVisitLog } onCancel={ this.onCancelVisitLog } addTechSlip={this.addTechSlip}
							customerList={this.props.customerList} staffList={this.props.staffList} techList={this.props.techList} goodsList={this.props.goodsList}  taxList={this.props.taxList} creditList={this.props.creditList} />

			</div>
		);
	}
	
}

const help = 
	"##### 概要\n" +
	"予約項目の内容を編集できます。  \n" +
	"タイトルに**予約項目**を追加した日(**予約受付日**)が表示されます。  \n" +
	"##### レジ担当\n" +
	"予約項目を編集している担当者（あなた）を選択して下さい。\n" +
	"レジ担当者を入力しないと**登録**ボタンが無効になって押せなくなります。　\n" +
	"予約項目を編集すると予約ログを残します。誰が・いつ・何を編集したかわかります。　\n" +
	"##### 予約状況\n" +
	"###### 　状況︎   \n" +
	"<span style='background-color: lightcyan; border-width:1px solid #ccc '>予約</span>・" + 
	"<span style='color: white; background-color: SteelBlue; '>来店</span>・" + 
	"<span style='color: white; background-color: DarkBlue;  '>会計</span>を選択できます。  \n" +
	"**合計金額**より**お預かり**の金額が大きい場合は自動的に" +
	"<span style='color: white; background-color: DarkBlue;  '>会計</span>になります。  \n" +
	"###### 　予約日時\n" +
	"お客様が来店する日時を表示/変更できます。  \n" +
	"**予約管理**画面から**予約の追加**でこの画面を表示した場合は、追加時に選択した日時になります。  \n" +
	"**予約日時**の入力項目をタップするとカレンダーと時刻一覧から日時を選択できます。" +
	"またキー入力でも日時を変更できます。" +
	"**予約管理**画面では、ここで指定した日時に**予約項目**が表示されます。  \n" +
	"###### 　担当者\n" +
	"お客様の担当者を選択できます。  \n" +
	"**予約管理**画面から**予約の追加**でこの画面を表示した場合は、追加時に選択した担当者になります。" +
	"**予約管理**画面では、ここで指定した担当者に**予約項目**が表示されます。  \n" +
	"###### 　お客様、検索  \n" +
	"お客様が登録されている場合はお客様の番号を入力して下さい。" +
	"登録されていないお客様の場合は何も入力しないで下さい。  \n" +
	"**お客様**に番号を入力すると**お名前**が自動挿入されます。" +
	"**検索**ボタンを押すと**お客様検索**画面が表示されます。" +
	"**お客様検索**画面で選択したお客様の番号を表示します。  \n" +
	"###### 　お名前、変更  \n" +
	"お客様の**お名前**を入力できます。  \n" +
	"お客様が登録されている場合は**お客様**に番号を入力すると**お名前**が自動挿入されます。" +
	"お客様が登録されている場合**変更**ボタンを押すと**お客様変更**画面が表示されてお客様の情報を表示/変更できます。  \n" +
	"##### 会計情報  \n" +
	"###### 　税込金額  \n" +
	"**技術合計** + **商品合計** + 税　が表示されます。  \n" +
	"###### 　保有ポイント  \n" +
	"お客様が登録されている場合はお客様が現在保有しているポイントが表示されます。  \n" +
	"お客様の**ポイント**は**合計金額**から[ポイント還元率](./#/Point)を参照して計算され、付与されます。" +
	"ポイント還元の計算は翌日以降に初めてロクインした時に計算されます。" +
	"ポイント還元の計算が終了すると状況は" + 
	"<span style='color: white; background-color: dimgray; '>集計</span>に変更されます。  \n" +
	"###### 　利用ポイント\n" +
	"お客様が登録されている場合、今回利用するポイントを入力して下さい。  \n" +
	"入力された**利用ポイント**は１ポイントを1円として、**税込金額**から値引きされて**合計金額**になります。" +
	"保有ポイントより大きい値は入力できません。" +
	"税込金額より大きい値は入力できません。  \n" +
	"お客様のポイントから**利用ポイント**を差し引くのは、翌日以降に初めてロクインした時に計算されます。" +
	"**利用ポイント**の計算が終了すると状況を" + 
	"<span style='color: white; background-color: dimgray; '>集計</span>に変更します  \n" +
	"###### 　割引\n" +
	"お客様が登録されていない場合、割引金額を入力できます。  \n" +
	"###### 　合計金額\n" +
	"支払い金額 (**税込金額** - **利用ポイント**) が表示されます。  \n" +
	"###### 　支払い方法 \n" +
	"現金または[決済サービス](./#/credit)を選択できます。  \n" +
	"###### 　お預かり\n" +
	"お客様からお預かりした金額を入力して下さい。  \n" +
	"###### 　お釣り\n" +
	"お預かり金額を入力するとお釣りが表示されます。  \n" +
	"###### 　メモ\n" +
	"予約時などにお客様からの伝言があればメモに記入しておくことで、他の担当者にも伝えます。  \n" +
	"予約表にも表示されます。  \n" +
	"##### 　技術\n" +
	"###### 　追加\n" +
	"施術する技術を追加できます。入力した技術は、技術一覧に追加されます。  \n" +
	"###### 　修正\n" +
	"施術する技術を修正できます。  \n" +
	"###### 　削除\n" +
	"施術する技術を削除できます。  \n" +
	"###### 　技術合計\n" +
	"施術する技術の合計金額を表示します。  \n" +
	"合計金額は切り捨て処理を行います。  \n" +
	"###### 　技術一覧\n" +
	"施術する技術の一覧を表示します。  \n" +
	"技術項目をダブルクリックすると修正画面が表示されます。  \n" +
	"##### 　商品\n" +
	"###### 　追加\n" +
	"購入する商品を追加できます。入力した商品は、商品一覧に追加されます。  \n" +
	"###### 　修正\n" +
	"購入する商品を修正できます。  \n" +
	"###### 　削除\n" +
	"購入する商品を削除できます。  \n" +
	"###### 　商品合計\n" +
	"購入する商品の合計金額を表示します。  \n" +
	"合計金額は切り捨て処理を行います。  \n" +
	"###### 　商品一覧\n" +
	"購入する商品の一覧を表示します。  \n" +
	"商品項目をダブルクリックすると修正画面が表示されます。  \n" +
	"##### 　予約表印刷\n" +
	"状態が予約の場合、**レシートプリンター**に予約表を印刷できます。  \n" +
	"##### 　レシート印刷\n" +
	"状態が会計の場合、**レシートプリンター**にレシートを印刷できます。  \n" +
	"##### 　ご請求\n" +
	"現在の会計情報の画面を大きく表示できます。  \n" +
	"##### 　来店履歴\n" +
	"お客様の過去の**来店履歴**を表示できます。  \n" +
	"##### 　削除\n" +
	"この予約を削除する。確認画面を表示できます。  \n" +
	"##### 　キャンセル\n" +
	"予約の追加や変更をキャンセルして画面を閉じます。  \n" +
	"##### 　登録\n" +
	"予約の追加や変更を登録して画面を閉じます。  \n" +
	"";

export default Visitor;
