/* ==============================================================================
 日計集計表の表示画面

=============================================================================== */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PrintPreview from './PrintPreview';
import { InputGroup,InputGroupText,FormGroup,Table,Button } from 'reactstrap';
import moment from 'moment'
import 'moment-timezone';
import 'moment/locale/ja';

import Request from 'superagent';
import {JWToken} from './auth/Login';

import DatePicker, { registerLocale } from 'react-datepicker'
import ja from 'date-fns/locale/ja'
import "react-datepicker/dist/react-datepicker.css";

import { YenFormat } from './Formatter'; 

import './Printer.css';		// ページ番号の印刷

// タイムゾーン
import {
	TIMEZONE
} from './Define';

// font size 12px に設定した時
const minimumTebleHeight = 61;
const rowHeight  = 17;
const pageHeight = 1043 - 14;			// A4 サイズ

// 会計伝票を作成するには、会計情報、技術会計、商品会計、税金情報、カードが読み終わってないといけない
const ITEM_VISIT	= 0x01;
const ITEM_TECHSLIP	= 0x02;
const ITEM_GOODSSLIP= 0x04;
const ITEM_TAX		= 0x08;
const ITEM_CREDIT   = 0x10;
const ITEM_STAFF    = 0x20;
const ITEM_ALL		= ITEM_VISIT | ITEM_TECHSLIP | ITEM_GOODSSLIP | ITEM_TAX | ITEM_CREDIT | ITEM_STAFF;


// マスターのreadに使用する
const visitParam	= { url: "/visit/print/",		errMsg: "会計情報",		list: [],	item: ITEM_VISIT	};
const techSlipParam	= { url: "/tech_slip/print/",	errMsg: "技術会計",		list: [],	item: ITEM_TECHSLIP	};
const goodsSlipParam= { url: "/goods_slip/print/",	errMsg: "商品会計",		list: [],	item: ITEM_GOODSSLIP};
const taxParam		= { url: "/sales_tax",			errMsg: "消費税率",		list: [],	item: ITEM_TAX		};
const creditParam	= { url: "/credit",				errMsg: "CREDIT一覧",	list: [],	item: ITEM_CREDIT	};
const staffParam    = { url: "/visit/staff/",       errMsg: "担当者売上",	list: [],	item: ITEM_STAFF	};

var	item_flag = 0;	// ITEM_XXX DBが読込まれた事を確認する。 ITEM_ALLになったか？

var creditTable = Object();

var cash =  [{  id: 0,  salon_id: 0,  no: 0,  name: '現金' }];
var creditList = [];

function taxRate( day) {
	let tax = 0;
	let taxList = taxParam.list;
	for ( let i = 0 ; i < taxList.length ; i++ ) {
		if ( taxList[i].start > day ) break;
		tax = taxList[i].rate;
	}
	return tax/100;
}

class DaySlip extends Component {
	// propTypes
	static propTypes = {
		salon_id:   PropTypes.number,		// 店舗ID
		salon_name: PropTypes.string,		// 店舗名
		viewDate:	PropTypes.string,		// 表示日
		changeDate: PropTypes.func,			// ReactCalenderTimeline の日付を変更する
	}

	static defaultProps = {
		salon_id: 0,
		salon_name: "",
		viewDate: moment().tz(TIMEZONE).format('YYYY-MM-DD'),
		changeDate: null,
	}

	// コンストラクター
	constructor(props) {
		super(props);
		this.state = {  
			creditTable:  ( <div></div> ),
			tax: 0,
			visit_day: this.props.viewDate,			// moment().format('YYYY-MM-DD'),
			staffTable: ( <div></div> ),
			chart: ( <section className="sheet padding-10mm">A4 Seet </section> ),
		};

		moment.locale('ja');
		moment.tz.setDefault(TIMEZONE);		// timezoneをJSTに変更する

		registerLocale('ja', ja);		// react-datepicker の日本語表記
	}

	// ComponentがDOMツリーに追加される前に一度だけ呼ばれる。
	componentDidMount = () => {			// componentWillMount から修正した
		console.log("componentDidMount");
		this.setState({visit_day: this.props.viewDate });

		let day = this.props.viewDate;
		visitParam.url		= "/visit/print/"		+ day;
		techSlipParam.url	= "/tech_slip/print/"	+ day;
		goodsSlipParam.url	= "/goods_slip/print/"	+ day;
		staffParam.url		= "/visit/staff/"		+ day+ "_" + day;

		if ( this.props.salon_id !== 0 ) {
			visitParam.url  	+= "/"  + this.props.salon_id;
			techSlipParam.url	+= "/"  + this.props.salon_id;
			goodsSlipParam.url	+= "/"  + this.props.salon_id;
			staffParam.url      += "/"  + this.props.salon_id;
		}
		item_flag = 0;		// 消費税とクレジットは再読み込みしない
		this.getList(visitParam);		// 会計情報
		this.getList(techSlipParam);	// 技術会計
		this.getList(goodsSlipParam);	// 商品会計
		this.getList(taxParam);			// 消費税率の読み込み
		this.getList(creditParam);		// クレジットの読み込み
		this.getList(staffParam);		// 担当者の読み込み
	}

// ======================================================================================
// DB 入出力 関数
// ======================================================================================

/* -----------------------------------------------------------
	マスターの取得
------------------------------------------------------------- */
	getList = (param) => {
		//ajax通信する
		Request.get(param.url)
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.error(param.errMsg +"を取得できませんでした。");
					console.log(err);
				} else {
					// 店舗表示
					param.list = res.body.dbData;
					this.getInitialTable( param.item );		// group,itemの作成
				}
			}.bind(this));
	}

	getInitialTable = (flag) => {
		item_flag |= flag;
		if ( item_flag !== ITEM_ALL ) return;		// マスターがすべて読み込まれたか？

//		creditParam.list.unshift(cash);				// クレジットに現金を追加する
		creditList = cash.concat(creditParam.list);
		this.createChart();
	}



// ======================================================================================
// 画面入力 関数
// ======================================================================================

	// 日時が変更された
	dayChange = (date) => {
		
		let day = moment(date).format('YYYY-MM-DD');

		this.setState({ visit_day: day });

// 以下はcomponentDidMount() と同じ
		visitParam.url		= "/visit/print/"		+ day;
		techSlipParam.url	= "/tech_slip/print/"	+ day;
		goodsSlipParam.url	= "/goods_slip/print/"	+ day;
		staffParam.url		= "/visit/staff/"	+ day+ "_" + day;
		if ( this.props.salon_id !== 0 ) {
			visitParam.url  	+= "/"  + this.props.salon_id;
			techSlipParam.url	+= "/"  + this.props.salon_id;
			goodsSlipParam.url	+= "/"  + this.props.salon_id;
			staffParam.url      += "/"  + this.props.salon_id;
		}
		item_flag = 0;		// 消費税とクレジットは再読み込みしない
		this.getList(visitParam);		// 会計情報
		this.getList(techSlipParam);	// 技術会計
		this.getList(goodsSlipParam);	// 商品会計
		this.getList(taxParam);			// 消費税率の読み込み
		this.getList(creditParam);		// クレジットの読み込み
		this.getList(staffParam);		// 担当者の読み込み

		if ( this.props.changeDate !== null ) this.props.changeDate(date);		// ReactCalendarTimelineの日付を変更する

	}

	decDay= () => {
		let day = moment(this.state.visit_day).add(-1,'days');
		this.dayChange(day);
	}

	incDay = () => {
		let day = moment(this.state.visit_day).add(1,'days');
		this.dayChange(day);
	}


	// クレジット毎に技術会計、商品会計、税金、合計を表示する
	createChart = () => {

		let tableHeight = [];
		
		// 日計表合計の初期化
		creditList.forEach(function(credit, index) {
			credit.totalTech  = 0;
			credit.totalGoods = 0;
			credit.totalPoint = 0;
			credit.salesTax   = 0;
			credit.totalSales = 0;
		} );

		let header = (
				<Table bordered size={"sm"} hover>
					<tbody >
						<tr>
							<th>{"会計伝票　" + this.props.salon_name + "  " + this.state.visit_day}</th>
						</tr>
					</tbody>
				</Table>
		);

		let visitElement = visitParam.list.map(function(value, index) {
			let id = value.id;
			let techTotal  = 0;
			let goodsTotal = 0;
			let salesTax   = 0;
			//--------------------------------------------------------
			// 商品売上Tableの作成
			//--------------------------------------------------------
			let goods = goodsSlipParam.list.filter(function(obj) {
				return (id === obj.id);
			});
			let goodsLength = goods.length;
			let goodsElement = goods.map(function(value, index) {
				goodsTotal += value.price;
				return (
						<tr key={index}>
						  <td>{value.goods}</td>
						  <td><YenFormat value={value.price} /></td>
						</tr>
				);
			});

			let goodsTable = (
					<Table bordered size={"sm"} hover>
						 <thead bgcolor="#ebf0f7">
							<tr>
								<th>商品名</th>
								<th>価格</th>
							</tr>
						</thead>
						<tbody>
							{ goodsElement }
							<tr className="font-weight-bold">
								<td><div className="text-center">合計</div></td>
								<td><YenFormat mark='¥' value={goodsTotal} /></td>
							</tr>
						</tbody>
					</Table>
			);

			//--------------------------------------------------------
			// 技術売上Tableの作成
			//--------------------------------------------------------
			let tech = techSlipParam.list.filter(function(obj) {
				return (id === obj.id);
			});
			let techLength = tech.length;
			let techElement = tech.map(function(value, index) {
				techTotal += value.price;
				return (
						<tr key={index}>
						  <td>{value.tech}</td>
						  <td>{value.staff}</td>
						  <td><YenFormat value={value.price} /></td>
						</tr>
				);
			});

			let techTable = (
						<Table bordered size={"sm"} hover>
							<thead bgcolor="#ebf0f7">
								<tr>
									<th>技術名</th>
									<th>担当</th>
									<th>価格</th>
								</tr>
							</thead>
							<tbody>
								{ techElement }
								<tr className="font-weight-bold">
									<td colSpan='2'><div className="text-center">合計</div></td>
									<td><YenFormat value={techTotal} mark='¥' /></td>
								</tr>
							</tbody>
						</Table>
			);
			
			//--------------------------------------------------------
			// 予約情報の作成			
			//--------------------------------------------------------
			let point = value.use_point;
			let tax   = taxRate(this.state.visit_day);
			salesTax  = Math.floor( (techTotal+goodsTotal)*tax/10 ) * 10;

			// 売上合計の作成
			let credit = creditList.find(function(obj) { return obj.id === value.credit_id });
			if ( credit === undefined ) {
				console.log('クレジット代行が見つかりません',value);
				return;
			}
			credit.totalTech  += techTotal;
			credit.totalGoods += goodsTotal;
			credit.totalPoint += point;
			credit.salesTax   += salesTax;
			credit.totalSales += techTotal + goodsTotal + salesTax - point;
			
			// ページ位置の計算
			let rowCount = techLength > goodsLength ? techLength : goodsLength;
			let pixels = minimumTebleHeight + rowCount * rowHeight;
			tableHeight.push(pixels);

			return (
					<tbody key={index}>
						<tr bgcolor="#ebf0f7">
							<th>{index+1}</th>
							<th>{value.customer}</th>
							<th>担当:{value.staff}</th>
							<th>{credit.name}</th>
							<th><YenFormat mark='合計: ¥' value={techTotal+goodsTotal}  /></th>
							<th><YenFormat mark='使用ポイント: ' value={value.use_point}/></th>
							<th><YenFormat mark='消費税: ¥' value={salesTax} /></th>
							<th><div className="text-right">予約:{value.visit_time}</div></th>
						</tr>
						<tr>
							<td></td>
							<td colSpan='4'>
								{ techTable }
							</td>
							<td colSpan='2'>
								{ goodsTable }
							</td>
						</tr>
					</tbody>
			);
			
		}.bind(this));

		this.setState({ visitElement: visitElement });

		//--------------------------------------------------------
		// 売上合計 Credit Table の作成
		//--------------------------------------------------------
		let creditLength = creditList.length;

		// 合計の集計
		let dayTotalTech  = 0;
		let dayTotalGoods = 0;
		let dayTotalPoint = 0;
		let dayTotalTax   = 0;
		let dayTotalSales = 0;
		// eslint-disable-next-line
		let creditElement = creditList.map(function(value, index) {
			dayTotalTech  += value.totalTech;
			dayTotalGoods += value.totalGoods;
			dayTotalTax   += value.salesTax;
			dayTotalPoint += value.totalPoint;
			dayTotalSales += value.totalSales;
			if ( value.totalSales !== 0 ) {
				return (
						<tr key={index}>
						  <td>{value.name}</td>
						  <td><YenFormat value={value.totalTech} /></td>
						  <td><YenFormat value={value.totalGoods} /></td>
						  <td><YenFormat value={value.salesTax} /></td>
						  <td><YenFormat value={value.totalPoint} /></td>
						  <td><YenFormat value={value.totalSales} /></td>
						</tr>
				);
			}
		}).filter(x => x);

		creditTable = (
				<Table bordered size={"sm"} hover>
					 <thead bgcolor="#ebf0f7">
						<tr>
							<th></th>
							<th>技術　</th>
							<th>商品　</th>
							<th>消費税</th>
							<th>POINT </th>
							<th>合計　</th>
						</tr>
					</thead>
					<tbody>
						{ creditElement }
						<tr className="font-weight-bold">
							<td ><div className="text-center">合計</div></td>
							<td><YenFormat mark='¥' value={dayTotalTech}  /></td>
							<td><YenFormat mark='¥' value={dayTotalGoods}  /></td>
							<td><YenFormat mark='¥' value={dayTotalTax}  /></td>
							<td><YenFormat mark=''  value={dayTotalPoint}  /></td>
							<td><YenFormat mark='¥' value={dayTotalSales}  /></td>
						</tr>
					</tbody>
				</Table>
		);
		let pixels = minimumTebleHeight + creditLength * rowHeight;

		this.setState({creditTable:creditTable});

		//--------------------------------------------------------
		// 担当者の売上合計の作成
		//--------------------------------------------------------
		let totalTech  = 0;
		let totalGoods = 0;
		let totalTax   = 0;
		let totalPoint = 0;
		let staffElement = staffParam.list.map(function(value, index) {
			totalTech  += Number(value.staff_price);
			totalGoods += Number(value.goods_price);
			totalTax   += Number(value.tax);
			totalPoint += Number(value.use_point);
			return (
					<tr key={index}>
					  <td>{value.name}</td>
					  <td><YenFormat value={Number(value.staff_price)} /></td>
					  <td><YenFormat value={Number(value.goods_price)} /></td>
					  <td><YenFormat value={Number(value.tax)} /></td>
					  <td><YenFormat value={Number(value.use_point)} /></td>
					  <td><YenFormat value={Number(value.staff_price) + Number(value.goods_price) + Number(value.tax) - Number(value.use_point)} /></td>
					</tr>
			);
		});

		let staffTable = (
				<Table bordered size={"sm"} hover>
					 <thead bgcolor="#ebf0f7">
						<tr>
							<th>担当者</th>
							<th>技術　</th>
							<th>商品　</th>
							<th>消費税</th>
							<th>POINT </th>
							<th>合計　</th>
						</tr>
					</thead>
					<tbody>
						{ staffElement }
						<tr className="font-weight-bold">
							<td ><div className="text-center">合計</div></td>
							<td><YenFormat mark='¥' value={totalTech}  /></td>
							<td><YenFormat mark='¥' value={totalGoods}  /></td>
							<td><YenFormat mark='¥' value={totalTax}  /></td>
							<td><YenFormat          value={totalPoint}  /></td>
							<td><YenFormat mark='¥' value={totalTech + totalGoods + totalTax - totalPoint}  /></td>
						</tr>
					</tbody>
				</Table>
		);
		this.setState({staffTable:staffTable});


		//--------------------------------------------------------
		// 印刷ページの作成
		//--------------------------------------------------------
		let offset = 0;
		let tables = [];
		let pages = [];
		for ( let i = 0; i < tableHeight.length ; i++ ) {
			offset += tableHeight[i];
			if ( offset > pageHeight ) {
				pages.push(
					<section className={"sheet padding-10mm"} key={i}>
						{header}
						<Table size={"sm"}>
							{tables}
						</Table>
					</section>
				);
				offset = tableHeight[i];
				tables = [];
			}
			tables.push(visitElement[i]);
		}
		if ( offset + pixels < pageHeight ) {
			pages.push(
				<section className={"sheet padding-10mm"} key={tableHeight.length}>
					{header}
					<Table size={"sm"}>
						{tables}
					</Table>
					<Table><thead>　</thead></Table>
					<Table><thead>担当者別</thead></Table>
					{staffTable}
					<Table><thead>　</thead></Table>
					<Table><thead>現金/クレジット代行</thead></Table>
					{creditTable}
				</section>
			);
			
		} else {
			pages.push(
				<div>
					<section className={"sheet padding-10mm"} key={tableHeight.length+1}>
						{header}
						<Table size={"sm"}>
							{tables}
						</Table>
					</section>
					<section className={"sheet padding-10mm"} key={tableHeight.length+2}>
						{header}
						<Table><thead>　</thead></Table>
						<Table><thead>担当者別</thead></Table>
						{staffTable}
						<Table><thead>　</thead></Table>
						<Table><thead>現金/クレジット代行</thead></Table>
						{creditTable}
					</section>
				</div>
			);
		}

		let print =	(
					<div>
						{ pages }
					</div>
		);
					
		this.setState( { chart: print } );
	}

	render() {
		// 集計日
		var summaryDate = new Date(this.state.visit_day);
		
		return (
			<div className="DaySlip" >
				<form>
					<FormGroup disabled>
						<InputGroup>
							<InputGroupText>日付</InputGroupText>
							<Button onClick={this.decDay} >◀︎</Button>
						    <DatePicker className="form-control" selected={summaryDate} onChange={this.dayChange} locale="ja" dateFormat="yyyy-MM-dd" />
{/*							<Input type="date" placeholder="日付" value={this.state.visit_day} onChange={this.dayChange}/> */}
							<Button onClick={this.incDay} >▶︎</Button>
						</InputGroup>
					</FormGroup>
				</form>
				担当者別
				{ this.state.staffTable }
				現金/クレジット代行
				{ this.state.creditTable }
				<PrintPreview isPoppedOut={false} title={ "会計伝票 " + this.props.salon_name} contents={this.state.chart} />
			</div>
		);

	}
}

export default DaySlip;
