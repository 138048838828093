/* ==============================================================================
 テナントの作成画面

 履歴-----------------------------------------
 2020/04/29 reactstrap 対応済み
 
=============================================================================== */

import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import {JWToken} from './Login';
import { InputGroup,InputGroupText,FormGroup,Input,Button,
		 Modal,ModalHeader,ModalBody,ModalFooter} from 'reactstrap';
// import { ButtonDropdown,DropdownMenu,DropdownItem,DropdownToggle } from 'reactstrap';

import Request from 'superagent';
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import Check, {InputLoginName} from '../Input';
import Zip2Addr from '../ZipAddr';

// スクエア
// import './components/style.css';
// import PaymentForm from './components/PaymentForm';

const MAX_COMPANY_LENGTH  = 40;
const MAX_NAME_LENGTH  = 10;
const MAX_PREF_LENGTH  = 18;
const MAX_CITY_LENGTH  = 40;
const MAX_SUBURB_LENGTH = 60;
const MAX_ADDRESS_LENGTH = 60;
// const MAX_USERNAME_LENGTH = 16;
const MAX_PASSWORD_LENGTH = 60;


const initialTenant = {
					first_name: "", last_name: "", first_kana: "", last_kana: "", 
					mail: "", phone: "",
					zipcode: "", pref:"", city:"", suburb:"", address: "",
					company_name: "", reference_id: "", note: "", birthday:"",
					username:"", password:"", password1:"", registCode:"",
					last_login: '2020-12-05',
					begin_month:1, begin_day:1, month:1, floor:10,
					labor_month:4, labor_day:1, month_labor:4, start_week:3, 
					start_time: '09:00', quit_time: '18:00', break_time: 60,
					after_work:44, before_work: 1
				};

var tenant = Object.assign({},initialTenant);	// tenAntはモーダル編集画面で使用している
var conform = false;							// 画面の入力確認
var userNames = [];								// ログイン名の一覧

class CreateTenant extends Component {

	// コンストラクター
	constructor(props) {
		super(props);
		this.state = {  
			tenant:  Object.assign({},initialTenant),
			step: 0,							// テナント作成の工程
												// 0.テナント情報の入力、1.登録コード・ユーザ名・パスワードの入力、2.カード情報の入力
			title: "アカウントの作成",
			showModal:false,
		};
	}


	// テナント取得
	getTable = (id) => {
		//ajax通信する
		Request.get("/tenant/" + id)
			.query( { token: JWToken.getToken().token } )
			.then( res => {
				this.setState({tenant: res.body.dbData[0] });
				tenant = res.body.dbData[0];
			})
			.catch( err => {
				console.log(err);
				console.error("テナントを取得できませんでした。");
				this.setState({tenant: tenant });
			});

	}


	// login名を探す
	getUserNames = () => {
		//ajax通信する
		Request.get("/tenant/username" )
			.query( {  token: JWToken.getToken().token } )
			.then( res => {
//				console.log(res.body.dbData);
				userNames = res.body.dbData;
			})
			.catch( err => {
				console.log(err);
				console.error("テナントを取得できませんでした。");
			});
	}

	// テナントを作成する
	createTenant = (tenant) => {
		Request.post('/tenant')
			.send({ dbData:tenant, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.error("テナントを追加できませんでした");
				}
				console.log(res);
				// SQUARE 顧客の作成
				// !!!!
//				this.getTable(res.body.rows[0].id);
//			}.bind(this));
			});
	}

	companyNameChange = (event) => {
		if ( event.target.value.length > MAX_COMPANY_LENGTH ) return;
		tenant.company_name   = event.target.value;
		this.setState({ tenant:tenant });
	}
	
	firstNameChange = (event) => {
		if ( event.target.value.length > MAX_NAME_LENGTH ) return;
		tenant.first_name    = event.target.value;
		this.setState({ tenant:tenant });
	}
	lastNameChange = (event) => {
		if ( event.target.value.length > MAX_NAME_LENGTH ) return;
		tenant.last_name    = event.target.value;
		this.setState({ tenant:tenant });
	}
	firstKanaChange = (event) => {
		if ( event.target.value.length > MAX_NAME_LENGTH ) return;
		tenant.first_kana    = event.target.value;
		this.setState({ tenant:tenant });
	}
	lastKanaChange = (event) => {
		if ( event.target.value.length > MAX_NAME_LENGTH ) return;
		tenant.last_kana    = event.target.value;
		this.setState({ tenant:tenant });
	}
	phoneChange = (event) => {
		if ( Check.phone(event.target.value) === false ) {
			return;
		}
		tenant.phone   = event.target.value;
		this.setState({ tenant:tenant });
	}
	zipcodeChange = (event) => {
		if ( Check.postCode(event.target.value) === false ) return;
		tenant.zipcode= event.target.value;
		this.setState({ tenant:tenant });
	}

	prefChange = (event) => {
		if ( event.target.value.length > MAX_PREF_LENGTH ) return;
		tenant.pref = event.target.value;
		this.setState({ tenant:tenant });
	}

	cityChange = (event) => {
		if ( event.target.value.length > MAX_CITY_LENGTH ) return;
		tenant.city = event.target.value;
		this.setState({ tenant:tenant });
	}

	suburbChange = (event) => {
		if ( event.target.value.length > MAX_SUBURB_LENGTH ) return;
		tenant.suburb = event.target.value;
		this.setState({ tenant:tenant });
	}
	
	address1Change = (event) => {
		if ( event.target.value.length > MAX_ADDRESS_LENGTH ) return;
		tenant.address1 = event.target.value;
		this.setState({ tenant:tenant });
	}
	address2Change = (event) => {
		if ( event.target.value.length > MAX_ADDRESS_LENGTH ) return;
		tenant.address2 = event.target.value;
		this.setState({ tenant:tenant });
	}
	
	mailChange = (event) => {
		if ( Check.email(event.target.value) === false ) return;
		tenant.mail = event.target.value;
		this.setState({ tenant:tenant });
	}
/********
	userNameChange  = (event) => { 
		if ( event.target.value.length > MAX_USERNAME_LENGTH ) return;
		
    	tenant.username  = event.target.value;
    	this.setState({ tenant:tenant });
    }
*********/
	userName  = (name) => { 
    	tenant.username  = name;
    	this.setState({ tenant:tenant });
    }
    passwordChange  = (event) => { 
		if ( event.target.value.length > MAX_PASSWORD_LENGTH ) return;
    	tenant.password  = event.target.value;
    	this.setState({ tenant:tenant });
    }
    password1Change = (event) => {
		if ( event.target.value.length > MAX_PASSWORD_LENGTH ) return;
    	tenant.password1 = event.target.value;
    	this.setState({ tenant:tenant });
    }

	registCodeChange = (event) => {
		if ( event.target.value.length > 4 ) return;
		tenant.registCode = event.target.value;
    	this.setState({ tenant:tenant });
	}

	// 乱数で文字列を自動生成する
	random = (N=4,S="0123456789") => {
//		const crypto = require('crypto');
//		return Array.from(crypto.randomFillSync(new Uint8Array(N))).map((n)=>S[n%S.length]).join('');
		let value = [];
		for (var i = 0; i < N; i++) {
		    value[i] = Math.floor(Math.random() * 10);
		}
		return value.join("");
	}

	open = () => {
		this.setState({	showModal: true });
		this.getUserNames();

	}

	// 変更で登録ボタンが押された
	close = () => {
		this.next1();
	}

	cancel = () => {
		this.setState({	showModal: false });
//  		window.location.href = window.location.origin + '/#/booking';    // ログインへ

	}


	toggle = () => {
		if ( this.state.showModal === true ) {
			this.cancel();
			this.setState( { showModal: false } );
		} else {
			this.setState( { showModal: true } );
		}
	}


	// 郵便番号から住所を取得する Zip2Addrのコールバック関数
	setAddress = (pref, city, suburb) => {
		tenant.pref   = pref;
		tenant.city   = city;
		tenant.suburb = suburb;
		this.setState({tenant:tenant});
	}


	// -------------------------------------------------------------------------
	// テナント登録情報を入力
	// -------------------------------------------------------------------------
	form1 = () => {
		
		// ---------------------------------------------.
		// 登録情報の入力を確認する
		// ---------------------------------------------.
		let company = 'OK';
		if ( !this.state.tenant.company_name || !this.state.tenant.company_name ) company = '省略不可';

		let name = 'OK';
		if ( !this.state.tenant.last_name || !this.state.tenant.first_name ) name = '省略不可';

		let kana = 'OK';
		if ( !this.state.tenant.last_kana || !this.state.tenant.first_kana )   kana = '省略不可';
		else if ( Check.isZenKatakana(this.state.tenant.last_kana)  === false ) kana = '全角カナ';
		else if ( Check.isZenKatakana(this.state.tenant.first_kana) === false ) kana = '全角カナ';

		let phone  = 'OK';
		let tel = this.state.tenant.phone.replace( /-/g, '');		// '-'を削除
		if ( !tel ) { 
			phone = '省略不可';
		} else if (!tel.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/)) {
			phone = 'NG';
		}

		let mail  = 'OK';
		var regexp = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
		if ( !this.state.tenant.mail ) {
			mail = '省略不可';
		} else if ( !regexp.test(this.state.tenant.mail) ) mail = '不正宛先';

		let zip  = 'OK';
		if ( !this.state.tenant.zipcode ) {
			zip = '省略不可';
		} else {
			let zipcode = this.state.tenant.zipcode.replace( /-/g, '');		// '-'を削除
			if ( zipcode.length < 7  ) {
				zip = 'NG';
			}
		}
		
		let pref  = 'OK';
		if ( !this.state.tenant.pref ) pref = '省略不可';
		let city  = 'OK';
		if ( !this.state.tenant.city  ) city = '省略不可';
		let suburb  = 'OK';
		if ( !this.state.tenant.suburb  ) suburb = '省略不可';

		let user  = 'OK';
		if ( !this.state.tenant.username ) user = '省略不可';
		if ( userNames.find((v) => v.username === this.state.tenant.username) ) user = '既に使用';
		
		let pswd  = 'OK';
		if ( !this.state.tenant.password) pswd = 'NG';
		else if ( this.state.tenant.password !== this.state.tenant.password1 ) pswd = 'NG';

		// Buttonの 有効/無効 を設定
		if ( name === 'OK' && kana === 'OK' && phone === 'OK' && mail === 'OK' && zip === 'OK' && pref ==='OK' && city ==='OK' && suburb ==='OK' && user === 'OK' && pswd === 'OK' ) {
			conform  = true;
		} else {
			conform  = false;
		}

		return (
			<form>
				<FormGroup>
				
					会社名を入力してください。<br/>
                    <InputGroup>
                        <InputGroupText style={{width:'90px'}}>会社名　</InputGroupText>
                        <Input type="text" placeholder="会社名" value={this.state.tenant.company_name}  onChange={this.companyNameChange}/>
                        <InputGroupText style={{width:'90px'}}>{company}</InputGroupText>
                    </InputGroup>
					<br/>お名前を入力してください。<br/>
                    <InputGroup>
                        <InputGroupText style={{width:'90px'}}>お名前　</InputGroupText>
                        <Input type="text" placeholder="姓" value={this.state.tenant.last_name}  onChange={this.lastNameChange}/>
                        <Input type="text" placeholder="名" value={this.state.tenant.first_name} onChange={this.firstNameChange}/>
                        <InputGroupText style={{width:'90px'}}>{name}</InputGroupText>
                    </InputGroup>
                    <InputGroup>
                        <InputGroupText>カタカナ</InputGroupText>
                        <Input type="text" placeholder="セイ" value={this.state.tenant.last_kana}  onChange={this.lastKanaChange}/>
                        <Input type="text" placeholder="メイ" value={this.state.tenant.first_kana} onChange={this.firstKanaChange}/>
                        <InputGroupText style={{width:'90px'}}>{kana}</InputGroupText>
                    </InputGroup>
					<br/>お電話番号を半角数字で入力してください。<br/>
                    <InputGroup>
                        <InputGroupText>電話番号</InputGroupText>
                        <Input type="phone" placeholder="電話番号（15文字以内）" value={this.state.tenant.phone} onChange={this.phoneChange}/>
                        <InputGroupText style={{width:'90px'}}>{phone}</InputGroupText>
                    </InputGroup>
					<br/>メールアドレスを入力してください。<br/>
                    <InputGroup>
                        <InputGroupText>ｅメール</InputGroupText>
                        <Input type="mail" placeholder="email address" value={this.state.tenant.mail} onChange={this.mailChange}/>
                        <InputGroupText style={{width:'90px'}}>{mail}</InputGroupText>
                    </InputGroup>
					<br/>郵便番号を入力してください。<br/>
					<InputGroup>
						<InputGroupText>郵便番号</InputGroupText>
						<Input type="text" placeholder="郵便番号" value={this.state.tenant.zipcode} onChange={this.zipcodeChange}/>
						<Zip2Addr zipcode={this.state.tenant.zipcode} callBack={this.setAddress}/>
                        <InputGroupText style={{width:'90px'}}>{zip}</InputGroupText>
					</InputGroup>
					<br/>住所を入力してください。<br/>
					<InputGroup>
						<InputGroupText>都道府県</InputGroupText>
                        <Input type="text" placeholder="都道府県" value={this.state.tenant.pref} onChange={this.prefChange}/>
                        <InputGroupText style={{width:'90px'}}>{pref}</InputGroupText>
					</InputGroup>
					<InputGroup>
						<InputGroupText>市区町村</InputGroupText>
                        <Input type="text" placeholder="市区町村" value={this.state.tenant.city} onChange={this.cityChange}/>
                        <InputGroupText style={{width:'90px'}}>{city}</InputGroupText>
					</InputGroup>
					<InputGroup>
						<InputGroupText>町域名　</InputGroupText>
						<Input type="text" placeholder="町域名" value={this.state.tenant.suburb} onChange={this.suburbChange} />
                        <InputGroupText style={{width:'90px'}}>{suburb}</InputGroupText>
					</InputGroup>
					<InputGroup>
						<InputGroupText>丁目番地</InputGroupText>
						<Input type="text" placeholder="丁目、番地、号" value={this.state.tenant.address1} onChange={this.address1Change} />
					</InputGroup>
					<InputGroup>
						<InputGroupText>ビル名等</InputGroupText>
						<Input type="text" placeholder="ビル名、階層、室など" value={this.state.tenant.address2} onChange={this.address2Change} />
					</InputGroup>

 					<br/>ユーザー名、パスワードを入力してください。<br/>(Webアプリ運用中に変更できます)<br/>
					<InputGroup>
						<InputGroupText style={{width:'136px'}}>ユーザー名　　</InputGroupText>
						<InputLoginName value='' callBack={this.userName} />
{/*						<Input type="text"  value={this.state.tenant.username} onChange={this.userNameChange}/> */}
                        <InputGroupText style={{width:'90px'}}>{user}</InputGroupText>
					</InputGroup>
					<InputGroup>
						<InputGroupText style={{width:'136px'}}>パスワード　　</InputGroupText>
						<Input type="password"  value={this.state.tenant.password} autoComplete="new-password" onChange={this.passwordChange}/>
					</InputGroup>
					<InputGroup>
						<InputGroupText style={{width:'136px'}}>パスワード確認</InputGroupText>
						<Input type="password"  value={this.state.tenant.password1} autoComplete="new-password" onChange={this.password1Change}/>
                        <InputGroupText style={{width:'90px'}}>{pswd}</InputGroupText>
					</InputGroup>
 				</FormGroup>
			</form>
		);
	}

	// 登録情報をメールで送信する。
	next1 = () => {
		console.log(this.state.tenant);

		// ここでlogin名をチェックする
		
		tenant.code = this.random();		// 登録コード 4桁

		Request.post('/sendemail/tenant'  )
			.send({ dbData:tenant, token: JWToken.getToken().token } )
			.then( res => {
				console.log(res);
				if ( !res.body.dbData ) {
//					console.log(res.body.dbData);
				}
			})
			.catch( err => {
				console.log(err,err.status);
				if ( err & err.status === 403 ) {
				}
			});
		this.setState({
			tenant:tenant,
			step:1
		});
	}		

	before1 = () => {
		
	}

	// -------------------------------------------------------------------------
	// テナント登録情報を確認、登録コードを入力
	// -------------------------------------------------------------------------
	form2 = () => {
	
		return (
			<form>
				<FormGroup>
 					<br/>「登録確認」メールを送信しました。<br/>
 					<br/>登録内容をご確認ください。<br/>
                   <InputGroup>
                        <InputGroupText style={{width:'90px'}}>お名前　　</InputGroupText>
                        <Input type="text" placeholder="姓" value={this.state.tenant.last_name}  readOnly={true} />
                        <Input type="text" placeholder="名" value={this.state.tenant.first_name} readOnly={true}/>
                    </InputGroup>
                    <InputGroup>
                        <InputGroupText style={{width:'90px'}}>カタカナ　</InputGroupText>
                        <Input type="text" placeholder="セイ" value={this.state.tenant.last_kana}  readOnly={true}/>
                        <Input type="text" placeholder="メイ" value={this.state.tenant.first_kana} readOnly={true}/>
                    </InputGroup>
                    <InputGroup>
                        <InputGroupText style={{width:'90px'}}>電話番号　</InputGroupText>
                        <Input type="phone" placeholder="電話番号（15文字以内）" value={this.state.tenant.phone} readOnly={true}/>
                    </InputGroup>
                    <InputGroup>
                        <InputGroupText style={{width:'90px'}}>ｅメール　</InputGroupText>
                        <Input type="mail" placeholder="email address" value={this.state.tenant.mail} readOnly={true}/>
                    </InputGroup>
					<InputGroup>
						<InputGroupText addontype="prepend" style={{width:'90px'}}>郵便番号　</InputGroupText>
						<Input type="text" placeholder="郵便番号" value={this.state.tenant.zipcode} readOnly={true}/>
					</InputGroup>
					<InputGroup>
						<InputGroupText style={{width:'90px'}}>都道府県　</InputGroupText>
						<Input type="text" placeholder="都道府県名" value={this.state.tenant.pref} readOnly={true}/>
					</InputGroup>
					<InputGroup>
						<InputGroupText style={{width:'90px'}}>市区町村　</InputGroupText>
						<Input type="text" placeholder="市区町村名" value={this.state.tenant.city} readOnly={true}/>
					</InputGroup>
					<InputGroup>
						<InputGroupText style={{width:'90px'}}>町域名　　</InputGroupText>
						<Input type="text" placeholder="町域名" value={this.state.tenant.suburb} readOnly={true}/>
					</InputGroup>
					<InputGroup>
						<InputGroupText style={{width:'90px'}}>丁目番地</InputGroupText>
						<Input type="text" placeholder="丁目、番地、号" value={this.state.tenant.address1} readOnly={true} />
					</InputGroup>
					<InputGroup>
						<InputGroupText style={{width:'90px'}}>ビル名等</InputGroupText>
						<Input type="text" placeholder="ビル名、階層、室など" value={this.state.tenant.address2} readOnly={true} />
					</InputGroup>
					<InputGroup>
						<InputGroupText style={{width:'90px'}}>ユーザ名</InputGroupText>
						<Input type="text" placeholder="ユーザ名" value={this.state.tenant.username} readOnly={true} />
					</InputGroup>
 					<br/>
					<font size="2">
					<ul>
						<li>入力内容を修正するときは「戻る」ボタンを押してください。</li>
						<li>「登録確認」メールに記載されている４桁の「登録コード」を入力して「次へ」ボタンを押してください。</li>
						<ul style={{listStyleType: 'square'}}>
							<li>メールで新規請求書をお届けしますので、クレジットカードでのお支払いをお願いいたします。</li>
							<li>アカウントを作成しますので、登録した「ユーザー名」と「パスワード」でログインしてください。</li>
						</ul>
					</ul>
					</font>
					<InputGroup>
						<InputGroupText>登録コード</InputGroupText>
						<Input type="text"  value={this.state.tenant.registCode} onChange={this.registCodeChange} autoFocus={true}/>
					</InputGroup>


 				</FormGroup>
			</form>
		);

	}	
	
	// 確認する事はない
	next2 = () => {
		if ( this.state.tenant.registCode === tenant.code )	{
console.log('createTenant()',this.state.tenant);
			let tenant = {
			    name:		this.state.tenant.company_name,	/* テナント名   */
			    first_name:	this.state.tenant.first_name,	/* 名           */
			    last_name:  this.state.tenant.last_name,	/* 姓           */
			    first_kana: this.state.tenant.first_kana,	/* メイ         */
			    last_kana:	this.state.tenant.last_kana,	/* セイ         */
			    mail:		this.state.tenant.mail,			/* メール       */
			    phone:      this.state.tenant.phone,   		/* 電話番号     */
			    zipcode:	this.state.tenant.zipcode,		/* 郵便番号     */
			    pref:		this.state.tenant.pref,			/* 都道府県		*/
			    city:		this.state.tenant.city,			/* 市区町村		*/
			    suburb:		this.state.tenant.suburb,		/* 町域名		*/
			    address1:   this.state.tenant.address1,	    /* 住所1        */
			    address2:	this.state.tenant.address2,		/* 住所2        */
			    last_login:	'2020-01-01',				/* 最終ログイン日時 */
    
			    begin_month:4,							/* 期首の月     */
			    begin_day:	1,							/* 期首の日     */
			    month:		4,							/* 期首を何月とするか */
    
			    start_time:	'09:00',					/* 就業規則の定時 */
			    quit_time:	'18:00',					/* 就業規則の定時 */
			    break_time:	'01:00',					/* 休憩時間 */
			    day_off:     0x04,          			/* 休業日 bit 0:日、1:月、2:火、3:水、4:木、5:金、6:土 */
			    labor_month: 4,         				/* 法定労働時間の計算の起算日 月45時間 年間360時間 */
			    labor_day:   1,         				/* 法定労働時間の計算の起算日 月45時間 年間360時間 */
			    month_labor: 4,         				/* 法定労働時間の計算の起算日 を何月とするか */
			    after_work:  44,            			/* 週40or44時間を残業時間とする */
			    start_week:  0,         				/* 法定労働時間は週40時間。計算の開始曜日(0-6) 7:計算しない */
			    before_work: 0,         				/* 1早出を残業とする、0:しない */
			    
			    username:	this.state.tenant.username,	/* ユーザー名、ログイン名、メールアドレス   */
    			password:	this.state.tenant.password,	/* パスワード   */
			};
console.log('createTenant()',tenant);
			this.createTenant(tenant);

			this.setState({step:2});
//			this.props.login();			// ログイン画面の表示は不要

			return;
		}
		alert('登録コードが違います。');
	}
	before2 = () => {
		this.setState({step:0});
		
	}
	


//-----------------------------------------------
// スクエア　カード情報入力画面
//-----------------------------------------------
	// スクエア カード情報の入力
	form3 = () => {
		
		let appId= process.env.REACT_APP_APPLICATION_ID;		// Square Appricarion ID
	    let locId= process.env.REACT_APP_LOCATION_ID;			// Square Location ID 
	console.log(appId,locId);
	
		return (
			<div>
				お客様のアカウントを作成します。<br/>
				クレジットカードをSquareに登録して頂くと、毎月3,300円を<br/>
				Squareを通じて自動的にお支払いできます。<br/>
				<PaymentForm
				    applicationId={appId}
				    locationId={locId}
					cardTokenizeResponseReceived = {(token, verifiedBuyer) => {
		        		console.log('token:', token);
		        		console.log('verifiedBuyer:', verifiedBuyer);
		        	}}>
					<CreditCard>クレジットカードの登録</CreditCard>
				</PaymentForm>
			</div>
		);
/*******************
		return (
			<form id="payment-form">
			  <div id="card-container"></div>
			  <button id="card-button" type="button">Pay</button>
			</form>
		)
*******************/
/****		
		let form = 
			this.state.loaded && <PaymentForm paymentForm={ window.SqPaymentForm } />		
console.log(form);
		return(form);
****/
	}
	
	// テナント情報の登録とカード情報の保存・テナントの作成
	next3 = () => {
		this.setState({
			step:0, 			
			showModal: false
		});
//		this.props.login();		// ログイン画面の表示は不要
		
	}
	before3 = () => {
		this.setState({step:1});
	}
	
	render() {

		const form = [this.form1, this.form2, this.form3];
		const next = [this.next1, this.next2, this.next3];
		const before = [this.before1, this.before2, this.before3];
		
		return (
			<div>
				<Button color="warning" onClick={this.open} className="btn btn-default">アカウント新規作成</Button>

				<Modal isOpen={this.state.showModal} backdrop={'static'} size={this.props.size} autoFocus={false}>
					<ModalHeader toggle={this.toggle} >{this.state.title}　{this.state.step+1}/3</ModalHeader>
					<ModalBody >
						{form[this.state.step]()}
					 </ModalBody>
					<ModalFooter>
						<Button color="warning" onClick={this.cancel}>キャンセル</Button>
							{ this.state.step !== 0 && <Button color="primary" onClick={before[this.state.step]} >戻る</Button> }
							{ this.state.step !== 2 && <Button color="primary" onClick={next[this.state.step]} disabled={!conform}>次へ</Button> }
					</ModalFooter>
				</Modal>

			</div>
		);

	}
}

export default CreateTenant;
