/* ==============================================================================
 お支払い情報の編集画面

 履歴-----------------------------------------
 2022/10/21 新規作成
 
=============================================================================== */

import React, { Component } from 'react';
import { InputGroup,InputGroupText,FormGroup,Input,Container,CardGroup,Card,CardBody,CardTitle} from 'reactstrap';
import ReactDataGrid from 'react-data-grid';
import Request from 'superagent';
import {JWToken} from './auth/Login';
// import GridTable from './GridTable';
import { AlignLeft,AlignCenter,YenFormat } from './Formatter';	// React Data Grid
// import { Filters } from 'react-data-grid-addons';
// import * as Square from '@square/web-sdk';
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';

/* =============================================================================
 * 請求先の情報表示
 * <Tenant master={tenant Object} result={callBack(tenant,confirm)} />
 * ============================================================================= */
const Tenant = (props) => {
//	const [dropdownAddr, setDropdownAddr] = useState(false);
//	const [address, setAddress] = useState([]);
//	const [tenant, setTenant] = useState(props.master);
	let tenant = props.master;

	return(
		<form>
			<FormGroup>
                <InputGroup>
                    <InputGroupText style={{width:'90px'}}>会社名　</InputGroupText>
                    <Input type="text" placeholder="会社名" value={tenant.name} readOnly />
                </InputGroup>
                <InputGroup>
                    <InputGroupText style={{width:'90px'}}>お名前　</InputGroupText>
                    <Input type="text" placeholder="姓" value={tenant.last_name} readOnly />
                    <Input type="text" placeholder="名" value={tenant.first_name} readOnly />
                </InputGroup>
                <InputGroup>
                    <InputGroupText>カタカナ</InputGroupText>
                    <Input type="text" placeholder="セイ" value={tenant.last_kana} readOnly />
                    <Input type="text" placeholder="メイ" value={tenant.first_kana} readOnly />
                </InputGroup>
				<br/>
                <InputGroup>
                    <InputGroupText>電話番号</InputGroupText>
                    <Input type="phone" placeholder="電話番号（15文字以内）" value={tenant.phone} readOnly />
                </InputGroup>
                <InputGroup>
                    <InputGroupText>ｅメール</InputGroupText>
                    <Input type="mail" placeholder="email address" value={tenant.mail} readOnly/>
                </InputGroup>
				<br/>
				<InputGroup>
					<InputGroupText>郵便番号</InputGroupText>
					<Input type="text" placeholder="郵便番号" value={tenant.zipcode} readOnly />
				</InputGroup>
				<InputGroup>
					<InputGroupText>住　所　</InputGroupText>
                    <Input type="text" placeholder="住所" value={tenant.pref+tenant.city+tenant.suburb} readOnly />
				</InputGroup>
				<InputGroup>
					<InputGroupText>丁目番地</InputGroupText>
					<Input type="text" placeholder="丁目、番地、号" value={tenant.address1} readOnly />
				</InputGroup>
				<InputGroup>
					<InputGroupText>ビル名等</InputGroupText>
					<Input type="text" placeholder="ビル名、階層、室など" value={tenant.address2} readOnly />
				</InputGroup>
			</FormGroup>
		</form>
	);
};

/* =============================================================================
 * クレジットカードの登録
 * <CardsPeiments master={tenant Object} result={callBack(tenant,confirm)} />
 * ============================================================================= */
/****************/
const CardsPayments = (props) => {
    let appId= process.env.REACT_APP_APPLICATION_ID;		// Square Appricarion ID
    let locId= process.env.REACT_APP_LOCATION_ID;			// Square Location ID 
console.log(appId,locId);

	return (
		<PaymentForm
		    applicationId={appId}
		    locationId={locId}
			cardTokenizeResponseReceived = {(token, verifiedBuyer) => {
        		console.log('token:', token);
        		console.log('verifiedBuyer:', verifiedBuyer);
        	}}
		    createVerificationDetails={() => ({
		      amount: 3000,
		      currencyCode: 'JPY',
		      intent: 'CHARGE',
		    })}
		>
			<CreditCard>税込3,300円お支払い</CreditCard>
		</PaymentForm>
	);
};
/****************
const CardsPayments = async (props) => {
	let appId= process.env.REACT_APP_APPLICATION_ID;		// Square Appricarion ID
    let locId= process.env.REACT_APP_LOCATION_ID;			// Square Location ID 
console.log(appId,locId);

    const payments = Square.payments( appId, locId);
	const cardOptions = {
		style: {
			input:{
				backgroundColor: 'white'
			}
		}	
	};
    const card = await payments.card(cardOptions);
console.log(card);    
    await card.attach('#card-container');
//    await card.attach('#card');

	document.querySelector('#card-button').addEventListener('click', async () => {
		try {
			const result = await card.tokenize();
			console.log(result);
			// TODO: use result.token as source_id in /v2/payments API call
		} catch (ex) {
			console.error(ex);
		}
	});

	return (
		<div>
			<form id="payment-form">
				<div id="card-container"></div>
				<button id="card-button" type="button">Pay \3300</button>
			</form>
			<div id="payment-status-container"></div>
		</div>
	)
};
/ ****************/

/* =============================================================================
 * 登録クレジットカードの情報表示
 * <Cards data={[cards Object]} />
 * ============================================================================= */
const Cards = (props) => {
//	[cards, setCards] = useState(props.data);
	let cards = props.data;
	
	const rowGetter = (rowIdx) => {
		return(cards[rowIdx]);
	};
	
	const columns = [
		{ key: 'cardBrand',		name: 'カード会社',	width: 200, editable: false, resizable: true, formatter:AlignCenter, filterable: true, sortable: true },
		{ key: 'last4',			name: '下4桁',		width:  60, editable: false, resizable: true, formatter:AlignLeft  , filterable: true, sortable: true },
		{ key: 'expMonth',		name: '月',			width:  60, editable: false, resizable: true, formatter:AlignLeft  , filterable: true, sortable: true },
		{ key: 'expYear',		name: '年',			width:  60, editable: false, resizable: true, formatter:AlignLeft  , filterable: true, sortable: true },
		{ key: 'cardholderName',name: 'お名前',				    editable: false, resizable: true, formatter:AlignLeft  , filterable: true, sortable: true },
	];
	
	return(
		<ReactDataGrid rowKey="id"
			columns={columns} rowGetter={rowGetter} rowsCount={cards.length}
			minHeight={184} 
		/>
	);
 		
};

/* =============================================================================
 * 請求明細書の情報表示
 * <Invoice master={tenant Object} result={callBack(tenant,confirm)} />
 * ============================================================================= */
const Invoice = (props) => {
	const invoice = props.data;

	const rowGetter = (rowIdx) => {
		return invoice[rowIdx];
	};

	const columns = [
		{ key: 'invoiceNumber',	name: '番号',	    width: 144, editable: false, resizable: false,formatter:AlignCenter, filterable: true, sortable: true },
		{ key: 'title',			name: 'タイトル',	width: 200, editable: false, resizable: true, formatter:AlignLeft  , filterable: true, sortable: true },
		{ key: 'dueDate',		name: '支払期日',	width: 120, editable: false, resizable: true, formatter:AlignLeft  , filterable: true, sortable: true },
		{ key: 'amount',		name: '金額',		width: 120, editable: false, resizable: true, formatter:YenFormat  , filterable: true, sortable: true },
		{ key: 'status',		name: '状況',		width: 120, editable: false, resizable: true, formatter:AlignLeft  , filterable: true, sortable: true },
		{ key: 'description',	name: '説明',					editable: false, resizable: true, formatter:AlignLeft,	 filterable: true, sortable: true },
	];
				 
	return (
			<ReactDataGrid rowKey="id"
				columns={columns} rowGetter={rowGetter} rowsCount={invoice.length}
				minHeight={240} //minWidth={660} 
			/>
	);
};

/* =============================================================================
 * 支払情報の表示
 * 
 * ============================================================================= */
const initialRow  = {id: 0, name: "", phone: "", pref: "", city: "", suburb: "", address1: "", address2: "", time_unit: 5, break_time: "01:00", stime: "09:00", etime: "19:00", workday: 0, shift_month: null };
const initialCard = [{id: "", card_brand: "", last_4: "", exp_month: "", exp_year: "", cardholder_name: "" }];

class Payment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tenant: initialRow,
			customers:[{}],
			cards: initialCard,
			invoice:[{}],
		};
		this.getTenant  (JWToken.getToken().tenant_id);
		this.getCustomer(JWToken.getToken().tenant_id);
	}

	getTenant = (id) => {
		//ajax通信する
		Request.get("/tenant/" + id)
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.log(err);
					console.log(res);
					console.error("テナントを取得できませんでした。");
//					this.setState({rowData: initialRow });
				} else {
					this.setState({tenant: res.body.dbData[0] });
				}
			}.bind(this));
	}


	// テナントの変更
	putTenant = () => {
		Request.put('/tenant/' + this.state.tenant.id )
			.send({ dbData:this.state.tenant, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.error("テナントを登録できませんでした");
					return;
				}
				this.getTableList();
			}.bind(this));
	};

    // square customer取得
    getCustomer = (id) => {
        //ajax通信する
        console.log('getCustomer', id);
        Request.get("/square/customer/" + id )
            .query({ token: JWToken.getToken().token})
            .end(function(err, res) {
                if (err) {
                    console.log(err);
					console.error("/square/customerを取得できませんでした。");
//                    setCustomer({});
					return;
                }
//                console.log(res.body.dbData);
                this.setState({
                	customers: res.body.dbData,
  					cards:     res.body.dbData[0].cards
                });
 				this.getInvoice(res.body.dbData[0].id);

            }.bind(this));
    };

	// square invoice 請求書取得
	 getInvoice = (customer_id) => {
		//ajax通信する
		Request.get("/square/invoice/" + customer_id)
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.log(err);
					console.log(res);
					console.error("/square/invoiceを取得できませんでした。");		// 高橋ハジメさんだけは取り込めない？？？？ 2022-12-30
					return;
				}
                console.log(res.body.dbData);
                this.setState({invoice: res.body.dbData});
			}.bind(this));
	};

	result = (master,confirm) => {
		this.setState({tenant:master});
		console.log(confirm);
	};

	render() {
		return (
			<Container >
				<CardGroup>
					<div className="col-sm-6 h-100">
						<Card >
							<CardBody>
								<CardTitle tag="h5">ご請求先</CardTitle>
								<Tenant master={this.state.tenant} result={this.result}/>
							</CardBody>
						</Card>
					</div>
				
					<div className="col-sm-6 h-100">
						<Card >
							<CardBody>
								<CardTitle tag="h5">お支払い</CardTitle>
								<CardsPayments />
							</CardBody>
						</Card>
						<Card style={{border:'none'}}>
							<CardBody>
								<CardTitle tag="h5">ご登録カード</CardTitle>
								<Cards data={this.state.cards } />
							</CardBody>
						</Card>
					</div>
					
					<div className="col-sm-12 h-100">
						<Card style={{border:'none'}}>
							<CardBody>
								<CardTitle tag="h5">お支払い情報</CardTitle>
								<Invoice data={this.state.invoice}/>
							</CardBody>
						</Card>
					</div>
				</CardGroup>
			</Container>
		);
    }
}

export default Payment;
