/* ==============================================================================
 個客情報の編集画面

 履歴-----------------------------------------
 2019/03/17 reactstrap 対応済み

=============================================================================== */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button,Modal,ModalHeader,ModalBody,ModalFooter} from 'reactstrap';
import { InputGroup,InputGroupText,Input,FormGroup } from 'reactstrap';
import { ButtonDropdown,DropdownMenu,DropdownItem,DropdownToggle } from 'reactstrap';
import { Container,Row,Col} from 'reactstrap';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/ja';
import Select from 'react-select';
import { BsFillSkipBackwardFill,BsFillSkipForwardFill } from "react-icons/bs";

import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ja from 'date-fns/locale/ja';

import HelpPopover from './HelpPopover';

import './Table.css';

import Request from 'superagent';
import {JWToken,Alert} from './auth/Login';
import Check from './Input';

import {
	EDIT_UNSELECT, /* EDIT_SELECT = 2, */ EDIT_ADD, EDIT_REP, EDIT_DEL,
	MAX_DB_LENGTH,
	
	MAX_NAME_LENGTH,
	MAX_KANA_LENGTH,
	MAX_MAIL_LENGTH,
	MAX_PHONE_LENGTH,
	MAX_ZIPCODE_LENGTH,
	MAX_ADDRESS1_LENGTH,
	MAX_ADDRESS2_LENGTH,
	MAX_PASSWORD_LENGTH,
	
	CHECK_NO, CHECK_NAME, CHECK_DUP, CHECK_STAFF,
	COLOR_ERROR, COLOR_OK,
	
	TIMEZONE
} from './Define';

/****************************************
const EDIT_UNSELECT = 1, / * EDIT_SELECT = 2, * / EDIT_ADD = 3, EDIT_REP = 4, EDIT_DEL = 5;
const MAX_DB_LENGTH = 100000;

const MAX_NAME_LENGTH = 20;
const MAX_KANA_LENGTH = 20;
const MAX_MAIL_LENGTH = 128;
const MAX_PHONE_LENGTH = 15;
const MAX_ZIPCODE_LENGTH = 8;
const MAX_ADDRESS1_LENGTH = 60;
const MAX_ADDRESS2_LENGTH = 60;
const MAX_PASSWORD_LENGTH = 64;

const CHECK_NO = 0x01, CHECK_NAME = 0x02, CHECK_DUP = 0x04;
const COLOR_ERROR = '#fff0c0', COLOR_OK = '#ffffff';
********************************************/

// タイムゾーン
// const TIMEZONE = 'Asia/Tokyo';

const initialRow = {id: 0, tenant_id: 0, no: 0, name: "", kana: "", mail: "", cellula: "", phone: "", zipcode:"", address1: "", address2:"", comment: "", staffname:"", staff_id: 0, birthday:null, password:"" };

var condition = 0;			// 画面の入力チェック

class CustEdit extends Component {

	static propTypes = {
		editStatus:		PropTypes.number.isRequired,
		showModal:		PropTypes.bool.isRequired,			// Modal画面の表示/非表示
    	customer:		PropTypes.object.isRequired,		// 顧客
/***
    	customer:		PropTypes.shape({
			id:     	PropTypes.number.isRequired,		// 顧客ID
			tenant_id:	PropTypes.number.isRequired,		// テナントID
			no:			PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,		// 顧客番号
			name:   	PropTypes.string.isRequired,		// 顧客名  
			kana:   	PropTypes.string.isRequired,		// カナ 
			mail:   	PropTypes.string.isRequired,		// メール 
			cellula:	PropTypes.string.isRequired,		// 携帯番号 
			phone:  	PropTypes.string.isRequired,		// 電話番号
			zipcode:	PropTypes.string.isRequired,		// 郵便番号 
			address1:	PropTypes.string.isRequired,		// 住所1 
			address2:	PropTypes.string.isRequired,		// 住所2
			birthday:	PropTypes.any.isRequired,			// 誕生日
			gender:     PropTypes.bool.isRequired,			// 性別
			kubun:      PropTypes.number.isRequired,        // 顧客区分
			staff_id:   PropTypes.number.isRequired,        // 担当者ID 
			point:      PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,        // 売上の数パーセントをポイントにする
			comment:    PropTypes.string.isRequired,        //コメント 別にすると高速になるのか？
			password:   PropTypes.string.isRequired,		// パスワード
		}),
***/
		customerList:	PropTypes.array.isRequired,			/* 顧客マスター   */
		staffList:		PropTypes.array.isRequired,			/* 担当者マスター */
		onCancel:		PropTypes.func.isRequired,			/* キャンセル時のコールバック */
		onCloseRep:		PropTypes.func.isRequired,			/* 編集のクローズ */
	}

	static defaultProps = {
		editStatus: EDIT_UNSELECT,
		customer: Object.assign({},initialRow),
	}

	// コンストラクター
	constructor(props) {
		super(props);

		this.state = {  customer: Object.assign({},this.props.customer),
						address: [],						// 郵便番号から住所を取得した時の格納エリア
						showModal: false,
						dropdownAddr: false,
						noStatus: "ＯＫ",
						readOnly: "",         // 削除時は各項目を編集付加に設定
						buttonDisable: true,  // 一覧選択時の修正ボタン、削除ボタンの設定
						closeButton: false,   // 編集時の登録ボタン
						noColor: COLOR_OK,
						nameColor: COLOR_OK
		};

		moment.locale('ja');
		moment.tz.setDefault(TIMEZONE);		// timezoneをJSTに変更する

		registerLocale('ja',ja);		// react-datepicker の日本語表記
	}

	// 描画が行われる直前に呼ばれる
	componentDidMount = () => {
		this.setState({ customer:	Object.assign({}, this.props.customer) });		// propsで受け取った顧客情報をstateにコピーする

		switch ( this.props.editStatus) {
			case EDIT_ADD:
				this.openAdd();
				break;
			
			case EDIT_REP:
				this.openRep();
				break;
			
			case EDIT_DEL:
				this.openDel();
				break;
			
			default:
				this.openDel();
				break;
		}
	}

	static getDerivedStateFromProps = (nextProps, prevState) => {
//		console.log('getDerivedStateFromProps()',nextProps);
		let cust = nextProps.customer;
		if ( cust === null || cust === undefined ) {
			cust = Object.assign({}, initialRow);
		}
		return ({
			customer: cust,
			showModal:nextProps.showModal,
		});
	}




	// 追加ボタンが押された
	openAdd = () => {
		let customer = Object.assign({},initialRow);
		customer.staff_id = this.props.staffList[0].id;	// 担当者は最初の担当者にする
		this.setState({ 
			customer:  customer,
			readOnly: ""
		});
		condition = 0;
		this.checkParam(customer);
	}

	// 修正ボタンが押された
	openRep = () => {
		let customer = Object.assign({},this.props.customer);
		if ( customer.comment !== null && customer.comment !== undefined ) {
			customer.comment = customer.comment.replace(/\\n/g,'\n');		// reactは'\n'の処理を行ってくれない？ので自分で改行した
		}
//		this.props.onEdit(customer,condition);				// 親Classにcustomerを渡す

		this.setState({ 
			customer: customer,
			readOnly: "",
			noColor: COLOR_OK,
			nameColor: COLOR_OK,
		});
		condition = 0;
//		this.checkParam(customer);

	}

	// 削除ボタンが押された
	openDel = ( row ) => {
		let customer = Object.assign({},this.props.customer);
		if ( customer.comment !== null && customer.comment !== undefined ) {
			customer.comment = customer.comment.replace(/\\n/g,'\n');		// reactは'\n'の処理を行ってくれない？ので自分で改行した
		}
		this.setState({ 
			customer: customer,
			readOnly: "readonly",
			noColor: '#e9ecef',
			nameColor: '#e9ecef',
			staffColor: '#e9ecef',
			closeButton: true
		});
	}

	// 追加でクローズボタンが押された
	closeAdd = () => {
		let customer = this.state.customer;
		delete customer.sumName;
		this.props.closeAdd(customer);
	}

	// 追加でクローズボタンが押された
	closeRep = () => {
		let customer = this.state.customer;
		delete customer.sumName;
		this.props.onCloseRep(customer);
	}

	// 削除でクローズボタンが押された
	closeDel = () => {
		let customer = this.state.customer;
		customer.no = MAX_DB_LENGTH + customer.id;		// 実際は削除しない
		delete customer.sumName;
		this.props.closeDell(customer);
	}


	cancelCust = () => {
		this.setState({showModal:!this.state.showModal});
		
	}

	closeButton = (bool) => {
		this.setState({closeButton: bool });
	}


	// 郵便番号から住所を取得する
	onZipAddr = () => {
		let zipcode = this.state.customer.zipcode;
		if ( zipcode === null || zipcode === undefined ) { 
			Alert("郵便番号を入力してください。");
			return;
		}

		zipcode = zipcode.replace( "-" , "" ) ;
		if ( zipcode.length !== 7 ) {
			Alert("郵便番号を確認してください。");
			return;
		}

		//ajax通信する
		Request.get("/zipcode/" + zipcode)
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.error("住所を取得できませんでした。");
					console.log(err);
				} else {
					console.log(res);
					let customer = this.state.customer;
					switch (res.body.dbData.length) {
						case 0:
							Alert('指定された郵便番号は見つかりません。');
							break;
						case 1:
		        			customer.address1 = res.body.dbData[0].address;
			       			this.setState({ customer:customer });
//			       			this.props.onEdit(customer,condition);					// 親Classにcustomerを渡す
							break;
						default:
							this.setState({ address: res.body.dbData } );
							this.setState({ dropdownAddr: true });
							break;
					}
				}
			}.bind(this));
	}


	// DBに登録されている番号か検索する trueはnoに問題ない
	checkNo = ( id,no ) => {
		let res = this.props.customerList.find( function (row,index){
			// eslint-disable-next-line
			if ( id == row.id ) return false;
			// eslint-disable-next-line
			if ( no == row.no ) return true;
			return false;
		});

		if ( res === undefined ) return true;
		return false;
	}


//-----------------------------------------------
// イベント処理関数
//-----------------------------------------------
	noChange = (event) => {
		let customer = this.state.customer;
		let id    = customer.id;				// 編集中のid 追加時は0
		let newNo = event.target.value;     	// 入力した番号
		customer.no = newNo;

		let res = this.checkNo(id,newNo);		// 番号を検索する
		if ( res === true ) {
			this.setState( { 
				customer: customer,
				noStatus: "ＯＫ" ,
			});
			condition = condition & ~CHECK_DUP;
		} else {
			this.setState( { 
				customer: customer,
				noStatus: "重複" ,
			});
			condition = condition | CHECK_DUP;
		}
		this.checkParam(customer);
	}

	// InputにFocusを当てると選択状態にする
	focusSelect = (event) => {
		event.target.select();
	}
	

	decNo = () => {
		let customer = this.state.customer;
		let no = Number(customer.no);
		if ( no > 1 ) no--;
		let i = this.props.customerList.findIndex( cust =>  cust.no === no );
		if ( i !== -1 ) {
			for ( i-- ; i >= 0 ; i-- ) {
				if ( this.props.customerList[i].no !== --no ) {
					customer.no = no;
					this.setState( { 
						customer: customer,
						noColor:   COLOR_OK,
						noStatus: "ＯＫ" ,
					});
					condition = condition & ~CHECK_DUP;
					return;
				}
			}
			no--;
		}
		if ( no > 0 ) {
			customer.no = no;
			this.setState({
				customer: customer,
				noColor:   COLOR_OK,
				noStatus: "ＯＫ" ,
			});
			condition = condition & ~CHECK_DUP;
		}
	}

	incNo = () => {
		let customer = this.state.customer;
		let no = Number(customer.no) + 1;
		let i = this.props.customerList.findIndex( cust =>  cust.no === no );
		if ( i !== -1 ) {
			let len = this.props.customerList.length;
			for ( i++ ; i < len ; i++ ) {
				if ( this.props.customerList[i].no !== ++no ) {
					customer.no = no;
					this.setState( { 
						customer: customer,
						noColor:   COLOR_OK,
						noStatus: "ＯＫ" ,
					});
					condition = condition & ~CHECK_DUP;
					return;
				}
			}
			no++;
		}
		customer.no = no;
		this.setState( { 
			customer: customer,
			noColor:   COLOR_OK,
			noStatus: "ＯＫ" ,
		});
		condition = condition & ~CHECK_DUP;
	}


	nameChange = (event) => {
		if ( event.target.value.length > MAX_NAME_LENGTH ) return;
		
		let customer = this.state.customer;
		customer.name = event.target.value;
		this.setState({ customer:customer });

		this.checkParam(customer);
	}
	
	kanaChange = (event) => {
		if ( event.target.value.length > MAX_KANA_LENGTH ) return;
		
		let customer = this.state.customer;
		customer.kana = event.target.value;
		this.setState({ customer:customer });
	}
	
	mailChange = (event) => {
		if ( event.target.value.length > MAX_MAIL_LENGTH ) return;
		if ( Check.email(event.target.value) === false ) return;
		
		let customer = this.state.customer;
		customer.mail = event.target.value;
		this.setState({ customer:customer });
	}
	
	genderChange = (event) => {
		let customer = this.state.customer;
		customer.gender = event.target.value;
		this.setState({ customer:customer });
	}
	
	cellulaChange = (event) => {
		if ( event.target.value.length > MAX_PHONE_LENGTH ) return;
		if ( Check.phone(event.target.value) === false ) return;
		
		let customer = this.state.customer;
		customer.cellula   = event.target.value;
		this.setState({ customer:customer });
	}
	
	phoneChange = (event) => {
		if ( event.target.value.length > MAX_PHONE_LENGTH ) return;
		if ( Check.phone(event.target.value) === false ) return;
		
		let customer = this.state.customer;
		customer.phone   = event.target.value;
		this.setState({ customer:customer });
	}
	
	staffChange = (option) => {
		let customer = this.state.customer;
		customer.staff_id= option.value;
		this.setState({ customer:customer });
	}
	
	zipcodeChange = (event) => {
		if ( event.target.value.length > MAX_ZIPCODE_LENGTH ) return;
		if ( Check.postCode(event.target.value) === false ) return;
		
		let customer = this.state.customer;
		customer.zipcode= event.target.value;
		this.setState({ customer:customer });
	}
	
	birthdayChange = (date) => {
		let customer = this.state.customer;
		if ( date === "" ) {
			customer.birthday = "";
		} else {
			customer.birthday = date;
		}
		this.setState({ customer:customer });
	}
	
	age = (value) => {
		var d = new Date(value);
		if ( d.toString() === "Invalid Date" ) return '';
		if ( value === undefined ) return '';
		if ( value === null ) return '';
		if ( value === ""   ) return '';

		var birth = new Date(value);	//誕生日 年月日
		var today = new Date();			//今日

		//今年の誕生日
		var thisYearBirthday = new Date(today.getFullYear(), birth.getMonth(), birth.getDate());  

		var age = today.getFullYear() - birth.getFullYear();	//今年-誕生年
		 
		//今年の誕生日を迎えていなければage-1を返す
		return (today < thisYearBirthday)?age-1:age;
	}
	
	address1Change = (event) => {
		if ( event.target.value.length > MAX_ADDRESS1_LENGTH ) return;
		let customer = this.state.customer;
		customer.address1 = event.target.value;
		this.setState({ customer:customer });
	}
	address2Change = (event) => {
		if ( event.target.value.length > MAX_ADDRESS2_LENGTH ) return;
		let customer = this.state.customer;
		customer.address2 = event.target.value;
		this.setState({ customer:customer });
	}

	commentChange = (event) => {
		let customer = this.state.customer;
		customer.comment  = event.target.value; 
		this.setState({ customer:customer });
	}

	pointChange = (event) => {
// console.log(event.target.value);
		let val = Number(event.target.value);
		if ( isNaN(val) ) return; 
		let customer = this.state.customer;
		customer.point = event.target.value;
		this.setState({ customer:customer });
	}

	passwordChange = (event) => {
		if ( event.target.value.length > MAX_PASSWORD_LENGTH ) return;
		let customer = this.state.customer;
		customer.password = event.target.value;
		this.setState({ customer:customer });
	}

	toggle = () => {
		if ( this.state.showModal === true ) {
			this.props.onCancel();
			this.setState( { showModal: false } );
		} else {
			this.setState( { showModal: true } );
		}
	}

	toggleAddr = (event) => {
		if ( this.state.dropdownAddr === true ) {
			let customer = this.state.customer;
			customer.address1 = event.currentTarget.textContent;
   			this.setState({
   						customer:customer,
						dropdownAddr: false
			});
		} else {
//			this.onPostCode();		// 
			this.onZipAddr();		// DBを参照
//			this.setState( { dropdownAddr: true } );
		}
	}

	checkParam = (customer) => {
		// 番号をチェック
		console.log('CustEdit checkParam',customer);
		switch ( customer.no ) {
			case undefined:
				break;
			case 0:
			case "":
			case "0":
			case null:
				condition = condition | CHECK_NO;				// 実行ボタンを無効にする
				this.setState( { noStatus: "ＮＧ" });
				break;
			default:
				condition = condition & (~CHECK_NO);			// 実行ボタンを有効にする
				break;
		}

		// 名前をチェック
		switch ( customer.name ) {
			case undefined:
				break;
			case "":
			case null:
				condition = condition | CHECK_NAME;		// 実行ボタンを無効にする
				break;
			default:
				condition = condition & (~CHECK_NAME);	// 実行ボタンを有効にする
				break;
		}

		// 担当者をチェック
		switch ( customer.staff_id ) {
			case undefined:
			case 0:
			case null:
				condition = condition | CHECK_STAFF;		// 実行ボタンを無効にする
				break;
			default:
				condition = condition & (~CHECK_STAFF);	// 実行ボタンを有効にする
				break;
		}

		// ボタン、バックグランドカラーの設定
		if ( condition === 0) {							// 一つでもエラーがあればボタンを無効にする
			this.setState({
				closeButton: true,
				noColor:   COLOR_OK,
				nameColor: COLOR_OK,
				staffColor: COLOR_OK,
				noStatus: "ＯＫ"
			});
		} else {
			this.setState({	closeButton: false });
			if ( (condition & (CHECK_NO | CHECK_DUP)) === 0 ) {
				this.setState({
					noColor: COLOR_OK,
					noStatus: "ＯＫ"
				});
			} else {
				this.setState({	noColor: COLOR_ERROR });
			}
	
			if ( (condition & CHECK_NAME) === 0 ) {
				this.setState({	nameColor: COLOR_OK	});
			} else {
				this.setState({	nameColor: COLOR_ERROR });
			}

			if ( (condition & CHECK_STAFF) === 0 ) {
				this.setState({	staffColor: COLOR_OK	});
			} else {
				this.setState({	staffColor: COLOR_ERROR });
			}

		}
	}


	render() {

		// 誕生日
		var birthDate = '';
		if ( this.state.customer !== null && this.state.customer !== undefined) {
			if ( this.state.customer.birthday !== null ) {
				birthDate = moment(this.state.customer.birthday).valueOf();
			}
		}
		
		// スタッフの設定
		let list = this.props.staffList.filter( (staff) => {		// 削除された番号は表示しない
			return staff.no < MAX_DB_LENGTH;
		});
		const options = list.map( (staff) => {
			return { value: staff.id, label:staff.no + ' ' + staff.name};
		});
		var staff = options.find( (option) => {
			return option.value === this.state.customer.staff_id;
		});

		const genderOption = [
			{ value: 1,  label:'女性'}, // true
			{ value: 0,  label:'男性'}	// false
		];
		var gender = this.state.customer.gender ? genderOption[0]:genderOption[1];

		return (
			<div>
				<Modal isOpen={this.state.showModal} backdrop={'static'} onClose={this.cancelCust} autoFocus={false}> 
					<ModalHeader toggle={this.toggle} >
						<Container >
					      <Row>
					        <Col>お客様</Col>
					        <Col>変更</Col>
					        <Col><HelpPopover id='GridTable' help={help} /></Col>
					      </Row>
						</Container>
					</ModalHeader>
					<ModalBody>
						<form>
							<FormGroup disabled>
								<InputGroup>
									<InputGroupText>番　号</InputGroupText>
									<Button onClick={this.decNo} ><BsFillSkipBackwardFill/></Button>
									<Input type="number" style={{backgroundColor: this.state.noColor}} placeholder="番号" value={this.state.customer.no} onChange={this.noChange} onFocus={this.focusSelect} readOnly={this.state.readOnly} autoFocus={true}/>
									<Button onClick={this.incNo} ><BsFillSkipForwardFill/></Button>
									<InputGroupText>{this.state.noStatus}</InputGroupText>
								</InputGroup>
								<InputGroup>
									<InputGroupText>名　前</InputGroupText>
									<Input type="text" style={{backgroundColor: this.state.nameColor}} placeholder="名前（20文字以内）" value={this.state.customer.name} onChange={this.nameChange} readOnly={this.state.readOnly}/>
									<InputGroupText>カ　ナ</InputGroupText>
									<Input type="text" placeholder="カナ（20文字以内）" value={this.state.customer.kana} onChange={this.kanaChange} readOnly={this.state.readOnly}/>
								</InputGroup>
								<InputGroup>
									<InputGroupText>性　別</InputGroupText>
									<Select className="form-control react-select-original" styles={{ menu: provided => ({ ...provided, zIndex: 100 }) }}
											placeholder="性別" value={gender} options={genderOption} onChange={this.genderChange}  isDisabled={this.state.readOnly}/>
{/*
									<Input type="select" placeholder="性別" value={this.state.customer.gender} onChange={this.genderChange} >
										<option value={true} key={0}>{"女性"}</option>
										<option value={false} key={1}>{"男性"}</option>
									</Input>
*/}			
									<InputGroupText>担当者</InputGroupText>
{/*									<Select className="form-control react-select-original" styles={{ menu: provided => ({ ...provided, zIndex: 100 }) }} */}
									<Select className="form-control react-select-original" styles={{ menu: provided => ({ ...provided, zIndex: 100 }) , control: (provided, state) => ({ ...provided,backgroundColor:this.state.staffColor }) }}
											placeholder="担当者名" value={staff} options={options} onChange={this.staffChange} />
								</InputGroup>
								<InputGroup>
									<InputGroupText>携　帯</InputGroupText>
									<Input type="tel" placeholder="携帯電話番号（15文字）" value={this.state.customer.cellula} onChange={this.cellulaChange} readOnly={this.state.readOnly}/>
									<InputGroupText>電　話</InputGroupText>
									<Input type="tel" placeholder="電話番号（15文字）" value={this.state.customer.phone} onChange={this.phoneChange} readOnly={this.state.readOnly}/>
								</InputGroup>
								<InputGroup>
									<InputGroupText>メール</InputGroupText>
									<Input type="email" placeholder="メール（128文字以内）" value={this.state.customer.mail} onChange={this.mailChange} readOnly={this.state.readOnly}/>
								</InputGroup>
								<InputGroup>
									<InputGroupText>誕生日</InputGroupText>
									<DatePicker className="form-control" selected={birthDate} onChange={this.birthdayChange} placeholderText="お誕生日"
											dateFormat="yyyy-MM-dd"  isClearable readOnly={this.state.readOnly} />
									<InputGroupText>{this.age(this.state.customer.birthday)+ '才'}</InputGroupText>
								</InputGroup>
								<InputGroup>
									<InputGroupText>〒番号</InputGroupText>
									<Input type="text" placeholder="郵便番号" value={this.state.customer.zipcode} onChange={this.zipcodeChange} readOnly={this.state.readOnly}/>
							        <ButtonDropdown isOpen={this.state.dropdownAddr} toggle={this.toggleAddr}>
							            <DropdownToggle caret>住所検索</DropdownToggle>
										<DropdownMenu>
											<DropdownItem header>以下から町域を選択してください</DropdownItem>
											<DropdownItem divider />
											{
												this.state.address.map(function (item) {
													return <DropdownItem>{item.address}</DropdownItem>;
			//										return <DropdownItem>{item}</DropdownItem>;
												})
											}
							        	</DropdownMenu>
							        </ButtonDropdown>
								</InputGroup>
								<InputGroup>
									<InputGroupText>住所１</InputGroupText>
									<Input type="text" placeholder="住所（100文字以内）" value={this.state.customer.address1} onChange={this.address1Change} readOnly={this.state.readOnly}/>
								</InputGroup>
								<InputGroup>
									<InputGroupText>住所２</InputGroupText>
									<Input type="text" placeholder="住所（100文字以内）" value={this.state.customer.address2} onChange={this.address2Change} readOnly={this.state.readOnly}/>
								</InputGroup>
								<InputGroup>
									<InputGroupText>備　考</InputGroupText>
									<Input type="textarea" style={{height: '54px' ,  whiteSpace: 'pre-line' }} placeholder="コメント" value={this.state.customer.comment} onChange={this.commentChange} readOnly={this.state.readOnly}/>
								</InputGroup>
								<InputGroup>
									<InputGroupText>ポイント　</InputGroupText>
									<Input type="number" placeholder="ポイント" value={this.state.customer.point} onChange={this.pointChange} onFocus={this.focusSelect} readOnly={this.state.readOnly}/>
								</InputGroup>
								<InputGroup>
									<InputGroupText>パスワード</InputGroupText>
									<Input type="password" placeholder="（パスワード（6文字以上）" value={this.state.customer.password} onChange={this.passwordChange} readOnly={this.state.readOnly}/>
								</InputGroup>
							</FormGroup>
						</form>
					</ModalBody>
					<ModalFooter>
						<Button color="warning" onClick={this.props.onCancel}>キャンセル</Button>
						<Button color="primary" onClick={this.closeRep} >OK</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}

const help = 
	"##### 概要\n" +
	"お客様の情報を表示/編集できます。  \n" +
	"###### 番号\n" +
	"お客様に一意の番号を表示/編集できます。(重複不可)  \n" +
	"**← ︎→** :表示している番号から重複していない番号を検索できます。  \n" +
	"###### 名前・カナ\n" +
	"お客様の**お名前**を表示/編集できます。  \n" +
	"###### 性別\n" +
	"お客様の**性別**を表示/選択できます。  \n" +
	"###### 担当者\n" +
	"お客様の**担当者**を表示/選択できます。  \n" +
	"###### 携帯・電話\n" +
	"お客様の**電話番号**を表示/編集できます。  \n" +
	"###### メール\n" +
	"お客様の**メールアドレス**を表示/編集できます。  \n" +
	"###### 誕生日/年齢\n" +
	"お客様の**誕生日**を表示/編集できます。  \n" +
	"年を省略する場合は9999を入力して下さい。  \n" +
	"###### 郵便番号/住所検索/住所１/住所２\n" +
	"お客様の**住所**を表示/編集できます。  \n" +
	"**郵便番号**を入力後に**住所検索**ボタンを押すと住所１に自動入力されます。" +
	"１つの郵便番号に複数の住所が対応している場合はドロップダウン表示され、住所を選択できます。  \n" +
	"**住所検索**せずに住所１を入力する事も可能です。" +
	"住所１以外を住所２に入力できます。  \n" +
	"###### 備考\n" +
	"お客様の**メモ**を表示/編集できます。  \n" +
	"###### ポイント\n" +
	"お客様の**ポイント**を表示/編集できます。  \n" +
	"";


export default CustEdit;
