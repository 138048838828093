/* ==============================================================================
 商品売上情報の編集画面

=============================================================================== */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button,ButtonToolbar,Modal,ModalHeader,ModalBody,ModalFooter,InputGroup,InputGroupText,Input,FormGroup } from 'reactstrap';

import ReactDataGrid from 'react-data-grid';
import Select from 'react-select';
import Check from './Input';
import {Alert} from './auth/Login';


import './Table.css';

import { AlignLeft,YenFormat } from './Formatter';	// React Data Grid

// const MAX_DB_LENGTH = 100000;
import {
	MAX_DB_LENGTH
} from './Define';

function changeYen(num){
    return '¥' + String(num).split("").reverse().join("").match(/\d{1,3}/g).join(",").split("").reverse().join("");
}


var rowProp = {};

export class GoodsSlipGrid extends Component {

	static propTypes = {
		visit: PropTypes.object.isRequired,
		slipList: PropTypes.array.isRequired,
		goodsList: PropTypes.array.isRequired,
		onRowSelect:  PropTypes.func,
		onRowUpdated: PropTypes.func,
		height: PropTypes.number
	}
	
	//props初期値指定
	static defaultProps = {
		onRowSelect: null,
		onRowUpdated: null,
		height: 600
	};
	
	
	// コンストラクター
	constructor(props) {
		super(props);
		this.state = {
			selectedIndexes:[0],    // react-data-grid
			rows:   [],  //{ tenant_id: 0, visit_id: 0, goods_id: 0, price: 0 }],  // 各プロパティの順番はDB登録時のJSON変換で重要
			rowData: {}, //{ tenant_id: 0, visit_id: 0, goods_id: 0, price: 0 },	// 各プロパティの順番はDB登録時のJSON変換で重要
			total: 0,
			buttonDisable: true,  // 一覧選択時の修正ボタン、削除ボタンの設定
			title: ""
		};

	}

/****

   	// 描画が成功して、DOMにアクセス可能になる
	componentDidMount() {
    	console.log("componentDidMount",this.props.slipList);
	
	}
*****/

	// プロパティ(props)が変更された時にコールされる
	static getDerivedStateFromProps = (nextProps, nextstate) => {
//		console.log('getDerivedStateFromProps nextProps',nextProps);

		let total = 0;
   		let slip = nextProps.slipList.map(function(o){
			let row  = Object.assign({},o);
			let goods  = nextProps.goodsList.find(function(t) { return o.goods_id === t.id; });
			row.goods  = goods.name;
			total += Number(o.price); 
			return row;
		});
	
		if ( slip.length === 0 ) {
			return ({
				buttonDisable:true,
				total:total,
				rows: slip,
			});
		} else {
			return ({
				buttonDisable:false,
				total:total,
				rows: slip,
			});
		}

	}


	// 追加ボタン
	onRowAdd = () => {
//		console.log('onRowAdd()',this.props.visit);
		if ( this.props.goodsList.length === 0 ) {
			Alert(<div>商品が登録されていません。<br/>商品を登録してください。</div>);
			return;
		}
		let goods = this.props.goodsList[0];
		let row = {			// DBに登録するときにJSONに変換する。このとき順番が重要
			tenant_id: this.props.visit.tenant_id, 
			visit_id: this.props.visit.id,
			goods_id: goods.id,
			price:    goods.price,
			goods:    goods.no + " " + goods.name,
		};
		
		let rows = this.state.rows.concat([row]);
//		this.onRowUpdated(rows);

		let i = this.state.rows.length;
		rowProp = rows[i];
		this.setState({ rowData: rowProp});
		this.setState({ selectedIndexes: [i]} );
		this.setState({ title: "商品 追加" });
		this.setState({ showModal:true });
		
	}

	// 修正ボタン
	onRowRep = () => {
		let i = this.state.selectedIndexes[0];
		rowProp = Object.assign({},this.state.rows[i]);
		
		this.setState({ rowData: this.state.rows[i]});
		this.setState({ title: "商品 修正" });
		this.setState({ showModal:true });
	}

	// 削除ボタン
	onRowDel = () => {
		let i = this.state.selectedIndexes[0];
		let rows = this.state.rows.concat();
		rows.splice(i,1);
		this.onRowUpdated(rows);
		this.setState({ selectedIndexes: [0]});

	}


	// 親コンポーネントのコールバックでrowsを渡す		
	onRowUpdated = (rows) => {
		if ( this.props.onRowUpdated === null ) {
			this.setState({rows:rows});
			return;
		}
		let total = 0;
		let slip = rows.map(function(o){
			total += Number(o.price);
			let s  = Object.assign({},o);
			delete s.goods;
			return s;
		});
		this.setState({total:total});
		
		if ( this.props.onRowUpdated(slip,total) ) {
			this.setState({rows:rows});
		}
	}


/* ReactDataGrid -------------------------------------------- */ 
	onCellSelected  = ({ rowIdx, idx }) => {
		this.setState( {selectedIndexes: [rowIdx]} );
// console.log(rowIdx);
		if ( this.props.onRowSelect !== null) {
			this.props.onRowSelect(this.rowGetter(rowIdx));  // propsに設定された外部関数をコールする
		}
	}

	onRowDoubleClick = () => {
		this.onRowRep();
	}

	rowGetter = (rowIdx) => {
		return this.state.rows[rowIdx];
	}

/* ReactDataGrid --------------------------------------------  */

	goodsChange = (event) => {
		let id = Number(event.value);
		let goods = this.props.goodsList.find(function(o) { return o.id === id; });
		rowProp.goods_id = id;
		rowProp.price   = goods.price;
		rowProp.goods   = goods.name;

		this.setState({ rowData:rowProp });
	}

	// InputにFocusを当てると選択状態にする
	focusSelect = (event) => {
		event.target.select();
	}
	
	priceChange = (event) => {
		if ( Check.money(event.target.value) === false ) return;
		rowProp.price = event.target.value;
		this.setState({ rowData:rowProp });
	}

	// 登録ボタンが押された
	close = () => {
		let i = this.state.selectedIndexes[0];
		var rows = this.state.rows.concat();
		rows[i] = this.state.rowData;
// console.log(rows);
		this.setState( { rows: rows });

		this.setState({showModal: false });

		this.onRowUpdated(rows);

	}

	// キャンセル
	cancel = () => {
		this.setState({showModal: false });
		if ( this.state.selectedIndexes[0] >= this.state.rows.length )	this.setState({ selectedIndexes: [0]});

	}
	
	//	toggle() {
	toggle = () => {
		if ( this.state.showModal === true ) {
			this.cancel();
			this.setState( { showModal: false } );
		} else {
			this.setState( { showModal: true } );
		}
	}

	render() {
		const columns = [
				 { key: 'goods', name: '商品名', width: 260, editable: false, resizable: true, formatter:AlignLeft },
				 { key: 'price', name: '価格',               editable: false, resizable: true, formatter:YenFormat }
			 ];
		// 商品の設定
		let list = this.props.goodsList.filter( (goods) => {		// 削除された番号は表示しない
			return goods.no < MAX_DB_LENGTH;
		});
		let goodsOptions = list.map( (goods) => {
			return { value: goods.id, label:goods.no + ' ' + goods.name};
		});
		var goods = goodsOptions.find( (option) => {
			return option.value === this.state.rowData.goods_id;
		});


		return (
			<div className="GoodsSlip" style={{marginTop:"7px"}}>
				<ButtonToolbar>
					<Button color="primary" onClick={this.onRowAdd} >追加</Button>
					<Button color="success" onClick={this.onRowRep} disabled={this.state.buttonDisable}>修正</Button>
					<Button color="danger"  onClick={this.onRowDel} disabled={this.state.buttonDisable}>削除</Button>
				    <div className="d-flex align-items-end">　商品合計：{changeYen(this.state.total)}円</div>
				</ButtonToolbar>

				<ReactDataGrid enableCellSelect={true}  minHeight={this.props.height} //minWidth={660} 
							rowGetter={this.rowGetter}  columns={columns} rowsCount={this.state.rows.length}
							onCellSelected={this.onCellSelected} onRowDoubleClick={this.onRowDoubleClick}
					rowSelection={{
						showCheckbox: false,
						enableShiftSelect: false,
						selectBy: {
							indexes: this.state.selectedIndexes
						}
					}}  />
				

				<Modal isOpen={this.state.showModal} backdrop={'static'} onClose={this.cancel} autoFocus={false}> 
					<ModalHeader toggle={this.toggle}>{this.state.title}</ModalHeader>
					<ModalBody>
						<form>
							<FormGroup>
								<InputGroup>
									<InputGroupText>商品名</InputGroupText>
{/*									<Input type="select" placeholder="goods" value={this.state.rowData.goods_id} onChange={this.goodsChange} readOnly={this.state.readOnly}>
									{
										this.props.goodsList.map(function (item) {
											return <option value={item.id} key={item.id}>{item.no + ' ' + item.name}</option>;
										})
									}
									</Input> */}
									<Select className="form-control react-select-original"  placeholder="商品" value={goods} options={goodsOptions} onChange={this.goodsChange} readOnly={this.state.readOnly} menuPosition={true} />
								</InputGroup>
								<InputGroup>
									<InputGroupText>価　格</InputGroupText>
									<Input type="number" placeholder="金額" value={this.state.rowData.price} onChange={this.priceChange} onFocus={this.focusSelect} readOnly={this.state.readOnly}/>
								</InputGroup>
							</FormGroup>
						</form>
					 </ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.close} disabled={this.state.closeDisable}>OK</Button>
					</ModalFooter>
				</Modal>


			</div>
		)
	}

}


export default GoodsSlipGrid;
