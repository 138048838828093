/* ==============================================================================
 郵便番号

 履歴-----------------------------------------
 2022/12/03 reactstrap 対応済み
 
=============================================================================== */

import React, { useState } from 'react';
import { ButtonDropdown,DropdownToggle,DropdownMenu,DropdownItem } from 'reactstrap';

import Request from 'superagent';
import {JWToken,Alert} from './auth/Login';


/* =============================================================================
 * 郵便番号から住所を検索する
 * <Zip2Addr  result={callBack(pref city suburb)} />
 * ============================================================================= */

const Zip2Addr = (props) => {
	const [dropdown, setDropdown] = useState(false);
	const [address,  setAddress]  = useState([]);

	const zipcode  = props.zipcode;
	const callBack = props.callBack;
	
//	console.log(zipcode);
	// 郵便番号から住所を取得する
	const onZipAddr = (zipcode) => {
		console.log('onZipAddr(' + zipcode + ')' );
		if ( zipcode === null || zipcode === undefined ) { 
			Alert("郵便番号を確認してください。");
			return;
		}

		zipcode = zipcode.replace( "-" , "" ) ;
		if ( zipcode.length !== 7 ) {
			Alert("郵便番号を確認してください。");
			return;
		}

		//ajax通信する
		Request.get("/zipcode/part/" + zipcode)
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.error("住所を取得できませんでした。");
					console.log(err);
				} else {
					console.log(res);
					switch (res.body.dbData.length) {
						case 0:
							Alert('指定された郵便番号は見つかりません。');
							break;
						case 1:
							console.log(res.body.dbData[0]);
							callBack(res.body.dbData[0].pref, res.body.dbData[0].city, res.body.dbData[0].suburb);
							setDropdown( false );
							break;
						default:
							setAddress( res.body.dbData );
							setDropdown( true );
							break;
					}
				}
			});
	};
	
	const toggleAddr = (event) => {
		if ( dropdown === true ) {
			setDropdown( false );
		} else {
			onZipAddr(zipcode);		// DBを参照
		}
	};

	const selected = (index) => {
		callBack(address[index].pref,address[index].city,address[index].suburb);
	};
	
	return (
		<ButtonDropdown isOpen={dropdown} toggle={toggleAddr} >
            <DropdownToggle caret >住所検索</DropdownToggle>
			<DropdownMenu>
	            <DropdownItem header>以下から町域を選択してください</DropdownItem>
	            <DropdownItem divider />
				{
					address.map(function (item, index) {
						return <DropdownItem onClick={()=>{selected(index)}}>{address[index].pref + ' ' +  address[index].city + ' ' + address[index].suburb}</DropdownItem>;
					})
				}
        	</DropdownMenu>
        </ButtonDropdown>
	);
} ;
export default Zip2Addr;