// import React from 'react';

// '02:20' -> 140分
function hm2m( str ) {
    if ( str === null ) return 0;
    let hhmm = str.split(':');
    return Number(hhmm[0] * 60) + Number(hhmm[1]);
}

// '02:20' -> 140分
function m2hm( min ) {
    let i = String(min/60).split('.');
    if (min < 600)  return ('0' + i[0]).slice(-2) + ':' + ('0' + min%60).slice(-2);
    return i[0] + ':' + ('0' + min%60).slice(-2);
}

// '02:20' - '01:00' => 80分
function sub( str1, str2 ) {
    return hm2m(str1) - hm2m(str2);
}

// '02:20' + '01:00' => 200分
function add( str1,str2 ) {
    return hm2m(str1) + hm2m(str2);
}

// 出勤、退勤、休憩時間から、勤務時間を計算する
function hours( stime, etime, btime ) {
    if (stime === null || stime ==="" ) return null;
    if (etime === null || etime ==="" ) return null;
    if (btime === null || btime ==="" ) return null;
    return m2hm(hm2m(etime)- hm2m(stime) - hm2m(btime));
    
}

// 勤務開始時間は切り上げ
function sTimeCeil( stime, unit ) {
    var m = hm2m(stime);
    m = Math.ceil(m/unit);
    m *= unit;

    return m2hm(m);
}

// 勤務終了時間は切り捨て
function eTimeFloor( etime, unit ) {
    var m = hm2m(etime);
    m = Math.floor(m/unit);
    m *= unit;

    return m2hm(m);
}

module.exports = { hm2m,sub,add,m2hm ,hours, sTimeCeil,eTimeFloor };

