/* ==============================================================================
  勤務表 表示 モジュール

DB table R  : workhour、tenant_id


=============================================================================== */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDataGrid from 'react-data-grid';
import {InputGroup,InputGroupText,Input,FormGroup,ButtonToolbar,
		Modal,ModalHeader,ModalBody,ModalFooter,Button } from 'reactstrap';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment';
import Request from 'superagent';
import {JWToken} from './auth/Login';
import { Date2Format,TimeHMFormat } from './Formatter';	// React Data Grid

// import {changeYen} from './Formatter';

// import { AlignLeft,AlignRight,YenFormat } from './Formatter';	// React Data Grid

import './Table.css';

import { MAX_DB_LENGTH } from './Define';

var initRoster = {
		tenant_id:		0,			/* テナントID   */
		staff_id:       0,			/* 担当者ID     */
		work_date:      '',			/* 出勤日       */
		day_off_id:     0,			/* 休日の理由　有給、振替休日、欠勤など   */
		punch_start:    '',			/* 出勤打刻時間 */
		punch_quit:     '',			/* 退勤打刻時間 */
		start:          '',			/* 出勤時刻     */            
		quit:           '',			/* 退勤時刻     */
		break_time:     '',			/* 休憩時間     */
		abstract:       ''			/* 摘要         */
	};

var rowProp =  Object.assign({},initRoster);

function time2int(time) {
	if ( !time ) return null;
    let t = time.split(':');
    return Number(t[0]) * 60 + Number(t[1]);
}

function int2time(i) {
    return [ ( '00' + parseInt(i / 60, 10 ) ).slice(-2) , ( '00' + i % 60).slice(-2) , '00'].join(":");
}


// ====================================================================================================
// 勤務表の表示
// 
// ====================================================================================================

class Roster extends Component {
	static propTypes = {
		staff_id:		PropTypes.number.isRequired,	// 担当者番号
		name:			PropTypes.string.isRequired,	// 担当者名
		start:			PropTypes.string.isRequired,	// １週間の始まりの日付
		dayOffItems:	PropTypes.array.isRequired,		// 勤怠届リスト
		setModal:		PropTypes.func.isRequired,		// Modal 表示/非表示
		tenant:			PropTypes.object.isRequired,
	};

		//props初期値指定
	static defaultProps = {
	};
	
	// コンストラクター
	constructor(props) {
		super(props);
		this.state = {
			start:	this.props.start,
			rosterList: [Object.assign({},initRoster)],
			roster: Object.assign({},initRoster),			// Default
			selectedIndexes: [0],
			showModalEdit: false,
		};
		registerLocale('ja', ja);		// react-datepicker の日本語表記
		this.getRoster(this.props.staff_id, this.props.start);
	}

	componentDidUpdate(prevProps, prevState) {
		if ( prevProps.start    === this.props.start
		  && prevProps.staff_id === this.props.staff_id ) return;
		this.setState({start:this.props.start});
		this.getRoster(this.props.staff_id, this.props.start);
	}

	getTableList = () => {
		this.getRoster(this.props.staff_id, this.state.start);
	}
	
// ======================================================================================
// DB 入出力 関数
// ======================================================================================

	// 勤務表を１週間分取得する
	getRoster = (staff_id,start) => {
		let end = moment(start).add('days',7).format('YYYY-MM-DD');
		
		//ajax通信する
		Request.get("/workhour/week/" + staff_id + "/" + start + '_' + end )
			.query( { token: JWToken.getToken().token } )
			.then( res => {
				const h05  = time2int('05:00');		// 朝5:00前は深夜残業
//				const h08  = time2int('08:00');		// 8時間以上の勤務は残業時間  2021-05-26 法定労働時間を所定労働時間に変更
				const h22  = time2int('22:00');		// 22時以降は深夜残業
				const week = [1,2,4,8,16,32,64,128];
				let list =[];
				let j = 0;
				let open   = time2int(this.props.tenant.start_time);
				let close  = time2int(this.props.tenant.quit_time );
				let recess = time2int(this.props.tenant.break_time);	// 休憩時間
				const h08  = close - open - recess;						// 2021-05-26 法定労働時間を所定労働時間に変更

				let week_ontime_limit = this.props.tenant.after_work * 60;	// １週間の上限時間(40h or 44h)
				let week_ontime       = 0;								// １週間の累積時間
						
				for ( let i = 0 ; i < 7 ; i++) {
					let work_date = moment(start).add('days', i).format('YYYY-MM-DD');
					if ( j < res.body.dbData.length  && work_date === res.body.dbData[j].work_date ) {
						list.push(res.body.dbData[j++]);
						
						let dayOfWeek  = week[new Date(work_date).getDay()];
						let start      = time2int(list[i].start);
						let quit       = time2int(list[i].quit );
						let break_time = time2int(list[i].break_time );
// console.log('所定',open,close);
// console.log('出退勤',start,quit,break_time);
						if ( start !== null && quit !== null && break_time !== null ) {
							let hours     = quit - start - break_time;
							let ontime = Math.min( h08, Math.min(quit,close) - Math.max(open,start) - break_time);
							if ( start > close || quit  < open ) ontime = 0;
// console.log('定休日',this.props.tenant.day_off,dayOfWeek,new Date(work_date).getDay());
							let overtime4 = ( this.props.tenant.day_off & dayOfWeek ) ? ontime : 0;			// 休日出勤
							let overtime  = Math.max(hours,ontime) - ontime;
							let overtime3 = ( start < h05 ) ? Math.min(h05,quit) - start : 0   +  ( quit > h22 ) ? quit - Math.max(h22,start) : 0 ;
							let overtime2 = Math.max(hours,h08) - h08 - overtime3;
							let overtime1 = overtime - overtime2 - overtime3;
// console.log('overtime', ontime,overtime,overtime1,overtime2,overtime3,overtime4);
							// １週間の上限時間を超えた場合
							week_ontime += ontime + overtime1;
// console.log(week_ontime_limit,week_ontime);
							if ( week_ontime > week_ontime_limit ) {
								overtime2 += week_ontime - week_ontime_limit;
								overtime1  = overtime - overtime2 - overtime3;
								if ( overtime1 < 0 ) {
									ontime += overtime1;
									overtime1 = 0;
								}
							}
// console.log('結果',hours,ontime,overtime,overtime1,overtime2,overtime3,overtime4);
							list[i].hours     = int2time(hours);
							list[i].ontime    = int2time(ontime-overtime4);	// 所定
							list[i].overtime1 = int2time(overtime1);		// 所定外
							list[i].overtime2 = int2time(overtime2);		// 残業
							list[i].overtime3 = int2time(overtime3);		// 深夜
							list[i].overtime4 = int2time(overtime4);		// 休日
						} 
					} else {
						list.push(Object.assign({},initRoster));
						list[i].tenant_id = JWToken.getToken().tenant_id;
						list[i].staff_id  = staff_id;
						list[i].work_date = work_date;
					}
				}
				this.setState({ 
					rosterList: list,
					selectedIndexes: [0]
				});
			})
			.catch( err => {
				console.error("勤務表を取得できませんでした。");
				console.log(err);
				if ( err.status === 403 ) JWToken.clearToken();
			});
	}

	// 編集時の1日データを読み込み
	getTableEdit = (staff_id,work_date) => {
		//ajax通信する
		Request.get("/workhour/edit/" + staff_id + '/' + work_date)
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.log(err);
					console.log(res);
					console.error("出退勤データを取得できませんでした。");
					return;
				}
				rowProp = Object.assign({},res.body.dbData[0]);
				if ( !rowProp.day_off_id && ! rowProp.break_time )  {
					rowProp.break_time = this.props.tenant.break_time;
// console.log(rowProp.break_time,this.props.tenant);
				}
				this.setState({roster: rowProp });
			}.bind(this));
	}

// ======================================================================================
// Roster イベント処理関数
// 
// ======================================================================================
	onRosterCellSelected = ({ rowIdx, idx }) => {
		// 表示する内容を設定
		this.setState({
			selectedIndexes: [rowIdx],
			roster: this.state.rosterList[rowIdx]
		});
	}

	onRosterRowsSelected = (rowIdx) => {

		this.setState({
			selectedIndexes: [rowIdx],
			roster: this.state.rosterList[rowIdx]
		});
	}

	onRosterRowsDeselected = (row) => {

		this.setState({selectedIndexes: []});
	}

	onRowDoubleClick = (rowIdx) => {
		this.setState({
			selectedIndexes: [rowIdx],
			roster: this.state.rosterList[rowIdx],
		});
		this.openEdit();
	}


// ======================================================================================
// 編集画面 Modal イベント処理関数
// 
// ======================================================================================
	openEdit = () => {
		let index = this.state.selectedIndexes[0];
		this.getTableEdit(this.props.staff_id,this.state.rosterList[index].work_date);
		this.setState({
			showModalEdit: true,				// modal表示
		});
		
	}
	
	closeEdit = () => {
		Request.put('/workhour/' + rowProp.staff_id + '/' + rowProp.work_date )
			.send({ dbData:rowProp, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.error("勤務表を登録できませんでした");
					return;
				}
				this.getTableList();
			}.bind(this));

		this.setState({
			showModalEdit: false,				// modal非表示
		});
		
	}
	
	cancelEdit = () => {
		this.setState({
			showModalEdit: false,				// modal非表示
		});

	}

	toggleEdit = () => {
		if ( this.state.showModalEdit === true ) {
			this.cancelEdit();
		} else {
			this.openEdit();
		}
	}

// ======================================================================================
// 画面入力 関数
// ======================================================================================

	// 日時が変更された
	dayChange = (date) => {
		let startWeek = this.props.tenant.start_week;
		let dayOfWeek = moment(date).day();
		let start = '';
		if ( dayOfWeek < startWeek) {
			start  = moment(date).add('days', -7 - dayOfWeek + startWeek ).format("YYYY-MM-DD");
		} else {
			start  = moment(date).add('days', startWeek - dayOfWeek ).format("YYYY-MM-DD");
		}

		this.setState({ start: start });

		//指定された日付でファイルを読み込む
		this.getRoster(this.props.staff_id,start);
	}

	decWeek = () => {
		let start = moment(this.state.start).add(-1,'weeks').format('YYYY-MM-DD');
		this.setState({ start: start});

		//指定された日付でファイルを読み込む
		this.getRoster(this.props.staff_id,start);
	}

	incWeek = () => {
		let start = moment(this.state.start).add(1,'weeks').format('YYYY-MM-DD');
		this.setState({ start: start});

		//指定された日付でファイルを読み込む
		this.getRoster(this.props.staff_id,start);
	}

//-----------------------------------------------
// イベント処理関数
//-----------------------------------------------
	// InputにFocusを当てると選択状態にする
	workdayChange = (event) => { 
		if ( this.state.readOnly === true ) return;
		rowProp.workday ^= event.target.value; 
		this.setState({ roster:rowProp });
	}

	startChange = ( event ) => {
		rowProp.start = event.target.value;
		this.setState({ roster:rowProp });
	}

	quitChange = ( event ) => {
		rowProp.quit = event.target.value;
		this.setState({ roster:rowProp });
	}

	dayOffChange = (option) => {
		if ( option.value !== 0 ) {
			rowProp.start       = null;
			rowProp.quit        = null;
			rowProp.break_time  = null;
			rowProp.punch_start = this.props.tenant.start_time;
			rowProp.punch_quit  = this.props.tenant.quit_time;
		} else {
			rowProp.punch_start = null;
			rowProp.punch_quit  = null;
			rowProp.break_time  = this.props.tenant.break_time;
		}
		rowProp.day_off_id= option.value;
		this.setState({ roster:rowProp });
	}
	
	breakTimeChange = ( event ) => {
		rowProp.break_time = event.target.value;
		this.setState({ roster:rowProp });
	} 
	

// ======================================================================================
// render()
// 
// ======================================================================================

	render = () => {
		
// console.log('Roster render()'+this.state.rosterList.length);
		let start       = this.state.roster.start       ? this.state.roster.start       : '--:--';
		let punch_start = this.state.roster.punch_start ? this.state.roster.punch_start : '--:--:--';
		let quit        = this.state.roster.quit        ? this.state.roster.quit        : '--:--';
		let punch_quit  = this.state.roster.punch_quit  ? this.state.roster.punch_quit  : '--:--:--';
		let break_time  = this.state.roster.break_time  ? this.state.roster.break_time  : '--:--';

		// 勤怠届マスターの設定
		let list = this.props.dayOffItems.filter( (dayOff) => {		// 削除された番号は表示しない
			return dayOff.no < MAX_DB_LENGTH;
		});
		const options = list.map( (dayOff) => {
			return { value: dayOff.id, label:dayOff.no + ' ' + dayOff.name};
		});
		options.unshift( { value: 0, label:'　'} ) ;
		var dayOff = options.find( (option) => {
			return option.value === this.state.roster.day_off_id;
		});

		const columnsRoster = [
			{ key: 'work_date', name: '日付',		width: 86,  editable: false, resizable: false,formatter:Date2Format },
			{ key: 'start', 	name: '出勤',   	width: 80,	editable: false, resizable: true, formatter:TimeHMFormat },
			{ key: 'quit',		name: '退勤',   	width: 80,  editable: false, resizable: true, formatter:TimeHMFormat },
			{ key: 'break_time',name: '休憩',   	width: 80,  editable: false, resizable: true, formatter:TimeHMFormat },
			{ key: 'name',		name: '勤怠届',		width: 100, editable: false, resizable: true },
			{ key: 'hours',		name: '勤務時間',	width: 80,  editable: false, resizable: true, formatter:TimeHMFormat },
//			{ key: 'ontime',	name: '所定',		width: 60,  editable: false, resizable: true, formatter:TimeHMFormat },
//			{ key: 'overtime1',	name: '所定外',		width: 60,  editable: false, resizable: true, formatter:TimeHMFormat },
			{ key: 'overtime2',	name: '残業時間',	width: 80,  editable: false, resizable: true, formatter:TimeHMFormat },
			{ key: 'overtime3',	name: '深夜残業',	width: 80,  editable: false, resizable: true, formatter:TimeHMFormat },
			{ key: 'overtime4',	name: '休日出勤',				editable: false, resizable: true, formatter:TimeHMFormat},
		];

		const form=
				<form>
					<FormGroup disabled>
						<InputGroup>
							<InputGroupText>勤怠届　</InputGroupText>
							<Select className="form-control react-select-original" style={{ menu: provided => ({ ...provided, zIndex: 100 }) }}
									placeholder="勤怠届" value={dayOff} options={options} onChange={this.dayOffChange} autoFocus={true} />
						</InputGroup>
						<InputGroup>
							<InputGroupText>出勤時刻</InputGroupText>
							<Input type="time" placeholder="出勤時間" value={start} onChange={this.startChange} readOnly={false}/>
							<InputGroupText>{ "打刻: " + punch_start}</InputGroupText>
						</InputGroup>
						<InputGroup>
							<InputGroupText>退勤時刻</InputGroupText>
							<Input type="time" placeholder="退勤時間" value={quit} onChange={this.quitChange} readOnly={false}/>
							<InputGroupText>{"打刻: " + punch_quit}</InputGroupText>
						</InputGroup>
						<InputGroup>
							<InputGroupText>休憩時間</InputGroupText>
							<Input type="time" placeholder="休憩時間" value={break_time} onChange={this.breakTimeChange}/>
						</InputGroup>
					</FormGroup>
				</form>

		var week_start = new Date(this.state.start);

		return (
			<div>
				<ButtonToolbar>
					<Button color="info"    onClick={this.getTableList}>{this.props.name}</Button>{'　'}
					<Button color="success" onClick={this.openEdit} disabled={this.state.buttonDisable}>修正</Button>{'　'}
					<form>
						<FormGroup disabled>
							<InputGroup>
								<InputGroupText>日付</InputGroupText>
								<Button onClick={this.decWeek} >◀︎</Button>
							    <DatePicker className="form-control" selected={week_start} onChange={this.dayChange} locale="ja" dateFormat="yyyy-MM-dd" />
								<InputGroupText>{' 〜 ' + moment(this.state.start).add(6,'days').format('YYYY-MM-DD')}</InputGroupText>
								<Button onClick={this.incWeek} >▶︎</Button>
							</InputGroup>
						</FormGroup>
					</form>
					
				</ButtonToolbar>
				<ReactDataGrid enableCellSelect={true} enableDragAndDrop={false} minHeight={281} // minWidth={315} 
					columns={columnsRoster}
					rowGetter={i => this.state.rosterList[i]}
					rowsCount={this.state.rosterList.length}
					onCellSelected={this.onRosterCellSelected}
					onRowDoubleClick={this.onRowDoubleClick}
					rowSelection={{
			            showCheckbox: false,
			            onRowsSelected:   this.onRosterRowsSelected,
			            selectBy: {
			              indexes: this.state.selectedIndexes
			            }
					}}  
				/>
				<Modal isOpen={this.state.showModalEdit} backdrop={'static'} size={'md'} onClose={this.cancelEdit} autoFocus={false}> 
					<ModalHeader toggle={this.toggleEdit} >{this.props.name}　{this.state.roster.work_date} </ModalHeader>
					<ModalBody>
						{form}
					</ModalBody>
					<ModalFooter>
 						<Button color="warning" onClick={this.cancelEdit} >キャンセル</Button>
						<Button color="primary" onClick={this.closeEdit}  >実行</Button>
					</ModalFooter>
				</Modal>
			</div>	
		);
	}
	
}



export default Roster;
