/* ==============================================================================
  顧客の来店履歴 表示　モジュール

DB table R  : visit、tech_slip、goods_slip
		 R  : staff、customer、tech、goods
		 
 group: id,title,<staff>
 staff: id,no+name
 salesax: startDate, rate
 
 item:  id,group,   title,start_time,end_time,<visit>
 visit: id,staff_id,name, start_time,duration,visit_state,customer_id
 
=============================================================================== */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDataGrid from 'react-data-grid';
import {InputGroup,InputGroupText,Input,
		Modal,ModalHeader,ModalBody,ModalFooter,
		Container,Row,Col,Table,Button } from 'reactstrap';

import moment from 'moment';
import Request from 'superagent';
import {JWToken} from './auth/Login';

// import {changeYen} from './Formatter';

import { AlignLeft,AlignRight,YenFormat } from './Formatter';	// React Data Grid

import './Table.css';

var visit = {
			    id:         0,			/* キー         */
			    tenant_id:  0,			/* テナントID   */
			    salon_id:   0,			/* 店舗ID       */
			    visit_date:	"",			/* 来店日時	    */
			    visit_time: "",			/* 来店時間     */
			    name:       "",			/* 顧客名       */
			    staff_id:	0,			/* 担当者ID     */
			    visit_state:0,			/* 状況:予約:来店:会計:集計*/
			    use_point:  0,			/* 使用ポイント */
			    credit_id:  0,			/* 0:現金、 1〜:クレジット代行 */
			    tech_price: 0,			/* 技術売上     */
			    goods_price:0,			/* 商品売上     */
			    rate:       0,			/* 税率         */
			    tax:        0,			/* 消費税       */
			    visit:		"",
			    date:		"",
			    staff:		"",
			    memo:		"",
			};

// ====================================================================================================
// 予約・来店履歴の表示
// 
// ====================================================================================================

class Record extends Component {
	static propTypes = {
	    customer:		PropTypes.object.isRequired,		// 顧客情報
//		salon:			PropTypes.object.isRequired,		// 店舗情報
	    staffList:		PropTypes.array.isRequired,			// 担当者マスター
	    techList:		PropTypes.array.isRequired,			// 技術マスター
	    goodsList:		PropTypes.array.isRequired,			// 商品マスター
	    creditList:		PropTypes.array.isRequired,			// 決済サービス
	    taxList:		PropTypes.array.isRequired,			// 消費税率
	    pointList:		PropTypes.array.isRequired,			// ポイントの還元率
	    onAddTechSlip:	PropTypes.func,						// 技術情報を返す
	};

	static defaultProps = {
		onAddTechSlip: null,
	}

	// コンストラクター
	constructor(props) {
		super(props);
		this.state = {
			visit: visit,			// Default
			visitList:[],			// Timeline で選択されている item
			techSlipList: [],		// 技術売上
			goodsSlipList: [],		// 商品売上
			selectedIndexes:[],		// 行の選択
		};
		
		this.getVisit(this.props.customer.id);
	}

	// 消費税のレート
	taxRate = ( day) => {
		let tax = 0;
		let taxList = this.props.taxList;
		for ( let i = 0 ; i < taxList.length ; i++ ) {
			if ( taxList[i].start > day ) break;
			tax = taxList[i].rate;
		}
		return 1 + tax/100;
	}

	amount = (visit) => {
//		console.log(visit);
		return Number(visit.tech_price) + Number(visit.goods_price) + Number(visit.tax) - Number(visit.use_point);
	}
	
	taxInc = (item) => {
		let total = (item.techTotal + item.goodsTotal) * this.taxRate(item.visit.visit_date);
	
		return Math.floor(total/10) * 10;		// 10円未満を切り捨て
	}
	
	// ポイントのレート
	pointRate = (day) => {
		let rate = 0;
		let pointList = this.props.pointList;
		for ( let i = 0 ; i < pointList.length ; i++ ) {
			if ( pointList[i].start > day ) break;
			rate = pointList[i].rate;
		}
		return rate/100;
	}
	
	// 
	addPoint = (item) => {
		let point = (item.techTotal + item.goodsTotal - item.visit.use_point) * this.pointRate(item.visit.visit_date);
// console.log(point);		
		return Math.floor(point);
	}


// ======================================================================================
// DB 入出力 関数
// ======================================================================================

	// 顧客のVisitListを取得する
	getVisit = (customer_id) => {
		//ajax通信する
		const visitName = ['予約','来店','会計','集計'];
		Request.get("/visit/record/" + customer_id )
			.query( { token: JWToken.getToken().token } )
			.end( (err, res) => {
				if (err) {
					console.error("Visitを取得できませんでした。");
					console.log(err);
					if ( err.status === 403 ) JWToken.clearToken();
				} else {
					let list = res.body.dbData;
					let visitList = list.map( (visit, index) => {
						visit.visit = visitName[visit.visit_state];
						visit.date  = moment(visit.visit_date + ' ' + visit.visit_time).format('YYYY-MM-DD HH:mm');
						let staff = this.props.staffList.find( (staff) => { return staff.id === visit.staff_id } );
						visit.staff = staff.name;
						return visit;
					});
					if ( list.length ) {
						this.setState({
							visitList: visitList,
							visit:     visitList[0],
							selectedIndexes: [0]
						});
						// 先頭のVisitを表示する
						this.getTechSlip (visitList[0].id);
						this.getGoodsSlip(visitList[0].id);
					} else {
						this.setState({
							visitList: visitList,
							visit:     visit,
							selectedIndexes: [0],
							techSlipList: [],
							goodsSlipList: [],
						});
					}
				}
			});
	}

/* -----------------------------------------------------------
	技術売上情報の取得
	・技術売上情報は予約・来店情報１つ分を読み書きする
	・
------------------------------------------------------------- */
	getTechSlip = (visit_id) => {
		//ajax通信する
		Request.get("/tech_slip/edit/" + visit_id)
			.query( { token: JWToken.getToken().token } )
			.end( (err, res) => {
				if (err) {
					console.error("技術売上一覧を取得できませんでした。");
					console.log(err);
					if ( err.status === 403 ) JWToken.clearToken();
					this.setState({techSlipList: [] });		// 技術売を初期化
				} else {
					// 技術表示
					let list = res.body.dbData;
					let techSlipList = list.map( (techSlip, index) => {
						let tech = this.props.techList.find( (tech) => { return tech.id === techSlip.tech_id } );
						techSlip.tech   = tech.name;
						techSlip.period = tech.period;
						let staff = this.props.staffList.find( (staff) => { return staff.id === techSlip.staff_id } );
						techSlip.staff = staff.name;
						return techSlip;
					});
					if ( this.props.onAddTechSlip )  this.props.onAddTechSlip(techSlipList);
					this.setState({ techSlipList: techSlipList });
				}
			});
	}

/* -----------------------------------------------------------
	商品売上情報の取得
	・商品売上情報は予約・来店情報１つ分を読み書きする
------------------------------------------------------------- */
	getGoodsSlip = (visit_id) => {
		//ajax通信する
		Request.get("/goods_slip/edit/" + visit_id)
			.query( { token: JWToken.getToken().token } )
			.end( (err, res) => {
				if (err) {
					console.error("商品売上一覧を取得できませんでした。");
					console.log(err);
					if ( err.status === 403 ) JWToken.clearToken();
					this.setState({goodsSlipList: [] });		// 商品売上を初期化
				} else {
					// 商品表示
					let list = res.body.dbData;
					let goodsSlipList = list.map( (goodsSlip, index) => {
						let goods = this.props.goodsList.find( (goods) => { return goods.id === goodsSlip.goods_id } );
						goodsSlip.goods = goods.name;
						return goodsSlip;
					});
					this.setState({ goodsSlipList: goodsSlipList });
				}
			});
	}


// ======================================================================================
// Visit イベント処理関数
// 
// ======================================================================================
	onVisitCellSelected = ({ rowIdx, idx }) => {
		this.setState({selectedIndexes: [rowIdx] });
		
		// 表示する内容を設定
		this.setState({visit: this.state.visitList[rowIdx]});
		
		// TechSlip、GoodsSlipの読み込み
		let visit_id = this.state.visitList[rowIdx].id;
		this.getTechSlip (visit_id);
		this.getGoodsSlip(visit_id);
	}

	onVisitRowsSelected = (rows) => {
//		console.log('onVisitRowsSelected',rows);
		
		this.setState({selectedIndexes: [rows[0].rowIdx]});
	}

	onVisitRowsDeselected = (row) => {
//		console.log('onVisitRowsSelected',row);
		
		this.setState({selectedIndexes: []});
	}

// ======================================================================================
// TechSlip イベント処理関数
// 
// ======================================================================================
	onTechCellSelected = ({ rowIdx, idx }) => {
		this.setState({techSelectedIndexes: [rowIdx] });
		
	}

	onTechRowsSelected = (rows) => {
//		console.log('onTechRowsSelected',rows);
		
		this.setState({techSelectedIndexes: [rows[0].rowIdx]});
	}

	onTechRowsDeselected = (row) => {
//		console.log('onTechRowsSelected',row);
		
		this.setState({techSelectedIndexes: []});
	}

// ======================================================================================
// GoodsSlip イベント処理関数
// 
// ======================================================================================
	onGoodsCellSelected = ({ rowIdx, idx }) => {
		this.setState({goodsSelectedIndexes: [rowIdx] });
		
	}

	onGoodsRowsSelected = (rows) => {
//		console.log('onGoodsRowsSelected',rows);
		
		this.setState({goodsSelectedIndexes: [rows[0].rowIdx]});
	}

	onGoodsRowsDeselected = (row) => {
//		console.log('onGoodsRowsSelected',row);
		
		this.setState({goodsSelectedIndexes: []});
	}


// ======================================================================================
// render()
// 
// ======================================================================================

	render = () => {
		
		const columnsVisit = [
			{ key: 'visit',  name: '状況',   width: 50,   editable: false, locked: true },
			{ key: 'date',	 name: '日時',                editable: false, locked: true },
			{ key: 'staff',  name: '担当者', width: 65,   editable: false, locked: true },
		];

		const columnsTechSlip = [
			 { key: 'tech', name: '技術名', width: 180, editable: false, resizable: true, formatter:AlignLeft },
			 { key: 'staff',name: '担当者',             editable: false, resizable: true, formatter:AlignLeft },
			 { key: 'price',name: '価格'  , width:  80, editable: false, resizable: true, formatter:YenFormat },
			 { key: 'period',name:'時間',   width:  60, editable: false, resizable: true, formatter:AlignRight}
		 ];

		const columnsGoodsSlip = [
			 { key: 'goods', name: '商品名', width: 240, editable: false, resizable: true, formatter:AlignLeft },
			 { key: 'price', name: '価格',               editable: false, resizable: true, formatter:YenFormat }
		 ];
		
		let creditName = '現金';
		if ( this.state.visit.credit_id !== 0 ) {
			let credit = this.props.creditList.find( (credit) => {return credit.id === this.state.visit.credit_id });
			creditName = credit.name;		
		}

		// 経過日数を計算する
		let from = new Date(this.state.visit.visit_date);
		let to = new Date();
		// 経過時間をミリ秒で取得
		let ms = to.getTime() - from.getTime();
		// ミリ秒を日付に変換(端数切捨て)
		let days = Math.floor(ms / (1000*60*60*24));

		// テーブルを作成
		let cashTable = (
			<Table striped bordered size={"sm"} hover>
				<thead className="text-white" bgcolor="#4682b4" >
					<tr>
						<th>技術売上</th>
						<th>商品売上</th>
						<th>税金　　</th>
						<th>利用Point</th>
						<th>売上合計</th>
						<th>支払方法</th>
						<th>経過日数</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td><YenFormat mark='¥' value={Number(this.state.visit.tech_price)  } /></td>
						<td><YenFormat mark='¥' value={Number(this.state.visit.goods_price) } /></td>
						<td><YenFormat mark='¥' value={Number(this.state.visit.tax)         } /></td>
						<td><YenFormat mark='¥' value={this.state.visit.use_point   } /></td>
						<td><YenFormat mark='¥' value={this.amount(this.state.visit)} /></td>
						<td>{creditName}</td>
						<td><AlignRight value={days} /></td>
					</tr>
				</tbody>
			</Table>
		);

		return (
			<Container style={{width: '100%'}}>
				<Row>
					<Col xs="5"> {/* style={"padding-right:5"}> */}
						<ReactDataGrid enableCellSelect={true} enableDragAndDrop={false} minHeight={352} // minWidth={315} 
								columns={columnsVisit}
								rowGetter={i => this.state.visitList[i]}
								rowsCount={this.state.visitList.length}
								onCellSelected={this.onVisitCellSelected}
								rowSelection={{
						            showCheckbox: false,
						            onRowsSelected:   this.onVisitRowsSelected,
						            selectBy: {
						              indexes: this.state.selectedIndexes
						            }
								}}
								autoFocus={true}
						/>
					</Col>
					<Col xs="7">
						<ReactDataGrid enableCellSelect={true} enableDragAndDrop={false} minHeight={210} //minWidth={660} 
								columns={columnsTechSlip}
								rowGetter={i => this.state.techSlipList[i]}
								rowsCount={this.state.techSlipList.length}
								onCellSelected={this.onTechSlipCellSelected}
								rowSelection={{
						            showCheckbox: false,
						            onRowsSelected:   this.onTechRowsSelected,
						            selectBy: {
						              indexes: this.state.techSelectedIndexes
						            }
								}}  
						/>
						<ReactDataGrid enableCellSelect={true} enableDragAndDrop={false} minHeight={142} //minWidth={660} 
								columns={columnsGoodsSlip}
								rowGetter={i => this.state.goodsSlipList[i]}
								rowsCount={this.state.goodsSlipList.length}
								onCellSelected={this.onGoodsSlipCellSelected}
								rowSelection={{
						            showCheckbox: false,
						            onRowsSelected:   this.onGoodsRowsSelected,
						            selectBy: {
						              indexes: this.state.goodsSelectedIndexes
						            }
								}}  
						/>
					</Col>
				</Row>
				<Row>
					{cashTable}
				</Row>
				<Row>
					<Col xs="12">
						<InputGroup>
							<InputGroupText>メ　モ　</InputGroupText>
							<Input style={{height: '54px'}} type="textarea" placeholder="メモ" value={this.state.visit.memo.replace(/\\n/g, '\n')} onChange={this.memoChange} readOnly={true}/>
						</InputGroup>
					</Col>
				</Row>

			</Container>
		);
	}
	
}


// ====================================================================================================
// 予約・来店履歴の表示
// 
// ====================================================================================================

// item,group を作成するには、担当者、顧客、勤怠、予約が読み終わってないといけない
const ITEM_STAFF    = 0x01;
const ITEM_TECH		= 0x02;
const ITEM_GOODS    = 0x04;
const ITEM_TAX		= 0x08;
const ITEM_POINT	= 0x10;
const ITEM_CREDIT   = 0x20;
// const ITEM_SALON    = 0x40;
const ITEM_ALL    = ITEM_STAFF | ITEM_TECH | ITEM_GOODS | ITEM_TAX | ITEM_POINT | ITEM_CREDIT; //  | ITEM_SALON;

// マスターのreadに使用する
// const salonParam	= { url: "/salon",		errMsg: "店舗一覧",		list: [],	item: ITEM_SALON	};
const staffParam	= { url: "/staff",		errMsg: "担当者一覧",	list: [],	item: ITEM_STAFF	};
const techParam		= { url: "/tech",		errMsg: "技術一覧",		list: [],	item: ITEM_TECH		};
const goodsParam	= { url: "/goods",		errMsg: "商品一覧",		list: [],	item: ITEM_GOODS	};
const taxParam		= { url: "/sales_tax",	errMsg: "消費税率一覧",	list: [],	item: ITEM_TAX		};
const pointParam	= { url: "/point",		errMsg: "POINT率一覧",	list: [],	item: ITEM_POINT	};
const creditParam	= { url: "/credit",		errMsg: "CREDIT一覧",	list: [],	item: ITEM_CREDIT	};

var item_flag = 0;

export class RecordMaster extends Component {
	
	static propTypes = {
	    customer:		PropTypes.object.isRequired,		// 顧客情報
	}

	// コンストラクター
	constructor(props) {
		super(props);
		this.state = {
//			salonList:	salonParam.list,
			staffList:	staffParam.list,
			techList:	techParam.list,
			goodsList:	goodsParam.list,
			taxList:	taxParam.list,
			pointList:	pointParam.list,
			creditList:	creditParam.list,
			customer:	this.props.customer,
			showModalRecord:	false,		// モーダル表示しない
		};
		
		this.getMaster();
	}

	getMaster = () => {
		item_flag = 0;
//		this.getList(salonParam);		// 店舗マスター
		this.getList(staffParam);		// 担当者マスター
		this.getList(techParam);		// 技術マスターの読み込み
		this.getList(goodsParam);		// 商品マスターの読み込み
		this.getList(taxParam);			// 消費税率の読み込み
		this.getList(pointParam);		// ポイント率の読み込み
		this.getList(creditParam);		// クレジットの読み込み
	}

/* -----------------------------------------------------------
	マスターの取得
------------------------------------------------------------- */
	getList = (param) => {
		item_flag &= ~param.item;				// ビットを０にする
		//ajax通信する
		Request.get(param.url)
			.query( { token: JWToken.getToken().token } )
			.then( res => {
				// 店舗表示
				param.list = res.body.dbData;

				item_flag |= param.item;				// ビットを1に

				if ( item_flag === ITEM_ALL ) {			// マスターがすべて読み込まれたか？
					this.getInitialRecord();		// group,itemの作成
				}
			})
			.catch( err => {
				console.error(param.errMsg +"を取得できませんでした。");
				console.log(err,err.status);
				// eslint-disable-next-line
				if ( err & err.status == 403 ) JWToken.clearToken();
			});
	}

	getInitialRecord = () => {

		this.setState({
//			salonList:	salonParam.list,
			staffList:	staffParam.list,
			techList:	techParam.list,
			goodsList:	goodsParam.list,
			taxList:	taxParam.list,
			pointList:	pointParam.list,
			creditList:	creditParam.list,
			showModalRecord: false,				// modal表示
		});

	}

	openRecord = () => {
		this.setState({
			showModalRecord: true,				// modal表示
		});
		
	}
	
	cancelRecord = () => {

		this.setState({
			showModalRecord: false,				// modal非表示
		});

	}

	toggleRecord = () => {
		if ( this.state.showModalRecord === true ) {
			this.cancelRecord();
			this.setState( { showModalRecord: false } );
		} else {
			this.setState( { showModalRecord: true } );
		}
	}

// ======================================================================================
// render()
// 
// ======================================================================================

	render = () => {
 console.log(this.props.customer);

		var modal = 
				<Modal isOpen={this.state.showModalRecord} backdrop={'static'} style={{maxWidth: '750px', width: '99%'}} size={'lg'} onClose={this.cancelRecord} autoFocus={false}> 
					<ModalHeader toggle={this.toggleRecord} >来店履歴　　{this.props.customer.name} 様 </ModalHeader>
					<ModalBody>
						<Record customer	= {this.state.customer	}			// 選択された顧客
//								salonList	= {this.state.salonList} 			// 店舗情報
								staffList	= {this.state.staffList}			// 担当者マスター
								techList	= {this.state.techList}				// 技術マスター
								goodsList	= {this.state.goodsList}			// 商品マスター
								taxList		= {this.state.taxList}				// 消費税
								pointList	= {this.state.pointList}			// ポイントの還元率
								creditList  = {this.state.creditList}			// 決済サービス
						/>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.cancelRecord} >OK</Button>
					</ModalFooter>
				</Modal>
		
		return (
			<div>
				<Button color="info"  onClick={this.openRecord}>来店履歴</Button>
				{ modal }
			</div>
		);
	}

}

export default Record;
