/* ==============================================================================
 Navi Bar 画面

=============================================================================== */

import React, { Component } from 'react';
import { Nav, NavItem, NavbarBrand, NavLink, Navbar, NavbarToggler, Collapse } from 'reactstrap';

// import './AppNavBar.css';

class GuestNavBar extends Component  {

    constructor(props) {
        super(props);
        this.state = {
            salonName: "予約管理システム",
            isOpen: false
        };
        console.log('GuestNavBar');
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render = () => {
        return (
            <div id="appnav">
              <Navbar color="light" light expand="lg">  {/* collapseOnSelect   onSelect={ this.onSelect } */}
                <NavbarBrand href="#/">{this.state.salonName}</NavbarBrand>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>
                  <Nav className="mr" navbar>
                    <NavItem><NavLink href="#/GuestHome"  >ホーム</NavLink></NavItem>
                    <NavItem><NavLink href="#/GuestRecord">履歴</NavLink></NavItem>
                    <NavItem><NavLink href="#/GuestSet"   >設定</NavLink></NavItem>
                  </Nav>
                </Collapse>
              </Navbar>
            </div>
        );
    }
}


export default GuestNavBar;
