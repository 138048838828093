/* ==============================================================================
 Navi Bar 画面

=============================================================================== */

import React, { useState } from 'react';
import { Nav, NavItem, NavbarBrand, NavLink, Navbar, NavbarToggler, Collapse,
        UncontrolledDropdown, DropdownToggle, DropdownMenu,DropdownItem } from 'reactstrap';
import HelpPopover from './HelpPopover';

// import './AppNavBar.css';
/* ----
var products = [
  {id: 1, no:10, name: "Cloud Salon", phone: "090-1111-2222", address: "東京都新宿区", stime: "10:00", etime: "19:00",  workday: 0x7b }
];
---- */


// class AppNavbar extends Component  {
const AppNavBar = (props) => {

  const [isOpen, setOpen ] = useState(false);
  const toggle = () => setOpen(!isOpen);

  return (
    <div id="appnav">
      <Navbar color="secondary" light expand="md">  {/* collapseOnSelect   onSelect={ this.onSelect } */}
        <NavbarBrand href="#/">予約管理システム</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar>
            <NavItem><NavLink href="#/booking" >予約管理</NavLink></NavItem>
            <NavItem><NavLink href="#/customer">お客様</NavLink></NavItem>
            <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>集計表</DropdownToggle>
             <DropdownMenu >
              <DropdownItem href="#/daySlip"   >日次集計</DropdownItem>
              <DropdownItem href="#/monthChart">月次集計</DropdownItem>
              <DropdownItem href="#/yearChart" >年次集計</DropdownItem>
              <DropdownItem href="#/stock"     >商品棚卸</DropdownItem>
             </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem><NavLink href="#/workHour">勤怠管理</NavLink></NavItem>
{/*            <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>勤怠管理</DropdownToggle>
             <DropdownMenu >
              <DropdownItem href="#/shiftModel" >基本シフト（１週間）</DropdownItem>
              <DropdownItem href="#/workHour"   >勤怠管理</DropdownItem>
             </DropdownMenu>
            </UncontrolledDropdown>
 */}
          </Nav>
          <Nav className="ml-auto justify-content-end"  navbar >
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>設定登録</DropdownToggle>
             <DropdownMenu >
              <DropdownItem href="#/salon"   >店舗</DropdownItem>
              <DropdownItem href="#/salestax">消費税率</DropdownItem>
              <DropdownItem href="#/Point"   >ポイント還元率</DropdownItem>
              <DropdownItem href="#/credit"  >決済サービス</DropdownItem>
              <DropdownItem href="#/staff"   >担当者</DropdownItem>
              <DropdownItem href="#/tech"    >技術</DropdownItem>
              <DropdownItem href="#/techSum" >技術集計</DropdownItem>
{/*              <DropdownItem href="#/menu"    >Web予約メニュー</DropdownItem>  2021/12/14 今は無しで */}
              <DropdownItem href="#/goods"   >商品</DropdownItem>
              <DropdownItem href="#/supplier">仕入先</DropdownItem>
              <DropdownItem href="#/dayoffitem">勤怠届</DropdownItem>
              <DropdownItem href="#/accountitem">勘定科目</DropdownItem>
              <DropdownItem href="#/settings">設定</DropdownItem>
              <DropdownItem href="#/payment">お支払情報</DropdownItem>
{ /*          <DropdownItem href="#/reserve" >ウェブ予約設定</DropdownItem>     Web予約・勤怠管理は無しとする
              <DropdownItem href="#/excuse"  >勤怠届</DropdownItem>  */ }
              <DropdownItem divider />
              <DropdownItem href="#/about">About</DropdownItem>
             </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
        <HelpPopover id="NavBar" help={help}/>
      </Navbar>
    </div>
    );
};

const help = 
  "##### 予約管理\n" +
  "店舗毎に予約状況を表示/編集できます。  \n" +
  "勤怠情報を表示します。  \n" +
  "##### お客様\n" +
  "お客様一覧を表示/検索し編集できます。  \n" +
  "はがきの宛名を印刷できます。  \n" +
  "##### 集計表\n" +
  "全店舗の日次/月次/年次集計表を表示/印刷できます。  \n" +
  "###### 　日次集計\n" +
  "今日の集計表を表示/印刷できます。  \n" +
  "###### 　月次集計\n" +
  "今月の集計表を表示/印刷できます。  \n" +
  "期首の設定は**設定登録**>[設定](./#/settings)の**経理情報**で行います。  \n" +
  "###### 　年次集計\n" +
  "今年の集計表を表示/印刷できます。  \n" +
  "期首の設定は**設定登録**>[設定](./#/settings)の**経理情報**で行います。  \n" +
  "###### 　棚　卸\n" +
  "店舗毎に入力した在庫数を集計して印刷します。  \n" +
  "##### 勤怠管理\n" +
  "勤怠管理を表示/入力/印刷できます。  \n" +
  "##### 設定登録\n" +
  "各種の設定/登録を行います。  \n" +
  "###### 　店舗\n" +
  "店舗の一覧を表示/編集できます。  \n" +
  "複数の店舗を登録でき、予約管理は店舗毎に行います。  \n" +
  "###### 　消費税率\n" +
  "消費税率の一覧を表示/編集できます。  \n" +
  "過去の消費税率は追加・修正・削除できません。  \n" +
  "「総額表示」の場合は消費税率を0に設定してください。  \n" +
  "###### 　ポイント還元率\n" +
  "ポイント還元率の一覧を表示/編集できます。  \n" +
  "過去のポイント還元率は追加・修正・削除できません。  \n" +
  "###### 　決済サービス\n" +
  "決済サービスの一覧を表示/編集できます。  \n" +
  "###### 　担当者\n" +
  "担当者の一覧を表示/編集できます。  \n" +
  "###### 　技術\n" +
  "技術の一覧を表示/編集できます。  \n" +
  "###### 　技術集計\n" +
  "技術集計の一覧を表示/編集できます。  \n" +
  "###### 　商品\n" +
  "商品や材料の一覧を表示/編集できます。  \n" +
  "###### 　仕入先\n" +
  "仕入先の一覧を表示/編集できます。  \n" +
  "###### 　勤怠届\n" +
  "勤怠届の一覧を表示/編集できます。  \n" +
  "###### 　勘定科目\n" +
  "勘定科目の一覧を表示/編集できます。  \n" +
  "売上以外の**入出金を記録**してレジの現金を正確に把握できます。  \n" +
  "現金実査をいつでも正確に行えます。  \n" +
  "###### 　設定\n" +
  "テナント/経理情報/勤怠管理情報を設定します。  \n" +
  "設定した場合は再ログインして下さい。  \n" +
  "###### 　お支払い情報\n" +
  "クレジットカード情報/お支払い/停止・再開を設定します。  \n" +
  "  \n" +
  "";


export default AppNavBar;
