import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { QRCodeSVG } from 'qrcode.react';

const QRcalendar = (props) => {
	const {
        summary,
        location,
        dtstart,
        dtend,
        description,
	} = props;

    const [modal, setModal] = useState(false);
    const [body,  setBody] =  useState('');
    
    const toggle = () => setModal(!modal);
    
    useEffect(() => {
        if ( modal ) {
            let event = ['BEGIN:VEVENT'];
            event.push('SUMMARY:'     + summary);
            event.push('DTSTART:'     + dtstart);
            event.push('DTEND:'       + dtend);
            event.push('LOCATION:'    + location);
            event.push('DESCRIPTION:' + description);
            event.push('END:VEVENT');
            
            setBody( event.join('\n') );
            
            console.log(summary,location,dtstart,dtend,description);
        }
	// eslint-disable-next-line
	},[modal]);

    
    return (
        <div >
            <Button color="info" onClick={toggle}>QRコード</Button>{" "}
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>カレンダーに登録</ModalHeader>
                <ModalBody>
                お客様のスマートホンで このQRコードを取り込んでください。
                スマートフォンのカレンダーに ご予約を登録できます。
                <center>
                    <QRCodeSVG size='200' value={body}/> 
                </center>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>OK</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default QRcalendar;