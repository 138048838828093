/* ==============================================================================
予約・来店・会計　管理モジュール


=============================================================================== */
// import React   from 'react';
import Request from 'superagent';
import {JWToken,Alert /* , GotoLogin */ } from './auth/Login';
// import {useNavigate} from 'react-router-dom';

/* =============================================================
	マスターの取得
   -------------------------------------------------------------
	getMasters( masterInfo , callback )

	masterInfo = [{
       url:     "/salon/"	// REST API のアドレス 
       errMsg:  "店舗", 	// エラーメッセージに表示するマスター名称
       list:    object[]	// 出力用 マスターDB
	}];

	function	init()      // 全てのマスターDBが読み込まれると呼び出される関数

============================================================= */

var items = 0;			// 配列数をビットに変換
export function getMasters (masterInfo, callback)  {
	items = (2 ** masterInfo.length) -1;			// 配列数をビットに変換
    masterInfo.forEach( function(param,index) {
        getList(param, 1<<index, callback);			// indexはビット位置に変換
    });
}

function getList  (param, index, callBack)  {
//	console.log("getList: ", param,index);
	//ajax通信する
	Request.get(param.url)
		.query( { token: JWToken.getToken().token } )
		.then( res => {
			param.list = res.body.dbData;
			items &= ~index;					// ビットを０にする
			if ( items === 0 ) {				// マスターがすべて読み込まれたか？
				callBack();						// 初期化関数
			}
		})
		.catch( err => {
			console.error(param.errMsg +"一覧を取得できませんでした。");
			console.log(err,err.status,param.url);
			if ( err & err.status === 403 ) {
				Alert('再ログインしてください').then(result => {
					JWToken.clearToken();
//					GotoLogin();
				});
			}
		});
}

/* =============================================================
	Recordの取得
   -------------------------------------------------------------
	getRecord( masterInfo , function )

	masterInfo = {
       url:     "/salon/"       // REST API のアドレス 
       errMsg:  "店舗", 		// エラーメッセージに表示するマスター名称
       list:    object[]        // 出力用 マスターDB
	};

	function	init()          // 全てのマスターDBが読み込まれると呼び出される関数

============================================================= */
export function getRecord (param, id, callBack) {
//	console.log('getRecord:'+param,id);
	//ajax通信する
	Request.get(param.url + id)
		.query( { token: JWToken.getToken().token } )
		.then( res => {
			callBack(res.body.dbData);
		})
		.catch( err => {
			console.error(param.errMsg +"を取得できませんでした。");
			console.log(err,err.status);
			if ( err & err.status === 403 ) {
				Alert('再ログインしてください').then(result => {
					JWToken.clearToken();
				});
			}
		});
}

/* =============================================================
	Recordの追加(複数)
   -------------------------------------------------------------
	addRecords( masterInfo, callBack )

	masterInfo = {
       url:     "/salon/"       // REST API のアドレス 
       errMsg:  "店舗", 		// エラーメッセージに表示するマスター名称
       list:    object[]        // 入力用 マスターDB
	};

	function	callBack()      // 全てのマスターDBが読み込まれると呼び出される関数

============================================================= */
export function addRecords (masterInfo, callBack) {
	//ajax通信する
	Request.post(masterInfo.url)
		.send({ dbData:masterInfo.list, token: JWToken.getToken().token } )
		.then( res => {
			callBack(res.body.dbData);
		})
		.catch( err => {
			console.error(masterInfo.errMsg +"を追加できませんでした");
			console.log(err,err.status);
			if ( err & err.status === 403 ) {
				Alert('再ログインしてください').then(result => {
					JWToken.clearToken();
				});
			}
		});
}

/* =============================================================
	Recordの追加
   -------------------------------------------------------------
	addRecord( masterInfo, callBack )

	masterInfo = {
       url:     "/salon/"       // REST API のアドレス 
       errMsg:  "店舗", 		// エラーメッセージに表示するマスター名称
       list:    object[]        // 入力用 マスターDB
	};

	function	callBack()      // 全てのマスターDBが読み込まれると呼び出される関数

============================================================= */
	export function addRecord (masterInfo, callBack) {
	//ajax通信する
	Request.post(masterInfo.url)
		.send({ dbData:masterInfo.list[0], token: JWToken.getToken().token } )
		.then( res => {
			callBack && callBack(res.body.dbData);
		})
		.catch( err => {
			console.error(masterInfo.errMsg +"を追加できませんでした");
			console.log(masterInfo);
			console.log(err,err.status);
			if ( err & err.status === 403 ) {
				Alert('再ログインしてください').then(result => {
					JWToken.clearToken();
				});
			}
		});
}

/* =============================================================
	Recordの変更(複数Records)
   -------------------------------------------------------------
	repRecords( masterInfo, callBack )

	masterInfo = {
       url:     "/salon/"       // REST API のアドレス 
       errMsg:  "店舗", 		// エラーメッセージに表示するマスター名称
       list:    object[]        // 出力用 マスターDB
	};

	function	callBack()      // Recordが変更される度に呼び出される関数

============================================================= */
export function repRecords (masterInfo, callBack) {
	//ajax通信する
	Request.put(masterInfo.url )
		.send({ dbData:masterInfo.list, token: JWToken.getToken().token } )
		.then( res => {
//			masterInfo.list = res.body.dbData;
			callBack && callBack(res.body.dbData);
		})
		.catch( err => {
			console.error(masterInfo.errMsg +"を修正できませんでした");
			console.log(masterInfo);
			console.log(err,err.status);
			if ( err & err.status === 403 ) {
				Alert('再ログインしてください').then(result => {
					JWToken.clearToken();
				});
			}
		});
}

/* =============================================================
	Recordの変更(1 Record)
   -------------------------------------------------------------
	repRecord( masterInfo, callBack )

	masterInfo = {
       url:     "/salon/"       // REST API のアドレス 
       errMsg:  "店舗", 		// エラーメッセージに表示するマスター名称
       list:    object[]        // 出力用 マスターDB
	};

	function	callBack()      // Recordが変更される度に呼び出される関数

============================================================= */
export function repRecord (masterInfo, row, callBack) {
	//ajax通信する
	Request.put(masterInfo.url + '/' + row.id )
		.send({ dbData:row, token: JWToken.getToken().token } )
		.then( res => {
//			masterInfo.list = res.body.dbData;
			callBack && callBack(res.body.dbData);
		})
		.catch( err => {
			console.error(masterInfo.errMsg +"を修正できませんでした");
			console.log(err,err.status);
			if ( err & err.status === 403 ) {
				Alert('再ログインしてください').then(result => {
					JWToken.clearToken();
				});
			}
		});
}

/* =============================================================
	Recordの変更(1 Record)　workhourで使用している。(Attendane.jp)
	id が無い場合に使う。
   -------------------------------------------------------------
	repRecord( masterInfo, callBack )

	masterInfo = {
       url:     "/workhour/" + work.staff_id  + '/' + work.work_date,		// REST API のアドレス 
       errMsg:  "出勤/退勤データ", 											// エラーメッセージに表示するマスター名称
       list:    object[]        											// 出力用 マスターDB
	};

	function	callBack()      // Recordが変更される度に呼び出される関数

============================================================= */
export function repRecord1 (masterInfo, row, callBack) {
	//ajax通信する
	Request.put(masterInfo.url )
		.send({ dbData:row, token: JWToken.getToken().token } )
		.then( res => {
//			masterInfo.list = res.body.dbData;
			callBack();
		})
		.catch( err => {
			console.error(masterInfo.errMsg +"を修正できませんでした");
			console.log(err,err.status);
			if ( err & err.status === 403 ) {
				Alert('再ログインしてください').then(result => {
					JWToken.clearToken();
				});
			}
		});
}

/* =============================================================
	レコードの削除(複数Records)
   -------------------------------------------------------------
	delRecords( masterInfo, callBack )

	masterInfo = {
       url:     "/salon/"       // REST API のアドレス 
       errMsg:  "店舗", 		// エラーメッセージに表示するマスター名称
       list:    object[]        // 出力用 マスターDB
	};

	function	callBack()      // Recordが変更される度に呼び出される関数

============================================================= */
export function delRecords (masterInfo, callBack) {
	//ajax通信する
	Request.delete(masterInfo.url )
		.send({ dbData:masterInfo.list, token: JWToken.getToken().token } )
		.then( res => {
//			masterInfo.list = res.body.dbData;
			callBack();
		})
		.catch( err => {
			console.error(masterInfo.errMsg +"を削除できませんでした");
			console.log(err,err.status);
			if ( err & err.status === 403 ) {
				Alert('再ログインしてください').then(result => {
					JWToken.clearToken();
				});
			}
		});
}

/* =============================================================
	レコードの削除(1 Records)
   -------------------------------------------------------------
	delRecord ( masterInfo, callBack )

	masterInfo = {
       url:     "/salon/"       // REST API のアドレス 
       errMsg:  "店舗", 		// エラーメッセージに表示するマスター名称
       list:    object[]        // 出力用 マスターDB
	};

	function	callBack()      // Recordが変更される度に呼び出される関数

============================================================= */
export function delRecord (masterInfo, row, callBack) {
	//ajax通信する
	Request.delete(masterInfo.url + '/' + row.id )
		.send({ dbData:row, token: JWToken.getToken().token } )
		.then( res => {
//			masterInfo.list = res.body.dbData;
			callBack();
		})
		.catch( err => {
			console.error(masterInfo.errMsg +"を削除できませんでした");
			console.log(err,err.status);
			if ( err & err.status === 403 ) {
				Alert('再ログインしてください').then(result => {
					JWToken.clearToken();
				});
			}
		});
}

/* =============================================================
	レコードの削除(1 Records)　workhourで使用している。(Attendane.jp)
	id が無い場合に使う。
   -------------------------------------------------------------
	delRecord1( masterInfo, row, callBack )

	masterInfo = {
       url:     "/salon/"       // REST API のアドレス 
       errMsg:  "店舗", 		// エラーメッセージに表示するマスター名称
       list:    object[]        // 出力用 マスターDB
	};

	function	callBack()      // Recordが変更される度に呼び出される関数

============================================================= */
export function delRecord1 (masterInfo, row, callBack) {
	//ajax通信する
	Request.delete(masterInfo.url  )
		.send({ dbData:row, token: JWToken.getToken().token } )
		.then( res => {
//			masterInfo.list = res.body.dbData;
			callBack();
		})
		.catch( err => {
			console.error(masterInfo.errMsg +"を削除できませんでした");
			console.log(err,err.status);
			if ( err & err.status === 403 ) {
				Alert('再ログインしてください').then(result => {
					JWToken.clearToken();
				});
			}
		});
}
