import React, {useState} from 'react';
import { Input } from 'reactstrap';
import useSound from 'use-sound';
import glugSfx from './error.mp3';

import {
	MAX_NUMBER_LENGTH,
	MAX_MONEY_LENGTH,
	MAX_MAIL_LENGTH,
	MAX_PHONE_LENGTH,
	MAX_ZIPCODE_LENGTH,
	MAX_NAME_LENGTH,
} from './Define';

/********
const Beep = ( ) => {
    const [playbackRate, setPlaybackRate] = React.useState(1.0);
    const [play] = useSound(glugSfx, {
        playbackRate,
        // `interrupt` ensures that if the sound starts again before it's
        // ended, it will truncate it. Otherwise, the sound can overlap.
        interrupt:true,
    });
    console.log('Beep');
    play();
};
********/
const Check = {
    phone: function( str ) {
        if ( str.match( /[^0-9\-+()]/ ) )       return false;
        if ( str.length > MAX_PHONE_LENGTH )    return false;
        return true;
    },
    email: function( str ) {
        if ( str.match( /[^0-9A-Za-z\-_@.]/ ) ) return false;
        if ( str.length > MAX_MAIL_LENGTH )     return false;
        return true;
    },
    number: function( str ) {       // Tax、Point Rate
        if ( str.match( /[^0-9]/ ) )            return false;
        if ( str.length > MAX_NUMBER_LENGTH )   return false;
        return true;
    },
    postCode: function ( str ) {
        if ( str.match( /[^0-9-]/ ) ) return false;
        let code = str.replace( "-" , "" ) ;
        if ( code.length > MAX_ZIPCODE_LENGTH -1 ) return false;
        return true;
    },
    money: function( str ) {
        if ( str.match( /[^0-9\-+]/ ) ) return false;
        if ( str.length > MAX_MONEY_LENGTH ) return false;
        if ( isNaN( Number(str) ) ) return false;
        return true;
    },
    isZenKatakana: function (str) {
        if ( ! str ) return true;
//        str = (str==null)?"":str;
        if(str.match(/^[ァ-ヶー　]+$/)){    //"ー"の後ろの文字は全角スペースです。
            return true;
        }else{
            return false;
        }
    }
};

/* =============================================================================
    Input Login Name
        autoFocus: true | false     // 
   ============================================================================= */
export const InputLoginName = (props) => {
    const [username, setUsername] = useState('');
    const [play] = useSound(glugSfx, { playbackRate:1.0, interrupt:true});
    const { maxLength = MAX_NAME_LENGTH,
            autoFocus = false,
            callBack  = null } = props;

    const usernameChange = (event) => {
        if ( event.target.value.length > maxLength ) { play(); return; }
        setUsername( event.target.value);
        if ( callBack ) callBack(event.target.value);
    };

    return(
        <Input type="text" value={username} placeholder="User Name" autoComplete="username" onChange={usernameChange} autoFocus={autoFocus} />
    );
};

/* =============================================================================
    Input String
        maxLength: String Length    // default MAX_NAME_LENGTH
        autoFocus: true | false     // default false
   ============================================================================= */
export const InputString = (props) => {
    const [valueString, setValueString] = useState('');
    const [play] = useSound(glugSfx, { playbackRate:1.0, interrupt:true});
    const { maxLength = MAX_NAME_LENGTH,
            autoFocus = false,
            callBack  = null,
            placeholder = "User Name"} = props;

    const valueStringChange = (event) => {
        if ( event.target.value.length > maxLength ) { play(); return; }
        setValueString( event.target.value);
        if ( callBack ) callBack(event.target.value);
    };

    return(
        <Input type="text" value={valueString} placeholder={placeholder} autoComplete="username" onChange={valueStringChange} autoFocus={autoFocus} />
    );
};


/* =============================================================================
    Input Number
        maxLength: String Length    // default MAX_NUMBER_LENGTH
        autoFocus: true | false     // default false
   ============================================================================= */
export const InputNumber = (props) => {
    const [valueString, setValueString] = useState('');
    const [invalid, setInvalid] = useState(false);
    const { maxLength = MAX_NUMBER_LENGTH,
            placeholder = '数値',
            autoFocus = false           } = props;

    const valueStringChange = (event) => {
        if ( event.target.value.length > maxLength ) {
            setInvalid(true);
        } else {
            setInvalid(true);
        }
        setValueString( event.target.value);
    };

    return(
        <Input type="text" invalid={invalid} value={valueString} placeholder={placeholder} autoComplete="username" onChange={valueStringChange} autoFocus={autoFocus} />
    );
};


export default Check;