/* ==============================================================================
 勤怠管理、出勤、退勤、勤怠届

　予約管理画面のgroups(担当者)を選択すると実行する。
　担当者の出勤画面、退勤画面、勤怠届画面を表示してデータを保存する

 履歴-----------------------------------------
 2023/07/05 作成
 
=============================================================================== */
import React, { useState, useEffect }  from 'react';
import {  Form, FormGroup, InputGroup, InputGroupText, Input, Table } from 'reactstrap';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Dropdown, Tooltip } from 'reactstrap';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import Select from 'react-select';
import Clock  from 'react-live-clock';
import moment from 'moment';

import { getRecord, repRecord, delRecord1, addRecords, addRecord, repRecords } from './Agent';
import ModalHook, {FooterOkCancel,FooterOkCancelDelete} from './ModalHook';
import {JWToken} from './auth/Login';
import {sub,hm2m} from './Time';

import {
	MAX_DB_LENGTH,
	MAX_ABSTRACT_LENGTH,
} from './Define';

// 0:他店舗、1:勤怠届、1:作成、2:確定、4:出勤、5:退勤
import {
//	WORK_OTHER,			// 他店舗
	WORK_REQUEST,		// 勤怠届
//	WORK_CREATE,		// 作成
	WORK_RELEASE,		// 確定　予約開始
	WORK_ARRIVE,		// 出勤
	WORK_LEAVE,			// 退勤
} from './Define';

const shiftStatus =  [ "他店", "要望", "計画",  "確定", "出勤", "退勤"  ];

const initialWorkHour = { tenant_id: 0, staff_id: 0, work_date: "2020-01-01", day_off_id: 0, punch_start: "00:01", punch_quit: "00:01", start: "00:01", quit: "00:01", break_time: "01:00", abstract: "" };

const Attendance = (props) => {
	const {
        id,             // 担当者id
        name,           // 担当者名
        reload,         // 再読込
		dayOffItem,		// 勤怠届マスター
		salon,			// 店舗情報
		getBookInfo,	// Bookが表示している店舗と日付を取り込む
	} = props;

    const [workhour,	 setWorkhour ]    = useState(initialWorkHour);  // 出勤画面 表示
    const [isOpenArrive, setIsOpenArrive ] = useState(false);       // 出勤画面 表示
    const [isOpenLeave,  setIsOpenLeave  ] = useState(false);       // 退勤画面 表示
    const [isOpenNotify, setIsOpenNotify ] = useState(false);       // 勤怠届画面 表示
    const tenant = JWToken.getToken().tenant;	// テナント情報


    useEffect(() => {       // componentDidMount() の代わり。 最初に1度だけコールされる
    }, []);

	/* ----------------------------------------------
	   gropusをクリックして当日の出勤情報を登録する
	---------------------------------------------- */
	const onClickArrive= (staff_id ) => {
		console.log("onClickArrive staff_id: " , staff_id );

        let info = getBookInfo();
        const readParam = { url: "/workhour/start/",	    errMsg: "出勤情報",	list: {}};
		getRecord(readParam, staff_id, (dbData) => {
		    console.log(dbData[0]);
            let work = dbData[0];
            
			// 出勤時間の丸め処理
			let time = work.start.split(':');
			time[2] = '00';
			time[1] = ( '00' + (Number(time[1]) + 1) ).slice( -2 );
			if ( time[1] >= 60 ) {
				time[1] = '00';
				time[0] = ( '00' + (Number(time[0]) + 1) ).slice( -2 );
				if ( time[0] >= 24 ) time[0] = '00';
			}
			work.start = time.join(":");

			// 出勤時刻前の出勤は残業としない場合は、テナントの開始時刻を出勤時間にする
			if ( tenant.before_work === 0 ) {
				if ( work.start < salon.start_time ) {
					 work.start = salon.start_time;
				}
			}
			
			// 休憩時間がnullなら初期値をいれる
			if ( !work.break_time ) {
				work.break_time = tenant.break_time;
			}

			// 店舗ID が設定されていないなら
			work.salon_id = info.salon_id;

			// 勤怠届は出勤にする。
			if ( !work.day_off_id ) work.day_off_id = dayOffItem[0].id;
			
			work.status = WORK_ARRIVE;		// ステータスを出勤にする

		    setWorkhour(work);
            setIsOpenArrive(true);
		});
 	};

	/* ----------------------------------------------
	   gropusをクリックして当日の退勤情報を登録する
	---------------------------------------------- */
	const onClickLeave = (staff_id ) => {
        console.log('onClickLeave staff_id:', staff_id); 

        let info = getBookInfo();
        const readParam = { url: "/workhour/quit/",	    errMsg: "退勤情報",	list: {}};
		getRecord(readParam, staff_id, (dbData) => {
		    console.log(dbData[0]);
			let	work = dbData[0];

			// 出勤時間の丸め処理
			let time = work.quit.split(':');
			time[2] = '00';
			work.quit = time.join(":");

			// 出勤時刻より退勤時刻が早い場合は、同じ時刻にする
			if ( work.quit < work.start ) work.quit = work.start;

			// 勤務時間が6時間未満の場合は、休憩を0時間にする
			if ( sub(work.quit, work.start) < hm2m('06:00:00') ) {
				work.break_time = '00:00:00';
			}

			// 勤怠届は出勤にする。
			if ( !work.day_off_id ) work.day_off_id = dayOffItem[0].id;
			
			// 店舗ID が設定されていないなら
			work.salon_id = info.salon_id;

			work.status = WORK_LEAVE;		// statusを退勤にする
			
		    setWorkhour(work);
            setIsOpenLeave(true);
		});
	};

	/* ----------------------------------------------
	   勤怠届データを取得する
	---------------------------------------------- */
	const onClickNotify = ( staff_id ) => {
        let info = getBookInfo();
        const readParam = { url: "/workhour/edit/" + staff_id + '/' ,	    errMsg: "勤怠届",	list: {}};
		getRecord(readParam, info.date, (dbData) => {
			let	work = dbData[0];

			// シフトが、設定されていないなら、テナントの開始時刻から終了時刻とする。
			if ( !work.shift_start && !work.shift_quit ) {
				work.shift_start = tenant.start_time;
				work.shift_quit  = tenant.quit_time;
			}
			
			// 店舗ID が設定されていないなら
			work.salon_id = info.salon_id;
			
			work.work_date	= info.date;		// bookingに表示している日付を設定する
//			work.status		= WORK_REQUEST;		// statusを要望にする　→ statusは変更しない

		    setWorkhour(work);
	        setIsOpenNotify(true);
		});
	};

	/* ----------------------------------------------
	   勤怠届データを登録する
	---------------------------------------------- */
	const onChangeDate = ( staff_id, date ) => {
        let info = getBookInfo();
        const readParam = { url: "/workhour/edit/" + staff_id + '/' ,	    errMsg: "勤怠届",	list: {}};
		getRecord(readParam, date, (dbData) => {
			let	work = dbData[0];

			// シフトが、設定されていないなら、テナントの開始時刻から終了時刻とする。
			if ( !work.shift_start && !work.shift_quit ) {
				work.shift_start = tenant.start_time;
				work.shift_quit  = tenant.quit_time;
			}
			
			// 店舗ID が設定されていないなら
			work.salon_id = info.salon_id;
			
			work.work_date	= date;		// bookingに表示している日付を設定する
//			work.status		= WORK_REQUEST;		// statusを要望にする　→ statusは変更しない

		    setWorkhour(work);
	        setIsOpenNotify(true);
		});
	};

	/* ----------------------------------------------
	   出勤、退勤データを登録する
	---------------------------------------------- */
    const onClose = (work) => {
        let repParam = { url: "/workhour/" ,	errMsg: "出勤/退勤データ",	list: [work]};

 		addRecord(repParam,  (dbData) => {		// idが無いdbDataを扱う場
	        if ( reload ) reload(work.work_date);
 		});

        setIsOpenArrive(false);
        setIsOpenLeave(false);
        setIsOpenNotify(false);
    };

    const onCancel = (workhour) => {
        setIsOpenArrive(false);
        setIsOpenLeave(false);
        setIsOpenNotify(false);
    };

    return (
        <div>
            <UncontrolledDropdown className="me-2" direction="end" >
                <DropdownToggle caret color='transparent' style={{ fontSize:'15px' }} >
                    {name}
                </DropdownToggle>
                <DropdownMenu container="body">
                  <DropdownItem header>
					<Clock format={'HH:mm:ss'} style={{fontSize: '1.5em'}}  ticking={true} />
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => { onClickArrive(id) }}>
                    出勤
                  </DropdownItem>
                  <DropdownItem onClick={() => { onClickLeave(id) }}>
                    退勤
                  </DropdownItem>
                  <DropdownItem onClick={() => { onClickNotify(id) }}>
                    勤怠届
                  </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            <ModalArrive isOpen={isOpenArrive} subTitle={name} workhour={workhour} onClose={onClose} onCancel={onCancel} salon={salon} />
            <ModalLeave  isOpen={isOpenLeave}  subTitle={name} workhour={workhour} onClose={onClose} onCancel={onCancel} salon={salon} />
            <ModalNotify isOpen={isOpenNotify} subTitle={name} workhour={workhour} onClose={onClose} onCancel={onCancel} salon={salon} changeDate={onChangeDate} dayOffItem={dayOffItem} />
        </div>
    );
};

/*******************************************************************************
 * シフト作成・確定画面
 * 
 * Bookの「指名なし」をclickされると表示
 * 　・salonのshift_date以降に1週間分のシフトを作成
 * 　・salon.shift_sate以降の1週間分のシフトを確定にする
 ******************************************************************************/
export const CreateShift = (props) => {
	const {
        name,           // 担当者名
        reload,         // 再読込
		salon,			// 店舗情報
		dropdown=null,	// ドロップダウンリスト
	} = props;

	const [dropDownReq, setDropDownReq] = useState("");
	const [dropDownRight, setDropDownRight] = useState(false);
	const [tooltip1Open, setTooltip1Open] = useState(false);
    const toggleTooltip1 = () => setTooltip1Open(prevState => !prevState);
	const [tooltip2Open, setTooltip2Open] = useState(false);
    const toggleTooltip2 = () => setTooltip2Open(prevState => !prevState);
	const [tooltip3Open, setTooltip3Open] = useState(false);
    const toggleTooltip3 = () => setTooltip3Open(prevState => !prevState);
  
    useEffect(() => {       // componentDidMount() の代わり。 最初に1度だけコールされる

    }, []);

	/* ----------------------------------------------
	   スタイリストをクリックしてシフトパターンをシフトに登録する
	---------------------------------------------- */
	const onClickCreate = ( ) => {

		const shiftDate = salon.shift_date || moment().format('YYYY-MM-DD');

        const addParam = { url: "/workhour/" + salon.id + '/' + shiftDate,	  errMsg: "シフト作成",	list: []};
		addRecords(addParam, (dbData) => {
			console.log(dbData[0]);
//            let work = dbData[0];
		    salon.shift_date = moment(shiftDate).add(7,'Days').format('YYYY-MM-DD');
	        const readParam = { url: "/salon"　,	errMsg: "サロンのシフト作成日",	list: [salon]};
			repRecord(readParam, salon, (dbData) => {
				console.log(dbData[0]);
			});
            
			reload && reload(shiftDate);
		});
 	};


	/* ----------------------------------------------
	   スタイリストをクリックしてシフトを確認する
	---------------------------------------------- */
	const toggleRight = ( ) => {
		console.log(dropDownRight )
		if ( dropDownRight ) {
			setDropDownRight(false)
			return;
		}

// 		setDropDownReq(<DropdownItem >ワーク1</DropdownItem> );
		
		const shiftDate = salon.shift_date || moment().format('YYYY-MM-DD');

        const getParam = { url: "/workhour/request/" + salon.id + '/' ,	  errMsg: "シフト要望",	list: []};
		getRecord(getParam, shiftDate, (dbData) => {
			console.log(dbData);
			
			let req = dbData.map((workhour,index) =>{
				return <DropdownItem onClick={() => reload(workhour.work_date)}> { workhour.work_date + " " + workhour.name + " " + workhour.staff } </DropdownItem>;
			});

			setDropDownReq(req);
			
		});

		setDropDownRight(true);
 	};


	/* ----------------------------------------------
	   スタイリストクリックしてシフトを確定する
	---------------------------------------------- */
	const onClickConfirm = ( ) => {
		const shiftDate = salon.shift_date || moment().format('YYYY-MM-DD');
		
        const readParam = { url: "/workhour/confirm/"　+ salon.id + '/' + shiftDate,	errMsg: "シフト確定",	list: {}};
		repRecords(readParam, (dbData) => {
		    console.log(dbData[0]);
		

			reload && reload(shiftDate);
		});
	};


	/* ----------------------------------------------
	   出勤、退勤データを登録する
	---------------------------------------------- * /
    const onClose = (work) => {
        console.log('onClose', work );
        let repParam = { url: "/workhour/" + work.staff_id  + '/' + work.work_date,	errMsg: "出勤/退勤データ",	list: work};
 		repRecord1(repParam, work, (dbData) => {		// idが無いdbDataを扱う場合
 			console.log('onClose put:',work,dbData);
	        reload(work.work_date);
 		});

    };

    const onCancel = (workhour) => {
    };
*/

    return (
        <div>
            <UncontrolledDropdown className="me-2" direction="end" >
                <DropdownToggle caret color='transparent' style={{ color:'white', fontSize:'15px' }} >
                    {name}
                </DropdownToggle>
                <DropdownMenu container="body">
                	<DropdownItem header>
						シフト作成日：{salon.shift_date ||  moment().format('YYYY-MM-DD') }
                	</DropdownItem>
                	<DropdownItem divider />
                	
                					    
				    <DropdownItem href="#/shiftModel" >基本シフト（１週間）</DropdownItem>

                	<DropdownItem id="item1"
                		onClick={() => { onClickCreate() }} 
	                	onMouseEnter={() => setTooltip1Open(true)}
					    onMouseLeave={() => setTooltip1Open(false)}
                	>
                    	シフト作成
                	</DropdownItem>
	                <Tooltip
				        placement="right"
				        isOpen={tooltip1Open}
				        target="item1"
				        toggle={toggleTooltip1}
				    >
				        シフト作成日から１週間分のシフトを作成します
				    </Tooltip>
				    
					<Dropdown direction="right" isOpen={dropDownRight} toggle={toggleRight}>
						<DropdownToggle caret tag="div" className="dropdown-item" id="item2">シフト要望の確認</DropdownToggle>
						<DropdownMenu>
							{ dropDownReq }
						</DropdownMenu>
					</Dropdown>             
	                <Tooltip
				        placement="right"
				        isOpen={tooltip2Open}
				        target="item2"
				        toggle={toggleTooltip2}
				    >
				        シフト作成日より前のシフト要望を確認します。
				    </Tooltip>
				    
					<DropdownItem id="item3" 
						onClick={() => { onClickConfirm() }}
	                	onMouseEnter={() => setTooltip3Open(true)}
					    onMouseLeave={() => setTooltip3Open(false)}
					>
                    	シフト確定
                	</DropdownItem>
	                <Tooltip
				        placement="right"
				        isOpen={tooltip3Open}
				        target="item3"
				        toggle={toggleTooltip3}
				    >
				        シフト作成日までのシフトを確定します。
				    </Tooltip>
				    
                	{dropdown}
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    );
};


/*******************************************************************************
 * 出勤モーダル画面
 * 
 * onCanvasClick()、onItemClick()で表示される
 ******************************************************************************/
const ModalArrive = (props) => {
	const {
		isOpen,
		title='出勤',
		subTitle,
		workhour,
		salon,
		onClose,        // Shift_PatternにWriteする
		onCancel,
	} = props;

	const [workDate,    setWorkDate]    = useState();
    const [shiftStart,	setShiftStart]	= useState(undefined);
    const [shiftQuit,	setShiftQuit ]	= useState(undefined);
    const [start,		setStart]		= useState(undefined);
    const [quit,		setQuit ]		= useState(undefined);
    const [punchStart,	setPunchStart]	= useState(undefined);
    const [punchQuit,	setPunchQuit]	= useState(undefined);
    
    useEffect(() => {       // componentDidMount() の代わり。 最初に1度だけコールされる
        setWorkDate(	workhour.work_date );
     	setShiftStart(	workhour.shift_start	||	salon.stime);
    	setShiftQuit(	workhour.shift_quit		||	salon.etime);
		setStart(		workhour.start			||  undefined);
        setPunchStart(	workhour.punch_start	||  undefined);
        setQuit(		workhour.quit			||	undefined);
        setPunchQuit(	workhour.punch_quit		||	undefined);
	// eslint-disable-next-line 
    }, [isOpen]);

	const onCloseButton = () => {
		let work = workhour;
		work.shift_start	= shiftStart;
		work.shift_quit		= shiftQuit;
		work.start			= start;
		work.punch_start	= punchStart;
		work.status			= WORK_ARRIVE;	// 出勤
		onClose(work);
	};

    let body =
		<Form>
            <h5>おはようございます。</h5>
            {workDate}
            <Table bordered style={{marginBottom: '7px'}}>
				<thead style={{backgroundColor: '#e9ecef', fontWeight:'normal'}}>
					<tr>
						<th>
						</th>
						<th>
							シフト
						</th>
						<th>
							勤 怠
						</th>
						<th>
							打 刻
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th style={{backgroundColor: '#e9ecef', width:'90px'}}>
							 出 勤
						</th>
						<td>
							{shiftStart ? shiftStart.substr( 0, 5 ) : '--:--'}
						</td>
						<td>
							{start ? start.substr( 0, 5 ) : '--:--'}
						</td>
						<td>
							{punchStart || '--:--:--'}
						</td>
					</tr>
					
					<tr style={{backgroundColor:'#F6F8FA'}}>
						<th style={{backgroundColor: '#e9ecef', width:'90px'}}>
							退 勤
						</th>
						<td>
							{shiftQuit ? shiftQuit.substr( 0, 5 ) : '--:--'}
						</td>
						<td>
							{quit ? quit.substr( 0, 5 ) : '--:--'}
						</td>
						<td>
							{punchQuit || '--:--:--'}
						</td>
					</tr>
				</tbody>
			</Table>
		</Form>

    return (
        <ModalHook isOpen={isOpen} title={title} subTitle={subTitle} body={body} maxWidth = '400px' onCancel={onCancel} 
                   footer={<FooterOkCancel onCancel={onCancel} onClose={onCloseButton} />} />
    );
};

/*******************************************************************************
 * 退勤モーダル画面
 * 
 * onCanvasClick()、onItemClick()で表示される
 ******************************************************************************/
const ModalLeave = (props) => {
	const {
		isOpen,
		title='退勤',
		subTitle,
		workhour,
		salon,
		onClose,        // Shift_PatternにWriteする
		onCancel,
	} = props;

	const [workDate,    setWorkDate]    = useState();
    const [shiftStart,	setShiftStart]	= useState(undefined);
    const [shiftQuit,	setShiftQuit ]	= useState(undefined);
    const [start,		setStart]		= useState(undefined);
    const [quit,		setQuit ]		= useState(undefined);
    const [punchStart,	setPunchStart]	= useState(undefined);
    const [punchQuit,	setPunchQuit]	= useState(undefined);
    const [breakTime,	setBreakTime]	= useState('01:00');
        
    useEffect(() => {       // componentDidMount() の代わり。 最初に1度だけコールされる
    	setWorkDate( workhour.work_date );
    	setShiftStart(	workhour.shift_start	||	salon.stime);
    	setShiftQuit(	workhour.shift_quit		||	salon.etime);
        setStart(		workhour.start			|| 	salon.stime);
        setQuit(		workhour.quit			||	undefined);
        setPunchStart(	workhour.punch_start	||	undefined);
        setPunchQuit(	workhour.punch_quit		||	undefined);
        let min = ( start && quit ) ?  sub(	quit, start) : 0;
        setBreakTime(	workhour.break_time?	workhour.break_time :  min < 360 ? '00:00' : min < 480 ? '00:45' : '01:00');
	// eslint-disable-next-line
    }, [isOpen]);

	const breakTimeChange = ( event ) => {
		setBreakTime(event.target.value);
	};
	
	const onCloseButton = () => {
		let work = workhour;
		work.shift_start	= shiftStart;		// シフト開始時間
		work.shift_quit		= shiftQuit;		// シフト終了時間
		work.start			= start;
		work.quit			= quit;
		work.punch_start	= punchStart;
		work.punch_quit		= punchQuit;
		work.break_time		= breakTime;
		work.status			= WORK_LEAVE;	// 退勤
		
		onClose(work);
	};
	
    let body =
		<Form>
            <h5>お疲れ様でした。</h5>
            {workDate}
            <Table bordered style={{marginBottom: '7px'}}>
				<thead style={{backgroundColor: '#e9ecef', fontWeight:'normal'}}>
					<tr>
						<th>
						</th>
						<th>
							シフト
						</th>
						<th>
							勤 怠
						</th>
						<th>
							打 刻
						</th>
					</tr>
				</thead>
				<tbody>
					<tr style={{backgroundColor:'#F6F8FA'}}>
						<th style={{backgroundColor: '#e9ecef', width:'90px'}}>
							 出 勤
						</th>
						<td>
							{shiftStart ? shiftStart.substr( 0, 5 ) : '--:--'}
						</td>
						<td>
							{start ? start.substr( 0, 5 ) : '--:--'}
						</td>
						<td>
							{punchStart || '--:--:--'}
						</td>
					</tr>
					
					<tr>
						<th style={{backgroundColor: '#e9ecef', width:'90px'}}>
							退 勤
						</th>
						<td>
							{shiftQuit ? shiftQuit.substr( 0, 5 ) : '--:--'}
						</td>
						<td>
							{quit ? quit.substr( 0, 5 ) : '--:--'}
						</td>
						<td>
							{punchQuit || '--:--:--'}
						</td>
					</tr>

				</tbody>
			</Table>
			<span style={{height:'10px'}}/>
			<FormGroup disabled>
				<InputGroup>
					<InputGroupText>休憩時間</InputGroupText>
					<Input type="time" placeholder="休憩時間" value={breakTime} onChange={breakTimeChange}  autoFocus={true}/>
				</InputGroup>
			</FormGroup>
		</Form>

    return (
        <ModalHook isOpen={isOpen} title={title} subTitle={subTitle} body={body} maxWidth = '400px' onCancel={onCancel} 
                   footer={<FooterOkCancel onCancel={onCancel} onClose={onCloseButton} />} />
    );
};

/*******************************************************************************
 * 勤怠届(要望)モーダル画面
 * 
 * 担当者->勤怠届
 * onCanvasClick()、onItemClick()で表示される
 ******************************************************************************/
const ModalNotify = (props) => {
	const {
		isOpen,
		title='勤怠届',
		subTitle,
		workhour,
		salon,
		dayOffItem,
//		getBookInfo,	// 予約の表示情報
		changeDate,		// 日付変更関数
		onClose,        // Shift_PatternにWriteする
		onCancel,
	} = props;

	const [workStatus,	setWorkStatus]	= useState(0);
    const [shiftStart,	setShiftStart]	= useState('--:--:--');			// 出勤画面 表示
    const [shiftQuit,	setShiftQuit]	= useState('--:--:--');			// 出勤画面 表示
    const [dayOffId,	setDayOffId]	= useState();					// 勤怠届 表示
	const [workDate,	setWorkDate]    = useState();					// シフト、勤怠

    useEffect(() => {       // isOpenが変化するたびにコールされる
        setShiftStart(workhour.shift_start? workhour.shift_start : salon.stime);
        setShiftQuit (workhour.shift_quit ? workhour.shift_quit  : salon.etime);
		setDayOffId  (workhour.day_off_id ? workhour.day_off_id  : dayOffItem[0].id);
    	setWorkStatus(workhour.status		|| WORK_RELEASE);		// シフト確定
        setWorkDate  (new Date(workhour.work_date));
	// eslint-disable-next-line 
    }, [isOpen]);

	const onDayOffChange = (option) => {
		setDayOffId(option.value);
	};

	// 日付をYYYY-MM-DDの書式で返すメソッド
	const formatDate = (dt) => {
		var y = dt.getFullYear();
		var m = ('00' + (dt.getMonth()+1)).slice(-2);
		var d = ('00' + dt.getDate()).slice(-2);
		return (y + '-' + m + '-' + d);
	};
	
	const onCloseButton = () => {
		let work = workhour;
		work.work_date		= formatDate(workDate);		// 勤怠の日付
		work.shift_start	= shiftStart;				// シフト開始時間
		work.shift_quit		= shiftQuit;				// シフト終了時間
		work.start			= undefined;
		work.quit			= undefined;
		work.punch_start	= undefined;
		work.punch_quit		= undefined;
		work.day_off_id		= dayOffId;					// 諸届のID
		work.break_time     = "00:00:00";				// 休憩時間
		work.status			= WORK_REQUEST;				// 勤怠届
		onClose(work);
	}

	const onChangeDate = (date) => {
		setWorkDate(new Date(date));
		changeDate(workhour.staff_id,formatDate(date));		
	}	

	const shiftStartChange = ( event ) => {
		setShiftStart(event.target.value);
	};

	const shiftQuitChange = ( event ) => {
		setShiftQuit(event.target.value);
	};

	// 勤怠届マスターの設定
	let list = dayOffItem.filter( (dayOff) => {		// 削除された番号は表示しない
		return dayOff.no < MAX_DB_LENGTH;
	});
	const options = list.map( (dayOff) => {
		return { value: dayOff.id, label:dayOff.no + ' ' + dayOff.name};
	});

	var dayOff = options.find( (option) => {
		return option.value === dayOffId;
	});
	if ( dayOff === undefined ){
		dayOff = options[0];
	}

	const readOnly = workhour.status !== WORK_REQUEST;

    let body =
		<Form>
			<FormGroup disabled>
				<InputGroup>
					<InputGroupText>勤怠届　</InputGroupText>
					<Select className="form-control react-select-original" 
							placeholder="勤怠届" value={dayOff} options={options} onChange={onDayOffChange} isDisabled={readOnly} />
					<InputGroupText id="basic-addon2">{shiftStatus[workStatus]}</InputGroupText>
				</InputGroup>
				<InputGroup>
					<InputGroupText>年月日　</InputGroupText>
					<DatePicker className='form-control' selected={workDate} onChange={onChangeDate} locale="ja" dateFormat="yyyy-MM-dd" fixedHeight style={{width:80}} />
				</InputGroup>
				<InputGroup>
					<InputGroupText style={{width:'90px'}}>開始時刻　</InputGroupText>
					<Input type="time" placeholder="出勤時刻" value={shiftStart} onChange={shiftStartChange} disabled={readOnly} />
				</InputGroup>
				<InputGroup>
					<InputGroupText style={{width:'90px'}}>終了時刻　</InputGroupText>
					<Input type="time" placeholder="退勤時刻" value={shiftQuit} onChange={shiftQuitChange} disabled={readOnly} />
				</InputGroup>
			</FormGroup>
		</Form>

    return (
        <ModalHook isOpen={isOpen} title={title} subTitle={subTitle} body={body} maxWidth = '400px' onCancel={onCancel} 
                   footer={<FooterOkCancel onCancel={onCancel} onClose={onCloseButton}  disabled={readOnly} />} />
    );

};

/*******************************************************************************
 * 勤怠届・変更モーダル画面
 * 勤怠itemをclick 
 * bookingのonItemClick()で表示される  2023/08/09
 ******************************************************************************/
export const ModalNotifyEdit = (props) => {
	const {
		isOpen,
		title='勤怠届',
		subTitle,
		workhour,
		dayOffItem,
		onClose,        // Shift_PatternにWriteする
		onCancel,
		onDelete,
		reload,
	} = props;

	const [workDate,	setWorkDate]    = useState();
    const [dayOffId,	setDayOffId]	= useState();				// 勤怠届 表示
	const [workStatus,	setWorkStatus]	= useState(0);
	const [abstract,	setAbstract]	= useState("");				// 理由
    const [shiftStart,	setShiftStart]	= useState(undefined);		// 出勤画面 表示
    const [shiftQuit,	setShiftQuit]	= useState(undefined);		// 出勤画面 表示
    const [start,		setStart]		= useState(undefined);
    const [quit,		setQuit ]		= useState(undefined);
    const [punchStart,	setPunchStart]	= useState(undefined);		// 出勤画面 表示
    const [punchQuit,	setPunchQuit]	= useState(undefined);		// 出勤画面 表示
    const tenant = JWToken.getToken().tenant;	// テナント情報

    useEffect(() => {       // isOpenが変化するたびにコールされる
    	setWorkStatus(workhour.status		|| WORK_RELEASE);		// シフト確定
		setAbstract  (workhour.abstract 	|| "")
        setShiftStart(workhour.shift_start  || tenant.start_time);
        setShiftQuit (workhour.shift_quit   || tenant.quit_time);
        setStart	 (workhour.start		|| undefined);
        setQuit		 (workhour.quit			|| undefined);
        setPunchStart(workhour.punch_start  || undefined);
        setPunchQuit (workhour.punch_quit   || undefined);
		setDayOffId  (workhour.day_off_id   || dayOffItem[0].id);
        setWorkDate  (new Date(workhour.work_date));
	// eslint-disable-next-line 
    }, [isOpen]);

	const onDayOffChange = (option) => {
		setDayOffId(option.value);
	};

	// 日付をYYYY-MM-DDの書式で返すメソッド
	const formatDate = (dt) => {
		var y = dt.getFullYear();
		var m = ('00' + (dt.getMonth()+1)).slice(-2);
		var d = ('00' + dt.getDate()).slice(-2);
		return (y + '-' + m + '-' + d);
	};
	
	const onCloseButton = () => {
		let work = workhour;
		work.abstract		= abstract;					// 理由
		work.work_date		= formatDate(workDate);		// 勤怠の日付
		work.shift_start	= shiftStart;				// シフト開始時間
		work.shift_quit		= shiftQuit;				// シフト終了時間
		work.start			= start;					// 開始時間
		work.quit			= quit;						// 終了時間
		work.punch_start	= punchStart;				// 打刻開始時間
		work.punch_quit		= punchQuit;				// 打刻終了時間
		work.day_off_id		= dayOffId;					// 諸届のID
		work.status			= work.status || WORK_REQUEST;	// 勤怠届

		// day_off_item の削除		
		delete	work.id;        	/* キー         */
    	delete	work.no;			/* 休日番号     */
    	delete	work.on_off;		/* 勤務 or 休暇 */
    	delete	work.name;			/* 休日の理由 有給、振替休日、欠勤など */
		delete	work.description;	/* 説明         */

        let repParam = { url: "/workhour",	errMsg: "出勤/退勤データ",	list: [work]};
 		addRecord(repParam, (dbData) => {		// idが無いdbDataを扱う場合
	        reload(work.work_date);
 		});
		onClose(work);

	}

	const onDeleteButton = () => {
		let work = workhour;
        let repParam = { url: "/workhour/" + work.staff_id  + '/' + work.work_date,	errMsg: "出勤/退勤データ",	list: work};
 		delRecord1(repParam, work, (dbData) => {		// idが無いdbDataを扱う場合
 			console.log('onDelete put:',work);
	        reload(work.work_date);
 		});
 		
		onDelete(work);

	}

	const changeDate = (date) => {
		setWorkDate(new Date(date));
	}	

	const shiftStartChange = ( event ) => {
		setShiftStart(event.target.value);
	};

	const shiftQuitChange = ( event ) => {
		setShiftQuit(event.target.value);
	};

	const startChange = ( event ) => {
		setStart(event.target.value);
	};

	const quitChange = ( event ) => {
		setQuit(event.target.value);
	};

	// 勤怠届マスターの設定
	let list = dayOffItem.filter( (dayOff) => {		// 削除された番号は表示しない
		return dayOff.no < MAX_DB_LENGTH;
	});
	const options = list.map( (dayOff) => {
		return { value: dayOff.id, label:dayOff.no + ' ' + dayOff.name};
	});
	var dayOff = options.find( (option) => {
		return option.value === dayOffId;
	});
	if ( dayOff === undefined ){
		dayOff = options[0];
	}

	var onChangeAbstract = (event) => {
		if ( event.target.value.length > MAX_ABSTRACT_LENGTH ) return;
		setAbstract(event.target.value);
	}

	const readOnly = ( workStatus >= WORK_RELEASE );

    let body =
		<Form>
			<FormGroup disabled>
				<InputGroup>
					<InputGroupText style={{width:'90px', textAlign: 'justify'}}>勤 怠 届</InputGroupText>
					<Select className="form-control react-select-original" 
							placeholder="勤怠届" value={dayOff} options={options} onChange={onDayOffChange}  />
					<InputGroupText id="basic-addon2">{shiftStatus[workStatus]}</InputGroupText>
				</InputGroup>
				<InputGroup>
					<InputGroupText style={{width:'90px', textAlign: 'justify'}}>年 月 日</InputGroupText>
					<DatePicker className='form-control' selected={workDate} onChange={changeDate} locale="ja" dateFormat="yyyy-MM-dd" fixedHeight style={{width:80}} />
				</InputGroup>
				<InputGroup>
					<InputGroupText style={{width:'90px', textAlign: 'justify'}}>理 由</InputGroupText>
		            <Input type="text" value={abstract} placeholder="理由(60文字以内)" onChange={onChangeAbstract} />
				</InputGroup>
				

	            <Table bordered style={{marginBottom: '7px', marginTop:'7px'}}>
					<thead style={{backgroundColor: '#e9ecef', fontWeight:'normal'}}>
						<tr>
							<th>
							</th>
							<th style={{textAlign: 'center'}}>
								シフト
							</th>
							<th style={{textAlign: 'center'}}>
								勤 怠
							</th>
							<th style={{textAlign: 'center'}}>
								打 刻
							</th>
						</tr>
					</thead>
					<tbody>
						<tr >
							<th style={{backgroundColor: '#e9ecef', width:'90px'}}>
								 出 勤
							</th>
							<td>
								<Input type="time" style={{paddingTop:'0px', paddingBottom:'0px'}} value={shiftStart} onChange={shiftStartChange}  disabled={readOnly}/>
							</td>
							<td>
								<Input type="time" style={{paddingTop:'0px', paddingBottom:'0px'}} placeholder="出勤時刻" value={start} onChange={startChange} />
							</td>
							<td>
								{punchStart || '--:--:--'}
							</td>
						</tr>
						
						<tr>
							<th style={{backgroundColor: '#e9ecef', width:'90px'}}>
								退 勤
							</th>
							<td>
								<Input type="time" style={{paddingTop:'0px', paddingBottom:'0px'}} value={shiftQuit} onChange={shiftQuitChange}  disabled={readOnly}/>
							</td>
							<td>
								<Input type="time" style={{paddingTop:'0px', paddingBottom:'0px'}} value={quit} onChange={quitChange} />
							</td>
							<td>
								{punchQuit || '--:--:--'}
							</td>
						</tr>
	
					</tbody>
				</Table>
	
			</FormGroup>
		</Form>

    return (
        <ModalHook isOpen={isOpen} title={title} subTitle={subTitle} body={body} maxWidth = '450px' onCancel={onCancel} 
                   footer={<FooterOkCancelDelete onCancel={onCancel} onClose={onCloseButton} onDelete={onDeleteButton} />} />
    );
};


export default Attendance;
