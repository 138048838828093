/* ==============================================================================
 スタイリストの選択画面

 履歴-----------------------------------------
 2019/06/09 新規作成
 
=============================================================================== */

import React, { Component } from 'react';

import Request from 'superagent';
import PropTypes from 'prop-types';
import ReactDataGrid from 'react-data-grid';
import {JWToken} from './auth/Login';
import { AlignLeft,AlignCenter } from './Formatter';	// React Data Grid

import { MAX_DB_LENGTH } from './Define';

class Stylist extends Component {

	static propTypes = {
	    staffList:		PropTypes.array.isRequired,			// 担当者マスター
	    stylistList:	PropTypes.array.isRequired,			// スタイリストマスター
	    onChange:		PropTypes.func.isRequired,			// 変更を知らせる関数
	    salon_id:		PropTypes.number.isRequired			// 店舗ID
	};

    // コンストラクター
    constructor(props) {
        super(props);
        
        this.state = { 
            stylistList:[],
            staffList:[],
			selectedIndexes:[], 	// react-data-grid
        };
    }

	// 描画が行われる直前に一度だけ呼ばれる
	componentDidMount = () => {
//		console.log("componentDidMount()",this.props.stylistList)

		// スタッフの設定
		let list = this.props.staffList.filter( (staff) => {		// 削除された番号は表示しない
			return staff.no < MAX_DB_LENGTH;
		});

		this.setState({
			staffList: list,
			stylistList:Object.assign({},this.props.stylistList)
		});
	}

	// 
	static getDerivedStateFromProps(props, state) {
		console.log("getDerivedStateFromProps()",props);
		if ( props.stylistList === state.stylistList ) return null;

		let selectedIndexes = [];
		props.stylistList.forEach( (stylist) => {
			let index = state.staffList.findIndex( ( staff ) => {
				return staff.id === stylist.staff_id;
			});
			if ( index !== -1 ) selectedIndexes.push( index );
		});
		return ({
			stylistList:props.stylistList,
			selectedIndexes: selectedIndexes
		});

	}

//-----------------------------------------------
// イベント処理関数
//-----------------------------------------------


/* ================================================================= 
	スタイリスト Grid
	
	
   ================================================================= */
	// スタイリスト一覧を取得する
	getStylistList = (salon_id) => {
		//ajax通信する
		Request.get("/stylist/" + salon_id)
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.log(err);
					console.log(res);
					console.error("スタイリスト一覧を取得できませんでした。");
					return;
				}
				let stylist = res.body.dbData;
				this.setState( { stylistList: stylist } );
				let selectedIndexes = [];
				stylist.forEach( function(stylist) {
					let index = this.state.staffList.findIndex(function( staff ) {
						return staff.id === stylist.staff_id;
					});
					if ( index !== -1 ) selectedIndexes.push( index );
				}.bind(this));
				this.setState({selectedIndexes: selectedIndexes});
			}.bind(this));
	}

	// スタイリスト一覧を登録する
	putStylistList = (salon_id) => {
		let stylistList = this.state.selectedIndexes.map(function(index){
			let stylist = Object();
			stylist.tenant_id = JWToken.getToken().tenant_id;
			stylist.salon_id  = salon_id;
			stylist.staff_id  = this.state.staffList[index].id;
			return stylist;
		}.bind(this));
		//ajax通信する
		Request.put('/stylist/' + salon_id )
			.send({ dbData:stylistList, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.error("スタイリストを登録できませんでした");
					return;
				}
			});
	}


	// スタイリスト一覧を削除する
	delStylistList = (salon_id) => {
		//ajax通信する
		Request.del('/stylist/' + salon_id )
			.send({ token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.error("スタイリストを削除できませんでした");
					return;
				}
			});
	}


/* ReactDataGrid -------------------------------------------- */ 

	rowStylistGetter = (rowIdx) => {
		return this.state.staffList[rowIdx];
	}


	onRowsSelected = rows => {
		
		let selectedIndexes = this.state.selectedIndexes.concat( rows.map(r => r.rowIdx) );
		
		this.setState({	selectedIndexes: selectedIndexes});
		
		let stylistList = selectedIndexes.map((index) => {
			let stylist = Object();
			stylist.tenant_id = JWToken.getToken().tenant_id;
			stylist.salon_id  = this.props.salon_id;
			stylist.staff_id  = this.state.staffList[index].id;
			return stylist;
		});
		
		this.props.onChange(stylistList);
	};
	
	onRowsDeselected = rows => {
		let rowIndexes = rows.map(r => r.rowIdx);
		let selectedIndexes = this.state.selectedIndexes.filter( i => rowIndexes.indexOf(i) === -1 );
		this.setState({	selectedIndexes: selectedIndexes }	);

		let stylistList = selectedIndexes.map((index) => {
			let stylist = Object();
			stylist.tenant_id = JWToken.getToken().tenant_id;
			stylist.salon_id  = this.props.salon_id;
			stylist.staff_id  = this.state.staffList[index].id;
			return stylist;
		});
		
		this.props.onChange(stylistList);
	};
		
		


/* ReactDataGrid --------------------------------------------  */

    render = () => {
		const columnsStyle = [{ key: 'no',     name: '番号',    width: 50, editable: false, locked : true, formatter:AlignCenter   },
							  { key: 'name',   name: '担当者名',           editable: false, resizable: false, formatter:AlignLeft, locked: true },
						];

	    return (
	    	<div>
				<ReactDataGrid rowKey="id"
					columns={columnsStyle} rowGetter={this.rowStylistGetter} rowsCount={this.state.staffList.length}
					minHeight={400} //minWidth={660} 
					enableCellSelect={true}
							// onRowUpdated={this.handleRowUpdated}
					rowSelection={{
			            showCheckbox: true,
			            enableShiftSelect: true,
			            onRowsSelected: this.onRowsSelected,
			            onRowsDeselected: this.onRowsDeselected,
			            selectBy: {
			              indexes: this.state.selectedIndexes
			            }
					}}
				/>
			</div>
	    );
	}
}

export default Stylist;
