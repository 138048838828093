import React, { Component, useState /*, useRef, useEffect*/ } from 'react';
// import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
// import {ButtonToolbar } from 'reactstrap';
// import { Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { CardGroup, Card, CardText, CardBody,CardTitle, CardSubtitle } from 'reactstrap';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarText } from 'reactstrap';
import { Container,Table } from  'reactstrap';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Request from 'superagent';
import { Navigate, useNavigate } from 'react-router-dom';
import LoginRoutes  from '../index';
import CreateTenant from './CreateTenant';
// import confirm from "./reactstrap-confirm";


/* ================================================ *
  Token
 * ================================================ */
var storeToken = {
    isLoggedIn: false,
    token: "",
    tenant_id: 0,
    name: "",
};

export var JWToken = {
    setToken: function(res) {
        storeToken.isLoggedIn = true;
        storeToken.token     = res.token;       // Object.assign({},token); オブジェクトをコピーしておきたい
        storeToken.tenant_id = res.tenant_id;   // Object.assign({},token); オブジェクトをコピーしておきたい
        storeToken.name      = res.name;        // テナント名をtokenに保存する
        storeToken.tenant    = res.tenant;      // テナントをtokenに保存する
        
//      console.log('setToken');
//      console.log( storeToken );
        return;
    },
    getToken: function() {
        return storeToken;
    },
    clearToken: function() {
        storeToken.isLoggedIn = false;
        storeToken.token  = "";
        storeToken.tenant_id = 0;
        storeToken.name   = "";
        storeToken.tenant = {};
//        console.log("clearToken:",storeToken);
//        console.trace();
        return;
    }  
};

/* ================================================ *
  login画面に遷移する
 * ================================================ */
export const GotoLogin = () => {
    const navigate = useNavigate();
    navigate('/'); // 画面遷移
}

/* ================================================ *
  ローカルストレージ
 * ================================================ */
var storeItem = {
    salon_id: 0
};

export var LocalStorage = {
    setItem: function(res) {
        storeItem.salon_id = res.salon_id;
//      storeItem.salon_id = res.salon_id;

        window.localStorage.setItem("hairaccess", JSON.stringify(storeItem));
        return;
    },
    getItem: function() {
        let json = window.localStorage.getItem("hairaccess");
//        console.log("ローカルストレージ：",json);
        if ( json === null ) return storeItem;
        
        return JSON.parse(json);
    },
    removeItem: function() {
        storeItem.salon_id = 0;
        window.localStorage.removeItem("hairaccess");
        return;
    }  
};

/* ================================================ *
  Alert Confirm
 * ================================================ */
var SwAlert = null;

export const Alert = async(message) => {
  if ( !SwAlert ) {
    SwAlert = withReactContent(Swal);
  }
  await SwAlert.fire({
      title: message,
      icon: 'error',
  });

};

export const Confirm = async (message) => {
  if ( !SwAlert ) {
    SwAlert = withReactContent(Swal);
  }
  let result = await SwAlert.fire({
      title: message,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'キャンセル',
      confirmButtonText: 'OK',

  })
  return result.isConfirmed;
  
};


// =============================================
// メイン画面の表示
// ---------------------------------------------
// 
// =============================================
export default class Login extends Component {

    // コンストラクター
    constructor(props) {
        super(props);
        this.state = {  
          isLogin: false,
      };
    }

  render() {

    if ( JWToken.getToken().isLoggedIn === true ) {
      Alert(<div style={{textAlign:'center'}}>ログイン済みです</div>);
      console.log("ログイン済みです");
      return (<Navigate  to={'/#/booking'} />);
    }
    return (
      <div>
        <MainTitle />
        <Ditail open={this.detail}/>
      </div>
    );
  }

}



// ---------------------------------------------
// ログイン入力画面
// ---------------------------------------------
const InputLogin = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const usernameChange = (event) => {  setUsername( event.target.value) };
  const passwordChange = (event) => {  setPassword( event.target.value) };
 
  const handleKeyDown = (e) => {
    if (e.nativeEvent.isComposing || e.key !== 'Enter') return
    close();
  }  
  
  const close = () => {
        var req = { username: username.trim(),
                    password: password.trim()
        };
        console.log(req);
        
if ( JWToken.getToken().isLoggedIn === true ) {
Alert(<div style={{textAlign:'center'}}>ログイン済みです</div>);
console.log("ログイン済みです");
}
        Request.post('/login')
            .send(req)
            .end(function(err, res){
                if (err) {
                    console.log(err);
                    Alert(<div style={{textAlign:'center'}}>認証に失敗しました<br/>ユーザー名、パスワードを確認後<br/>もう一度入力してください</div>);
                    return;
                }
                if ( res.body.success === false ) {
                  Alert(<div style={{textAlign:'center'}}>認証に失敗しました<br/>確認後にもう一度試してください</div>);
                  return;
                }
//                console.log(res.body);
                // ここで、JWTを受け取り、ローカルストレージに登録する
                JWToken.setToken(res.body);
                LoginRoutes();
// Alert(<div style={{textAlign:'center'}}>認証に成功しました</div>);
                navigate('/booking'); // 画面遷移
            });
  }
  
  return(
    <Card color="light">
      <Form onSubmit={close}>
        <CardBody >
          <FormGroup style={{textAlign:'left'}}>
            <Label>ユーザー名</Label>
            <Input type="text" value={username} placeholder="User Name" autoComplete="username" onChange={usernameChange} autoFocus={true} onKeyDown={handleKeyDown}/>
          </FormGroup>
          <FormGroup style={{textAlign:'left'}}>
            <Label>パスワード</Label>
           <Input type="password" value={password} placeholder="Password" autoComplete="current-password" onChange={passwordChange} onKeyDown={handleKeyDown}/>
          </FormGroup>
            <Button type="button" color="info" onClick={close} className="btn btn-default" >ログイン</Button>
{/*            <Button type="submit" color="info" className="btn btn-default" >ログイン</Button> */}
        </CardBody>
      </Form>
    </Card>
  );
  
/*******************************************************************************
 * 
 * 
  return(
      <Card color="light">
        <Form onSubmit={close}>
          <Card.Body>
            <Card.Title as="h4">Register</Card.Title>
              <FormGroup controlId="username" style={{textAlign:'left'}}>
                <Label>ユーザー名</Label>
                <Input type="text" label="Username" placeholder="ユーザー名" autoComplete="username" onChange={usernameChange} autoFocus={true} />
              </FormGroup>
              <FormGroup controlId="password" style={{textAlign:'left'}}>
                <Label>パスワード</Label>
                <Input type="password" label="Password" placeholder="パスワード" autoComplete="current-password" onChange={passwordChange} />
              </FormGroup>
            <ButtonToolbar>
              <Button variant="primary" type="submit">ログイン</Button>
            </ButtonToolbar>
          </Card.Body>
        </Form>
      </Card>
  );
 * 
 * 
 * *****************************************************************************/
};

// ---------------------------------------------
// メイン画面のヘッダー　ナビゲーションバー
// ---------------------------------------------
export const GuestNavBar = (props) => {
    
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar color="light" light expand="md">
        <NavbarBrand href="#/">
          <CreateTenant />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr" navbar style={{ width: "100%" }}>
          <NavbarBrand href="#/news">News</NavbarBrand>
          <Nav className="ml-auto justify-content-end"  navbar  style={{ width: "100%" }}>
           <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                機能説明
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem href="#/guest/webapp"  >Webアプリケーション</DropdownItem>
                <DropdownItem href="#/guest/receipt" >レシート印刷</DropdownItem>
                <DropdownItem href="#/guest/price"   >料金体系</DropdownItem>
                <DropdownItem href="#/guest/book"    >予約管理</DropdownItem>
                <DropdownItem href="#/guest/customer">顧客管理</DropdownItem>
                <DropdownItem href="#/guest/register">レジ集計</DropdownItem>
                <DropdownItem href="#/guest/workhour">勤怠管理</DropdownItem>
                <DropdownItem divider />
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          <NavbarText>
{/*            <Button color="warning" onClick={props.login} className="btn btn-default">ログイン</Button> */}
          </NavbarText>
          </Nav>
        </Collapse>
      </Navbar>
    </div>  );
}

// ---------------------------------------------
// メインンタイトルを表示する
// ---------------------------------------------
const MainTitle = (props) => {
  return (
    <div className='text-center'>
      <Container style={{textAlign:'center'}}>
      <CardGroup>
        <div className="col-sm-7 h-100">
          <Card style={{border:'none'}}>
            <CardBody >
              <CardTitle tag="h3">サロンの事務作業は hairaccess にお任せ下さい</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">予約管理、顧客管理、レジ集計、勤怠管理</CardSubtitle>
            </CardBody>
          </Card>
        </div>
        <div className="col-sm-5 h-100">
          <InputLogin />
        </div>
      </CardGroup>
      </Container>
      <br/>
{/*        <CardBody>
        <CardText>すぐにサービスを利用する</CardText>
          <CreateTenant login={props.login} />    {/* login() は何のためにあるのか分からない * /}
        </CardBody> */}
     </div>
  );
};

// ---------------------------------------------
// メインンタイトルを表示する
// ---------------------------------------------

const Ditail = (props) => {
  const navigate = useNavigate();

    const openWebApp   = () => { navigate('/guest/webapp'); };
    const openReceipt  = () => { navigate('/guest/receipt'); };
    const openPrice    = () => { navigate('/guest/price'); };
    const openBook     = () => { navigate('/guest/book'); };
    const openCustomer = () => { navigate('/guest/customer'); };
    const openRegister = () => { navigate('/guest/register'); };
    const openWorkHou  = () => { navigate('/guest/workhour'); };

  return (
    <Container >
    <CardGroup>
      <div className="col-sm-4 h-100">
      <Card>
{/*        <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
        <CardBody>
          <CardTitle tag="h5">Webアプリケーション</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">いつでも、どこでも作業できます</CardSubtitle>
          <CardText>hairaccessはWebアプリケーションですから、スマホでもタブレットでもWindowsでもMacでも機種を問わずにご利用いただけます。<br/>Webブラウザーがあればどこにいても作業できます。</CardText>
          <Button color="primary" onClick={openWebApp} className="btn btn-default">詳細説明</Button>
        </CardBody>
      </Card>
      </div>
      <div className="col-sm-4 h-100">
      <Card>
{/*        <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
        <CardBody>
          <CardTitle tag="h5">レシート印刷</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">レシートを印刷できます</CardSubtitle>
          <CardText>レシートプリンター、カスタマーディスプレイ、ドロアーをご利用いただくことも可能です。<br/>レシートはもちろん領収書、予約表、レジ締めを印刷することも可能です。<br/></CardText>
          <Button color="primary" onClick={openReceipt} className="btn btn-default">詳細説明</Button>
        </CardBody>
      </Card>
      </div>
      <div className="col-sm-4 h-100">
      <Card>
{/*        <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
        <CardBody>
          <CardTitle tag="h5">ご利用料金</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">簡単に始められるサブスクリプション</CardSubtitle>
          <CardText>分かり易い定額制(月3,300円)で、複数店舗でご利用いただけます。<br/>
          顧客、技術、商品・材料、スタッフ、消費税率、ポイント還元率などは全店舗共通です。売上集計は全店舗合計と店舗別に集計します。</CardText>
          <Button color="primary" onClick={openPrice} className="btn btn-default">詳細説明</Button>
        </CardBody>
      </Card>
      </div>
      <div className="col-sm-4 h-100">
      <Card>
{/*        <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
        <CardBody>
          <CardTitle tag="h5">予約管理</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">ご予約を分かり易く表示します</CardSubtitle>
          <CardText>日時とスタイリストのマトリクス表にお客様のご予約を表示します。メニュー項目やメモも表示できますので連絡ミスを減らします。</CardText>
          <Button color="primary" onClick={openBook} className="btn btn-default">詳細説明</Button>
        </CardBody>
      </Card>
      </div>
      <div className="col-sm-4 h-100">
      <Card>
{/*        <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
        <CardBody>
          <CardTitle tag="h5">顧客管理</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">お客様の情報を登録、参照できます</CardSubtitle>
          <CardText>お客様の年齢や住所、電話番号、メモなどを登録して検索できます。住所があればハガキの宛名を印刷できます。</CardText>
          <Button color="primary" onClick={openCustomer} className="btn btn-default">詳細説明</Button>
        </CardBody>
      </Card>
      </div>
      <div className="col-sm-4 h-100">
      <Card>
{/*        <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
        <CardBody>
          <CardTitle tag="h5">レジ集計</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">レジ入力を集計します</CardSubtitle>
          <CardText>雑費などをレジの現金で支払った時に金額を登録しておく事で、いつでも簡単にレジ締めを行えます。また、日次・月次・年次集計を表示・印刷できます。</CardText>
          <Button color="primary" onClick={openRegister} className="btn btn-default">詳細説明</Button>
        </CardBody>
      </Card>
      </div>
      <div className="col-sm-4 h-100">
      <Card>
{/*        <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
        <CardBody>
          <CardTitle tag="h5">シフト管理</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">スタッフのシフトを作成します</CardSubtitle>
          <CardText>1週間分の基本シフトを使って、スタッフのシフトを作成します。作成したシフトを確定するまでは自由に変更できます。</CardText>
          <Button color="primary" onClick={openWorkHou} className="btn btn-default">詳細説明</Button>
        </CardBody>
      </Card>
      </div>
      <div className="col-sm-4 h-100">
      <Card>
{/*        <CardImg top width="100%" src="/assets/256x186.svg" alt="Card image cap" /> */}
        <CardBody>
          <CardTitle tag="h5">勤怠管理</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">スタッフの出退勤を管理します</CardSubtitle>
          <CardText>最新の労働基準法に従って勤怠を管理します。スタッフごとに動労時間、残業時間、深夜残業時間などを集計します。</CardText>
          <Button color="primary" onClick={openWorkHou} className="btn btn-default">詳細説明</Button>
        </CardBody>
      </Card>
      </div>
    </CardGroup>
    </Container>
  );
};


// ---------------------------------------------
// Webアプリケーションの詳細説明を行う
// ---------------------------------------------
export const GuestWebApp = (props) => {
  const navigate = useNavigate();

  const move = () => {
    navigate('/login'); // 画面遷移
  };

  return (
    <div>
        <div className='text-center'>
          <Card>
            <CardBody>
              <CardTitle tag="h3">hairaccessはWebアプリケーションです</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">簡単に開始できて、いつでもどこからでも、様々な機種で同時に利用可能です</CardSubtitle>
            </CardBody>
            <CardBody>
                 <Button className='text-left' color="primary" onClick={move}>戻る</Button>
           </CardBody>
          </Card>
        </div>
      <Container >
        <CardGroup>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">簡単に始められます</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">Webブラウザーがあれば始められます</CardSubtitle>
              <CardText>hairaccessはWebアプリケーションですからアプリケーションをインストールする必要はありません。
                        Webブラウザーさえあれば簡単に作業を始められます。<br/>
                        機種を変更しても問題ありません。
                        面倒なバージョンアップの作業も必要ありません。
              </CardText>
            </CardBody>
          </Card>
          </div>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">いつでも、どこからでも</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">Webブラウザーで https://www.hairaccess.jp にアクセスしてください。</CardSubtitle>
              <CardText>出勤前に電車の中で予約の確認。<br/>
                        帰りに売上の報告メールを送信。<br/>
                        お休みの日に来週の予定を確認。<br/>
                        いつでも、どこからでもご利用可能です。<br/>
                        通信データ量は予約量やお客様の登録人数により増減します。
              </CardText>
            </CardBody>
          </Card>
          </div>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">様々な機種で</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">機種やOSを限定しません</CardSubtitle>
              <CardText>スマートフォン、タブレット、Windows、Macなど機種やOSを問わず、ブラウザーが動作する環境でご利用可能です。<br/>
                        ブラウザーはGoogle Chrome、Microsoft Edge、Safari で動作確認しております。<br/>
                        できるだけ最新版をご利用ください。<br/>
              </CardText>
            </CardBody>
          </Card>
          </div>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">同時に利用できます</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">複数の端末から同時に利用できます</CardSubtitle>
              <CardText>一つの店舗でも複数の端末から同時にご利用可能です。お会計窓口だけでなく、施術中にスマートフォンでも操作できます。<br/>
                        フリーランスの美容師様にもご利用し易くなっております。<br/>
                        なお、他の端末から情報を変更しても、現在表示されている情報は自動更新されません。
                        画面を更新して最新情報を表示してからご利用ください。<br/>
              </CardText>
            </CardBody>
          </Card>
          </div>
        </CardGroup>
      </Container>
    </div>
  );
};

// ---------------------------------------------
// レシートと印刷の詳細説明を行う
// ---------------------------------------------
export const GuestReceipt = (props) => {
  const navigate = useNavigate();

  const move = () => {
    navigate('/login'); // 画面遷移
  };

  return (
    <div>
        <div className='text-center'>
          <Card>
            <CardBody>
              <CardTitle tag="h3">レシートを印刷できます</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">レシートプリンター、カスタマーディスプレイ、ドロアーを利用できます</CardSubtitle>
            </CardBody>
            <CardBody>
                <Button className='text-left' color="primary" onClick={move}>戻る</Button>
           </CardBody>
          </Card>
        </div>
      <Container>
        <CardGroup>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">レシートプリンター</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">レシート、領収書、予約表、レジ締めを印刷</CardSubtitle>
              <CardText>お会計時にレシートを印刷できます。領収書が必要な方には領収書の印刷もご用意しております。<br/>
                        また、お会計時に次回のご予約を頂いたお客様には予約表を印刷してお渡しできます。<br/>
                        毎日のレジ締め後に１日の売上をレシートプリンターに印刷できます。売上現金と一緒に保管すると便利です。
              </CardText>
            </CardBody>
          </Card>
          </div>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">コンパクト</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">限られたスペースに収まるコンパクトサイズ</CardSubtitle>
              <CardText>
        			mC-Print3は80/58mm幅のレシートに<br/>
        			mC-Print2は58mm幅のレシートに印刷できます。<br/>
        			カスタマーディスプレイとキャッシュドロアーはレシートプリンターが制御していますので単体ではご購入いただけません。
        			各製品のカラーはホワイトとブラックの２種類からお選びください。
              </CardText>
            </CardBody>
          </Card>
          </div>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">ご購入価格</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">機材の価格は以下の通りです</CardSubtitle>
              <CardText>
                <Table bordered size={"sm"} className="col-sm-4" hover>
    				<thead bgcolor="#ebf0f7">
    					<tr>
    						<th className="text-center">機種</th>
    						<th className="text-center">型番</th>
    						<th className="text-center">価格</th>
    					</tr>
    				</thead>
    				<tbody>
    					<tr >
    						<td rowSpan='2' className="text-left" >レシート<br/>　プリンター</td>
    						<td className="text-left">mC-Print3</td>
    						<td className="text-right">50,000円</td>
    					</tr>
    					<tr >
    						<td className="text-left">mC-Print2</td>
    						<td className="text-right">48,000円</td>
    					</tr>
    					<tr>
    						<td colSpan='2' className="text-left">カスタマディスプレイ</td>
    						<td className="text-right">28,000円</td>
    					</tr>
    					<tr>
    						<td colSpan='2' className="text-left">キャッシュドロア</td>
    						<td className="text-right">12,000円</td>
    					</tr>
    				</tbody>
    			</Table>
    			<br/>
              </CardText>
            </CardBody>
          </Card>
          </div>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">キッティング</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">動作環境に合わせた設定が必要です</CardSubtitle>
              <CardText>
        			レシートプリンターは有線LANで接続します。<br/>
        			決済代行会社様のシステムとBluetoothで接続できる場合は、hairaccessと同時に利用することも可能です。<br/>
        			接続に関してはキッティングを行いますので、下記にご相談ください。<br/>
        			メールアドレス：sales@strline.jp
              </CardText>
            </CardBody>
          </Card>
          </div>
        </CardGroup>
      </Container>
    </div>
  );
};

// ---------------------------------------------
// 利用料金の説明を行う
// ---------------------------------------------
export const GuestPrice = (props) => {
  const navigate = useNavigate();

  const move = () => {
    navigate('/login'); // 画面遷移
  };

  return (
    <div>
        <div className='text-center'>
          <Card>
            <CardBody>
              <CardTitle tag="h3">利用料金</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">分かり易い定額制(月額3,300円)で、複数店舗でご利用いただけます</CardSubtitle>
            </CardBody>
            <CardBody>
                 <Button className='text-left' color="primary" onClick={move}>戻る</Button>
           </CardBody>
          </Card>
        </div>
      <Container >
        <CardGroup>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">分かり易い定額制</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">月額3,300円の定額制</CardSubtitle>
              <CardText>
                １アカウント(月額3,300円)の契約で、複数店舗でご利用いただけます。<br/>
                別のアカウントが必要な場合は、別途アカウントの作成手続きをお願いします。
              </CardText>
            </CardBody>
          </Card>
          </div>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">複数店舗でご利用できます</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">店舗を複数登録できます</CardSubtitle>
              <CardText>
                １アカウントで複数の店舗を登録できます。<br/>
                店舗数に制限はありません。<br/>
                売上は全店舗合計と店舗別に集計します。<br/>
                顧客、技術、商品・材料、スタッフ、消費税率、ポイント還元率などは全店舗共通です。
                店舗別に設定したい場合は店舗ごとにアカウントが必要になります。<br/>
              </CardText>
            </CardBody>
          </Card>
          </div>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">利用料金のご請求</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">Squareの決済サービスを利用しています</CardSubtitle>
              <CardText>
                アカウント登録を行うと、Squareから定期請求のメールを送信します。<br/>
                クレジットカードをご登録いただくとその後は自動的に引落し手続きを行います。<br/>
                ご登録いただかなくても毎月ご請求のメールを送信します。<br/>
              </CardText>
            </CardBody>
          </Card>
          </div>
        </CardGroup>
      </Container>
    </div>


 );
};

// ---------------------------------------------
// 予約管理の説明を行う
// ---------------------------------------------
export const GuestBook = (props) => {
  const navigate = useNavigate();

  const move = () => {
    navigate('/login'); // 画面遷移
  };

  return (
    <div>
        <div className='text-center'>
          <Card>
            <CardBody>
              <CardTitle tag="h3">予約管理</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">ご予約を分かり易く表示します</CardSubtitle>
            </CardBody>
            <div className='center'>
                <img width="800" src="book.JPG" alt="Card cap" />
            </div>
            <CardBody>
                 <Button className='text-left' color="primary" onClick={move}>戻る</Button>
           </CardBody>
          </Card>
        </div>
      <Container >
        <CardGroup>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">スタイリストと日時の表</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">予約は表の上に表示します</CardSubtitle>
              <CardText>
                スタイリストの行に予約が表示されます。<br/>
                時刻の列には何人の予約があるか一目でわかります。<br/>
                施術時間によって予約の長さが変わります。<br/>
                <span style={{color:'black', backgroundColor:'lavenderblush'}}>国民の祝日</span>、
                <span style={{color:'black', backgroundColor:'lemonchiffon'}}>予約連絡</span>、
                <span style={{color:'black', backgroundColor:'lightcyan'   }}>予約済</span>、
                <span style={{color:'white', backgroundColor:'SteelBlue'   }}>来店中</span>、
                <span style={{color:'white', backgroundColor:'DarkBlue'    }}>会計済み</span>、
                <span style={{color:'white', backgroundColor:'dimgray'     }}>集計済み</span>は色分けして表示しているので一目で確認できます。<br/>
                予約はお客様名、技術項目、連絡事項などのメモが表示されます。<br/>
              </CardText>
            </CardBody>
          </Card>
          </div>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">複数店舗でご利用できます</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">店舗を複数登録できます</CardSubtitle>
              <CardText>
                １アカウントで複数の店舗を登録できます。<br/>
                店舗数に制限はありません。<br/>
                売上は全店舗合計と店舗別に集計します。<br/>
                顧客、技術、商品・材料、スタッフ、消費税率、ポイント還元率などは全店舗共通です。
                店舗別に設定したい場合は店舗ごとにアカウントが必要になります。<br/>
              </CardText>
            </CardBody>
          </Card>
          </div>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">ご予約</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">お客様の来店時間や施術項目を管理</CardSubtitle>
              <CardText>
                予約時に来店時間と担当スタッフ、お客様情報、施術内容(技術項目)、連絡メモなどを入力し、予約を見やすく表示します。<br/>
                技術項目ごとに施術時間を登録しておくと、お客様にかかる時間が一目で分かります。<br/>
                時間の変更はドラッグ＆ドロップで簡単にできます。<br/>
              </CardText>
            </CardBody>
          </Card>
          </div>
        </CardGroup>
      </Container>
    </div>
  );
};

// ---------------------------------------------
// 顧客管理の説明を行う
// ---------------------------------------------
export const GuestCustomer = (props) => {
  const navigate = useNavigate();

  const move = () => {
    navigate('/login'); // 画面遷移
  };

  return (
    <div>
        <div className='text-center'>
          <Card>
            <CardBody>
              <CardTitle tag="h3">お客様管理</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">お客様情報の登録・検索・ハガキ印刷できます</CardSubtitle>
            </CardBody>
            <div className='center'>
                <img width="800" src="customer.png" alt="Card cap" />
            </div>
            <CardBody>
                 <Button className='text-left' color="primary" onClick={move}>戻る</Button>
           </CardBody>
          </Card>
        </div>
      <Container >
        <CardGroup>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">お客様の登録</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">お客様の情報を登録します</CardSubtitle>
              <CardText>
                お客様番号、お名前、カナ名、携帯番号、電話番号、担当者、誕生日、メールアドレス、郵便番号、住所を登録します。<br/>
                前回来店日からの今日までの経過日数や溜まったポイント数を表示します。<br/>
              </CardText>
            </CardBody>
          </Card>
          </div>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">お客様の検索</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">登録した情報で絞込み検索できます</CardSubtitle>
              <CardText>
                お客様番号、経過日数、ポイントは数値の範囲で検索できます。<br/>
                お名前、カナ名、携帯番号、電話番号、メールアドレスに含まれた文字列で検索します。<br/>
                担当者は複数選択して検索できます<br/>
              </CardText>
            </CardBody>
          </Card>
          </div>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">お客様の並べ替え</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">登録した情報で並べ替えできます</CardSubtitle>
              <CardText>
                お客様番号、お名前、カナ名、経過日数、携帯番号、電話番号、担当者、メールアドレスで並べ替えできます。<br/>
                並べ替えは、昇順、降順、登録順で行います。<br/>
                絞込みした結果も並べ替えでえきます。<br/>
              </CardText>
            </CardBody>
          </Card>
          </div>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">ハガキの宛名印刷</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">選択したお客様のハガキ</CardSubtitle>
              <CardText>
                印刷したいお客様を検索して選択します。<br/>
                ブラウザーの印刷機能でお手元のプリンターにハガキの宛名を印刷します。<br/>
                ブラウザーはGoogle Chrome、Microsoft Edge、Safari で動作確認しております。<br/>
              </CardText>
            </CardBody>
          </Card>
          </div>
        </CardGroup>
      </Container>
    </div>
  );
};

// ---------------------------------------------
// レジ集計の説明を行う
// ---------------------------------------------
export const GuestRegister = (props) => {
  const navigate = useNavigate();

  const move = () => {
    navigate('/login'); // 画面遷移
  };

  return (
    <div>
        <div className='text-center'>
          <Card>
            <CardBody>
              <CardTitle tag="h3">レジ集計</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">日次・月次・年次集計を表示・印刷できます</CardSubtitle>
            </CardBody>
            <div className='center'>
                <img width="800" src="CashCount.png" alt="Card cap" />
            </div>
            <CardBody>
                 <Button className='text-left' color="primary" onClick={move}>戻る</Button>
           </CardBody>
          </Card>
        </div>
      <Container >
        <CardGroup>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">レジ集計</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">レジの現金を使用する場合</CardSubtitle>
              <CardText>
                交通費や書籍代などの雑費・雑収入をレジの現金から入出金する場合に、
                現金の入出金をシステムに記録してシステムの計算とレジ現金が一致するようにしておく事で
                いつでもレジ締めを実施できます。
              </CardText>
            </CardBody>
          </Card>
          </div>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">日次集計</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">１日の売上詳細を印刷します</CardSubtitle>
              <CardText>
                現在表示している店舗の１日分の会計情報を表示・印刷します。<br/>
                <br/>
              </CardText>
            </CardBody>
          </Card>
          </div>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">月次集計</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">指定した月の売上を印刷します</CardSubtitle>
              <CardText>
                指定した月の毎日の売上合計を表示・印刷します。<br/>
              </CardText>
            </CardBody>
          </Card>
          </div>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">年次集計</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">指定した年の売上を印刷します</CardSubtitle>
              <CardText>
                指定した年の毎月の売上合計表示・を印刷します。<br/>
              </CardText>
            </CardBody>
          </Card>
          </div>
        </CardGroup>
      </Container>
    </div>
  );
};

// ---------------------------------------------
// 勤怠管理の説明を行う
// ---------------------------------------------
export const GuestWorkHour = (props) => {
  const navigate = useNavigate();

  const move = () => {
    navigate('/login'); // 画面遷移
  };

  return (
    <div>
        <div className='text-center'>
          <Card>
            <CardBody>
              <CardTitle tag="h3">勤怠管理</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">毎日の打刻と週次集計の編集や月次集計を印刷できます</CardSubtitle>
            </CardBody>
            <div className='center'>
                <img width="800" src="Roster.png" alt="Card cap" />
            </div>
            <CardBody>
                 <Button className='text-left' color="primary" onClick={move}>戻る</Button>
           </CardBody>
          </Card>
        </div>
      <Container >
        <CardGroup>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">毎日の打刻</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">担当者ごとに出勤、退勤の打刻を行います</CardSubtitle>
              <CardText>
                画面から担当者を選んで、出勤、退勤ボタンを押すと現在時刻を取得して打刻します。<br/>
                休憩時間は登録されている時間が自動で入力されます。<br/>
                出勤時刻、退勤時刻、休憩時間は勤怠管理 健康画面で修正できます。<br/>
              </CardText>
            </CardBody>
          </Card>
          </div>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">週次集計と編集</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">１週間の労働時間を表示・編集できます</CardSubtitle>
              <CardText>
                勤怠管理画面で担当者を選択して編集ボタンを押すと１週間の勤務時間を表示します。<br/>
                １週間の勤務時間から編集したい日を選択して編集ボタンを押すと編集画面が開きます。<br/>
                ただし、打刻時間は編集できません。
                <br/>
              </CardText>
            </CardBody>
          </Card>
          </div>
          <div className="col-sm-4 h-100">
          <Card>
            <CardBody>
              <CardTitle tag="h5">月次集計を印刷</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">指定した月の勤務時間を印刷します</CardSubtitle>
              <CardText>
                指定した月の毎日の勤務時間を表示・印刷します。<br/>
              </CardText>
            </CardBody>
          </Card>
          </div>
        </CardGroup>
      </Container>
    </div>
  );
};


