/* ==============================================================================
 月次集計表の表示画面

=============================================================================== */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PrintPreview from './PrintPreview';
import { InputGroup,InputGroupText,FormGroup,Table,Container,Row,Button } from 'reactstrap';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/ja';

import Request from 'superagent';
import {JWToken,Alert} from './auth/Login';

import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import "react-datepicker/dist/react-datepicker.css";


import { YenFormat } from './Formatter'; 

import './Printer.css';		// ページ番号の印刷

// タイムゾーン
import { TIMEZONE } from './Define';

// font size 12px に設定した時
const minimumTebleHeight = 61;
const rowHeight  = 17;
// const pageHeight = 1043 - 14;			// A4 サイズ

// 日次集計表を作成するには、会計情報、技術会計、商品会計、税金情報、カードが読み終わってないといけない
const ITEM_MONTH	= 0x01;
const ITEM_TECH		= 0x02;
const ITEM_GOODS	= 0x04;
const ITEM_STAFF	= 0x08;
const ITEM_TENANT   = 0x10;
const ITEM_ALL		= ITEM_MONTH | ITEM_TECH | ITEM_GOODS | ITEM_STAFF | ITEM_TENANT ;


// マスターのreadに使用する
const monthParam  = { url: "/visit/month/", 		errMsg: "月次売上",	  list: [],	item: ITEM_MONTH	};
const techParam   = { url: "/visit/tenant_tech/",	errMsg: "技術売上",	  list: [],	item: ITEM_TECH		};
const goodsParam  = { url: "/visit/tenant_goods/",	errMsg: "商品売上",	  list: [],	item: ITEM_GOODS	};
const staffParam  = { url: "/visit/staff/", 		errMsg: "担当者売上", list: [],	item: ITEM_STAFF	};
const tenantParam = { url: "/tenant/",      		errMsg: "テナント",	  list: [],	item: ITEM_TENANT	};

var	item_flag = 0;	// ITEM_XXX DBが読込まれた事を確認する。 ITEM_ALLになったか？

class MonthChart extends Component {
	// propTypes
	static propTypes = {
		salon_id:   PropTypes.number,		// 店舗ID
		salon_name: PropTypes.string,		// 店舗名
		viewDate:	PropTypes.string,		// 表示日
		changeDate: PropTypes.func,			// ReactCalenderTimeline の日付を変更する

	}

	static defaultProps = {
		salon_id: 0,
		salon_name: "",
		viewDate: moment().tz(TIMEZONE).format('YYYY-MM-DD'),
		changeDate: null,
	}

	// コンストラクター
	constructor(props) {
		super(props);
		this.state = {  
			creditTable:  ( <div></div> ),
			tax: 0,
			visit_day: this.props.viewDate,
			end_day: moment().tz(TIMEZONE).add(1,'month').add(-1,'day').format('YYYY-MM-DD'),
			chart: ( <section className="sheet padding-10mm">A4 Seet </section> ),
		};

		moment.locale('ja');
		moment.tz.setDefault(TIMEZONE);		// timezoneをJSTに変更する

		registerLocale('ja', ja);		// react-datepicker の日本語表記
	}

	// ComponentがDOMツリーに追加される前に一度だけ呼ばれる。
	componentDidMount = () => {
		console.log("componentDidMount");
		this.setState({visit_day: this.props.viewDate });

		item_flag = 0;				// 
		tenantParam.url     = "/tenant/" + JWToken.getToken().tenant_id;
		this.getTenant(tenantParam);	// テナント情報の読み込み（月次会計情報、担当者の読み込み)
	}
	
	getAll = ( start,end ) => {
		monthParam.url		= "/visit/month/"	+ start;
		techParam.url       = "/visit/tenant_tech/"  + JWToken.getToken().tenant_id + '/' + start + '/' + end;
		goodsParam.url      = "/visit/tenant_goods/" + JWToken.getToken().tenant_id + '/' + start + '/' + end;
		staffParam.url		= "/visit/staff/"	+ start+ "_" + end;
		if ( this.props.salon_id !== 0 ) {
			monthParam.url 	+= "/"  + this.props.salon_id;
			techParam.url    = "/visit/salon_tech/"  + this.props.salon_id + '/' + start + '/' + end;
			goodsParam.url   = "/visit/salon_goods/" + this.props.salon_id + '/' + start + '/' + end;
			staffParam.url  += "/"  + this.props.salon_id;
		}

		item_flag = ITEM_TENANT;	// テナントは再読み込みしない
		this.getList(monthParam);	// 月次会計情報
		this.getList(techParam);	// 技術
		this.getList(goodsParam);	// 商品
		this.getList(staffParam);	// 担当者の読み込み
	}

// ======================================================================================
// DB 入出力 関数
// ======================================================================================

	getTenant = (param) => {
		//ajax通信する
		Request.get(param.url)
			.query( { token: JWToken.getToken().token } )
			.then( res => {
				param.list = res.body.dbData;
				item_flag |= param.item;				// ビットを1に

				// 月次の期首はテナントから読み取る
				let start = moment().tz(TIMEZONE);
				if ( start.get('date') < param.list[0].begin_day ) start.add(-1,'month');
				start.set('date', param.list[0].begin_day);
				let startDay = start.format('YYYY-MM-DD');
				this.setState({ visit_day: startDay });

				let endDay = start.add(1,'month').add(-1,'days').format('YYYY-MM-DD');
				this.setState({ end_day: endDay });
				
				this.getAll(startDay,endDay);
			})
			.catch( err => {
				console.error(param.errMsg +"を取得できませんでした。");
				console.log(err,err.status);
				if ( err & err.status === 403 ) {
					Alert('再ログインしてください');
					JWToken.clearToken();
				}
			});
	}

/* -----------------------------------------------------------
	マスターの取得
------------------------------------------------------------- */
	getList = (param) => {
		//ajax通信する
		Request.get(param.url)
			.query( { token: JWToken.getToken().token } )
			.then( res => {
				param.list = res.body.dbData;
				this.getInitialTable(param.item);		// Chartの作成
			})
			.catch( err => {
				console.error(param.errMsg +"を取得できませんでした。");
				console.log(err,err.status);
				if ( err & err.status === 403 ) {
					Alert('再ログインしてください');
					JWToken.clearToken();
				}
			});
	}

	getInitialTable = (flag) => {
		item_flag |= flag;
		if ( item_flag !== ITEM_ALL ) return;		// マスターがすべて読み込まれたか？

		this.createChart();
	}



// ======================================================================================
// 画面入力 関数
// ======================================================================================

	// 日時が変更された
	dayChange = (date) => {
//		console.log(event.target.value);
		let start = moment(date).format('YYYY-MM-DD');
		this.setState({ visit_day: start });
		let end   = moment(start).add(1,'month').add(-1,'days').format('YYYY-MM-DD');
		this.setState({ end_day:  end});

		//指定された日付でファイルを読み込む
		this.getAll(start,end);
	}

	decMonth = () => {
		let start = moment(this.state.visit_day).add(-1,'month').format('YYYY-MM-DD');
		let end   = moment(start).add(1,'month').add(-1,'days'  ).format('YYYY-MM-DD');
		this.setState({ visit_day: start});
		this.setState({ end_day:   end  });

		//指定された日付でファイルを読み込む
		this.getAll(start,end);
	}

	incMonth = () => {
		let start = moment(this.state.visit_day).add(1,'month').format('YYYY-MM-DD');
		let end   = moment(start).add(1,'month').add(-1,'days').format('YYYY-MM-DD');
		this.setState({ visit_day: start});
		this.setState({ end_day:   end  });

		//指定された日付でファイルを読み込む
		this.getAll(start,end);
	}

	// クレジット毎に技術会計、商品会計、税金、合計を表示する
	createChart = () => {

		let pages = [];			// 1ページ目は日別の集計と担当者別の集計
								// 2ページ目は技術集計
								// 3ページ目は商品集計

		let tableHeight = [];
		
		// 月計表合計の初期化
		monthParam.list.forEach(function(credit, index) {
			credit.totalTech  = 0;
			credit.totalGoods = 0;
			credit.salesTax   = 0;
			credit.totalSales = 0;
		} );

		let header = (
				<Table striped bordered size={"sm"} hover>
					<tbody >
						<tr>
							<th>{ "月次集計表" + this.props.salon_name + "  " + this.state.visit_day + " 〜 " + this.state.end_day }</th>
						</tr>
					</tbody>
				</Table>
		);

		//--------------------------------------------------------
		// 予約情報1日の合計作成			
		//--------------------------------------------------------
		let totalTech  = 0;
		let totalGoods = 0;
		let totalTax   = 0;
		let totalPoint = 0;
		let rowCount   = 0;
		let week       = [' (日)',' (月)',' (火)',' (水)',' (木)',' (金)',' (土)'];
		let dayElement = monthParam.list.map(function(value, index) {

			totalTech  += Number(value.tech_price);
			totalGoods += Number(value.goods_price);
			totalTax   += Number(value.tax);
			totalPoint += Number(value.use_point);
			
			// ページ位置の計算
			rowCount++;

			return (
					<tr key={index}>
					  <td>{value.visit_date + week[moment(value.visit_date).day()]}</td>
					  <td><YenFormat value={Number(value.tech_price) + Number(value.goods_price) - Number(value.use_point)} /></td>
					  <td><YenFormat value={Number(value.tech_price)} /></td>
					  <td><YenFormat value={Number(value.goods_price)} /></td>
					  <td><YenFormat value={Number(value.tax)} /></td>
					  <td><YenFormat value={Number(value.use_point)} /></td>
					  <td><YenFormat value={Number(value.tech_price) + Number(value.goods_price) + Number(value.tax) - Number(value.use_point)} /></td>
					</tr>
			);
			
		});

		let pixels = minimumTebleHeight + rowCount * rowHeight;
		tableHeight.push(pixels);
		
		let dayTable = (
				<Table bordered hover height="80">
					 <thead bgcolor="#ebf0f7">
						<tr>
							<th className="text-center">日付</th>
							<th className="text-center">売上</th>
							<th className="text-center">技術</th>
							<th className="text-center">商品</th>
							<th className="text-center">消費税</th>
							<th className="text-center">Point</th>
							<th className="text-center">入金</th>
						</tr>
					</thead>
					<tbody>
						{ dayElement }
						<tr className="font-weight-bold">
							<td ><div className="text-center">合計</div></td>
							<td><YenFormat mark='¥' value={totalTech + totalGoods - totalPoint}  /></td>
							<td><YenFormat mark='¥' value={totalTech}  /></td>
							<td><YenFormat mark='¥' value={totalGoods}  /></td>
							<td><YenFormat mark='¥' value={totalTax}  /></td>
							<td><YenFormat          value={totalPoint}  /></td>
							<td><YenFormat mark='¥' value={totalTech + totalGoods + totalTax - totalPoint}  /></td>
						</tr>
					</tbody>
				</Table>
		);
		
		this.setState({ dayElement: dayElement });
		
		//--------------------------------------------------------
		// 担当者の売上合計の作成
		//--------------------------------------------------------
		totalTech  = 0;
		totalGoods = 0;
		totalTax   = 0;
		totalPoint = 0;
		rowCount   = 0;
		let staffElement = staffParam.list.map(function(value, index) {
			totalTech  += Number(value.staff_price);
			totalGoods += Number(value.goods_price);
			totalTax   += Number(value.tax);
			totalPoint += Number(value.use_point);
			return (
					<tr key={index}>
					  <td>{ value.name}</td>
					  <td><YenFormat value={Number(value.staff_price) + Number(value.goods_price) - Number(value.use_point)} /></td>
					  <td><YenFormat value={Number(value.staff_price)} /></td>
					  <td><YenFormat value={Number(value.goods_price)} /></td>
					  <td><YenFormat value={Number(value.tax)} /></td>
					  <td><YenFormat value={Number(value.use_point)} /></td>
					  <td><YenFormat value={Number(value.staff_price) + Number(value.goods_price) + Number(value.tax) - Number(value.use_point)} /></td>
					</tr>
			);
		});

		let staffTable = (
				<Table bordered hover >
					 <thead bgcolor="#ebf0f7">
						<tr>
							<th className="text-center">担当者</th>
							<th className="text-center">売上</th>
							<th className="text-center">技術</th>
							<th className="text-center">商品</th>
							<th className="text-center">消費税</th>
							<th className="text-center">Point</th>
							<th className="text-center">入金</th>
						</tr>
					</thead>
					<tbody>
						{ staffElement }
						<tr className="font-weight-bold">
							<td ><div className="text-center">合計</div></td>
							<td><YenFormat mark='¥' value={totalTech + totalGoods - totalPoint}  /></td>
							<td><YenFormat          value={totalTech}  /></td>
							<td><YenFormat          value={totalGoods} /></td>
							<td><YenFormat          value={totalTax}  /></td>
							<td><YenFormat          value={totalPoint} /></td>
							<td><YenFormat mark='¥' value={totalTech + totalGoods + totalTax - totalPoint}  /></td>
						</tr>
					</tbody>
				</Table>
		);
		pixels = minimumTebleHeight + rowCount * rowHeight;
		tableHeight.push(pixels);
		
		this.setState({staffTable:staffTable});

		pages.push(
			<div>
				<section className={"sheet padding-10mm"}>
					{header}
					{dayTable}
				</section>
				<section className={"sheet padding-10mm"}>
					{header}
					{staffTable}
				</section>
			</div>
		);

		/* 技術売上集計 ------------------------------------------------ */
		this.techElement(pages);
		this.goodsElement(pages);
		
		let print =	(
					<div>
						{ pages }
					</div>
		);
					
		this.setState( { chart: print } );
	}

	/* ------------------------------------------------------------ */
	/* 技術売上集計													*/
	/* ------------------------------------------------------------ */
	techElement = (pages) => {
		let totalTech = 0;		// 技術合計金額
		let totalNum  = 0;		// 技術合計回数
		let totalSum  = 0;		// 技術集計金額
		let sum_no    = 0;		// 集計番号
		let sumCount  = 0;
		
		let techRevList = techParam.list.reverse();
		let techSumList = techRevList.map(function(value, index) {
			if ( sum_no !== value.sum_no ) {
				totalSum   = 0;
				sumCount   = 0;
			}
			totalSum   += Number(value.total);
			sumCount++;
			value.totalSum = totalSum;
			value.sumCount = sumCount;
			sum_no = value.sum_no;
			return ( value );
		});
		let techList = techSumList.reverse();

		sum_no = 0;
		let techElement = techList.map(function(value, index) {
			totalTech  += Number(value.total);
			totalNum   += Number(value.num);
			let no   = sum_no !== value.sum_no ? <td rowSpan={value.sumCount}>{value.sum_no  }</td>  : '';
			let name = sum_no !== value.sum_no ? <td rowSpan={value.sumCount}>{value.sum_name}</td>  : '';
			let total= sum_no !== value.sum_no ? <td rowSpan={value.sumCount}><YenFormat mark='¥' value={value.totalSum}/></td>  : '';
			sum_no = value.sum_no;
			return (
					<tr key={index}>
					  { no } {name } { total} 
					  <td>{value.no  }</td>
					  <td>{value.name}</td>
					  <td><YenFormat mark='¥' value={Number(value.total)} /></td>
					  <td><YenFormat          value={Number(value.num)  } /></td>
					</tr>
			);
		});
		techElement.push(
					<tr className="font-weight-bold">
						<td colSpan="5" ><div className="text-center">合　　計</div></td>
						<td><YenFormat mark='¥' value={totalTech} /></td>
						<td><YenFormat          value={totalNum } /></td>
					</tr>
		);
		techList.push({
			sumCount: 1
		});

		sumCount = 0;
		for ( let i = 0 ; i < techList.length ; i += techList[i].sumCount ) {
			if ( i + techList[i].sumCount - sumCount < 50 ) continue;				// 1つの集計で50以上の技術があるとページをはみ出す可能性あり。
			this.techPage(pages,techElement,sumCount,i);							// 1ページを出力
			sumCount = i;
		}
		this.techPage(pages,techElement,sumCount,techList.length);					// １ページを出力
		
	}
	
	techPage = (pages,techElement,start,end) => {
		let techTable = (
				<Table bordered hover >
					 <thead bgcolor="#ebf0f7">
						<tr>
							<th>集計番号</th>
							<th>技術集計名</th>
							<th>技術集計合計</th>
							<th>番号</th>
							<th>技術名</th>
							<th>売上合計</th>
							<th>回数</th>
						</tr>
					</thead>
					<tbody>
						{ techElement.slice(start,end) }
					</tbody>
				</Table>
		);

		pages.push(
			<section className={"sheet padding-10mm"}>
				<Table striped bordered size={"sm"} hover>
					<tbody >
						<tr>
							<th>{ "月次集計表  技術売上　" + this.props.salon_name + "  " + this.state.visit_day + " 〜 " + this.state.end_day }</th>
						</tr>
					</tbody>
				</Table>
				{techTable}
			</section>
		);
	}


	/* ------------------------------------------------------------ */
	/* 商品売上集計													*/
	/* ------------------------------------------------------------ */
	goodsElement = (pages) => {
		let totalGoods= 0;		// 商品合計金額
		let totalCost = 0;
		let totalNum  = 0;		// 商品合計回数

		let goodsTable = goodsParam.list.map(function(value, index) {
			totalGoods += Number(value.total);
			totalCost  += Number(value.cost);
			totalNum   += Number(value.num);
			return (
					<tr key={index}>
					  <td>{value.no  }</td>
					  <td>{value.name}</td>
					  <td><YenFormat          value={Number(value.num)       } /></td>
					  <td><YenFormat mark='¥' value={value.num * value.cost  } /></td>
					  <td><YenFormat mark='¥' value={Number(value.total)     } /></td>
					  <td>{value.sup_name}</td>
					</tr>
			);
		});
		goodsTable.push(
					<tr className="font-weight-bold">
						<td colSpan="2" ><div className="text-center">合　　計</div></td>
						<td><YenFormat          value={totalNum } /></td>
						<td><YenFormat mark='¥' value={totalCost} /></td>
						<td><YenFormat mark='¥' value={totalGoods} /></td>
						<td></td>
					</tr>
		);

		for ( let i = 0 ; i < goodsTable.length ; i += 50 ) {
			this.goodsPage(pages,goodsTable,i,i+50);							// 1ページを出力
		}
	}
	
	goodsPage = (pages,goodsElement,start,end) => {
		let goodsTable = (
				<Table bordered hover >
					 <thead bgcolor="#ebf0f7">
						<tr>
							<th>番号</th>
							<th>商品名</th>
							<th>個数</th>
							<th>仕入合計金額</th>
							<th>売上合計金額</th>
							<th>仕入先</th>
						</tr>
					</thead>
					<tbody>
						{ goodsElement.slice(start,end) }
					</tbody>
				</Table>
		);

		pages.push(
			<section className={"sheet padding-10mm"}>
				<Table striped bordered size={"sm"} hover>
					<tbody >
						<tr>
							<th>{ "月次集計表  商品売上　" + this.props.salon_name + "  " + this.state.visit_day + " 〜 " + this.state.end_day }</th>
						</tr>
					</tbody>
				</Table>
				{goodsTable}
			</section>
		);
	}


	render() {
		var summaryDate = new Date(this.state.visit_day);
		
		return (
			<div className="DayChart" >

				<Container>
					<Row>
						<form>
							<FormGroup disabled>
								<InputGroup>
									<InputGroupText>日付</InputGroupText>
									<Button onClick={this.decMonth} autoFocus={true}>◀︎</Button>
								    <DatePicker className="form-control" selected={summaryDate} onChange={this.dayChange} locale="ja" dateFormat="yyyy-MM-dd" />
									<InputGroupText>{' 〜 ' + moment(this.state.end_day).format('YYYY/MM/DD')}</InputGroupText>
									<Button onClick={this.incMonth} >▶︎</Button>
								</InputGroup>
							</FormGroup>
						</form>
					</Row>
				</Container>
				
				{ this.state.staffTable }
				<PrintPreview isPoppedOut={false} title={ "月次集計表 " + this.props.salon_name} contents={this.state.chart} />
			</div>
		);

	}
}

export default MonthChart;
