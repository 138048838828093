/* ==============================================================================
 クレジットカード情報の編集画面

 履歴-----------------------------------------
 2019/03/09 reactstrap 対応済み

=============================================================================== */

import React, { Component } from 'react';
import { InputGroup,InputGroupText,FormGroup,Input,Table } from 'reactstrap';

import GridTable from './GridTable';

import { AlignNumber,AlignLeft} from './Formatter';	// React Data Grid
import { Filters } from 'react-data-grid-addons';
import { getMasters,getRecord,addRecord,repRecords } from './Agent';
import {
	MAX_DB_LENGTH,
	MAX_NAME_LENGTH,
	CHECK_NO, CHECK_NAME, CHECK_DUP,
	COLOR_ERROR, COLOR_OK
} from './Define';

const masterParam	= [
	{ url: "/credit",		errMsg: "決済サービス",		list: []	},
];

const initialRow = {id: 0, tenant_id: 0, no: 0, name: "" };

var rowProp = Object.assign({},initialRow);
var condition = 0;			// 画面の入力チェック

const {
	NumericFilter,
//	AutoCompleteFilter,
//	MultiSelectFilter,
//	SingleSelectFilter
} = Filters;

class Credit extends Component {

	// コンストラクター
	constructor(props) {
		super(props);
		this.state = {
			tableList: [ {id: 0, no: 0, name: ""} ],
			rowData:  {d: 0, no: 0, name: ""},
			noStatus: "ＯＫ",
			indexes:	null,
			searchRow:  null,
			closeButton: true,
			noColor:   '#fff0c0',
			nameColor: '#fff0c0'
		};
		
		this.getTableList();
	}

	// 決済サービス情報一覧を取得する
	getTableList = () => {
		getMasters(masterParam,function () {
			this.setState({ tableList: masterParam[0].list });	
		}.bind(this));
	}

	// 決済サービス取得
	getTable = (id) => {
		getRecord(masterParam[0],id,function(rec){
			rowProp = Object.assign({},rec);
			this.setState({rowData: rowProp });
		}.bind(this));		
	}

	rowGetter = (rowIdx) => {
		return this.state.tableList[rowIdx];
	}

	// 追加ボタンが押された
	openAdd = () => {
		rowProp = Object.assign({},initialRow);
		this.setState({ 
			rowData: rowProp,
			indexes: null,
		});
		condition = 0;
		this.checkParam();
	}

	// 修正ボタンが押された
	openRep = ( row,index ) => {
		rowProp = Object.assign({},row[index]);
		this.setState({ 
			rowData: rowProp,
			indexes: null,
		});
		condition = 0;
		this.checkParam();
	}

	// 削除ボタンが押された
	openDel = ( row,indexes ) => {
		this.setState({ 
			rowData:	null,				
			indexes:	indexes,
			searchRow:	row,
			closeButton: true
		});
	}

	// 登録ボタンが押された
	closeAdd = () => {
		masterParam[0].list = new Array(rowProp);
		addRecord(masterParam[0],function (record) {
			this.getTableList();
		}.bind(this));
	}

	// 追加でクローズボタンが押された
	closeRep = () => {
		masterParam[0].list = new Array(rowProp);
		repRecords(masterParam[0], function (){
			this.getTableList();
		}.bind(this));
	}

	// 削除でクローズボタンが押された
	closeDel = () => {
		// 実際は削除せずに大きな番号を付与する
		let row = this.state.indexes.map(function(value,ondex){
			let table = this.state.searchRow[value];
			return { 
				id: table.id,
				no: table.id + MAX_DB_LENGTH,
			};
		}.bind(this));
		masterParam[0].list = row;
		repRecords(masterParam[0], function (record){
			this.getTableList();
		}.bind(this));
	}

	cancel = () => {
	}

	closeButton = (bool) => {
		this.setState({closeButton: bool });
	}

	// DBに登録されている番号か検索する
	checkNo = ( id,no ) => {
		let res = this.state.tableList.find( function (row,index){
				// eslint-disable-next-line
				if ( id == row.id ) return false;
				// eslint-disable-next-line
				if ( no == row.no ) return true;
				return false;
			});

		if ( res === undefined ) return true;
		return false;
	}
	

//-----------------------------------------------
// イベント処理関数
//-----------------------------------------------

	noChange = (event) => {
		let id    = rowProp.id;					// 編集中のid 追加時は0
		let newNo = event.target.value;     	// 入力した番号
		rowProp.no = newNo;
		
		let res = this.checkNo(id,newNo);		// 番号を検索する
		if ( res === true ) {
			this.setState( { 
				rowData: rowProp,
				noStatus: "ＯＫ" ,
			});
			condition = condition & ~CHECK_DUP;
		} else {
			this.setState( { 
				rowData: rowProp,
				noStatus: "重複" ,
			});
			condition = condition | CHECK_DUP;
		}
		
		this.checkParam();
	}

	// InputにFocusを当てると選択状態にする
	focusSelect = (event) => {
		event.target.select();
	}
	
	nameChange = (event) => {
		if ( event.target.value.length > MAX_NAME_LENGTH ) return;
		rowProp.name    = event.target.value;
		this.setState({ rowData:rowProp });

		this.checkParam();
	}

	checkParam = () => {
		// 番号をチェック
		switch ( rowProp.no ) {
			case undefined:
			case 0:
			case "":
			case "0":
			case null:
				condition = condition | CHECK_NO;				// 実行ボタンを無効にする
				this.setState( { noStatus: "ＮＧ" });
				break;
			default:
				condition = condition & (~CHECK_NO);			// 実行ボタンを有効にする
				break;
		}

		// 名前をチェック
		switch ( rowProp.name ) {
			case undefined:
			case "":
			case null:
				condition = condition | CHECK_NAME;		// 実行ボタンを無効にする
				break;
			default:
				condition = condition & (~CHECK_NAME);	// 実行ボタンを有効にする
				break;
		}

		// ボタン、バックグランドカラーの設定
		if ( condition === 0) {							// 一つでもエラーがあればボタンを無効にする
			this.setState({
				closeButton: true,
				noColor:   COLOR_OK,
				nameColor: COLOR_OK,
				noStatus: "ＯＫ"
			});
		} else {
			this.setState({	closeButton: false });
			if ( (condition & (CHECK_NO | CHECK_DUP)) === 0 ) {
				this.setState({
					noColor: COLOR_OK,
					noStatus: "ＯＫ"
				});
			} else {
				this.setState({	noColor: COLOR_ERROR });
			}
	
			if ( (condition & CHECK_NAME) === 0 ) {
				this.setState({	nameColor: COLOR_OK	});
			} else {
				this.setState({	nameColor: COLOR_ERROR });
			}

		}
	}

	render = () => {
		var form = <div></div>;
		
		if ( !this.state.indexes ) {
			form = 
				<form>
					<FormGroup disabled>
						<InputGroup>
							<InputGroupText>番　号</InputGroupText>
							<Input type="number" style={{backgroundColor: this.state.noColor}} placeholder="番号" value={this.state.rowData.no} onFocus={this.focusSelect} onChange={this.noChange} autoFocus={true}/>
							<InputGroupText>{this.state.noStatus}</InputGroupText>
						</InputGroup>
						<InputGroup>
							<InputGroupText>名　称</InputGroupText>
							<Input type="text" style={{backgroundColor: this.state.nameColor}} placeholder="名称（20文字以内）" value={this.state.rowData.name} onChange={this.nameChange} />
						</InputGroup>
					</FormGroup>
				</form>
		} else {
			let rowData = this.state.indexes.map(function(element, index, array) {
	    		return Object.assign({},this.state.searchRow[element]);
			}.bind(this));
			
			// rowDataのを番号順にソート
			rowData.sort(function(a,b){
				return a.no - b.no;	
			});

			form =
				<form>
					以下の決済サービスを削除します。<br/>
					<Table bordered hover size={"sm"} >
						 <thead bgcolor="#ebf0f7">
							<tr>
								<th><div className="text-center">番号</div></th>
								<th>決済サービス名</th>
							</tr>
						</thead>
						<tbody>
							{
								rowData.map(function(value, index, array) {
									return (
										<tr key={index}>
											<td ><div className="text-center">{value.no  }</div></td>
											<td ><div className="text-left"  >{value.name}</div></td>
										</tr>
									);
								})
							}
						</tbody>
					</Table>
				</form>;
		}

		const columns = [{ key: 'no',   name: '番号',   width: 80, editable: false, resizable: false, formatter:AlignNumber, filterable: true, sortable: true, filterRenderer: NumericFilter },
						 { key: 'name', name: '名称',	           editable: false, resizable: true , formatter:AlignLeft,   filterable: true, sortable: true }
					 ];

		return (
			<GridTable title={'決済サービス'} form={form}
					tableRows={this.state.tableList} rowGetter={this.rowGetter} length={this.state.tableList.length} getTable={this.getTableList}
					columns={columns} 
					closeButton={this.state.closeButton}
					openAdd={this.openAdd} openRep={this.openRep} openDel={this.openDel}
					closeAdd={this.closeAdd} closeRep={this.closeRep} closeDel={this.closeDel}
					cancel={this.cancel}
			/>
		);

	}
}

export default Credit;
