/* ==============================================================================
 ポイントの編集画面
 　　ポイント施行日：ポイント還元率％
 履歴-----------------------------------------
 2019/05/30 reactstrap 対応済み

=============================================================================== */

import React, { Component } from 'react';
import { InputGroup,InputGroupText,FormGroup,Input,Table } from 'reactstrap';
import {Alert} from './auth/Login';
import GridTable from './GridTable';
import Check from './Input';
import { DateFormat,AlignRight} from './Formatter';	// React Data Grid
import { Filters } from 'react-data-grid-addons';
import { getMasters,getRecord,addRecord,repRecords,delRecords } from './Agent';

const masterParam	= [{ url: "/point",		errMsg: "ポイント還元率",	list: []	}];

const initialRow = {id: 0, tenant_id: 0, start: '1900-04-01', rate: 2};

var rowProp = Object.assign({},initialRow);

const {
	NumericFilter,
//	AutoCompleteFilter,
//	MultiSelectFilter,
//	SingleSelectFilter
} = Filters;

function today(add) {
			var today = new Date();
			today.setDate(today.getDate() + add);
			var year  = today.getFullYear();
			var month = today.getMonth() + 1;
			var day   = today.getDate();
			return  year + '-' + ( '00' + month ).slice( -2 ) + '-' + ( '00' + day ).slice( -2 );
}

class Point extends Component {

	// コンストラクター
	constructor(props) {
		super(props);
		this.state = {
			tableList: [ {id: 0, start: '2001-01-01', rate: 2} ],
			rowData:  {},
			noStatus: "ＯＫ",
			readOnly: "readonly",         // 削除時は各項目を編集付加に設定
			indexes:	null,
			searchRow:  null,
			closeButton: true,
			noColor:   '#fff0c0',
			nameColor: '#fff0c0'
		};
		
		this.getTableList();

	}

	// ポイント還元率の一覧を取得する
	getTableList = () => {
		getMasters(masterParam,function () {
			this.setState({ tableList: masterParam[0].list });	
		}.bind(this));
	}

	// ポイント還元率取得
	getTable = (id) => {
		getRecord(masterParam[0],id,function(rec){
			rowProp = Object.assign({},rec);
			this.setState({rowData: rowProp });
		}.bind(this));		
	}

	rowGetter = (rowIdx) => {
		return this.state.tableList[rowIdx];
	}

	// 追加ボタンが押された
	openAdd = () => {
		rowProp = Object.assign({},initialRow);
		rowProp.start = today(1);

		this.setState({ 
			rowData: rowProp,
			indexes: null,
		});
	}

	// 修正ボタンが押された
	openRep = ( row,index ) => {
		rowProp = Object.assign({},row[index]);
		this.setState({ 
			rowData: rowProp,
			indexes: null,
		});
	}

	// 削除ボタンが押された
	openDel = ( row,indexes ) => {
		this.setState({ 
			rowData:	null,				
			indexes:	indexes,
			searchRow:	row,
			closeButton: true
		});
	}

	// 登録ボタンが押された
	closeAdd = () => {
		if ( rowProp.stat < today(1) ) {
			Alert('過去のポイント還元率は追加できません。');
			return;
		}
		masterParam[0].list = new Array(rowProp);
		addRecord(masterParam[0],function (record) {
			this.getTableList();
		}.bind(this));
	}

	// 追加でクローズボタンが押された
	closeRep = () => {
		masterParam[0].list = new Array(rowProp);
		repRecords(masterParam[0], function (){
			this.getTableList();
		}.bind(this));
	}

	// 削除でクローズボタンが押された
	closeDel = () => {
		// 過去の消費税率は削除できない
		let now = today(0);
		let row = this.state.indexes.map(function(value,ondex){
			let table = this.state.searchRow[value];
			if ( table.start <= now ) return null;
			return { 
				id: table.id,
			};
		}.bind(this)).filter(Boolean);
		masterParam[0].list = row;
		delRecords(masterParam[0], function (record){
			this.getTableList();
		}.bind(this));
			}

	cancel = () => {
	}

	closeButton = (bool) => {
		this.setState({closeButton: bool });
	}

//-----------------------------------------------
// イベント処理関数
//-----------------------------------------------
	// InputにFocusを当てると選択状態にする
	focusSelect = (event) => {
		event.target.select();
	}
	

	dayChange = (event) => {
		if ( event.target.value <= today(0) ) {
			Alert('過去のポイント還元率は変更できません。');
			return;
		}
		rowProp.start = event.target.value;
		this.setState({ rowData:rowProp });
	}

	rateChange = (event) => {
		if ( Check.number(event.target.value) === false ) return;
		rowProp.rate    = event.target.value;
		this.setState({ rowData:rowProp });
	}


	render() {

		var form = <div></div>;
		
		if ( !this.state.indexes ) {
			let readOnly = this.state.rowData.start > today(0) ? "" : "readonly";

			form = 
				<form>
					<FormGroup disabled>
						<InputGroup bsstyle="success">
							<InputGroupText>開始日　</InputGroupText>
							<Input type="date" placeholder="還元率率施行日" value={this.state.rowData.start} onChange={this.dayChange} readOnly={readOnly} autoFocus={true}/>
						</InputGroup>
						<InputGroup>
							<InputGroupText>還元率％</InputGroupText>
							<Input type="number" placeholder="還元率率％" value={this.state.rowData.rate} onFocus={this.focusSelect} onChange={this.rateChange} readOnly={readOnly}/>
						</InputGroup>
					</FormGroup>
				</form>
		} else {

			let now = today(0);
			let rowData = this.state.indexes.map(function(element, index, array) {
				let row = this.state.searchRow[element];
				if ( row.start <= now ) return null;
//				console.log(row.start,a);
	    		return Object.assign({},row);
			}.bind(this)).filter(Boolean);
			
			// rowDataのを番号順にソート
			rowData.sort(function(a,b){
				if (a.start > b.start) 	return 1;
				if (a.start < b.start) 	return -1;
				return 0;
			});

			console.log(rowData);

			form =
				<form>
					以下のポイント還元率を削除します。<br/>
					<Table bordered hover size={"sm"} >
						 <thead bgcolor="#ebf0f7">
							<tr>
								<th><div className="text-center">開始日</div></th>
								<th>還元率％</th>
							</tr>
						</thead>
						<tbody>
							{
								rowData.map(function(value, index, array) {
									return (
										<tr key={index}>
											<td ><div className="text-center">{value.start}</div></td>
											<td ><div className="text-right" >{value.rate }</div></td>
										</tr>
									);
								})
							}
						</tbody>
					</Table>
				</form>;
		}

		const columns = [
			{ key: 'start',  name: '開始日',  width: 120, editable: false, resizable: false, formatter:DateFormat, filterable: true, sortable: true, filterRenderer: NumericFilter },
			{ key: 'rate',   name: '還元率％',width: 120, editable: false, resizable: true, formatter:AlignRight, locked: true }
		];


		return (
			<GridTable title={'ポイント'} form={form}
					tableRows={this.state.tableList} rowGetter={this.rowGetter} length={this.state.tableList.length} getTable={this.getTableList}
					columns={columns} 
					closeButton={this.state.closeButton}
					openAdd={this.openAdd} openRep={this.openRep} openDel={this.openDel}
					closeAdd={this.closeAdd} closeRep={this.closeRep} closeDel={this.closeDel}
					cancel={this.cancel}
			/>
		);

	}
}

export default Point;
