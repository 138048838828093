/* ==============================================================================
 レジの現金予実  編集画面
    レジ開始時（朝）
    レジ締め時（夜）
    
    ・Web予約
    ・レジ集計
    。日次、月次、年次集計
    ・商品棚卸
=============================================================================== */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InputGroup,InputGroupText,Input,Form,FormGroup,Button,Table } from 'reactstrap';
import { Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import { Dropdown,DropdownToggle,DropdownMenu,DropdownItem } from 'reactstrap';
import { Container,Row,Col} from 'reactstrap';
import { Filters } from 'react-data-grid-addons';
import './Table.css';

import Request from 'superagent';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/ja';
import {JWToken,Alert}  from './auth/Login';
import GridTable  from './GridTable';
import DaySlip    from './DaySlip';
import MonthChart from './MonthChart';
import YearChart  from './YearChart';
import Stock	  from './Stock';
import { AlignLeft,AlignRight,YenFormat,changeYen  } from './Formatter';	// React Data Grid
import Check from './Input';
import StarReceipt from './StarWebPrint/StarReceipt';
import HelpPopover from './HelpPopover';

import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ja from 'date-fns/locale/ja';
import {
	STATE_NET, STATE_CANCEL,
	MAX_DB_LENGTH,
	MAX_NUMBER_LENGTH,
	MAX_ABSTRACT_LENGTH,
	TIMEZONE
} from './Define';

const initialRow = { tenant_id: 0, salon_id: 0, deal_date: '2014-12-05', account_item_id: 0,  abstract: "", flow:false, account: 0};
const cashRate = [10000,5000,2000,1000,500,100,50,10,5,1];

// タイムゾーン
// const TIMEZONE = 'Asia/Tokyo';

var rowProp = initialRow;


export class CashCount extends Component {
	static propTypes = {
		salon_id:		PropTypes.number.isRequired,		// 店舗ID
		salon:			PropTypes.object.isRequired,
		viewDate:		PropTypes.string,					// 表示する日付
		changeDate:		PropTypes.func,						// 予約表の日付
		staffList:		PropTypes.array.isRequired,		// 担当者一覧　
		customerList:	PropTypes.array.isRequired,		// 顧客一覧 予約メール受信で顧客を探す
    }

	static defaultProps = {
		viewDate: moment().tz(TIMEZONE).format('YYYY-MM-DD'),			// 省略時は今日の日付
		changeDate: null,
	}

     // コンストラクター
    constructor(props) {
        super(props);
        this.state = { 
          dropdownOpen: false,
          showCashBook: false,
          title: "",
          chart: <div/>,
          dropDownRight: <div/>
        };

		moment.locale('ja');
		moment.tz.setDefault("Asia/Tokyo");		// timezoneをJSTに変更する
        
		registerLocale('ja', ja);		// react-datepicker の日本語表記
    }
  
	onMove = (arg) => {
		console.log(arg.target.outerText.substr(0,10));
		this.props.changeDate(arg.target.outerText.substr(0,10));
	}

	toggle = () => {
	    this.setState({	dropdownOpen: !this.state.dropdownOpen});
	}


	// DaySlip 日次集計表 =================================
    onClickDaySlip = () =>{
	    this.setState( {
	    	showModalChart: true,
	    	title: '会計伝票 ',
			chart: <DaySlip salon_id={this.props.salon.id} salon_name={this.props.salon.name} viewDate={this.props.viewDate} changeDate={this.props.changeDate}/>
	    } );
    }

	// MonthChart 月次集計表 =================================
    onClickMonthChart = () =>{
	    this.setState( {
	    	showModalChart: true,
	    	title: '月次集計表 ',
			chart: <MonthChart salon_id={this.props.salon.id} salon_name={this.props.salon.name} viewDate={this.props.viewDate} changeDate={this.props.changeDate}/>
	    } );
    }

	// YearChart 年次集計表 =================================
    onClickYearChart = () =>{
	    this.setState( {
	    	showModalChart: true,
	    	title: '年次集計表 ',
			chart: <YearChart salon_id={this.props.salon.id} salon_name={this.props.salon.name} viewDate={this.props.viewDate} changeDate={this.props.changeDate}/>
	    } );
    }
    
    onClickStock = () =>{
	    this.setState( {
	    	showModalChart: true,
	    	title: '商品棚卸　 ',
			chart: <Stock salon_id={this.props.salon_id} salonName={this.props.salon.name} />
	    } );
    }

	onChartToggle = () => {
	    this.setState({
	    	showModalChart: !this.state.showModalChart
	    });
	}

	// CashBack レジ集計 ==================================
    onClickCashBook = () =>{
	    this.setState( { showCashBook: true  } );
    }

    onCancelCashBook = () => {
	    this.setState( { showCashBook: false } );
    }

	onCloseCashBook = () => {
	    this.setState( { showCashBook: false } );
    }

	// RadyCash 金種表 ==================================
    onClickRadyCash = () =>{
	    this.setState( { showRadyCash: true  } );
    }

    onCancelRadyCash = () => {
    	this.setState( { showRadyCash: false } );
    }

    onCloseRadyCash = () => {
 	    this.setState( { showRadyCash: false } );
    }


	// メール受信 ==================================
	onReceiveMail = () => {
		let salon = this.props.salon;
		if ( !salon.imap_host || !salon.imap_port || !salon.imap_user || !salon.imap_passwd ) {
			Alert("店舗の「予約メール受信」を設定して下さい。");
			return;
		}
		Request.get("/pop3/" + this.props.salon_id)
			.query( { token: JWToken.getToken().token } )
			.then( res => {
				// 技術表示
				let data = res.body.dbData;
				console.log(data);
				
				let visits = data.map((mail,index) =>{
					let state = STATE_NET;
					if ( mail.cancel ) state = STATE_CANCEL;
					// 担当者名の取り出し
					let staff   = this.props.staffList.find((staff)=> staff.name === mail.stylist );
					if ( staff === undefined ) staff = { id : 0 , name : "" };
					
					// 顧客名の取り出し
					let customer = this.props.customerList.find((cust)=> {
						if ( cust.name === mail.name ) {
							if ( cust.staff_id === staff.id ) {
								return true;
							}
						} 
						return false;
					});
					let custId = 0;
					if ( customer ) custId = customer.id;
console.log(mail,index);						
					let visit = {
					    tenant_id:   	JWToken.getToken().tenant_id,	/* テナントID   */
					    salon_id:		this.props.salon_id,			/* 店舗ID       */
	//				    receipt_no  	INT,            				/* レシート番号 */
//						reserv_no:		mail.num,						/* 予約番号     */
					    regist_date:	moment().format("YYYY-MM-DD"),	/* 予約の登録日 */
					    ope_staff:   	staff.id,            			/* レジ操作担当 */ 
					    visit_date:		mail.visit_date,				/* 来店日時	    */
					    visit_time:  	mail.visit_time,				/* 来店時間     */
					    duration:		60,								/* 施術時間 Default 30分 */
					    visit_state: 	state,							/* 0:予約、1:来店、2:会計 3:集計 4:ネット予約 5:キャンセル */
					    customer_id: 	custId,        					/* 顧客ID       */
					    name:        	mail.name,  					/* 顧客名       */
					    staff_id:    	staff.id,						/* 担当者ID     */
					    memo:			mail.memo,						/* メモ         */
					    add_point:   	0,								/* 追加ポイント */
					    use_point:   	0,								/* 使用ポイント */
					    credit_id:   	0,			  					/* 0:現金、 1〜:カード */
					    pay:     		0,								/* お預かり金額 */
					};
					return visit;		
				});
console.log(visits);
				if ( visits.length ) this.postVisit(visits);

			})
			.catch( err => {
				console.error("メールを取得できませんでした。");
				if ( err ) console.log(err);
				if ( err & err.status === 403 ) JWToken.clearToken();
			});
	}

	postVisit = (visit) => {
		Request.post('/visit')
			.send({ dbData: visit, token: JWToken.getToken().token } )
			.then( res => {
				// DBに登録した来店idを売上情報に設定する
				let id = res.body.dbData[0].id;
				let tech = []; //this.state.techSlipList.map(function(value, index) {  value.visit_id = id; return value; });
//				this.putTechSlip(id,tech);			// 技術売上のDB登録
				
				let goods = []; // this.state.goodsSlipList.map(function(value, index) {  value.visit_id = id; return value; });
//				this.putGoodsSlip(id,goods);		// 商品売上のDB登録

				visit.id = id;
				this.postLog(visit,tech,goods);		// ログ出力

//				this.getVisitTable(tableItemStart,tableItemEnd,true);  // 強制再読み込み
			})
			.catch( err => {
				console.error("予約を追加できませんでした");
				console.log(err);
				if ( err & err.status === 403 ) JWToken.clearToken();
			});
	}

	// 来店情報ログのAdd
	postLog = (visit,techSlipList,goodsSlipList) => {

		let log = {
			visit_id:	visit.id,
			staff_id:	1,							// レジ担当者
			edit_time:	moment().format("YYYY-MM-DD HH:mm:ss"),
			visit:		visit,
			tech_slip:	techSlipList,
			goods_slip:	goodsSlipList
		};
		console.log(log);

		Request.post('/visit_log')
			.send({ dbData: log, token: JWToken.getToken().token } )
			.then( res => {
				console.log(log);
			})
			.catch( err => {
				console.error("来店情報ログを追加できませんでした");
				console.log(err);
				if ( err & err.status === 403 ) JWToken.clearToken();
			});
	}

	toggleRight = () => {
		if ( this.state.btnDropRight ) 	this.setState( { dropdownOpen: false } );
		if ( !this.state.btnDropRight ) {
			Request.get("/visit/net/" + this.props.salon_id)
				.query( { token: JWToken.getToken().token } )
				.then( res => {
					// 技術表示
					let data = res.body.dbData;
					console.log(data);
					
					let dropDown = data.map((visit,index) =>{
						return <DropdownItem onClick={this.onMove}> { visit.visit_date + " " + visit.visit_time.substr(0,5) + " " + visit.name } </DropdownItem>;
//						return <DropdownItem { "onClick={this.onMove(" + visit.visit_date + ")}" }> { visit.visit_date + " " + visit.visit_time.substr(0,5) + " " + visit.name } </DropdownItem>;
					});
		console.log(dropDown);				
				    this.setState({ dropDownRight: dropDown});
				})
				.catch( err => {
					console.error("予約連絡を取得できませんでした。");
					if ( err ) console.log(err);
					if ( err & err.status === 403 ) JWToken.clearToken();
				});
				
		}
		this.setState({ btnDropRight: !this.state.btnDropRight});
	}

    render() {
// console.log(this.state.dropDownRight);
		let salonName = this.props.salon.name ? this.props.salon.name : '';
        return (
        	<div>
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                    <DropdownToggle caret>店舗集計</DropdownToggle>
                    <DropdownMenu>
						<Dropdown direction="right" isOpen={this.state.btnDropRight} toggle={this.toggleRight}>
							<DropdownToggle caret tag="div" className="dropdown-item">Web予約</DropdownToggle>
							<DropdownMenu>
		                        <DropdownItem onClick={this.onReceiveMail}>予約メール受信</DropdownItem>
								{ this.state.dropDownRight }
							</DropdownMenu>
						</Dropdown>             
						<DropdownItem onClick={this.onClickCashBook}  >レジ締め</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={this.onClickDaySlip}   >日次集計</DropdownItem>
                        <DropdownItem onClick={this.onClickMonthChart}>月次集計</DropdownItem>
                        <DropdownItem onClick={this.onClickYearChart} >年次集計</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={this.onClickStock}     >商品棚卸</DropdownItem>
                    </DropdownMenu>
                </Dropdown>

				<Modal isOpen={this.state.showModalChart} backdrop={'static'} style={{maxWidth: '750px', width: '99%'}} size="lg" autoFocus={false} >
					<ModalHeader toggle={this.onChartToggle}>{this.state.title + salonName.trim()}</ModalHeader>
					<ModalBody>
						{ this.state.chart }
					</ModalBody>
					<ModalFooter>
			            <Button color="secondary" onClick={this.onChartToggle}>終了</Button>
					</ModalFooter>
    			</Modal>

				<Modal isOpen={this.state.showCashBook} backdrop={'static'} size={"lg"} autoFocus={false}>
	    			<ModalHeader toggle={this.onCancelCashBook}>
						<Container >
					      <Row>
					        <Col>レジ集計</Col>
					        <Col>{salonName.trim()}</Col>
					        <Col><HelpPopover id='cashcount' help={help} /></Col>
					      </Row>
						</Container>
					</ModalHeader>
	    			<ModalBody>
						<CashBook salon_id={Number(this.props.salon.id)} salon={this.props.salon} viewDate={this.props.viewDate} changeDate={this.props.changeDate} />
	    			</ModalBody>
	    			<ModalFooter>
						<Button onClick={this.onClickRadyCash}>金種表</Button>{'  '}
						<Button color="secondary" onClick={this.onCloseCashBook}>終了</Button>
	    			</ModalFooter>
				</Modal >

    			<Modal isOpen={this.state.showRadyCash} backdrop={'static'} size={"Default"} autoFocus={false} >
        			<ModalHeader toggle={this.onCancelRadyCash}>
                            現金実査：現在の現金を確認する
        			</ModalHeader>
        			<ModalBody>
        			        金種表
                        <ReadyCash />
        			</ModalBody>
        			<ModalFooter>
        			    <Button color="secondary" onClick={this.onCloseRadyCash}>終了</Button>
        			</ModalFooter>
    			</Modal>
    		</div>
    	);
    }
}


/* ==============================================================================
	現金出納帳
=============================================================================== */

const ITEM_ACCOUNT_ITEM = 0x10;
const ITEM_CASH_BOOK	= 0x20;
const ITEM_CREDIT		= 0x40;
const ITEM_ALL      = ITEM_ACCOUNT_ITEM | ITEM_CASH_BOOK | ITEM_CREDIT;

const accountItemParam	= { url: "/account_item",	errMsg: "勘定科目",			list: [],	item: ITEM_ACCOUNT_ITEM	};
const cashBookParam		= { url: "/cashbook",		errMsg: "レジ入出金",		list: [],	item: ITEM_CASH_BOOK	};
const creditParam    	= { url: "/visit/credit/",  errMsg: "クレジット売上",	list: [],	item: ITEM_CREDIT	};

var	item_flag = 0;

const {
//	NumericFilter,
	AutoCompleteFilter,
//	MultiSelectFilter,
//	SingleSelectFilter
} = Filters;

export class CashBook extends Component {
	
	static propTypes = {
		salon_id:		PropTypes.number.isRequired,	// 店舗ID
		salon:			PropTypes.object.isRequired,	// 店舗情報
		viewDate:		PropTypes.string,				// 表示する日付
		changeDate:		PropTypes.func,					// 予約表の日付
    }
    
   	static defaultProps = {
		viewDate: moment().tz(TIMEZONE).format('YYYY-MM-DD'),
		changeDate: null,
	}

	// コンストラクター
	constructor(props) {
		super(props);
		this.state = {
			tableList:  [ { tenant_id: 0, salon_id: 0, deal_date: "", account_item_id: 1, abstract: "", flow:false, account: 0 } ],
			optionList: [ {id: 0, tenant_id: 0, no: 0, name: ""} ],		// 勘定科目 id, 勘定科目名
			rowData:      { tenant_id: 0, name: "", deal_date: "",  account_item_id: 1, abstract: "", flow:false, account: 0 },
			indexes:	null,
			searchRow:  null,
			closeButton: true,
			deal_date: this.props.viewDate,
			cashTotal: 0,
			showRadyCash: false,

		};
		moment.locale('ja');
		moment.tz.setDefault(TIMEZONE);		// timezoneをJSTに変更する

	}

	// ComponentがDOMツリーに追加される前に一度だけ呼ばれる。
	componentDidMount = () => {
//		console.log("componentDidMount");
		this.setState({ deal_date: this.props.viewDate });
		this.getTableList(this.props.viewDate);
	}
	
	// DBの読み込み
	getTableList = (day) => {
		if ( !day ) day = this.state.deal_date;
		
		cashBookParam.url	= "/cashbook/"	   + this.props.salon.id + "/" + day + "_" + day;
		creditParam.url		= "/visit/credit/" + this.props.salon.id + "/" + day + "_" + day;

		item_flag = 0;
		this.getList(accountItemParam);		// 勘定科目
		this.getList(cashBookParam);		// 現金出納帳
		this.getList(creditParam);			// クレジット売上
	};

	/* -----------------------------------------------------------
		マスターの取得
	------------------------------------------------------------- */
	getList = (param) => {
		//ajax通信する
		Request.get(param.url)
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					JWToken.clearToken();			// 再ログイン
					console.error(param.errMsg +"を取得できませんでした。");
					console.log(err,param);
				} else {
					// 店舗表示
					param.list = res.body.dbData;
					this.getInitialTable( param.item );		// group,itemの作成
				}
			}.bind(this));
	}

	/* -----------------------------------------------------------
		現金出納帳をDBに書き込む
	------------------------------------------------------------- */
	putTableList = (tableList = this.state.tableList.concat()) => {
		
		// lookupSum()で追加した、勘定科目名、収入/支出を削除する
		let list = tableList.map(function(value, index) {
			delete value.itemName;
			delete value.flowName;
			return value;
		});
		// DBに登録する
		Request.put('/cashbook/' + this.props.salon.id + "/" + this.state.deal_date )
			.send({ dbData:list, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.error("現金出納帳を登録できませんでした");
					return;
				}
				this.getTableList(this.props.viewDate);
//				console.log("WriteEnd:", list);
			}.bind(this));
	}

	// 初期値の
	getInitialTable = (flag) => {
		item_flag |= flag;
		if ( item_flag !== ITEM_ALL ) return;		// マスターがすべて読み込まれたか？

//		console.log(cashBookParam.list);
		this.lookupSum(cashBookParam.list,accountItemParam.list);
		this.setState({ tableList:  cashBookParam.list});
		this.setState({ optionList: accountItemParam.list});
		
		let credit = this.createTable(creditParam.list);
		this.setState({ cashTable: credit.cashTable });
		this.setState({ cashTotal: credit.cashTotal });
	}


	// 勘定科目id で勘定科目名を取得する
	lookupSum = (list,item) => {
		for ( let i = 0; i < list.length; i++) {
			let id = list[i].account_item_id;
			list[i].itemName = "-";
			for ( let j = 0; j < item.length; j++ ) {
				if ( id === item[j].id ) {
					list[i].itemName = item[j].name;
					break;
				}
			}
			if ( list[i].flow ) {
				list[i].flowName = "収入";
			} else {
				list[i].flowName = "支出";
			}
		}
	}


	toggle = () => {
	}
//-----------------------------------------------
// 売上テーブルの作成
//-----------------------------------------------


//-----------------------------------------------
// GridTable コールバック関数
//-----------------------------------------------

	rowGetter = (rowIdx) => {
		return this.state.tableList[rowIdx];
	}

	// 追加ボタンが押された
	openAdd = (row,index) => {
		rowProp = Object.assign({},initialRow);
		rowProp.salon_id  = this.props.salon.id;
		rowProp.deal_date = this.state.deal_date;
		if ( this.state.optionList.length !== 0 )
			rowProp.account_item_id = this.state.optionList[0].id;	// 勘定科目は最初に
		rowProp.flowName  = "支出";
// console.log('openAdd',rowProp);
		this.setState({ 
			rowData: rowProp,
			indexes: null,
		});
	}

	// 修正ボタンが押された
	openRep = ( row,index ) => {
		rowProp = Object.assign({},row[index]);
		this.setState({ 
			rowData: rowProp,
			indexes: null,
		});
	}

	// 削除ボタンが押された
	openDel = ( row,indexes ) => {
/*************************************************************************20210304
		rowProp = Object.assign({},this.state.tableList[index]);
		this.setState({ 
			rowData: rowProp,
			readOnly: "readonly",
			closeButton: true
		});
*************************************************************************/
		this.setState({ 
			rowData:	null,				
			indexes:	indexes,
			searchRow:	row,
			closeButton: true
		});

	}

	// 追加でクローズボタンが押された
	closeAdd = () => {
		let list = this.state.tableList.concat();
		list.push(rowProp);
// console.log('closeAdd',list);
		this.lookupSum(list,this.state.optionList);
		this.setState({ tableList: list});
		this.putTableList(list.concat());
	}

	// 修正でクローズボタンが押された
	closeRep = (index) => {
		let list = this.state.tableList.concat();
		list[index] = rowProp;
		this.lookupSum(list,this.state.optionList);
		this.setState({ tableList: list});
		this.putTableList(list.concat());
	}

	// 削除でクローズボタンが押された
	closeDel = (indexes) => {
		let list = this.state.tableList.concat();
		indexes.forEach(function(index){
			let table = this.state.searchRow[index];
			let i = list.findIndex(function(tbl,index){
				return ( list[index].account_item_id === table.account_item_id &&
						 list[index].abstract        === table.abstract &&
						 list[index].flow            === table.flow &&
						 list[index].account         === table.account );
			});
			list.splice(i, 1);
		}.bind(this));
console.log("closeDel", list);
		this.lookupSum(list,this.state.optionList);
		this.setState({ tableList: list});
		this.putTableList(list.concat());
	}

	cancel = () => {

	}

	closeButton = (bool) => {
		this.setState({closeButton: bool });


	}

//-----------------------------------------------
// イベント処理関数
//-----------------------------------------------

	// 日時が変更された
	dateChange = (date) => {
		let day = moment(date).format('YYYY-MM-DD');

		// 日付を変更する
		this.setState({ deal_date: day });

		// 指定された新たな日付でファイルを読み込む
		this.getTableList(day);

		if ( this.props.changeDate !== null ) this.props.changeDate(date);		// ReactCalendarTimelineの日付を変更する
		
	}

	// InputにFocusを当てると選択状態にする
	focusSelect = (event) => {
		event.target.select();
	}
	
    // 勘定科目
	itemChange = (event) => {
		let id = Number(event.target.value);
		rowProp.account_item_id= id;
		this.setState({ rowData:rowProp });
	}

    // 摘要
	abstractChange = (event) => {
		if ( event.target.value.length > MAX_ABSTRACT_LENGTH ) return;
		rowProp.abstract = event.target.value;
		this.setState({ rowData:rowProp });
	}

    // 入出金
    flowChange = (event) => {
 		if ( event.target.value === "true" ) {
	 		rowProp.flow     = true;
 			rowProp.flowName ='収入';
 		} else {
	 		rowProp.flow     = false;
 			rowProp.flowName ='支出';
 		}
		this.setState({ rowData:rowProp });
    }
    
    // 金額
	accountChange = (event) => {
		let account = event.target.value;
		if ( Check.money(account) === false ) return;
		rowProp.account   = Number(account);
		this.setState({ rowData:rowProp });
	}


	createTable = (rowData) => {
		// クレジットの合計を計算する
		if ( rowData.length === 0 ) return (<div/>);
		let cashTech   = 0, cashGoods   = 0, cashTax   = 0, cashPoint   = 0;
		let creditTech = 0, creditGoods = 0, creditTax = 0, creditPoint = 0;
	    rowData.forEach((item, index) => {
			 if ( item.credit_id === 0 ) {		// 現金はスキップ
				 cashTech  += Number(item.tech);
				 cashGoods += Number(item.goods);
				 cashTax   += Number(item.tax);
				 cashPoint += Number(item.point);
			 } else {
				 creditTech  += Number(item.tech);
				 creditGoods += Number(item.goods);
				 creditTax   += Number(item.tax);
				 creditPoint += Number(item.point);
			 }
		});
		let cashTotal   = cashTech   + cashGoods   + cashTax   - cashPoint;
		let creditTotal = creditTech + creditGoods + creditTax - creditPoint;
	
		// テーブルを作成
		let cashTable = (
			<Table striped bordered size={"sm"} hover>
				<thead className="text-white" bgcolor="#4682b4" >
					<tr>
						<th>　　　　</th>
						<th>技術売上</th>
						<th>商品売上</th>
						<th>税金　　</th>
						<th>ポイント</th>
						<th>売上合計</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>現　　　金</td>
						<td><YenFormat mark=''  value={cashTech } /></td>
						<td><YenFormat mark=''  value={cashGoods} /></td>
						<td><YenFormat mark=''  value={cashTax  } /></td>
						<td><YenFormat mark=''  value={cashPoint} /></td>
						<td><YenFormat mark='¥' value={cashTotal} /></td>
					</tr>
					<tr>
						<td>クレジット</td>
						<td><YenFormat mark=''  value={creditTech } /></td>
						<td><YenFormat mark=''  value={creditGoods} /></td>
						<td><YenFormat mark=''  value={creditTax  } /></td>
						<td><YenFormat mark=''  value={creditPoint} /></td>
						<td><YenFormat mark='¥' value={creditTotal} /></td>
					</tr>
					<tr>
						<td>　合　計　</td>
						<td><YenFormat mark='¥' value={cashTech  + creditTech } /></td>
						<td><YenFormat mark='¥' value={cashGoods + creditGoods} /></td>
						<td><YenFormat mark='¥' value={cashTax   + creditTax  } /></td>
						<td><YenFormat mark='¥' value={cashPoint + creditPoint} /></td>
						<td><YenFormat mark='¥' value={cashTotal + creditTotal} /></td>
					</tr>
				</tbody>
			</Table>
		);
		return { cashTable, cashTotal, creditTotal};
	}

	summary = () => {
		let income = 0;
		let outgo  = 0;
		this.state.tableList.forEach(function(v,index){
			if ( v.flow ) {
				income += v.account;
			} else {
				outgo  += v.account;
			}
		});
		let total = this.state.cashTotal + income - outgo;
		let table =
				<Table striped bordered size={"sm"} hover>
					 <thead className="text-white" bgcolor="#4682b4">
						<tr>
							<th>現金売上合計</th>
							<th>収入金額</th>
							<th>支出金額</th>
							<th>差引残高</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td><YenFormat mark='¥' value={this.state.cashTotal} /></td>
							<td><YenFormat mark='¥' value={income} /></td>
							<td><YenFormat mark='¥' value={outgo } /></td>
							<td><YenFormat mark='¥' value={total } /></td>
						</tr>
					</tbody>
				</Table>;
		return table;
	}


	// PrintCash ===========================================================================================
	onClickPrintCash = () => {
		// urlが登録されていない場合は使用できない
		if ( !this.props.salon.receipt_url ) return;

		let receipt = new StarReceipt();
		let url     = this.props.salon.receipt_url;
		let width   = this.props.salon.receipt_width;
		receipt.connect(url,width);

		receipt.createText2(this.props.salon.name);				// Salon名の印刷
		receipt.createText2("日付：" + this.state.deal_date);	// 日付
		receipt.createText ("");								// 改行
		
		receipt.createText  ('レジ集計');
		receipt.createTextW ('--------------------------------------------------','');

		let total = 0;
		cashBookParam.list.forEach( function( cashbook ) {
			let name = cashbook.itemName + ':' + cashbook.abstract;
			let yen  = 0;
			if ( cashbook.flow ) {
				yen  = ' '  + changeYen(cashbook.account);
				total += cashbook.account;
			} else {
				yen  = ' -' + changeYen(cashbook.account);
				total -= cashbook.account;
			}
			receipt.createTextW (name,yen);
		});
		receipt.createTextW ('--------------------------------------------------','');
		receipt.createTextW ('合計金額',changeYen(total));
		receipt.createText ("");								// 改行

		// 売上一覧
		let cashTech   = 0, cashGoods   = 0, cashTax   = 0, cashPoint   = 0;
		let creditTech = 0, creditGoods = 0, creditTax = 0, creditPoint = 0;
		creditParam.list.forEach((item, index) => {
			 if ( item.credit_id === 0 ) {		// 現金はスキップ
				 cashTech  += Number(item.tech);
				 cashGoods += Number(item.goods);
				 cashTax   += Number(item.tax);
				 cashPoint += Number(item.point);
			 } else {
				 creditTech  += Number(item.tech);
				 creditGoods += Number(item.goods);
				 creditTax   += Number(item.tax);
				 creditPoint += Number(item.point);
			 }
		});
		let cashTotal   = cashTech   + cashGoods   + cashTax   - cashPoint;
		let creditTotal = creditTech + creditGoods + creditTax - creditPoint;

		receipt.createTextW ('技術売上',  changeYen(cashTech  + creditTech ));
		receipt.createTextW ('商品売上',  changeYen(cashGoods + creditGoods));
		receipt.createTextW ('消費税',    changeYen(cashTax   + creditTax  ));
		receipt.createTextW ('ポイント',  '-' + changeYen(cashPoint + creditPoint ));
		receipt.createTextW2('売上合計',  changeYen(cashTotal + creditTotal));
		receipt.createTextW ('クレジット',changeYen(creditTotal ));
		receipt.createTextW2('現金合計',  changeYen(cashTotal + total ));
		
		receipt.createText ("");											// 改行

		receipt.createCutPaper();					// feed & Cut paper
		receipt.send();								// 印刷する
	}

	render() {
/****
		var color = {
            backgroundColor: "#4682b4",
            color: "#ffffff"
        };
****/

		var form = <div></div>;
		if ( !this.state.indexes ) {
			form = 
				<Form>
					<FormGroup disabled>
						<InputGroup>
							<InputGroupText>勘定科目</InputGroupText>
							<Input type="select" placeholder="勘定科目" value={this.state.rowData.account_item_id} onChange={this.itemChange} autoFocus={true} >
							{
								this.state.optionList.flatMap(function (item) {
									if ( item.no >= MAX_DB_LENGTH ) return [];
									return [<option value={item.id} key={item.id}>{item.name}</option>];
								})
							}
							</Input>
						</InputGroup>
						<InputGroup>
							<InputGroupText>摘　要　</InputGroupText>
							<Input type="text" style={{backgroundColor: this.state.abstract}} placeholder="摘要（60文字以内）" value={this.state.rowData.abstract} onChange={this.abstractChange} />
						</InputGroup>
						<InputGroup>
							<InputGroupText>入出金　</InputGroupText>
							<Input type="select" placeholder="収入/支出" value={this.state.rowDataabstract} onChange={this.flowChange} >
								<option value={"false"} key={0}>支出</option>
								<option value={"true"}  key={1}>収入</option>
							</Input>
						</InputGroup>
						<InputGroup>
							<InputGroupText>金　額　</InputGroupText>
							<Input type="number" className="text-right" placeholder="金　額" value={this.state.rowData.account} onChange={this.accountChange} onFocus={this.focusSelect} />
						</InputGroup>
					</FormGroup>
				</Form>;
		} else {
			let rowData = this.state.indexes.map(function(element, index, array) {
	    		return Object.assign({},this.state.searchRow[element]);
			}.bind(this));
			
			// rowDataのを番号順にソート
			rowData.sort(function(a,b){
				return a.no - b.no;	
			});

			form =
				<form>
					以下の科目を削除します。<br/>
					<Table bordered hover size={"sm"} >
						 <thead bgcolor="#ebf0f7">
							<tr>
								<th><div className="text-center">科目</div></th>
								<th>入出金</th>
								<th>金額</th>
							</tr>
						</thead>
						<tbody>
							{
								rowData.map(function(value, index, array) {
									const item = this.state.optionList.find(param => {
										return (param.id  === value.account_item_id);
									});

									return (
										<tr key={index}>
											<td ><div className="text-left" >{item.name}</div></td>
											<td ><div className="text-left" >{ (value.flow === true) ? '収入' : '支出' }</div></td>
											<td ><div className="text-right">{value.account}</div></td>
										</tr>
									);
								}.bind(this))
							}
						</tbody>
					</Table>
				</form>;
		}


		// 予約日時
		var startDate = new Date(this.state.deal_date);
		
		const columns = [
//					 { key: 'itemName',name: <div style={color}>勘定科目</div> ,width: 150, editable: false, resizable: true, formatter:AlignLeft, locked: true },
//					 { key: 'abstract',name: <div style={color}> 摘　要 </div> ,width: 400, editable: false, resizable: true, formatter:AlignLeft },
					 { key: 'itemName',name: '勘定科目',width: 150, editable: false, resizable: true, formatter:AlignLeft, filterable: true, sortable: true, filterRenderer: AutoCompleteFilter },
					 { key: 'abstract',name: ' 摘　要 ',            editable: false, resizable: true, formatter:AlignLeft, filterable: true, sortable: true },
					 { key: 'flowName',name: ' 入出金 ',width:  60, editable: false, resizable: true, formatter:AlignRight,filterable: true, sortable: true, filterRenderer: AutoCompleteFilter },
					 { key: 'account', name: ' 金　額 ',width: 100, editable: false, resizable: true, formatter:YenFormat, filterable: true, sortable: true }
				];

		return (
			<div>
				<form>
					<FormGroup disabled>
						<InputGroup>
							<InputGroupText>日付</InputGroupText>
						    <DatePicker className="form-control" selected={startDate} onChange={this.dateChange} locale="ja" dateFormat="yyyy-MM-dd" />
						</InputGroup>
					</FormGroup>
				</form>
				<GridTable title={'レジ集計'} form={form} height={500} 
						tableRows={this.state.tableList} rowGetter={this.rowGetter} length={this.state.tableList.length} getTable={this.getTableList}
						columns={columns} 
						closeButton={this.state.closeButton}
						openAdd={this.openAdd} openRep={this.openRep} openDel={this.openDel}
						closeAdd={this.closeAdd} closeRep={this.closeRep} closeDel={this.closeDel}
						cancel={this.cancel}
						header={
							this.props.salon.receipt_url && <Button onClick={this.onClickPrintCash}>レシート印刷</Button>
						}
				/>
				{this.state.cashTable}
				{this.summary()}

			</div>
		);
	}
}



/* ==============================================================================
　金種表
=============================================================================== */
export class ReadyCash extends Component {

    // コンストラクター
    constructor(props) {
        super(props);

        this.state = { 
            cashTable: <div/>,
            cash:[0,0,0,0,0,0,0,0,0,0],
            openTotal: 0,
            closeTotal: 0,
        };
    }
  
//-----------------------------------------------
// イベント処理関数
//-----------------------------------------------
	// InputにFocusを当てると選択状態にする
	focusSelect = (event) => {
		event.target.select();
	}
	
    openChange = (index,event) =>   {
        if ( event.target.value.length > MAX_NUMBER_LENGTH ) return;
		let coins = event.target.value.replace(/,/g, '');
        let cash = this.state.cash.concat();
		if ( !isNaN(coins) ) cash[index] = coins;
        this.setState({ cash:cash });
        
        // レジ合計金額の計算
        let total = 0;
        // eslint-disable-next-line
        cash.map(function(c,i) {
            total += Number(cash[i]) * cashRate[i];
        })
        this.setState({ openTotal: total});
    }

    open0Change = (event) => { this.openChange(0,event) }
    open1Change = (event) => { this.openChange(1,event) }
    open2Change = (event) => { this.openChange(2,event) }
    open3Change = (event) => { this.openChange(3,event) }
    open4Change = (event) => { this.openChange(4,event) }
    open5Change = (event) => { this.openChange(5,event) }
    open6Change = (event) => { this.openChange(6,event) }
    open7Change = (event) => { this.openChange(7,event) }
    open8Change = (event) => { this.openChange(8,event) }
    open9Change = (event) => { this.openChange(9,event) }

    render = () => {
        var color = {
            backgroundColor: "#e9ecef"
        };
        return (
        	<div>
                <Form >
                    <FormGroup disabled>
                        <InputGroup>
                            <InputGroupText>札・硬貨</InputGroupText>
                            <Input type="text" className="text-center" value={'枚　数'} style={color} readOnly={true} />
                            <Input type="text" className="text-center" value={'金　額'} style={color} readOnly={true} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroupText>一万円札</InputGroupText>
                            <Input type="number" className="text-right" onFocus={this.focusSelect} value={this.state.cash[0]} onChange={this.open0Change} />
                            <Input type="text" className="text-right" value={'¥'+changeYen(this.state.cash[0]*10000)+'円'} style={color} readOnly={true} tabindex={-1} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroupText>五千円札</InputGroupText>
                            <Input type="number" className="text-right" onFocus={this.focusSelect} value={this.state.cash[1]} onChange={this.open1Change} />
                            <Input type="text" className="text-right" value={'¥'+changeYen(this.state.cash[1]*5000)+'円'} style={color} readOnly={true} tabindex={-1} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroupText>二千円札</InputGroupText>
                            <Input type="number" className="text-right" onFocus={this.focusSelect} value={this.state.cash[2]} onChange={this.open2Change} />
                            <Input type="text" className="text-right" value={'¥'+changeYen(this.state.cash[2]*2000)+'円'} style={color} readOnly={true} tabindex={-1} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroupText>　千円札</InputGroupText>
                            <Input type="number" className="text-right" onFocus={this.focusSelect} value={this.state.cash[3] } onChange={this.open3Change} />
                            <Input type="text" className="text-right" value={'¥'+changeYen(this.state.cash[3]*1000)+'円'} style={color} readOnly={true} tabindex={-1} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroupText>　五百円</InputGroupText>
                            <Input type="number" className="text-right" onFocus={this.focusSelect} value={this.state.cash[4] } onChange={this.open4Change} />
                            <Input type="text" className="text-right" value={'¥'+changeYen(this.state.cash[4]*500)+'円'} style={color} readOnly={true} tabindex={-1} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroupText>　　百円</InputGroupText>
                            <Input type="number" className="text-right" onFocus={this.focusSelect} value={this.state.cash[5] } onChange={this.open5Change} />
                            <Input type="text" className="text-right" value={'¥'+changeYen(this.state.cash[5]*100)+'円'} style={color} readOnly={true} tabindex={-1} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroupText>　五十円</InputGroupText>
                            <Input type="number" className="text-right" onFocus={this.focusSelect} value={this.state.cash[6] } onChange={this.open6Change} />
                            <Input type="text" className="text-right" value={'¥'+changeYen(this.state.cash[6]*50)+'円'} style={color} readOnly={true} tabindex={-1} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroupText>　　十円</InputGroupText>
                            <Input type="number" className="text-right" onFocus={this.focusSelect} value={this.state.cash[7] } onChange={this.open7Change} />
                            <Input type="text" className="text-right" value={'¥'+changeYen(this.state.cash[7]*10)+'円'} style={color} readOnly={true} tabindex={-1} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroupText>　　五円</InputGroupText>
                            <Input type="number" className="text-right" onFocus={this.focusSelect} value={this.state.cash[8] } onChange={this.open8Change} />
                            <Input type="text" className="text-right" value={'¥'+changeYen(this.state.cash[8]*5)+'円'} style={color} readOnly={true} tabindex={-1} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroupText>　　一円</InputGroupText>
                            <Input type="number" className="text-right" onFocus={this.focusSelect} value={this.state.cash[9] } onChange={this.open9Change} />
                            <Input type="text" className="text-right" value={'¥'+changeYen(this.state.cash[9])+'円'} style={color} readOnly={true} tabindex={-1} />
                        </InputGroup>
                        <InputGroup>
                            <InputGroupText>合計金額</InputGroupText>
                            <Input type="text" className="text-right" value={'¥'+changeYen(this.state.openTotal)+'円'} style={color} readOnly={true} tabindex={-1} />
                        </InputGroup>
                    </FormGroup>
                </Form>
		    </div>
        );
    }
}

const help = 
	"##### 概要\n" +
	"定期的にレジの現金をカウントし、システム上の現金残高が正確かどうかを確かめる**現金実査**を行う必要がある。" +
	"交通費や消耗品などの雑費をレジから支払う場合は、**現金実査**が煩雑になる。" +
	"そこで、売上以外のレジ入出金を記録して、レジの現金とシステムが計算した現金を一致させる。" + 
	"**レジ入出金**は店舗毎に雑費/雑収入を記録する。  \n" +
	"##### 日付\n" +
	"売上以外の収入や支払が発生した日付。  \n" +
	"##### 追加\n" +
	"現金入出金の項目を追加する。︎  \n" +
	"##### 修正\n" +
	"現金入出金の項目を変更する。︎  \n" +
	"##### 削除\n" +
	"現金入出金の項目を削除する。︎  \n" +
	"##### 現金出納帳\n" +
	"###### 　勘定科目\n" +
	"入出金の種類が表示される。（例：交通費、雑費、消耗品、現金過不足 など）︎  \n" +
	"**勘定科目**の編集は **設定登録**>[勘定科目](./#/accountitem)で行う。  \n" +
	"###### 　摘要\n" +
	"交通費であれば、タクシー/電車/バスや区間を記入する。︎  \n" +
	"雑費であれば、購入した商品などが表示される。  \n" +
	"###### 　入出金\n" +
	"入金または出金が表示される。  \n" +
	"###### 　金額\n" +
	"入金または出金があった金額が表示される。  \n" +
	"";


export default CashCount;
