/* ==============================================================================
 個客情報の編集画面

 履歴-----------------------------------------
 2019/03/17 reactstrap 対応済み

=============================================================================== */

import React, { Component,useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button,InputGroup,InputGroupText,Input,FormGroup,Table } from 'reactstrap';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/ja';
import Select from 'react-select';
import { BsFillSkipBackwardFill,BsFillSkipForwardFill } from "react-icons/bs";
import Zip2Addr from './ZipAddr';

import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ja from 'date-fns/locale/ja';

import { Filters } from 'react-data-grid-addons';
import './Table.css';

// import Request from 'superagent';
// import {JWToken,Alert} from './auth/Login';
import GridTable from './GridTable';
import {RecordMaster} from './Record';
import PostCardPrint from './PostCardPrint';
import Check from './Input';
import { getMasters,getRecord,addRecord,repRecords } from './Agent';

/* ReactDataGrid ---------------------------------------------------------------------------------------- */
import { AlignLeft,AlignRight,AlignNumber,DateFormat } from './Formatter';	// React Data Grid

/* ReactDataGrid ---------------------------------------------------------------------------------------- */

import {
	MAX_DB_LENGTH,
	
	MAX_NAME_LENGTH,
	MAX_KANA_LENGTH,
	MAX_MAIL_LENGTH,
	MAX_PHONE_LENGTH,
	MAX_ZIPCODE_LENGTH,
	MAX_ADDRESS1_LENGTH,
	MAX_ADDRESS2_LENGTH,
	MAX_PASSWORD_LENGTH,
	
	CHECK_NO, CHECK_NAME, CHECK_DUP, CHECK_STAFF,
	COLOR_ERROR, COLOR_OK,
	
	TIMEZONE
} from './Define';


// タイムゾーン
// const TIMEZONE = 'Asia/Tokyo';

const initialRow = {id: 0, tenant_id: 0, no: 0, name: "", kana: "", mail: "",late_days:0, cellula: "", phone: "", zipcode:"", address1: "", address2:"", birthday:null, gender:true, point:0, comment: "", staff_id: 0, password:"", staffname:"" };
const initialStaffStaff = {id: 0, tenant_id: 0, no: 0, name: "", mail: "", phone: "",  workday: 0 };

const masterParam	= [
	{ url: "/customer",		errMsg: "顧客",		list: [initialRow]	},
	{ url: "/staff",		errMag: "担当者",	list: [initialStaffStaff]	}
];

var rowProp = Object.assign({},initialRow);
var condition = 0;			// 画面の入力チェック

const {
	NumericFilter,
	AutoCompleteFilter,
//	MultiSelectFilter,
//	SingleSelectFilter
} = Filters;

		const columns = [
			{ key: 'no',     name: '番号',    width: 80,  resizable: false, formatter:AlignNumber, filterable: true, sortable: true, filterRenderer: NumericFilter, locked: true },
			{ key: 'name',   name: 'お名前',  width: 120, resizable: true,  formatter:AlignLeft,   filterable: true, sortable: true, locked: true },
			{ key: 'kana',   name: 'カナ名',  width: 140, resizable: true,  formatter:AlignLeft,   filterable: true, sortable: true },
			{ key: 'staffname',name: '担当者',width: 120, resizable: true,  formatter:AlignLeft,   filterable: true, sortable: true, filterRenderer: AutoCompleteFilter },
			{ key: 'late_days',name: '経過日数',width:80, resizable: true,  formatter:AlignRight,  filterable: true, sortable: true, filterRenderer: NumericFilter },
			{ key: 'cycle',	 name: '来店周期',width:  80, resizable: true,  formatter:AlignRight,  filterable: true, sortable: true, filterRenderer: NumericFilter },
			{ key: 'point',	 name: 'ポイント',width:  80, resizable: true,  formatter:AlignRight,  filterable: true, sortable: true, filterRenderer: NumericFilter },
//			{ key: 'cellula',name: '携帯番号',width: 140, resizable: true,  formatter:AlignLeft,   filterable: true },
//			{ key: 'phone',  name: '電話番号',width: 140, resizable: true,  formatter:AlignLeft,   filterable: true },
			{ key: 'birthday',name: '誕生日', width: 120, resizable: true,  formatter:DateFormat,  filterable: true, sortable: true  },
//			{ key: 'mail',   name: 'メール',  width: 160, resizable: true,  formatter:AlignLeft,   filterable: true },
			{ key: 'zipcode',name: '〒番号'  ,width: 100,  resizable: true,  formatter:AlignLeft,   filterable: true },
			{ key: 'address1',name: '住所１',             resizable: true,  formatter:AlignLeft },
//			{ key: 'address1',name: '住所１', width: 200, resizable: true,  formatter:AlignLeft },
//			{ key: 'address2',name: '住所２',			  resizable: true,  formatter:AlignLeft },
		];


class Customer extends Component {

	static propTypes = {
		mstNo: PropTypes.number,		// マスター番号に移動する
		onRowSelect: PropTypes.func,	// 行を選択した時にコールされる。引数は顧客オブジェクト
		height: PropTypes.number,		// ダイヤログの高さ
		showCheckbox: PropTypes.bool,	// チェックボックス(マルチセレクト)を表示する／表示しない
		canFilter: PropTypes.bool,		// フィルタリングはDefault無効

	}

	static defaultProps = {
		mstNo: 0,
		onRowSelect: null,
		height: 150,					// ヘッダーの高さ
		showCheckbox: true,				// チェックボックスを表示する／表示しない
		canFilter: false,				// フィルタリングはDefault無効
	}

	// コンストラクター
	constructor(props) {
		super(props);

		this.openMode = 0;

		this.state = {  
			tableList: [ Object.assign({},initialRow) ],
			optionList: [ {id: 0, name: ""} ],	// 担当者のデーター
			rowData: {},						// １行分（顧客１人分：１レコード）のデーター
			selectedRow:[],						// 現在選択中の行(複数行も可能)
			address: [],						// 郵便番号から住所を取得した時の格納エリア
			isSelected: false,
			showModal: false,
//			dropdownAddr: false,
			noStatus: "ＯＫ",
			indexes:	null,
			searchRow:  null,
			buttonDisable: true,  // 一覧選択時の修正ボタン、削除ボタンの設定
			closeButton: false,   // 編集時の登録ボタン
			noColor: '#e9ecef',
			nameColor: '#e9ecef',
			staffColor: '#e9ecef',
		};
		moment.locale('ja');
		moment.tz.setDefault(TIMEZONE);		// timezoneをJSTに変更する

		registerLocale('ja',ja);		// react-datepicker の日本語表記

		this.getTableList();
	}

	// 描画が行われる直前に呼ばれる
	componentDidMount = () => {
//		console.log("componentDidMount");
	}

/* *******************************************************
	// 担当者id で 担当者名を取得する
	lookupStaff(list,staff) {
		list.forEach( function (cust,index) {
			let id = cust.staff_id;
			let sta = staff.find( function (st,index){
				if ( id === st.id ) return true;
				return false;
			});
			if ( sta === undefined ) cust.staffname = '-';
			else cust.staffname = sta.name;
		});
	}
   ******************************************************* */

	// 顧客一覧、担当者一覧を取得する
	getTableList = () => {
// console.log('getTableList()');
		getMasters(masterParam,function () {
			let custList = masterParam[0].list.map( (value,index) => {
				if ( value.times > 1 ) {
					value.cycle = Math.round(value.term / (value.times - 1));
				} else {
					value.cycle = null;
				}
				return { 
					...value
				};
			});
			this.setState({ 
				tableList: custList,
				optionList:masterParam[1].list,
			});	
		}.bind(this));
	}

	// 顧客取得
	getTable = (id) => {
		getRecord(masterParam[0],id,function(rec){
			rowProp = Object.assign({},rec);
			this.setState({rowData: rowProp });
		}.bind(this));		
	}

	// 追加ボタンが押された
	openAdd = () => {
		rowProp = Object.assign({},initialRow);
		if ( this.state.optionList.length !== 0 )
			rowProp.staff_id = this.state.optionList[0].id;	// 担当者は最初の担当者にする
		this.setState({ 
			rowData: rowProp,
			indexes: null,
		});
		condition = 0;
		this.checkParam();
		this.incNo();
	}

	// 修正ボタンが押された
	openRep = (row,index) => {
//		rowProp = Object.assign({},this.state.tableList[index]);
		rowProp = Object.assign({}, row[index]);
		if ( rowProp.comment !== null && rowProp.comment !== undefined ) {
			rowProp.comment = rowProp.comment.replace(/\\n/g,'\n');		// reactは'\n'の処理を行ってくれない？ので自分で改行した
		}
		this.setState({ 
			rowData: rowProp,
			indexes: null,
		});
		condition = 0;
		this.checkParam();
	}

	// 削除ボタンが押された
	openDel = ( row,indexes ) => {
		this.setState({
			rowData:	null,				
			indexes:	indexes,
			searchRow:	row,
			closeButton: true
		});
	}

	// 追加でクローズボタンが押された
	closeAdd = () => {
		delete rowProp.cycle;
		masterParam[0].list = new Array(rowProp);
		addRecord(masterParam[0],function (record) {
			this.getTableList();
		}.bind(this));
	}

	// 修正でクローズボタンが押された
	closeRep = () => {
// console.log('putCustomer()',rowProp);		
		delete rowProp.cycle;
		masterParam[0].list = new Array(rowProp);
		repRecords(masterParam[0], function (){
			this.getTableList();
		}.bind(this));
	}

	// 削除でクローズボタンが押された
	closeDel = () => {
		
		// 実際は削除せずに大きな番号を付与する
		let row = this.state.indexes.map((value,index)=>{
			let table = this.state.searchRow[value];
			return { 
				id: table.id,
				no: table.id + MAX_DB_LENGTH,
			};
		});
		masterParam[0].list = row;
		repRecords(masterParam[0], function (record){
			this.getTableList();
		}.bind(this));
	}


	cancel = () => {
	}

	closeButton = (bool) => {
		this.setState({closeButton: bool });
	}

	selectedRow = (rows) => {
		this.setState({
			selectedRow:rows
		});
	}

	// DBに登録されている番号か検索する
	checkNo = ( id,no ) => {
		let res = this.state.tableList.find( function (row,index){
				// eslint-disable-next-line
				if ( id == row.id ) return false;
				// eslint-disable-next-line
				if ( no == row.no ) return true;
				return false;
			});

		if ( res === undefined ) return true;
		return false;
	}


//-----------------------------------------------
// イベント処理関数
//-----------------------------------------------
	noChange = (event) => {
		
		let id    = rowProp.id;					// 編集中のid 追加時は0
		let newNo = event.target.value;     	// 入力した番号
		rowProp.no = newNo;
		
		let res = this.checkNo(id,newNo);		// 番号を検索する
		if ( res === true ) {
			this.setState( { 
				rowData: rowProp,
				noStatus: "ＯＫ" ,
			});
			condition = condition & ~CHECK_DUP;
		} else {
			this.setState( { 
				rowData: rowProp,
				noStatus: "重複" ,
			});
			condition = condition | CHECK_DUP;
		}
		
		this.checkParam();
	}

	decNo = () => {
		let no = Number(rowProp.no);
		if ( no > 1 ) no--;
		let i = this.state.tableList.findIndex( cust =>  cust.no === no );
		if ( i !== -1 ) {
			for ( i-- ; i >= 0 ; i-- ) {
				if ( this.state.tableList[i].no !== --no) {
					rowProp.no = no;
					this.setState( { 
						rowData: rowProp,
						noColor:   COLOR_OK,
						noStatus: "ＯＫ" ,
					});
					condition = condition & ~CHECK_DUP;
					return;
				}
			}
			no--;
		}
		if ( no > 0 ) {
			rowProp.no = no;
			this.setState({
				rowData: rowProp,
				noColor:   COLOR_OK,
				noStatus: "ＯＫ" ,
			});
			condition = condition & ~CHECK_DUP;
		}
	}

	incNo = () => {
		let no = Number(rowProp.no) + 1;
		let i = this.state.tableList.findIndex( cust =>  cust.no === no );
		if ( i !== -1 ) {
			let len = this.state.tableList.length;
			for ( i++ ; i < len ; i++ ) {
				if ( this.state.tableList[i].no !== ++no) {
					rowProp.no = no;
					this.setState( { 
						rowData: rowProp,
						noColor:   COLOR_OK,
						noStatus: "ＯＫ" ,
					});
					condition = condition & ~CHECK_DUP;
					return;
				}
			}
			no++;
		}
		rowProp.no = no;
		this.setState( { 
			rowData: rowProp,
			noColor:   COLOR_OK,
			noStatus: "ＯＫ" ,
		});
		condition = condition & ~CHECK_DUP;
	}

	// InputにFocusをあてると選択状態にする
	focusSelect = (event) => {
		event.target.select();
	}
	
	nameChange = (event) => {
		if ( event.target.value.length > MAX_NAME_LENGTH ) return;
		rowProp.name    = event.target.value;
		this.setState({ rowData:rowProp });

		this.checkParam();
	}
	
	kanaChange = (event) => {
		if ( event.target.value.length > MAX_KANA_LENGTH ) return;
		rowProp.kana    = event.target.value;
		this.setState({ rowData:rowProp });
	}
	mailChange = (event) => {
		if ( event.target.value.length > MAX_MAIL_LENGTH ) return;
		if ( Check.email(event.target.value) === false ) return;
		rowProp.mail = event.target.value;
		this.setState({ rowData:rowProp });
	}
	genderChange = (option) => {
// console.log('genderChange', option);
		rowProp.gender = option.value === 1 ? true : false;		// 女性：true、男性：false
		this.setState({ rowData:rowProp });
// 		this.props.onEdit(customer,condition);					// 親Classにcustomerを渡す
	}
	cellulaChange = (event) => {
		if ( event.target.value.length > MAX_PHONE_LENGTH ) return;
		if ( Check.phone(event.target.value) === false ) return;
		rowProp.cellula   = event.target.value;
		this.setState({ rowData:rowProp });
	}
	phoneChange = (event) => {
		if ( event.target.value.length > MAX_PHONE_LENGTH ) return;
		if ( Check.phone(event.target.value) === false ) return;
		rowProp.phone   = event.target.value;
		this.setState({ rowData:rowProp });
	}
/***********************************************
	staffChange = (event) => {
		rowProp.staff_id= event.target.value;
		this.setState({ rowData:rowProp });
	}
***********************************************/
	staffChange = (option) => {
		rowProp.staff_id= option.value;
		this.setState({ rowData:rowProp });
		this.checkParam();
//		this.props.onEdit(rowProp,condition);					// 親Classにcustomerを渡す
	}

	zipcodeChange = (event) => {
		if ( event.target.value.length > MAX_ZIPCODE_LENGTH ) return;
		if ( Check.postCode(event.target.value) === false ) return;
		rowProp.zipcode= event.target.value;
		this.setState({ rowData:rowProp });
	}

	// 郵便番号から住所を取得する Zip2Addrのコールバック関数
	setAddress = (pref, city, suburb) => {
		rowProp.address1 = pref + city + suburb;
		this.setState({rowData:rowProp});
	}

	birthdayChange = (date) => {
		if ( date === "" ) {
			rowProp.birthday = null;
		} else {
			rowProp.birthday = moment(date).format("YYYY-MM-DD HH:mm");
		}
		this.setState({ rowData:rowProp });
	}

	age = (value) => {
		var d = new Date(value);
		if ( d.toString() === "Invalid Date" ) return '';
		if ( value === undefined ) return '';
		if ( value === null ) return '';
		if ( value === ""   ) return '';

		var birth = new Date(value);	//誕生日 年月日
		var today = new Date();			//今日

		//今年の誕生日
		var thisYearBirthday = new Date(today.getFullYear(), birth.getMonth(), birth.getDate());  

		var age = today.getFullYear() - birth.getFullYear();	//今年-誕生年
		
		if ( age < 0 ) return '';
		
		//今年の誕生日を迎えていなければage-1を返す
		return (today < thisYearBirthday)?age-1:age;
	}
	
	address1Change = (event) => {
		if ( event.target.value.length > MAX_ADDRESS1_LENGTH ) return;
		rowProp.address1 = event.target.value;
		this.setState({ rowData:rowProp });
	}
	address2Change = (event) => {
		if ( event.target.value.length > MAX_ADDRESS2_LENGTH ) return;
		rowProp.address2 = event.target.value;
		this.setState({ rowData:rowProp });
	}
	commentChange = (event) => { rowProp.comment  = event.target.value; this.setState({ rowData:rowProp }); }

	pointChange = (event) => {
		rowProp.point = Number(event.target.value);
		console.log(rowProp.point);
		this.setState({ rowData:rowProp });
	}

	passwordChange = (event) => {
		if ( event.target.value.length > MAX_PASSWORD_LENGTH ) return;
		rowProp.password = event.target.value;
		this.setState({ rowData:rowProp });
	}

	toggle = () => {
		if ( this.state.showModal === true ) {
			this.cancel();
			this.setState( { showModal: false } );
		} else {
			this.setState( { showModal: true } );
		}
	}

	checkParam = () => {
		// 番号をチェック
//		console.log('Customer checkParam',rowProp);
		switch ( rowProp.no ) {
			case undefined:
			case 0:
			case "":
			case "0":
			case null:
				condition = condition | CHECK_NO;				// 実行ボタンを無効にする
				this.setState( { noStatus: "ＮＧ" });
				break;
			default:
				condition = condition & (~CHECK_NO);			// 実行ボタンを有効にする
				break;
		}

		// 名前をチェック
		switch ( rowProp.name ) {
			case undefined:
			case "":
			case null:
				condition = condition | CHECK_NAME;		// 実行ボタンを無効にする
				break;
			default:
				condition = condition & (~CHECK_NAME);	// 実行ボタンを有効にする
				break;
		}

		// 担当者をチェック
		switch ( rowProp.staff_id ) {
			case undefined:
			case 0:
			case null:
				condition = condition | CHECK_STAFF;		// 実行ボタンを無効にする
				break;
			default:
				condition = condition & (~CHECK_STAFF);	// 実行ボタンを有効にする
				break;
		}

		// ボタン、バックグランドカラーの設定
		if ( condition === 0) {							// 一つでもエラーがあればボタンを無効にする
			this.setState({
				closeButton: true,
				noColor:    COLOR_OK,
				nameColor:  COLOR_OK,
				staffColor: COLOR_OK,
				noStatus: "ＯＫ"
			});
		} else {
			this.setState({	closeButton: false });
			if ( (condition & (CHECK_NO | CHECK_DUP)) === 0 ) {
				this.setState({
					noColor: COLOR_OK,
					noStatus: "ＯＫ"
				});
			} else {
				this.setState({	noColor: COLOR_ERROR });
			}
	
			if ( (condition & CHECK_NAME) === 0 ) {
				this.setState({	nameColor: COLOR_OK	});
			} else {
				this.setState({	nameColor: COLOR_ERROR });
			}

			if ( (condition & CHECK_STAFF) === 0 ) {
				this.setState({	staffColor: COLOR_OK	});
			} else {
				this.setState({	staffColor: COLOR_ERROR });
			}

		}
	}


	render() {
			
		var form = <div></div>;
		
		if ( !this.state.indexes ) {

			// 誕生日
			var startDate = moment(this.state.rowData.birthday).valueOf();
	
			// スタッフの設定
			let list = this.state.optionList.filter( (staff) => {		// 削除された番号は表示しない
				return staff.no < MAX_DB_LENGTH;
			});
			const options = list.map( (staff) => {
				return { value: staff.id, label:staff.no + ' ' + staff.name};
			});
			var staff = options.find( (option) => {
				return option.value === this.state.rowData.staff_id;
			});
			
			const genderOption = [
				{ value: 1,  label:'女性'}, // true
				{ value: 0,  label:'男性'}	// false
			];
			var gender = this.state.rowData.gender ? genderOption[0]:genderOption[1];
	
	
			form = 
			<form>
				<FormGroup disabled>
					<InputGroup>
						<InputGroupText>番　号</InputGroupText>
						<Button onClick={this.decNo}><BsFillSkipBackwardFill/></Button>
						<Input type="number" style={{backgroundColor: this.state.noColor}} placeholder="番号" value={this.state.rowData.no} onFocus={this.focusSelect} onChange={this.noChange} autoFocus={true}/>
						<Button onClick={this.incNo}><BsFillSkipForwardFill/></Button>
						<InputGroupText>{this.state.noStatus}</InputGroupText>
{ /*						<RecordMaster customer={this.state.rowData}/> */ }
					</InputGroup>
<div class="row" style={{margin: '0px'}}>
  <div class="col-6" style={{padding: '0px'}}>

					<InputGroup>
						<InputGroupText>名　前</InputGroupText>
						<Input type="text" style={{backgroundColor: this.state.nameColor,inputmode:'kana-name'}} placeholder="名前（20文字以内）" value={this.state.rowData.name} onChange={this.nameChange} />
					</InputGroup>
  </div>
  <div class="col-6" style={{padding: '0px'}}>
					<InputGroup>
						<InputGroupText>カ　ナ</InputGroupText>
						<Input type="text" style={{inputmode:'katakana'}} placeholder="カナ（20文字以内）" value={this.state.rowData.kana} onChange={this.kanaChange} />
					</InputGroup>
  </div>
</div>

<div class="row" style={{margin: '0px'}}>
  <div class="col-6" style={{padding: '0px'}}>
					<InputGroup>
						<InputGroupText>性　別</InputGroupText>

						<Select className="form-control react-select-original" styles={{ menu: provided => ({ ...provided, zIndex: 100 }) }}
								placeholder="性別" value={gender} options={genderOption} onChange={this.genderChange} />
{/*
						<Input type="select" placeholder="性別" value={this.state.rowData.gender} onChange={this.genderChange}  readOnly={this.state.readOnly}>
							<option value={true} key={0}>{"女性"}</option>
							<option value={false} key={1}>{"男性"}</option>
						</Input>
*/}
					</InputGroup>
  </div>
  <div class="col-6" style={{padding: '0px'}}>
					<InputGroup>
						<InputGroupText>担当者</InputGroupText>
						<Select className="form-control react-select-original" styles={{ menu: provided => ({ ...provided, zIndex: 100 }) , control: (provided, state) => ({ ...provided,backgroundColor:this.state.staffColor }) }}
								placeholder="担当者名" value={staff} options={options} onChange={this.staffChange} />
					</InputGroup>
  </div>
</div>

<div class="row" style={{margin: '0px'}}>
  <div class="col-6" style={{padding: '0px'}}>
					<InputGroup>
						<InputGroupText>携　帯</InputGroupText>
						<Input type="tel" placeholder="電話番号（15文字）" value={this.state.rowData.cellula} onChange={this.cellulaChange} />
					</InputGroup>
  </div>
  <div class="col-6" style={{padding: '0px'}}>
					<InputGroup>
						<InputGroupText>電　話</InputGroupText>
						<Input type="tel" placeholder="電話番号（15文字）" value={this.state.rowData.phone} onChange={this.phoneChange} />
					</InputGroup>
  </div>
</div>

					<InputGroup>
						<InputGroupText>メール</InputGroupText>
						<Input type="email" placeholder="メール（128文字以内）" value={this.state.rowData.mail} onChange={this.mailChange} />
					</InputGroup>
					<InputGroup>
						<InputGroupText>誕生日</InputGroupText>
						<DatePicker className="form-control" selected={startDate} onChange={this.birthdayChange} placeholderText="お誕生日"
								dateFormat="yyyy-MM-dd"  isClearable readOnly={this.state.readOnly} />
						<InputGroupText>{this.age(this.state.rowData.birthday)+ '才'}</InputGroupText>
					</InputGroup>
					<InputGroup>
						<InputGroupText>〒番号</InputGroupText>
						<Input type="text" placeholder="郵便番号" value={this.state.rowData.zipcode} onChange={this.zipcodeChange} />
						<Zip2Addr zipcode={this.state.rowData.zipcode} callBack={this.setAddress}/>
{/*
				        <ButtonDropdown isOpen={this.state.dropdownAddr} toggle={this.toggleAddr}>
				            <DropdownToggle caret>住所検索</DropdownToggle>
    						<DropdownMenu>
					            <DropdownItem header>以下から町域を選択してください</DropdownItem>
					            <DropdownItem divider />
								{
									this.state.address.map(function (item) {
										return <DropdownItem>{item.address}</DropdownItem>;
//										return <DropdownItem>{item}</DropdownItem>;
									})
								}
				        	</DropdownMenu>
				        </ButtonDropdown>
*/}
					</InputGroup>
					<InputGroup>
						<InputGroupText>住所１</InputGroupText>
						<Input type="text" placeholder="住所（100文字以内）" value={this.state.rowData.address1} onChange={this.address1Change} />
					</InputGroup>
					<InputGroup>
					
						<InputGroupText>住所２</InputGroupText>
						<Input type="text" placeholder="住所（100文字以内）" value={this.state.rowData.address2} onChange={this.address2Change} />
					</InputGroup>
					<InputGroup>
						<InputGroupText>備　考</InputGroupText>
						<Input type="textarea" style={{height: '54px' ,  whiteSpace: 'pre-line' }} placeholder="コメント" value={this.state.rowData.comment} onChange={this.commentChange} />
					</InputGroup>
					<InputGroup>
						<InputGroupText>ポイント　</InputGroupText>
						<Input type="number" placeholder="ポイント" value={this.state.rowData.point} onChange={this.pointChange} onFocus={this.focusSelect} />
					</InputGroup>
					<InputGroup>
						<InputGroupText>パスワード</InputGroupText>
						<Input type="password" placeholder="パスワード（6文字以上）" value={this.state.rowData.password} onChange={this.passwordChange} />
					</InputGroup>
				</FormGroup>
			</form>;
		} else {
			let rowData = this.state.indexes.map(function(element, index, array) {
	    		return Object.assign({},this.state.searchRow[element]);
			}.bind(this));
			
			// rowDataのを番号順にソート
			rowData.sort(function(a,b){
				return a.no - b.no;	
			});

			form =
				<form>
					以下のお客様を削除します。<br/>
					<Table bordered hover size={"sm"} >
						 <thead bgcolor="#ebf0f7">
							<tr>
								<th><div className="text-center">番号</div></th>
								<th>お名前</th>
							</tr>
						</thead>
						<tbody>
							{
								rowData.map(function(value, index, array) {
									return (
										<tr key={index}>
											<td ><div className="text-center">{value.no  }</div></td>
											<td ><div className="text-left"  >{value.name}</div></td>
										</tr>
									);
								})
							}
						</tbody>
					</Table>
				</form>;
		}

/* classの外部へ=====================================================================================================================================================
		const columns = [
			{ key: 'no',     name: '番号',    width: 80,  resizable: false, formatter:AlignNumber, filterable: true, sortable: true, filterRenderer: NumericFilter, locked: true },
			{ key: 'name',   name: 'お名前',  width: 120, resizable: true,  formatter:AlignLeft,   filterable: true, sortable: true, locked: true },
			{ key: 'kana',   name: 'カナ名',  width: 140, resizable: true,  formatter:AlignLeft,   filterable: true, sortable: true },
			{ key: 'staffname',name: '担当者',width: 120, resizable: true,  formatter:AlignLeft,   filterable: true, sortable: true, filterRenderer: AutoCompleteFilter },
			{ key: 'late_days',name: '経過日数',width:80, resizable: true,  formatter:AlignRight,  filterable: true, sortable: true, filterRenderer: NumericFilter },
			{ key: 'point',	 name: 'ポイント',width:  80, resizable: true,  formatter:AlignRight,  filterable: true, sortable: true, filterRenderer: NumericFilter },
			{ key: 'cellula',name: '携帯番号',width: 140, resizable: true,  formatter:AlignLeft,   filterable: true },
			{ key: 'phone',  name: '電話番号',width: 140, resizable: true,  formatter:AlignLeft,   filterable: true },
			{ key: 'birthday',name: '誕生日', width: 110, resizable: true,  formatter:DateFormat,  filterable: true, sortable: true  },
			{ key: 'mail',   name: 'メール',  width: 160, resizable: true,  formatter:AlignLeft,   filterable: true },
			{ key: 'zipcode',name: '〒番号'  ,width: 90,  resizable: true,  formatter:AlignLeft,   filterable: true },
			{ key: 'address1',name: '住所１', width: 200, resizable: true,  formatter:AlignLeft },
			{ key: 'address2',name: '住所２',			  resizable: true,  formatter:AlignLeft },
		];
=================================================================================================================================================================== */

		return (
			<GridTable title={'お客様'} form={form}
					tableRows={this.state.tableList} length={this.state.tableList.length} getTable={this.getTableList}
					columns={columns} onSelect={this.selectedRow}
					header={<PostCardPrint customerList={this.state.selectedRow} staffList={this.state.optionList} />}
//					header={<table><tr><td><PostCardPrint customerList={this.state.selectedRow}/></td><td><RecordMaster customer={this.state.rowData}/></td></tr></table>}
					closeButton={this.state.closeButton}
					openAdd={this.openAdd} openRep={this.openRep} openDel={this.openDel}
					closeAdd={this.closeAdd} closeRep={this.closeRep} closeDel={this.closeDel}
					cancel={this.cancel}
					onRowSelect={this.props.onRowSelect}
					showCheckbox={this.props.showCheckbox}
					canFilter={this.props.canFilter}
					mstNo={Number(this.props.mstNo)}
					height={this.props.height}
					footerButton={ !this.state.indexes && <RecordMaster customer={this.state.rowData}/>}
					help={help} help1={help1}
			/>

		);
	}
}

export const CustomerFilter = (props) => {
	const {
		mstNo = 0,				// マスター番号に移動する
		onRowSelect = null,		// 行を選択した時にコールされる。引数は顧客オブジェクト
		height = 150,			// ダイヤログの高さ
		showCheckbox = true,	// チェックボックス(マルチセレクト)を表示する／表示しない
		canFilter = false,		// フィルタリングはDefault無効
		customerList
	} = props;

	const [tableList,	setTableList	] = useState( [initialRow] );

	const cancel   = () => {	};
//	const closeButton  = () => {	};
	const getTableList = () => {
		console.log('getTableList(######)')	;
		setTableList(customerList);
	};
	const rowSelect    = (row) => {
		if ( onRowSelect !== null ) onRowSelect(row);
	};
	const form = <div></div>;
	
	useEffect(() => {
		setTableList(customerList);
	// eslint-disable-next-line
	},[customerList]);

	return(
		<GridTable title={'お客様'} form={form}
				tableRows={tableList} length={tableList.length} getTable={getTableList}
				columns={columns} 
				buttonAdd={null} buttonRep={null} buttonDel={null} 
				closeButton={false}
				cancel={cancel}
				onRowSelect={rowSelect}
				showCheckbox={showCheckbox}
				canFilter={canFilter}
				mstNo={Number(mstNo)}
				height={height}
				help={help} help1={help1}
		/>
	);
};


const help = 
	"##### 概要\n" +
	"お客様の一覧が番号順に表示されます。  \n" +
	"お客様の追加・修正・削除・検索・はがきの印刷を行います。  \n" +
	"##### 追加\n" +
	"お客様の追加画面が表示されます。  \n" +
	"##### 修正\n" +
	"現在選択されているお客様の編集画面が表示されます。  \n" +
	"##### 削除\n" +
	"現在選択されているお客様の削除画面が表示されます。  \n" +
	"##### はがき\n" +
	"現在表示されているお客様の宛名をはがきに印刷します。  \n" +
	"下記の検索で表示件数を絞り込みます。  \n" +
	"##### 検索・件数\n" +
	"お客様の表示を絞り込む時に使用します。  \n" +
	"###### 　数値項目\n" + 
	"数値指定：「3,5,6」は３と５と６のお客様が表示されます。  \n" +
	"範囲指定：「4-8」は4,5,6,7,8のお客様が表示されます。  \n" +
	"大小指定：「<10」は10以下のお客様が表示されます。  \n" +
	"例えば、「3,10-15,>20」は 3,10,11,12,13,14,15 及び20以上のお客様が表示されます。  \n" +
	"１ヶ月以内に来店されたお客様を検索したい場合、**検索**をタップして**経過日数**の下に「<30」と入力します。  \n" +
	"###### 　文字項目\n" +
	"入力した文字を含むお客様だけが表示されます。  \n" +
	"誕生月が6月のお客様を検索する場合は**検索**をタップして、**誕生日**の下に「-06-」と入力します。  \n" +
	"**郵便番号**が入力されている事を確認する場合は、**郵便番号**の下に「-」と入力します。  \n" +
	"###### 　選択項目\n" +
	"複数選択可能です。  \n" +
	"##### 表示順\n" +
	"お客様一覧のタイトル（項目）をタップすると表示順を変更できます。" +
	"番号順 →  項目の昇順 → 項目の降順 がトグル表示されます。  \n" +
	"###### 　番号\n" +
	"お客様の番号が表示されます。(重複不可)  \n" +
	"###### 　お名前・カナ名\n" +
	"お客様の**お名前**が表示されます。  \n" +
	"###### 　担当者\n" +
	"お客様の**担当者**が表示されます。  \n" +
	"###### 　経過日数\n" +
	"お客様が最後に来店してから今日までの経過日数が表示されます。  \n" +
	"###### 　携帯・電話\n" +
	"お客様の**電話番号**が表示されます。  \n" +
	"###### 　誕生日\n" +
	"お客様の**誕生日**が表示されます。  \n" +
	"年を省略した場合は9999と表示されます。  \n" +
	"###### 　メール\n" +
	"お客様の**メールアドレス**が表示されます。  \n" +
	"###### 　〒番号・住所１・住所２\n" +
	"お客様の**住所**が表示されます。  \n" +
	"";


const help1 = 
	"##### 概要\n" +
	"お客様の情報を表示/編集します。  \n" +
	"###### 番号\n" +
	"お客様に一意の番号を表示/編集できます。(重複不可)  \n" +
	"**←　→** :表示している番号から重複していない番号を検索します。  \n" +
	"###### 名前・カナ\n" +
	"お客様の**お名前**を表示/編集できます。  \n" +
	"###### 性別\n" +
	"お客様の**性別**を表示/選択できます。  \n" +
	"###### 担当者\n" +
	"お客様の**担当者**を表示/選択できます。  \n" +
	"###### 携帯・電話\n" +
	"お客様の**電話番号**を表示/編集できます。  \n" +
	"###### メール\n" +
	"お客様の**メールアドレス**を表示/編集できます。  \n" +
	"###### 誕生日/年齢\n" +
	"お客様の**誕生日**を表示/編集できます。  \n" +
	"年を省略する場合は9999を入力して下さい。  \n" +
	"###### 郵便番号/住所検索/住所１/住所２\n" +
	"お客様の**住所**を表示/編集できます。  \n" +
	"**郵便番号**を入力後に**住所検索**ボタンを押すと住所１に自動入力されます。" +
	"１つの郵便番号に複数の住所が対応している場合はドロップダウン表示され、住所を選択できます。  \n" +
	"**住所検索**せずに住所１を入力する事も可能です。" +
	"住所１以外を住所２に入力できます。  \n" +
	"###### 備考\n" +
	"お客様の**メモ**を表示/編集できます。  \n" +
	"###### ポイント\n" +
	"お客様の**ポイント**を表示/編集できます。  \n" +
	"##### 来店履歴\n" +
	"お客様の**来店履歴**を表示します。  \n" +
	"";


export default Customer;
