/* ==============================================================================
 勤怠管理 編集画面

 履歴-----------------------------------------
 2020/07/21 新規作成

=============================================================================== */
import React, { Component } from 'react';
import { InputGroup,InputGroupText,FormGroup,Input,Button,
			Modal,ModalHeader,ModalBody,ModalFooter} from 'reactstrap';
import Select from 'react-select';

import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/ja';
import Request from 'superagent';

import Clock from 'react-live-clock';

import {JWToken,LocalStorage} from './auth/Login';
import GridTable from './GridTable';
import { AlignLeft,AlignCenter,TimeHMFormat } from './Formatter';	// React Data Grid
import { Filters } from 'react-data-grid-addons';
import Roster    from './Roster';			// 勤務表
import MonthRoster from './MonthRoster';	// 月間勤務表
import './Table.css';

import {
	MAX_DB_LENGTH,
	// タイムゾーン
	TIMEZONE
} from './Define';

// 0:他店舗、1:勤怠届、1:作成、2:確定、4:出勤、5:退勤
import {
//	WORK_OTHER,			// 他店舗
//	WORK_REQUEST,		// 勤怠届
//	WORK_CREATE,		// 作成
//	WORK_RELEASE,		// 確定　予約開始
	WORK_ARRIVE,		// 出勤
	WORK_LEAVE,			// 退勤
} from './Define';

const	ADD_STATE = 1;
const	REP_STATE = 2;
const	DEL_STATE = 3;

//const initialRow = {id: 0, tenant_id: 0, no: 0, name: "", mail: "", phone: "",  workday: 0 };
const initialWorkHour = { tenant_id: 0, staff_id: 0, work_date: "2020-01-01", day_off_id: 0, punch_start: "00:01", punch_quit: "00:01", start: "00:01", quit: "00:01", break_time: "01:00", abstract: "" };

var rowProp =  Object.assign({},initialWorkHour);

const {
	NumericFilter,
	AutoCompleteFilter,
//	MultiSelectFilter,
//	SingleSelectFilter
} = Filters;

/*****
var	defaultScheduleList=[ 
	{ salon_id: 0, staff_id: 0, day_of_week: 0, work_flag: true,  start_time: "10:00:00",  end_time: "19:00:00" },
	{ salon_id: 0, staff_id: 0, day_of_week: 1, work_flag: false, start_time: "00:00:00",  end_time: "00:00:00" },
	{ salon_id: 0, staff_id: 0, day_of_week: 2, work_flag: false, start_time: "00:00:00",  end_time: "00:00:00" },
	{ salon_id: 0, staff_id: 0, day_of_week: 3, work_flag: true,  start_time: "10:00:00",  end_time: "19:00:00" },
	{ salon_id: 0, staff_id: 0, day_of_week: 4, work_flag: true,  start_time: "10:00:00",  end_time: "19:00:00" },
	{ salon_id: 0, staff_id: 0, day_of_week: 5, work_flag: true,  start_time: "10:00:00",  end_time: "19:00:00" },
	{ salon_id: 0, staff_id: 0, day_of_week: 6, work_flag: true,  start_time: "10:00:00",  end_time: "19:00:00" }
];
*****/

function time2int(time) {
	if ( !time ) return 0;
    let t = time.split(':');
    return Number(t[0]) * 60 + Number(t[1]);
}

/***
function int2time(i) {
    return [ ( '00' + parseInt(i / 60, 10 ) ).slice(-2) , ( '00' + i % 60).slice(-2) , '00'].join(":");
}
***/

class WorkHour extends Component {

	// コンストラクター
	constructor(props) {
		super(props);
		this.state = {  
			tableList: [ Object.assign({},initialWorkHour) ],
			rowData:    Object.assign({},initialWorkHour),
			optionList:[ {id: 0, no: 0, name: "", description: ""}],
			tenant: {},
			readOnly: "",         // 削除時は各項目を編集付加に設定
			closeButton: true,
			noColor: '#e9ecef',
			nameColor: '#e9ecef',
			start: moment(new Date()).set('date', 1).format("YYYY-MM-DD"),									// １ヶ月の始まり
			end:   moment(new Date()).set('date', 1).add(1, 'months').add(-1, 'days').format("YYYY-MM-DD"),	// １ヶ月の終わり
			showModal: false,		// 勤務表の編集（１週間）
			state: ADD_STATE,

			indexes:	null,
			searchRow:  null,

			showModalRoster: false,
			title: '月間勤怠表',
			chart: <div/>

		};

		moment.locale('ja');
		moment.tz.setDefault(TIMEZONE);		// timezoneをJSTに変更する

		this.getTableList();
		this.getTenant(JWToken.getToken().tenant_id);
	}



	// 勤怠届id で勤怠届名称を取得する。担当者id を staff_id に代入する。
	lookupDayOff = (list,dayoff) => {
		let staff = list.filter( (l) => {
			if ( l.id === 0 || l.no >= MAX_DB_LENGTH ) return false;
			let r = dayoff.find( (d) => d.id === l.day_off_id );
			if ( r )  l.dayOffName = r.name;
			else	  l.dayOffName = '-';
			l.staff_id = l.id;
			return true;
		});
		return staff;
	}

	// 勤怠一覧を取得する
	getTableList = () => {
		//ajax通信する
		Request.get("/workhour/" + moment(new Date()).format("YYYY-MM-DD") )
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.log(err);
					console.log(res);
					console.error("勤怠一覧を取得できませんでした。");
					return;
				}
				this.getDayOffList(res.body.dbData);
//				this.setState( { tableList: res.body.dbData } );
//				console.log("WorkHour Read ",res.body.dbData);
			}.bind(this));
	}


	// 勤怠届一覧を取得する
	getDayOffList = (list) => {
		//ajax通信する
		Request.get("/day_off_item")
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if ( err ) {
					console.log(err);
					console.log(res);
					console.error("勤怠届一覧を取得できませんでした");
					return;
				}
//          console.log(res);
				this.setState( { optionList: res.body.dbData } );
				this.setState({ tableList: this.lookupDayOff(list,res.body.dbData) });
			}.bind(this));
	}


	// テナント情報を取得する
	getTenant = (id) => {
		//ajax通信する
		Request.get("/tenant/" + id )
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.log(err);
					console.log(res);
					console.error("テナント情報を取得できませんでした。");
					return;
				}
				
				this.setState( { tenant: res.body.dbData[0] } );
			}.bind(this));
	}


	// 出勤時の打刻
	// pounch_start,pounch_quitはサーバーが時刻を入れる
	getTableStart = (id) => {
		//ajax通信する
		Request.get("/workhour/start/" + id)
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.log(err);
					console.log(res);
					console.error("出勤時の勤怠データを取得できませんでした。");
					return;
				}
				rowProp = Object.assign({},res.body.dbData[0]);

				// 出勤時間の丸め処理
				let time = rowProp.start.split(':');
				time[2] = '00';
				time[1] = ( '00' + (Number(time[1]) + 1) ).slice( -2 );
				if ( time[1] >= 60 ) {
					time[1] = '00';
					time[0] = ( '00' + (Number(time[0]) + 1) ).slice( -2 );
					if ( time[0] >= 24 ) time[0] = '00';
				}
				rowProp.start = time.join(":");

				// 出勤時刻前の出勤は残業とするか？
				if ( this.state.tenant.before_work === 0 ) {
					if ( rowProp.start < this.state.tenant.start_time ) {
						rowProp.start = this.state.tenant.start_time;
					}
				}
				
				// 休憩時間がnullなら初期値をいれる
				if ( !rowProp.break_time ) {
					rowProp.break_time = this.state.tenant.break_time;
				}
				
				// シフト開始・終了をセットする
				if ( !rowProp.shift_start )  rowProp.shift_start = this.state.tenant.start_time;
				if ( !rowProp.shift_quit  )  rowProp.shift_quit  = this.state.tenant.quit_time;

				// 勤怠届の初期値を入力する
console.log(this.state.optionList[0].id);
				if ( !rowProp.day_off_id ) rowProp.day_off_id = this.state.optionList[0].id;
				
				const storeItem = LocalStorage.getItem();			// 本体に保存している店舗ID
				rowProp.salon_id = storeItem.salon_id;
console.log(storeItem.salon_id);
				rowProp.status = WORK_ARRIVE;

				this.setState({rowData: rowProp });
			}.bind(this));
	}
	
	// 退勤時の打刻
	// pounch_start,pounch_quitはサーバーが時刻を入れる
	getTableQuit = (id) => {
		//ajax通信する
		Request.get("/workhour/quit/" + id)
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.log(err);
					console.log(res);
					console.error("退勤時の勤怠データを取得できませんでした。");
					return;
				}
				rowProp = Object.assign({},res.body.dbData[0]);

				// 出勤時間の丸め処理
				let time = rowProp.quit.split(':');
				time[2] = '00';
				rowProp.quit = time.join(":");
				
				// 勤務時間が6時間未満の場合は、休憩を0時間にする
				if ( time2int(rowProp.spreadover) < time2int('06:00:00') ) {
					rowProp.break_time = '00:00:00';
				}

				// シフト開始・終了をセットする
				if ( !rowProp.shift_start )  rowProp.shift_start = this.state.tenant.start_time;
				if ( !rowProp.shift_quit  )  rowProp.shift_quit  = this.state.tenant.quit_time;

				// 勤怠届の初期値を入力する
console.log(this.state.optionList[0].id);
				if ( !rowProp.day_off_id ) rowProp.day_off_id = this.state.optionList[0].id;

				const storeItem = LocalStorage.getItem();			// 本体に保存している店舗ID
				rowProp.salon_id = storeItem.salon_id;
console.log(storeItem.salon_id);
				
				rowProp.status = WORK_LEAVE;

				this.setState({rowData: rowProp });
			}.bind(this));
	}
	
	// 編集時の打刻
	getTableEdit = (id) => {
		//ajax通信する
		Request.get("/workhour/edit/" + id)
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.log(err);
					console.log(res);
					console.error("出退勤データを取得できませんでした。");
					return;
				}
				rowProp = Object.assign({},res.body.dbData[0]);
				this.setState({rowData: rowProp });
			}.bind(this));
	}

	rowGetter = (rowIdx) => {
		return this.state.tableList[rowIdx];
	}

	// 出勤ボタンが押された
	openAdd = ( row,index ) => {
		rowProp = row[index];
		this.getTableStart(rowProp.id);
		this.setState({
			state: ADD_STATE,
			no:   rowProp.no,
			name: rowProp.name,
			readOnly: "readonly",
			closeButton: true
		});
	}

	// 退勤ボタンが押された
	openRep = ( row,index ) => {
		rowProp = row[index];
		this.getTableQuit(rowProp.id);
		this.setState({ 
			rowData: rowProp,
			state: REP_STATE,
			no:   rowProp.no,
			name: rowProp.name,
			readOnly: "readonly",
			closeButton: true
		});
	}

	// 編集ボタンが押された
	openDel = ( row,indexes ) => {
		rowProp = row[indexes[0]];
		this.setState({
			state: DEL_STATE,
			no:   rowProp.no,
			name: rowProp.name,
			readOnly: "readonly",
			closeButton: true

		});
/***
		this.getTableEdit(this.state.tableList[index].id);
		this.setState({ 
			no:   this.state.tableList[index].no,
			name: this.state.tableList[index].name,
			readOnly: "",
			closeButton: true
		});
***/
	}

	// 出勤で登録ボタンが押された
	closeAdd = () => {
// console.log("WorkHour:closeAdd()", rowProp);
		Request.put('/workhour/' + rowProp.staff_id + '/' + rowProp.work_date )
			.send({ dbData:rowProp, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.error("出勤データを登録できませんでした");
					return;
				}
//				this.postSchedule(res.body.dbData[0].id);
				this.getTableList();
			}.bind(this));
	}

	// 退勤でクローズボタンが押された
	closeRep = () => {
// console.log("WorkHour:closeRep()", rowProp);
		Request.put('/workhour/' + rowProp.staff_id + '/' + rowProp.work_date )
			.send({ dbData:rowProp, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.error("退勤データを登録できませんでした");
					return;
				}
//				this.postSchedule(this.state.rowData.id);
				this.getTableList();
			}.bind(this));
	}

	// 編集でクローズボタンが押された
	closeDel = () => {
/****
		Request.put('/workhour/' + rowProp.staff_id + '/' + rowProp.work_date )
			.send({ dbData:rowProp, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.error("勤怠データを登録できませんでした");
					return;
				}
//				this.postSchedule(this.state.rowData.id);
				this.getTableList();
			}.bind(this));
****/
		this.getTableList();
	}

	cancel = () => {
	}

	closeButton = (bool) => {
		this.setState({closeButton: bool });
	}


    onClickMonthRoster = () =>{
	    this.setState( {
	    	showModalRoster: true,
	    	title: '月次集計表 ',
			chart: <MonthRoster />
	    } );
    }

	onRosterToggle = () => {
	    this.setState( {
	    	showModalRoster: ! this.state.showModalRoster
	    } );
	    	

	}

//-----------------------------------------------
// イベント処理関数
//-----------------------------------------------
	// InputにFocusを当てると選択状態にする
	focusSelect = (event) => {
		event.target.select();
	}
	
	workdayChange = (event) => { 
		if ( this.state.readOnly === true ) return;
		rowProp.workday ^= event.target.value; 
		this.setState({ rowData:rowProp });
	}

	startChange = ( event ) => {
		rowProp.start = event.target.value;
		this.setState({ rowData:rowProp });
	}

	quitChange = ( event ) => {
		rowProp.quit = event.target.value;
		this.setState({ rowData:rowProp });
	}

	dayOffChange = (option) => {
		if ( option.value !== 0 ) {
			rowProp.day_off_id= option.value;
			rowProp.start       = null;
			rowProp.quit        = null;
			rowProp.break_time  = null;
			rowProp.punch_start = this.state.tenant.start_time;
			rowProp.punch_quit  = this.state.tenant.quit_time;
		} else {
			let now = moment().tz(TIMEZONE).format("HH:mm");
			switch ( this.state.state ) {
				case ADD_STATE:
					if ( ! rowProp.start ) {		// 開始時間が入力されていればそのままにしておく
						rowProp.start       = now;
						rowProp.punch_start = now;
						rowProp.break_time  = this.state.tenant.break_time;
					}
					break;
				case REP_STATE:						// 終了時間は更新する
					rowProp.start       = now;
					rowProp.punch_start = now;
					rowProp.break_time  = this.state.tenant.break_time;
					break;
				case DEL_STATE:
				default:
					break;
			}
		}		
// console.log(option.value,rowProp);
		this.setState({ rowData:rowProp });
	}

	breakTimeChange = ( event ) => {
		rowProp.break_time = event.target.value;
		this.setState({ roster:rowProp });
	} 
	

	setModal = (modal) => {
		this.setState({showModal: modal});
	}

	pounchForm = () => {
		// 勤怠届マスターの設定
		let list = this.state.optionList.filter( (dayOff) => {		// 削除された番号は表示しない
			return dayOff.no < MAX_DB_LENGTH;
		});
		const options = list.map( (dayOff) => {
			return { value: dayOff.id, label:dayOff.no + ' ' + dayOff.name};
		});
		options.unshift( { value: 0, label:'　'} ) ;
		var dayOff = options.find( (option) => {
			return option.value === this.state.rowData.day_off_id;
		});
		
		//店舗の選択
		
		
// console.log(dayOff);
		let start       = this.state.rowData.start       ? this.state.rowData.start       : '';
		let punch_start = this.state.rowData.punch_start ? this.state.rowData.punch_start : '--:--:--';
		let quit        = this.state.rowData.quit        ? this.state.rowData.quit        : '';
		let punch_quit  = this.state.rowData.punch_quit  ? this.state.rowData.punch_quit  : '--:--:--';
		let break_time  = this.state.rowData.break_time  ? this.state.rowData.break_time  : '--:--';

		const form=
				<form>
					<FormGroup disabled>
						<InputGroup>
							<InputGroupText>番　　号</InputGroupText>
							<Input type="number" style={{backgroundColor: this.state.noColor}} placeholder="Number" value={this.state.no} onFocus={this.focusSelect} readOnly={true}/>
						</InputGroup>
						<InputGroup>
							<InputGroupText>名　　前</InputGroupText>
							<Input type="text" style={{backgroundColor: this.state.nameColor}} placeholder="名前（20文字以内）" value={this.state.name} readOnly={true}/>
						</InputGroup>

						<InputGroup>
							<InputGroupText>出勤時刻</InputGroupText>
{/*							<Input type="time" placeholder="出勤時刻" value={this.state.rowData.stime} onChange={this.stimeChange} readOnly={this.state.readOnly}/> */}
							<Input type="time" style={{backgroundColor: this.state.nameColor}} placeholder="出勤時刻" value={start} readOnly={true}/>
							<InputGroupText  style={{width:'150px'}}>{ "打刻: " + punch_start}</InputGroupText>
						</InputGroup>
						<InputGroup>
							<InputGroupText>退勤時刻</InputGroupText>
							<Input type="time" style={{backgroundColor: this.state.nameColor}} placeholder="退勤時刻" value={quit} readOnly={true}/>
							<InputGroupText  style={{width:'150px'}} >{"打刻: " + punch_quit}</InputGroupText>
						</InputGroup>
						<InputGroup>
							<InputGroupText>休憩時間</InputGroupText>
							<Input type="time" placeholder="休憩時間" value={break_time} onChange={this.breakTimeChange}  autoFocus={true}/>
						</InputGroup>
						<InputGroup>
							<InputGroupText>勤怠届　</InputGroupText>
							<Select className="form-control react-select-original" styles={{ menu: provided => ({ ...provided, zIndex: 100 }) }}
									placeholder="勤怠届" value={dayOff} options={options} onChange={this.dayOffChange}  />
						</InputGroup>
					</FormGroup>
				</form>;

		return [form,'md',true];
	}


	editForm = () => {
		let startWeek   = this.state.tenant.start_week;		// 週の起算日
		let dayOfWeek   = moment().tz(TIMEZONE).day();					// 今の曜日

		let start_date  = '';
		if ( dayOfWeek < startWeek) {
			start_date  = moment().tz(TIMEZONE).add('days', -7 - dayOfWeek + startWeek ).format("YYYY-MM-DD");
		} else {
			start_date  = moment().tz(TIMEZONE).add('days', startWeek - dayOfWeek ).format("YYYY-MM-DD");
		}
		const form = 
				<Roster staff_id={rowProp.staff_id}
						name={rowProp.name}
						start={start_date}
						dayOffItems={this.state.optionList}
						setModal={this.setModal}
						tenant={this.state.tenant}
				/>;
				
		return [form,'lg', false];
	}	

	render() {
		const columns = [{ key: 'no',     name: '番号',		width: 80,  resizable: false, formatter:AlignCenter,  filterable: true, sortable: true, filterRenderer: NumericFilter },
						 { key: 'name',   name: '担当者名',	width: 120, resizable: true,  formatter:AlignLeft,    filterable: true, sortable: true },
						 { key: 'start',  name: '出勤',		width: 90,  resizable: true,  formatter:TimeHMFormat, filterable: true, sortable: true },
						 { key: 'quit',   name: '退勤',		width: 90,  resizable: true,  formatter:TimeHMFormat, filterable: true, sortable: true },
						 { key: 'break_time',name: '休憩',	width: 90,  resizable: true,  formatter:TimeHMFormat, filterable: true, sortable: true },
						 { key: 'dayOffName',name: '勤怠届',width: 120, resizable: true,  formatter:AlignLeft,    filterable: true, sortable: true, filterRenderer: AutoCompleteFilter },
						 { key: 'phone',  name: '電話番号',	width: 140, resizable: true,  formatter:AlignLeft,    filterable: true, sortable: true },
						 { key: 'mail',   name: 'メール',   editable: false, resizable: true, formatter:AlignLeft,filterable: true, sortable: true }
						];
		let form,size,footer;
		if ( this.state.state === DEL_STATE ) {
			[form,size,footer] = this.editForm();
		} else {
			[form,size,footer] = this.pounchForm();
		}

// console.log(this.state.rowData);
		return (
			<div>
				<GridTable title={'勤怠管理'} form={form} size={size} footer={footer}
					tableRows={this.state.tableList} rowGetter={this.rowGetter} length={this.state.tableList.length} getTable={this.getTableList}
					columns={columns} showCheckbox={false}
					header={
						<div>
							<Button color="info" onClick={this.onClickMonthRoster}>集計表印刷</Button>{"　"}
							<Clock format={'HH:mm:ss'} style={{fontSize: '1.5em'}}  ticking={true} />
						</div>
					}
					help={help}
					buttonAdd={'出勤'} buttonRep={'退勤'} buttonDel={'編集'}
					closeButton={this.state.closeButton}
					openAdd={this.openAdd} openRep={this.openRep} openDel={this.openDel}
					closeAdd={this.closeAdd} closeRep={this.closeRep} closeDel={this.closeDel}
					cancel={this.cancel}
				/>

				<Modal isOpen={this.state.showModalRoster} backdrop={'static'} size="lg" autoFocus={false}>
					<ModalHeader toggle={this.onRosterToggle}>{this.state.title}</ModalHeader>
					<ModalBody>
						{ this.state.chart }
					</ModalBody>
					<ModalFooter>
			            <Button color="secondary" onClick={this.onRosterToggle}>終了</Button>
					</ModalFooter>
    			</Modal>


			</div>
		);
	}
}

const help = 
	"##### 概要\n" +
	"担当者の勤怠を管理する。  \n" +
	"出退勤の打刻や休憩時間の入力、休暇/休日出勤などの入力を行う。  \n" +
	"##### 出勤\n" +
	"担当者を選択して、**出勤**ボタンを押すと、現在時刻を出勤時刻として**勤怠管理 出勤**画面が表示される。  \n" + 
	"**勤怠管理 出勤**画面で**実行**ボタンを押すと登録される。  \n" + 
	"##### 退勤\n" +
	"担当者を選択して、**退勤**ボタンを押すと、現在時刻を退勤時刻として**勤怠管理 退勤**画面が表示される。  \n" + 
	"**勤怠管理 退勤**画面で**実行**ボタンを押すと登録される。  \n" + 
	"##### 編集\n" +
	"担当者を選択して、**編集**ボタンを押すと、１週間分の**勤怠管理 編集**画面が表示される。  \n" + 
	"１週の開始曜日は[設定](./#/settings)の**起算日**で曜日を指定する。  \n" + 
	"編集したい日付を選択して、**修正**ボタンを押すと、担当者の勤怠修正画面が表示される。  \n" + 
	"編集したい日付を選択して、**修正**ボタンを押すと、担当者の勤怠修正画面が表示される。  \n" + 
	"勤怠修正画面では、勤怠届/出勤時刻/退勤時刻/休憩時間が修正できる。打刻時間は修正できない。  \n" + 
	"##### 集計表印刷\n" +
	"**集計表印刷**ボタンを押すと、勤怠管理の**月次集計表**画面を表示する。  \n" +
	"**勤怠管理**の設定項目は[設定](./#/settings)で**勤怠管理**の項目で変更できる。  \n" +
	"**勤務**：月間の労働時間  \n" +
	"**定時**：法定労働時間内の労働時間  \n" +
	"**残業**：法定労働時間外の労働時間（深夜も含む）  \n" +
	"**深夜**：22時から5時の間の労働時間  \n" +
	"**休日**：休日の労働時間  \n" +
	"法定労働時間とは1日８時間、１週間に40時間または44時間。  \n" +
	"######  印刷\n" +
	"**月次集計表**の印刷ボタンを押すと、月次集計表が表示される  \n" +
	"";

export default WorkHour;
