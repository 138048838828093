/* ==============================================================================
 はがき印刷

=============================================================================== */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PrintPreview from './PrintPreview';
import { Table } from 'reactstrap';
import { MAX_DB_LENGTH } from './Define';

// import './Printer.css';		// ページ番号の印刷

// font size 12px に設定した時
// const minimumTebleHeight = 61;
// const pageHeight = 1043 - 14;			// R58 サイズ

var chart=( <section className="sheet padding-10mm">postcard Seet </section> );
var disabled = false;

class PostCardPrint extends Component {
	// propTypes
	static propTypes = {
		customerList:	PropTypes.array.isRequired,			// 顧客マスター
		staffList:		PropTypes.array.isRequired,			// 担当者マスター
	}

	static defaultProps = {
	}

	// コンストラクター
	constructor(props) {
		super(props);
		this.state = { 
			
		};
	}

	/* 再描画キャンセル */
	shouldComponentUpdate = (nextProps, nextState) => {
		/* falseを返すと、componentWillUpdateとcomponentDidUpdateとrenderをskipする */
		/* propsやstateが変わっても描画内容に変更がないとわかるときにパフォーマンスを早くするために使用 */
//		console.log("shouldComponentUpdate");

		this.createPostCard(nextProps);
		return true;
	}

// ======================================================================================
// 画面入力 関数
// ======================================================================================

	// ポストカードを印刷する
	createPostCard = (nextProps) => {
//		console.log(nextProps);
		var post1 = { x:'67mm', y:'17mm', fontSize: 40, fontFamily:	'Courier', spacing: '3.8mm'	};
		var post2 = { x:'99mm', y:'17mm', fontSize: 40, fontFamily:	'Courier', spacing: '3.8mm'	};
//		var addr1 = { x:'10mm', y:'80mm'};
//		var addr2 = { x:'15mm', y:'100mm'};
		var addrFontSize = 30;
//		var name  = { x:'20mm', y:'120mm'};
		var nameFontSize = 40;

		let customerPages = nextProps.customerList.map( (c,index) => {
//			let addr = c.address1 + c.address2;
//			addr = addr.replace(/　/g, ' ');	// 漢字スペースをANKスペースに置換
//			let addrs = addr.split(' ');
			let staff = nextProps.staffList.find( (s,index) => { return s.id === c.staff_id; });
			let staffNo = staff.no;
			if ( staff.no > MAX_DB_LENGTH ) staffNo = 0;
			
			let zipcode  = c.zipcode.replace(/-/g, '');
			let zipcode1 = zipcode.substr( 0, 3 );
			let zipcode2 = zipcode.substr( 3 );
// Courier Meiryo
			return(
				<section className={"sheet padding-03mm"} key={index}>
					<div style={{position: 'absolute', left: post1.x, top: post1.y, fontSize: post1.fontSize, letterSpacing: post1.spacing }}>
						<tt style={{fontFamily: post1.fontFamily }}>{zipcode1}</tt>
					</div>
					<div style={{position: 'absolute', left: post2.x, top: post2.y, fontSize: post2.fontSize, letterSpacing: post2.spacing }}>
						<tt style={{fontFamily: post2.fontFamily }}>{zipcode2}</tt>
					</div>
{/*
					<div style={{position: 'absolute', left: addr1.x, top: addr1.y, fontSize: addrFontSize }}>{c.address1}</div>
					<div style={{position: 'absolute', left: addr2.x, top: addr2.y, fontSize: addrFontSize }}>{c.address2}</div>
					<div style={{position: 'absolute', left: name.x, top: name.y,  fontSize: nameFontSize }}>{c.name+' 様'}</div>
*/}
					<Table borderless size={"sm"} >
						<tbody >
							<tr><td> </td></tr>
							<tr key={index}><td width="150" align="center" style={{fontFamily: 'Serif', fontSize: addrFontSize }}>{ c.address1 } </td></tr>
							<tr            ><td width="150" align="center" style={{fontFamily: 'Serif', fontSize: addrFontSize }}>{ c.address2 } </td></tr>
							<tr><td width="150" align="center" style={{fontFamily: 'Serif', fontSize: nameFontSize }}>{  c.name + ' 様' }</td></tr>
							<tr><td width="150" align="right" vertical-align="top" style={{fontFamily: 'Courier', fontSize: 15 }}>{  c.no + '-' + staffNo + '　　　　' }</td></tr>
						</tbody>
					</Table>
				</section>
			);
		});


		chart =	(
			<div>
				{ customerPages }
			</div>
		);
					
	}

	render() {
		disabled = ( this.props.customerList.length === 0 ) ;
		return (
			<div className="PostCardPrint" >
				<PrintPreview isPoppedOut={false} disabled={disabled} button={"はがき"} title={ "はがき印刷 " } url="postcard.html" contents={chart} />
			</div>
		);

	}
}

export default PostCardPrint;
