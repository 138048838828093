/* ==============================================================================
 テナント情報の編集画面

 履歴-----------------------------------------
 2019/03/07 reactstrap 対応済み
 
=============================================================================== */

import React, { Component,useState } from 'react';
import { InputGroup,InputGroupText,FormGroup,Input,Button,
		 Modal,ModalHeader,ModalBody,ModalFooter} from 'reactstrap';
import Select from 'react-select';

import Request from 'superagent';
import {JWToken,Alert} from './auth/Login';
import Check from './Input';

const initialRow = {id: 0, name: "", phone: "", address: "", username: "", password: "",
					begin_month:1, begin_day:1, month:1,
					start_time: '09:00', quit_time: '18:00', break_time: '01:00', day_off: 4,
					labor_month:4, labor_day:1, month_labor:4, start_week:3, 
					after_work:44, before_work: 1
				};	// usernameはメールアドレスとする
var rowProp = Object.assign({},initialRow);		// rowPropはモーダル編集画面で使用している


// システム停止 解約
const ModalDeleteTenant = (props) => {
	const {
		buttonLabel,
		className
	} = props;
	
	const [modal, setModal]    = useState(false);
	const [check, setCheckBox] = useState(false);
	const [password, setPassword] = useState("");
	
	const toggle = () => setModal(!modal);
	
	const checkChange = (event) => {  setCheckBox(event.target.value) }

	const passwordChange = (event) => {
		setPassword(event.target.value);
	}

	const exec = () => {
		if ( putTable(rowProp.id,password) === true ) {
			toggle();
		} else {
			Alert('パスワードが間違っています');
			
		}
	}

	// テナントの停止
	const putTable = (id,password) => {
		Request.put('/tenant/' + id + '/' + password )
			.send({ dbData:rowProp, token: JWToken.getToken().token } )
			.then( res => {
				Alert('再ログインしてください');
				JWToken.clearToken();
				return(true);
			})
			.catch( err => {
				console.log(err);
				console.error("テナントを停止きできませんでした。");
				return(false);
			});

	}

	return (
	    <div>
	      <Button color="danger" onClick={toggle}>{buttonLabel}</Button>
	      <Modal isOpen={modal} toggle={toggle} className={className} autoFocus={false}>
	        <ModalHeader toggle={toggle}>hairaccessの利用を終了します。</ModalHeader>
	        <ModalBody>
				<InputGroup>
			        <InputGroupText>
			            <Input addon type="checkbox" aria-label="Checkbox for following text input"　onChange={checkChange} autoFocus={true}/>
			        </InputGroupText>
				    <Input placeholder="全てのデータを削除します。元に戻せません。"  readOnly={true}/>
				</InputGroup>
	            <InputGroup>
	                <InputGroupText>パスワード</InputGroupText>
	                <Input type="password" placeholder="パスワード" value={password} onChange={passwordChange}/>
	            </InputGroup>
	
	        </ModalBody>
	        <ModalFooter>
	          <Button color="secondary" onClick={toggle}>キャンセル</Button>{' '}
	          <Button color="primary" onClick={exec}  disabled={!check}>実行</Button>
	        </ModalFooter>
	      </Modal>
	    </div>
	);
}



class Settings extends Component {

	// コンストラクター
	constructor(props) {
		super(props);
		this.state = {  
			rowData:  Object.assign({},initialRow),
			title: "設定項目",
			oldPassword:"",
			newPassword:"",
			chkPassword:"",
			showModal:true,
			showModalPassword:false,
		};
		
		this.getTable(JWToken.getToken().tenant_id);

	}

	// テナント取得
	getTable = (id) => {
		//ajax通信する
		Request.get("/tenant/" + id)
			.query( { token: JWToken.getToken().token } )
			.then( res => {
				this.setState({rowData: res.body.dbData[0] });
				rowProp = res.body.dbData[0];
console.log(rowProp);
			})
			.catch( err => {
				console.log(err);
				console.error("テナントを取得できませんでした。");
				this.setState({rowData: rowProp });
			});

	}

	// 変更で登録ボタンが押された
	close = () => {
		// usernameがユニークかチェックして登録
		Request.get('/tenant/username/' + rowProp.id + '/' + rowProp.username )
			.query({ token: JWToken.getToken().token })
			.then( res => {
				if ( res.body.dbData.length === 0 ) {

					// テナントの上書き
					delete rowProp.password;
// console.log(rowProp);
// console.log(this.state.rowData);
					Request.put('/tenant/' + rowProp.id )
						.send({ dbData:rowProp, token: JWToken.getToken().token } )
						.then( res => {
							Alert('再ログインしてください');
							JWToken.clearToken();
						})
						.catch( err => {
							console.log(err);
							console.error("テナントを上書きできませんでした。");
							this.setState({rowData: rowProp });
						});
					
				} else {
					Alert("指定されたユーザー名は使われています")
				}
			})
			.catch( err => {
				console.log(err);
				console.error("テナントを取得できませんでした。");
				this.setState({rowData: rowProp });
			});


	}


	cancel = () => {
		this.setState({	showModal: false });
  		window.location.href = window.location.origin + '/#/booking';    // ログインへ

	}


	openPassword = () => {
		this.setState({	showModalPassword: true });
	}

	// 変更で登録ボタンが押された
	closePassword = () => {

		if ( this.state.newPassword !== this.state.chkPassword ) {
			Alert("新パスワードと再パスワードが違います");
			return;
		}
		
		let data = {
			id: 		 this.state.rowData.id,
			password:    this.state.newPassword,
			oldPassword: this.state.oldPassword,
		};
			
		// passwordの登録
		Request.put('/tenant/password/' + this.state.rowData.id )
			.send({ dbData:data, token: JWToken.getToken().token } )
			.then( res => {
				Alert('再ログインして下さい。');
				JWToken.clearToken();
			})
			.catch( err => {
				console.log(err);
				console.error("パスワードを登録できませんでした。");
			});
	}

	cancelPassword = () => {
		this.setState({	showModalPassword: false });
	}

	toggle = () => {
		if ( this.state.showModal === true ) {
			this.cancel();
			this.setState( { showModal: false } );
		} else {
			this.setState( { showModal: true } );
		}
	}

	togglePassword = () => {
		if ( this.state.showModalPassword === true ) {
			this.cancelPassword();
			this.setState( { showModalPassword: false } );
		} else {
			this.setState( { showModalPassword: true } );
		}
	}



//-----------------------------------------------
// イベント処理関数
//-----------------------------------------------

	nameChange = (event) => { rowProp.name    = event.target.value;	this.setState({ rowData:rowProp }); }

	phoneChange = (event) => {
		if ( Check.phone(event.target.value) === false ) return;
		rowProp.phone   = event.target.value;
		this.setState({ rowData:rowProp });
	}

	addressChange  = (event) => { rowProp.address   = event.target.value; this.setState({ rowData:rowProp }); }
    usernameChange = (event) => { rowProp.username  = event.target.value; this.setState({ rowData:rowProp }); }
//    passwordChange = (event) => { rowProp.password  = event.target.value; this.setState({ rowData:rowProp }); }
    beginMonthChange = (event) => { rowProp.begin_month= Number(event.target.value); this.setState({ rowData:rowProp }); }
    beginDayChange   = (event) => { rowProp.begin_day  = Number(event.target.value); this.setState({ rowData:rowProp }); }
    monthChange      = (event) => { rowProp.month      = Number(event.target.value); this.setState({ rowData:rowProp }); }

    laborMonthChange = (event) => { rowProp.labor_month= Number(event.target.value); this.setState({ rowData:rowProp }); }
    laborDayChange   = (event) => { rowProp.labor_day  = Number(event.target.value); this.setState({ rowData:rowProp }); }
    monthLoborChange = (event) => { rowProp.month_labor= Number(event.target.value); this.setState({ rowData:rowProp }); }

	startTimeChange  = (event) => { rowProp.start_time = event.target.value; this.setState({ rowData:rowProp }); }
	quitTimeChange   = (event) => { rowProp.quit_time  = event.target.value; this.setState({ rowData:rowProp }); }
	breakTimeChange  = (event) => { rowProp.break_time = event.target.value; this.setState({ rowData:rowProp }); }
	afterWorkChange  = (event) => { rowProp.after_work = Number(event.target.value); this.setState({ rowData:rowProp }); }
	beforeWorkChange = (event) => { rowProp.before_work= Number(event.target.value); this.setState({ rowData:rowProp }); }
	startWeekChange  = (event) => { rowProp.start_week = Number(event.target.value); this.setState({ rowData:rowProp }); }

	oldPasswordChange = (event) => { this.setState({oldPassword: event.target.value }) };
	newPasswordChange = (event) => { this.setState({newPassword: event.target.value }) };
	chkPasswordChange = (event) => { this.setState({chkPassword: event.target.value }) };

	dayOffChange = (option) => {
		let dayOff = 0;
		option.forEach( (opt,index) => {
			dayOff = dayOff | (1<<opt.value);
		});
		rowProp.day_off = dayOff;
		this.setState({ rowData:rowProp });
	}

	render() {
//		console.log(this.state.rowData);
		// ドロップダウン
		let month = [];
		for ( let i = 0 ; i < 12 ;  ) month[i] = ++i;
		let day = [];
		for ( let i = 0 ; i < 31 ;  ) day[i] = ++i;
		const week = [ '日','月','火','水','木','金','土'];
		const after_work = [ 
			{no: 40, descript: "週40時間" },
			{no: 44, descript: "週44時間" },
		];
		const before_work = [ 
			{no: 0, descript: "始業時前の労働を残業としない" },
			{no: 1, descript: "始業時前の労働を残業とする" },
		];

		const options = week.map( (w,index) => {
			return { value: index, label:w};
		});
		var dayOff = options.filter( (option,index) => {
			return this.state.rowData.day_off & (1 << index);
		});

		let address = this.state.rowData.pref + this.state.rowData.city + this.state.rowData.suburb + this.state.rowData.address1 + this.state.rowData.address2;

		const form =
			<form>
				<FormGroup>
                    <InputGroup>
                        <InputGroupText>番　号</InputGroupText>
                        <Input type="text" placeholder="ID" value={this.state.rowData.id} readOnly={true} />
                    </InputGroup>
                    <InputGroup>
                        <InputGroupText>名　称</InputGroupText>
                        <Input type="text" placeholder="テナント名（20文字以内）" value={this.state.rowData.name} onChange={this.nameChange}  readOnly={true}/>
                    </InputGroup>
                    <InputGroup>
                        <InputGroupText>電　話</InputGroupText>
                        <Input type="phone" placeholder="電話番号（15文字以内）" value={this.state.rowData.phone} onChange={this.phoneChange}  readOnly={true} />
                    </InputGroup>
                    <InputGroup>
                        <InputGroupText>住　所</InputGroupText>
                        <Input type="text" placeholder="住所（100文字以内）" value={ address } onChange={this.addressChange}  readOnly={true}/>
                    </InputGroup>
                    <br/>経理情報<br/>
                    <InputGroup>
                        <InputGroupText>期首は</InputGroupText>
                        <Input type="select" placeholder="月" value={this.state.rowData.begin_month} onChange={this.beginMonthChange} autoFocus={true} >
							{	
								month.map(function(n){	return 	<option value={n} key={n}>{n+"月"}</option>	})
							}
						</Input>
                        <Input type="select" placeholder="日" value={this.state.rowData.begin_day}   onChange={this.beginDayChange}>
							{	
								day.map(function(n){	return 	<option value={n} key={n}>{n+"日"}</option>	})
							}
						</Input>
                        <InputGroupText>を</InputGroupText>
                        <Input type="select" placeholder="月" value={this.state.rowData.month} onChange={this.monthChange}>
							{	
								month.map(function(n){	return 	<option value={n} key={n}>{n+"月"}</option>	})
							}
						</Input>
                        <InputGroupText>度とする</InputGroupText>
                    </InputGroup>
                    <br/>勤怠管理<br/>
					<InputGroup>
						<InputGroupText >出勤時刻</InputGroupText>
						<Input type="time" placeholder="時刻" value={this.state.rowData.start_time} onChange={this.startTimeChange} readOnly={this.state.readOnly}/>
						<InputGroupText >退勤時刻</InputGroupText>
						<Input type="time" placeholder="時刻" value={this.state.rowData.quit_time} onChange={this.quitTimeChange} readOnly={this.state.readOnly}/>
					</InputGroup>
                    <InputGroup>
						<InputGroupText>休憩時間</InputGroupText>
						<Input type="time" placeholder="時間" value={this.state.rowData.break_time} onChange={this.breakTimeChange} readOnly={this.state.readOnly}/>
					</InputGroup>
                    <InputGroup>
						<InputGroupText>定休日　</InputGroupText>
						<Select className="form-control react-select-original" styles={{ menu: provided => ({ ...provided, zIndex: 100 }) }}
								isMulti placeholder="休業" value={dayOff} options={options} onChange={this.dayOffChange}  />
					</InputGroup>
                    <InputGroup>
                        <InputGroupText>労働時間</InputGroupText>
                        <Input type="select" placeholder="時間" value={this.state.rowData.after_work} onChange={this.afterWorkChange}>
							{	
								after_work.map(function(n){	return 	<option value={n.no} key={n.no}>{n.descript}</option>	})
							}
						</Input>
                        <InputGroupText>起算日</InputGroupText>
                        <Input type="select" placeholder="曜日" value={this.state.rowData.start_week} onChange={this.startWeekChange}>
							{	
								week.map(function(w,index){	return 	<option value={index} key={index}>{w}</option>	})
							}
						</Input>
                        <InputGroupText>曜</InputGroupText>
                    </InputGroup>
					<InputGroup>
                        <InputGroupText>期首は</InputGroupText>
                        <Input type="select" placeholder="月" value={this.state.rowData.labor_month} onChange={this.laborMonthChange}>
							{	
								month.map(function(n){	return 	<option value={n} key={n}>{n+"月"}</option>	})
							}
						</Input>
                        <Input type="select" placeholder="日" value={this.state.rowData.labor_day}   onChange={this.laborDayChange}>
							{	
								day.map(function(n){	return 	<option value={n} key={n}>{n+"日"}</option>	})
							}
						</Input>
                        <InputGroupText>を</InputGroupText>
                        <Input type="select" placeholder="月" value={this.state.rowData.month_labor} onChange={this.monthLoborChange}>
							{	
								month.map(function(n){	return 	<option value={n} key={n}>{n+"月"}</option>	})
							}
						</Input>
                        <InputGroupText>度とする</InputGroupText>
                    </InputGroup>
                    <InputGroup>
                        <InputGroupText>早出残業</InputGroupText>
                        <Input type="select" placeholder="分" value={this.state.rowData.before_work} onChange={this.beforeWorkChange}>
							{	
								before_work.map(function(n){	return 	<option value={n.no} key={n.no}>{n.descript}</option>	})
							}
						</Input>
                    </InputGroup>
                    <br/>
                    <InputGroup>
                        <InputGroupText>ユーザー名</InputGroupText>
                        <Input type="mail" placeholder="ユーザー名" value={this.state.rowData.username} onChange={this.usernameChange}/>
                    </InputGroup>
 				</FormGroup>
			</form>;

		return (
			<div>
				<Modal isOpen={this.state.showModal} backdrop={'static'} size={this.props.size} autoFocus={false}>
					<ModalHeader toggle={this.toggle} >{this.state.title}{this.state.subTitle}</ModalHeader>
					<ModalBody focus="true" >
						{form}
					 </ModalBody>
					<ModalFooter>
						<ModalDeleteTenant buttonLabel={'システム解約'} />
						<Button color="warning" onClick={this.openPassword}>パスワード変更</Button>
						<Button color="warning" onClick={this.cancel}>キャンセル</Button>
						<Button color="primary" onClick={this.close}>登録</Button>
					</ModalFooter>
				</Modal>
				<Modal isOpen={this.state.showModalPassword} backdrop={'static'} size={this.props.size} autoFocus={false}>
					<ModalHeader toggle={this.togglePassword} >パスワード設定</ModalHeader>
					<ModalBody focus="true" >
	                    <InputGroup>
	                        <InputGroupText>旧パスワード</InputGroupText>
	                        <Input type="password" placeholder="今までのパスワード" value={this.state.oldPassword} onChange={this.oldPasswordChange} autoFocus={true}/>
	                    </InputGroup>
	                    <InputGroup>
	                        <InputGroupText>新パスワード</InputGroupText>
	                        <Input type="password" placeholder="新しいパスワード" value={this.state.newPpassword} onChange={this.newPasswordChange}/>
	                    </InputGroup>
	                    <InputGroup>
	                        <InputGroupText>再パスワード</InputGroupText>
	                        <Input type="password" placeholder="新しいパスワードを再入力" value={this.state.chkPassword} onChange={this.chkPasswordChange}/>
	                    </InputGroup>
					 </ModalBody>
					<ModalFooter>
						<Button color="warning" onClick={this.cancelPassword}>キャンセル</Button>
						<Button color="primary" onClick={this.closePassword}>登録</Button>
					</ModalFooter>
				</Modal>
			</div>
		);

	}
}

export default Settings;
