/* ==============================================================================
 シフトモデル（１週間のシフトモデル）基本シフト
　　１週間分の基本シフト
    テンプレート、モデル、パターン、シェイプ、フォーム

 履歴-----------------------------------------
 2023/04/15 作成
 
 【未解決事項】
 Macの場合、２本指で横スクロールすると範囲外までスクロールしてしまう。
   onTimeChange()で調整しても上手くいかないreact-calendar-timelineのバグなのか？
 
=============================================================================== */
import React, { useState, useEffect }  from 'react';
import { Button, ButtonToolbar, ButtonGroup, Form, FormGroup, InputGroup, InputGroupText } from 'reactstrap';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Timeline, {TimelineHeaders, DateHeader} from  'react-calendar-timeline';
// 必ずタイムライン スタイルシートを含めてください。そうしないとタイムラインのスタイルが設定されません
import 'react-calendar-timeline/lib/Timeline.css' ;
import Select from 'react-select';

// import DatePicker, { registerLocale } from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
// import ja from 'date-fns/locale/ja';

import moment  from  'moment';
import 'moment-timezone';
import 'moment/locale/ja';
import './Table.css';
import './TimeLine.css';
import { getMasters,addRecord,addRecords,repRecord,delRecord,delRecords } from './Agent';
import ModalHook, {FooterOkCancel} from './ModalHook';
import {JWToken,LocalStorage,Alert} from './auth/Login';

import {
	MAX_DB_LENGTH,
//	TIMEZONE,
} from './Define';

const initialStaff = {id: 0, tenant_id: 0, no: 0, name: "", mail: "", phone: "",  workday: 0 };
const initialSalon = {no: 0, name: "", phone: "", address: "", time_unit: 1, break_time: "01:00", stime: "09:00", etime: "19:00", workday: 0, shift_month: null };
const initialDayOffItem = { id: 0, tenant_id: 0, no: 0, name: "", description: "" , on_off: false};

/* -----------------------------------------------------------------------------
  １週間のスライダーを表示する
  weekTime[7][start,end]
-------------------------------------------------------------------------------- */


const initialGroups = [{ id: 1, title: 'group 1' }, { id: 2, title: 'group 2' }];

const initialItem = {id:1, group: 1, title:'item1 09:00 - 12:00', start_time: moment('2023-01-01 09:00'), end_time: moment('2023-01-01 16:00')};

const weekOfDay = [ "2023-01-01 ","2023-01-02 ","2023-01-03 ","2023-01-04 ","2023-01-05 ","2023-01-06 ","2023-01-07 " ];

const tsteps = {
    second: 1,
    minute: 15,
    hour: 1,
    day: 1,
    month: 1,
    year: 1
};

const days = [
  " （日曜）",
  " （月曜）",
  " （火曜）",
  " （水曜）",
  " （木曜）",
  " （金曜）",
  " （土曜）",
];

// default format object
var headerFormats = {
	year: {
	    "long": 'YYYY',
	    mediumLong: 'YYYY',
	    medium: 'YYYY',
	    "short": 'YY'
	},
	month: {
		"long": 'YYYY年 MM月',
		mediumLong: 'MMM',
		medium: 'MMM',
		"short": 'YY/MM'
	},
	week: {
		"long": 'dddd',
		mediumLong: 'dddd',
		medium: 'dddd',
		"short": 'dddd'
/*
		"long": 'w',
		mediumLong: 'w',
		medium: 'w',
		"short": 'w'
*/
	},
	day: {
		"long": 'dddd',
		mediumLong: 'dddd',
		medium: 'dddd',
		"short": 'dddd'
/*
		"long": 'LL dddd',
		mediumLong: 'DD日 dd',
		medium: 'D dd',
		"short": 'D'
*/
	},
	hour: {
		"long": 'HH:00',
		mediumLong: 'HH:00',
		medium: 'HH時',
		"short": 'HH'
	},
	minute: {
		"long": 'HH:mm',
		mediumLong: 'HH:mm',
		medium: 'HH:mm',
		"short": 'mm'
	}
};
    

const ShiftModel = (props) => {
    const [salonList,   setSalonList]   = useState([initialSalon]);
    const [staffList,   setStaffList]   = useState([initialStaff]);
    const [dayOffItem,  setDayOffItem]  = useState([initialDayOffItem]);
    
    const [groups,      setGroups]      = useState(initialGroups);
    const [items,       setItems]       = useState([]);
    const [timeStart,   setTimeStart]   = useState(moment('2023-01-01 00:00').format('x'));
    const [timeEnd,     setTimeEnd]     = useState(moment('2023-01-08 00:00').format('x'));

    const [item,        setItem]        = useState(initialItem);
    const [isOpen,      setIsOpen]      = useState(false);      // Modal画面オープン
    const [subTitle,    setSubTitle]    = useState('');         // Modal画面のサブタイトル（担当者名と曜日)

    // masterを読み取るための情報
    const masterParam	= [
    	{ url: "/salon",		        errMsg: "店舗",		        list: [initialSalon]	},
    	{ url: "/stylist",		        errMag: "担当者",	        list: [initialStaff]	},
    	{ url: "/day_off_item",         errMsg: "勤怠届",           list: [initialDayOffItem]},
        { url: "/shift_pattern/all",    errMsg: "シフトパターン",	list: [{}]	}
    ];

    const writeParam = 
    	{ url: "/shift_pattern",	    errMsg: "シフトパターン",	list: []	};

	// 店舗一覧、担当者一覧を取得する
	const getTableList = () => {

        masterParam[1].url = "/stylist/" + LocalStorage.getItem().salon_id;

		getMasters(masterParam, () => {
    		const tenant = JWToken.getToken().tenant;
            
        	const salons = masterParam[0].list.filter( (salon) => {		// 削除された番号は表示出来ない
        		return salon.no < MAX_DB_LENGTH;
        	});
            setSalonList(salons);
    		const salon_id = LocalStorage.getItem().salon_id;
    		const salon = salons.find((s) => s.id === salon_id);

        	const staffs = masterParam[1].list.filter( (staff) => {		// 削除された番号は表示出来ない
        		return staff.no < MAX_DB_LENGTH;
        	});
            setStaffList(staffs);

        	const dayOff = masterParam[2].list.filter( (value) => {		// 削除された番号は表示出来ない
        		return value.no < MAX_DB_LENGTH;
        	});
            if ( dayOff ) setDayOffItem(dayOff);
 
            const shiftList = masterParam[3].list;

            const group = staffs.map( (value,index) => {
                return ({
                    id:     value.staff_id,
                    title:  <MakeStaffShift id={value.staff_id} name={value.name} tenant={tenant} salon={salon} dayOff={dayOff[0]} shift={shiftList} reload={getTableList}/>
                });
            });
    		setGroups(group);

            // シフトパターンを読込み、itemを作成する
    		let itemList = shiftList.map( (value,index) => {

    		    const salon = salons.find(element => element.id === value.salon_id);
    		    const onOff = dayOff.find(element => element.id === value.day_off_id);
    		    const className = ( onOff && onOff.on_off ) ? 'request' : 'requestoff';
    		    const bgColor   = ( onOff && onOff.on_off ) ? 'honeydew' : 'lavenderblush';

    		    return({
                    id:         value.id,
                    group:      value.staff_id,
                    title:      salon.name.trim() + ' ' + value.shift_start.substr(0,5) + ' - ' + value.shift_quit.substr(0,5), 
                    tenant_id:  value.tenant_id,
                    salon_id:   value.salon_id,
                    day_off_id: value.day_off_id || dayOffItem[0].id,
                    day_of_week:value.day_of_week,
                    start_time: moment(weekOfDay[value.day_of_week] + value.shift_start),
                    end_time:   moment(weekOfDay[value.day_of_week] + value.shift_quit),
                    className:  value.salon_id === salon_id ? className : 'other',
                	canMove:    true,
                	canResize:  true,
                	canChangeGroup: false,
                	bgColor:    value.salon_id === salon_id ? bgColor : 'white', 
    		    });
    		});
 		
    		itemList.sort((a,b) => {
    		    let ret = a.group - b.group;
    		    if ( ret === 0 ) ret = a.start_time.diff(b.start_time);
    		    return ( ret );
    		});
    		setItems(itemList);
    		
		});
	};


    const itemReplace = (item) => {
        let shift = {
            id:            item.id,
            tenant_id:     item.tenant_id,
            salon_id:      item.salon_id,
            staff_id:      item.group,
            day_off_id:    item.day_off_id,
            day_of_week:   item.start_time.day(),
            shift_start:   item.start_time.format('HH:mm'),
            shift_quit:    item.end_time.format('HH:mm'),
        };
		let modifiedItem = {...item};
	    let salon = salonList.find(element => element.id === item.salon_id);
        modifiedItem.title    = salon.name.trim() + ' ' + item.start_time.format('HH:mm') + ' - ' + item.end_time.format('HH:mm');
        setItem(modifiedItem);

		repRecord(writeParam, shift, () => {
			getTableList();
		});
    };

    const itemInsert = (item) => {
        let shift = {
            id:            item.id,
            tenant_id:     item.tenant_id,
            salon_id:      item.salon_id,
            staff_id:      item.group,
            day_off_id:    item.day_off_id,
            day_of_week:   item.start_time.day(),
            shift_start:   item.start_time.format('HH:mm'),
            shift_quit:    item.end_time.format('HH:mm'),
        };
        writeParam.list = [shift];
		addRecord(writeParam, () => {
			getTableList();
		});
    };

    const itemDelete = (item) => {
        console.log(item);
        let shift = {
            id:            item.id,
            tenant_id:     item.tenant_id,
            salon_id:      item.salon_id,
            staff_id:      item.group,
            day_of_week:   item.start_time.day(),
            shift_start:   item.start_time.format('HH:mm'),
            shift_quit:    item.end_time.format('HH:mm'),
        };
        writeParam.list = [shift];
		delRecord(writeParam,shift, () => {
			getTableList();
		});
		
    };

/* ----------------------------------------------------------------------------- */
	const itemRenderer = ({
		item,
		timelineContext,
		itemContext,
		getItemProps,
		getResizeProps
	}) => {
		const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
		const backgroundColor = itemContext.selected ? itemContext.dragging ? "Orange" : item.selectedBgColor : item.bgColor;
//		const borderColor = itemContext.resizing ? "Orange" : item.color;
		return (
			<div {...getItemProps({
			      style: {
			        backgroundColor,
			        color: item.color,
//			        borderColor: borderColor,
//			        borderStyle: "solid",
//			        borderWidth: 1,
			        borderRadius: 2,
			        borderLeftWidth: itemContext.selected ? 3 : 1,
			        borderRightWidth: itemContext.selected ? 10 : 1
			      },
			      onMouseDown: () => {
			      }
			    })}
			>
				{itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
		
				<div style={{
						height: itemContext.dimensions.height,
						overflow: "hidden",
						paddingLeft: 3,
						textOverflow: "ellipsis",
						whiteSpace: "nowrap"
					}}
			    >
				{itemContext.title}
		    </div>
				{itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
		  </div>
		);
	};
/* ----------------------------------------------------------------------------- */


	const formatLabel = (
		[timeStart, timeEnd],
		unit,
		labelWidth,
		formatOptions = headerFormats
	) => {
		let format;
		if (labelWidth >= 150) {
			format = formatOptions[unit]['long'];
		} else if (labelWidth >= 100) {
			format = formatOptions[unit]['mediumLong'];
		} else if (labelWidth >= 50) {
			format = formatOptions[unit]['medium'];
		} else {
			format = formatOptions[unit]['short'];
		}
		return timeStart.format(format);
	};



    useEffect(() => {       // componentDidMount() の代わり。 最初に1度だけコールされる
        getTableList();     // マスターの読み込み
        // eslint-disable-next-line
    }, []);



	// 横スクロール時に毎回呼び出される。サイズ変更時にも呼び出される。
	const onTimeChange = (start, end, updateScrollCanvas) => {
		let minTime = Number(moment('2023-01-01 00:00').format('x'));
		let maxTime = minTime + 7*24*60*60*1000;      // 1週間
        let range   = (end - start) - (timeEnd - timeStart);


        if ( start < minTime && end > maxTime ) {
            setTimeStart(minTime);
            setTimeEnd  (maxTime);
            updateScrollCanvas(minTime, maxTime);
        } else if ( start < minTime ) {
            setTimeStart(minTime);
            if ( range > 0 ) setTimeEnd  (end);

            updateScrollCanvas( minTime, timeEnd);
        } else if ( end > maxTime) {
            if ( range > 0 ) setTimeStart(start);
            setTimeEnd  (maxTime);
            updateScrollCanvas( timeStart, maxTime);
        } else {
            setTimeStart(start);
            setTimeEnd  (end);
            updateScrollCanvas( start, end );
        }

	};


    const onItemMove = (itemId, dragTime, newGroupOrder) => {
        let index = items.findIndex(element => element.id === itemId );

/***/
        let baseTime  = Number(items[index].start_time.format('x'));
        let diff      = dragTime - baseTime;
        let baseDate  = new Date( baseTime ).setHours(0,0,0);
	    let startDate = new Date( dragTime ).setHours(0,0,0);         // 開始日（1970年１月１日からの日数
	    let endDate   = new Date( (Number(items[index].end_time.format('x')) + diff) ).setHours(0,0,0);

        if ( baseDate !== startDate || baseDate !== endDate) {
            Alert('日付を超えて指定できません。');
            return;
        }

        let itemList = items.concat();
	    let salon = salonList.find(element => element.id === itemList[index].salon_id);
        itemList[index].start_time.add(diff,'milliseconds');
        itemList[index].end_time.add(diff,'milliseconds');
        itemList[index].title      = salon.name.trim() + ' ' + itemList[index].start_time.format('HH:mm') + ' - ' + itemList[index].end_time.format('HH:mm');

        setItems(itemList.map(item => ({ ...item, key: item.id + Math.random() })));
        itemReplace(itemList[index]);
		onItemSelect(itemId);		// 2022-12-22 Close時にitem.titleが描き変わらないので再読み込み
    };
    
    const onItemResize = (itemId, time, edge) => {
        let index = items.findIndex(element => element.id === itemId );
        let itemList = items.concat();
	    let salon = salonList.find(element => element.id === itemList[index].salon_id);

        if ( ! itemList[index].start_time.isSame(moment(time), 'day') ) {
            Alert('日付を超えて指定できません。');
            return;
        }
        
        itemList[index].end_time   = moment(time);  // itemList[index].end_time.  add(diff,'milliseconds');
        itemList[index].title      = salon.name.trim() + ' ' + itemList[index].start_time.format('HH:mm') + ' - ' + itemList[index].end_time.format('HH:mm');
 
        setItems(itemList);
        itemReplace(itemList[index]);
		onItemSelect(itemId);		// 2022-12-22 Close時にitem.titleが描き変わらないので再読み込み
    };

    // 項目を選択した。項目を最初にクリックした時にコールされる
    const onItemSelect = (itemId, e, time) => {
        const findItem = items.find(element => element.id === itemId );

        setItem(findItem);
    };

    // 項目を選択した後にクリックした。
    const onItemClick = (itemId, e, time) => {
        const findItem = items.find(  element => element.id === itemId );
        const staff = staffList.find( element => element.staff_id === findItem.group);

        setSubTitle(staff.name + days[findItem.day_of_week]);
        setIsOpen(true);
    };

    const onCanvasClick = (groupId, time, e) => {
        const staff = staffList.find(element => element.staff_id === groupId );
        
        const salon_id = LocalStorage.getItem().salon_id;
    	const salon = salonList.find((s) => s.id === salon_id);
        const dt = new Date(time);
        setSubTitle(staff.name + days[dt.getDay()]);
        const newItem = {
            id:         0,
            group:      groupId,
            title:      salon.name.trim(), 
            tenant_id:  JWToken.getToken().tenant_id,
            salon_id:   salon_id,
            day_off_id: dayOffItem[0].id,
            start_time: moment(moment(time).format('YYYY-MM-DD ') + salon.stime),
            end_time:   moment(moment(time).format('YYYY-MM-DD ') + salon.etime),
            className:  'plan',
        	canMove:    true,
        	canResize:  true,
        	canChangeGroup: false,
        	bgColor:    'honeydew',
        };
        setItem(newItem);
        setIsOpen(true);
    };

    // ModalShift に渡す関数。    
    const onCancel = () => {
        setIsOpen(false);
    };
    
    const onClose = (item) => {
        setItem(item);
        if ( item.id ) {
            let index = items.findIndex(element => element.id === item.id );
            let itemList = items.concat();

            itemList[index] = item;

            setItems(itemList);

            itemReplace(item);
//    		onItemSelect(item.id);		// 2022-12-22 Close時にitem.titleが描き変わらないので再読み込み
        } else {
            itemInsert(item);
        }
        setIsOpen(false);

    };

    const onDelete = (item) => {
        itemDelete(item);
        setIsOpen(false);
    };

	// 店舗を変更した <Select onChange={}/> 用
	const salonChange = (option) =>  {

		salon_id = Number(option.value);

		// ローカルストレージにsalon_idを書き込む
		let storeItem = LocalStorage.getItem();
		storeItem.salon_id = salon_id;
		LocalStorage.setItem(storeItem);
		
		getTableList();		// 前後1ヶ月分の予約・勤怠データを読み込む
	}

	// 予約管理ボタンが押された
	const initButton = () => {
		getTableList();		// 前後1ヶ月分の予約・勤怠データを読み込む

	}


	// 店舗の設定
	let options = salonList.map( (salon) => {
		return { value: salon.id, label:salon.no + ' ' + salon.name.trim()};
	});
    let salon_id = LocalStorage.getItem().salon_id;
	let selectedSalon = salonList.find( (value) => {
		return value.id === salon_id;
	});
	let salonValue = { value: '', label:''};
	if ( selectedSalon !== undefined ) {
		salonValue = { value: selectedSalon.id, label: selectedSalon.name.trim()};
	}

    return (
      <div>
		<ButtonToolbar>
		    <ButtonGroup>
				<InputGroup style={{width:350}} >
					<Button onClick={initButton} id="ShiftBase">基本シフト</Button>　
					<InputGroupText>店舗</InputGroupText>
					<Select className="form-control react-select-original" placeholder="店舗名" value={salonValue} options={options} onChange={salonChange} />
				</InputGroup>
		    </ButtonGroup>
		</ButtonToolbar>
        <Timeline className="shiftpattern" id='TBL'
			sidebarWidth={120} lineHeight={45} itemHeightRatio={0.5} dragSnap={15*60*1000} canResizeRight={true} fixedHeader="fixed"
			maxZoom={7 * 86400 * 1000}
			stackItems={false} canChangeGroup={false}  clickTolerance={10} // itemTouchSendsClick={true} // bug? 他のイベントまで取り込んできまう
            timeSteps={tsteps}
            groups={groups}
            items={items}
            onTimeChange={onTimeChange}
            onItemMove={onItemMove}
            onItemResize={onItemResize}
            onItemClick={onItemClick}
            onItemSelect={onItemSelect}
            onCanvasClick={onCanvasClick}
            defaultTimeStart={moment('2023-01-01')}
            defaultTimeEnd=  {moment('2023-01-08')}
            visibleTimeStart={Number(timeStart)}
            visibleTimeEnd  ={Number(timeEnd)}
            itemRenderer={itemRenderer}
        >
            <TimelineHeaders className="sticky">
					    <DateHeader labelFormat={formatLabel} unit="primaryHeader" height={36}  />
					    <DateHeader labelFormat={formatLabel} height={36} />
			</TimelineHeaders>
        </Timeline >
        <ModalShift isOpen={isOpen} subTitle={subTitle} item={item} salonList={salonList} dayOffItem={dayOffItem} onDelete={onDelete} onClose={onClose} onCancel={onCancel} />

      </div>
    );  
};
/*			defaultTimeStart={moment('2023-01-01 00:00').tz(TIMEZONE)}  defaultTimeEnd={moment('2023-01-07 24:00').tz(TIMEZONE)}
			visibleTimeStart={moment('2023-01-01 00:00').tz(TIMEZONE)}  visibleTimeEnd={moment('2023-01-07 24:00').tz(TIMEZONE)}
*/

/*******************************************************************************
 * シフト管理のモーダル画面
 * 
 * onCanvasClick()、onItemClick()で表示される
 ******************************************************************************/
const ModalShift = (props) => {
	const {
		isOpen,
		title='シフトパターン',
		subTitle,
		item,
		salonList,
		dayOffItem,
		onClose,        // Shift_PatternにWriteする
		onCancel,
		onDelete,
//	    footer = <Button color="danger" onClick={onDelete(item)} >削除</Button>
	} = props;
    const [salonValue,  setSalonValue]  = useState({ });        // SelectのValue
    const [dayOffValue, setDayOffValue] = useState({ });        // SelectのValue
    const [startTime,   setStartTime]   = useState({ });
    const [endTime,     setEndTime]     = useState({ });
    const [startOptions,setStartOptions]= useState({ });
    const [endOptions,  setEndOptions]  = useState({ });

    const onUpdate = (value) => {
		let modifiedItem = {...item};
		modifiedItem.salon_id   = salonValue.value;
	    let salon = salonList.find(element => element.id === salonValue.value);
	    modifiedItem.day_off_id = dayOffValue.value;
        modifiedItem.start_time = moment(startTime.value);        // 日付を変えずに時間のみを変えるには？ ??????????????????????????????????????????
        modifiedItem.end_time   = moment(endTime.value);          // 日付を変えずに時間のみを変えるには？
        modifiedItem.title      = salon.name.trim() + ' ' + startTime.label + '-' + endTime.label; // moment(startTime.value).format('HH:mm') + ' - ' + moment(endTime.value).format('HH:mm');
        onClose(modifiedItem);
    };
    
    useEffect(() => {       // componentDidMount() の代わり
        let start    = moment().format('YYYY-MM-DD 09:00'); // 仮に 9:00
        let end      = moment().format('YYYY-MM-DD 18:00'); // 仮に18:00
        
        if ( item ) {
            // 開始時刻、終了時刻のoption(選択肢)を作成する
            start = item.start_time.format('YYYY-MM-DD HH:mm');
            end   = item.end_time.format(  'YYYY-MM-DD HH:mm');
            setStartTime  ({ value: start, label: item.start_time.format('HH:mm')});
            setEndTime    ({ value: end,   label: item.end_time.format(  'HH:mm')});

            let salonLabel = item.salon_id;
    	    let salon = salonList.find(element => element.id === item.salon_id);
            if ( salon ) salonLabel = salon.no + ' ' + salon.name.trim();
            setSalonValue ({ value: item.salon_id, label: salonLabel});

            let dayOffLabel = item.day_off_id;
            let dayOff = dayOffItem.find(element => element.id === item.day_off_id);
            if ( dayOff ) dayOffLabel = dayOff.no + ' ' + dayOff.name.trim();
            setDayOffValue({ value: item.day_off_id, label: dayOffLabel });
        }
        makeOptionList(start,end);
    }, [isOpen,item,salonList,dayOffItem]);


	let salonOptions = salonList.map( (value) => {						// 店舗のドロップダウンリスト
		return { value: value.id, label: value.no + ' ' + value.name.trim()};
	});
	
	let dayOffOptions = dayOffItem.map((value) => {
	    return { value: value.id, label: value.no  + ' ' + value.name.trim()};
	});

    const makeOptionList = (start,end) => {
        let st = start.split(/[\s:]+/);
        let ed = end.split(/[\s:]+/);
        let startMin = Number(st[1]) * 60 + Number(st[2]);
        let endMin   = Number(ed[1]) * 60 + Number(ed[2]);
        let startOpt = [];
        for ( let min = 0 ; min < endMin ; min += 15 ) {
            let label = parseInt(min/60,10).toString().padStart(2, '0') + ':' + (min%60).toString().padStart(2, '0');
            let value = st[0] + ' ' + label;
            startOpt.push({value: value ,label:label});
        }
        setStartOptions(startOpt);
        
    	let endOpt = [];
        for ( let min = startMin ; min <= 24*60 ; min += 15 ) {
            let label = parseInt(min/60,10).toString().padStart(2, '0') + ':' + (min%60).toString().padStart(2, '0');
            let value  = ed[0] + ' ' + label;
            endOpt.push({value: value ,label:label});
        }
        setEndOptions(endOpt);
    };

	const onDeleteItem = () => {
	    onDelete(item);
	};


    const onStartTime = (date) => {
        if ( moment(date).format('HH:mm') > moment(endTime).format('HH:mm') ) {
            Alert(<div>開始時刻は終了時刻{moment(endTime).format('HH:mm')}より前に設定してください。<br/>再設定してください。</div>)
            return;
        }       
        setStartTime(date);
        makeOptionList(date.value,endTime.value);
    }
	
    const onEndTime = (date) => {
        if ( moment(date).format('HH:mm') < moment(startTime).format('HH:mm') ) {
            Alert(<div>終了時刻は開始時刻{moment(startTime).format('HH:mm')}より後に設定してください。<br/>再設定してください。</div>)
            return;
        }
        setEndTime(date);
        makeOptionList(startTime.value,date.value);
    }


	let footer = <Button color="danger" onClick={onDeleteItem} >削除</Button>
	
    let body =
		<Form>
			<FormGroup disabled>
				<InputGroup>
					<InputGroupText style={{width:'90px'}}>店　　舗　</InputGroupText>
            		<Select className="form-control react-select-original" placeholder="店舗" value={salonValue} options={salonOptions} onChange={setSalonValue} 
            		        menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}/>
				</InputGroup>
				<InputGroup>
					<InputGroupText style={{width:'90px'}}>勤　　怠　</InputGroupText>
            		<Select className="form-control react-select-original" placeholder="勤怠" value={dayOffValue} options={dayOffOptions} onChange={setDayOffValue} 
            		        menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}/>
				</InputGroup>
				<InputGroup>
					<InputGroupText style={{width:'90px'}}>開始時刻　</InputGroupText>
            		<Select className="form-control react-select-original" placeholder="開始時間" value={startTime} options={startOptions} onChange={onStartTime}
            		        menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}/>
{/*                    <DatePicker className="form-control" selected={startTime} onChange={(date) => onStartTime(date)} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="開始時刻" dateFormat="HH:mm" /> */}
				</InputGroup>
				<InputGroup>
					<InputGroupText style={{width:'90px'}}>終了時刻　</InputGroupText>
            		<Select className="form-control react-select-original" placeholder="終了時間" value={endTime} options={endOptions} onChange={onEndTime}
            		        menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}/>
{/*                    <DatePicker className="form-control" selected={endTime}   onChange={(date) => onEndTime(date)  } showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="終了時刻" dateFormat="HH:mm" /> */}
				</InputGroup>
			</FormGroup>
		</Form>

    return (
        <ModalHook isOpen={isOpen} title={title} subTitle={subTitle} body={body} maxWidth = '400px' onCancel={onCancel} 
                   footer={<FooterOkCancel footer={footer} onDelete={onDeleteItem} onCancel={onCancel} onClose={onUpdate} />} />
    );

};

/*******************************************************************************
 * 担当者(group)をクリックするとDropcDown から１週間分の出勤時間を追加、削除する
 * 
 * onCanvasClick()、onItemClick()で表示される
 ******************************************************************************/
const MakeStaffShift = (props) => {
	const {
        id,             // 担当者id
        name,           // 担当者名
        tenant,         // テナント情報
        salon,          // 店舗
        dayOff,         // 勤怠届の１.勤務
        shift,          // シフトパターン
        reload,         // 再読込
	} = props;

	/* ----------------------------------------------
	   gropusをクリックして１週間分の追加を行う
	---------------------------------------------- */
	const onClickAdd = (staff_id ) => {

        // 既に登録されている 担当者のshiftListを作成する
        let preShift = shift.filter((value,index) => {
            return ( value.staff_id === staff_id);
        });
        
        console.log(preShift);      

        // 追加shiftを作成する		
		let shift1w = weekOfDay.map( (value,index) => {
            return ({
                tenant_id:      salon.tenant_id,    /* テナントID   */
                salon_id:       salon.id,           /* 店舗ID       */
                staff_id:       staff_id,           /* 担当者ID     */
                day_off_id:     dayOff.id,          /* 勤務         */
                day_of_week:    index,              /* 曜日 0:日、1:月、2:火、3:水、4:木、5:金、6:土 */
                shift_start:    salon.stime,        /* シフトパターン 開始時間  */
                shift_quit:     salon.etime,        /* シフトパターン 終了時間  */
            });
		});
		
		let addShift = shift1w.filter( (value, index ) => {
		    if ( tenant.day_off & ( 0x01 << value.day_of_week ) ) return(false);
		    let del = preShift.find( (val,index) => { return(val.day_of_week === value.day_of_week) });
            if ( del ) return(false);		    
		    return( true );
		});
		console.log(shift1w, preShift, addShift);
        const writeParam = { url: "/shift_pattern",	    errMsg: "シフトパターン",	list: addShift	};
		addRecords(writeParam, (dbData) => {
            reload();   // 再読込
		});
 	};

	/* ----------------------------------------------
	   gropusをクリックして１週間分を削除する
	---------------------------------------------- */
	const onClickDel = (staff_id ) => {
        // 既に登録されている 担当者のshiftListを作成する
        let preShift = shift.filter((value,index) => {
            return ( value.staff_id === staff_id);
        });
        		console.log("Del staff_id: " + staff_id, preShift);
        const writeParam = { url: "/shift_pattern",	    errMsg: "シフトパターン",	list: preShift	};
		delRecords(writeParam, (dbData) => {
            reload();   // 再読込
		});
	};


    return (
        <div>
            <UncontrolledDropdown className="me-2" direction="end" >
                <DropdownToggle caret color='transparent' style={{ fontSize:'15px' }} >
                    {name}
                </DropdownToggle>
                <DropdownMenu container="body">
                  <DropdownItem header>
                    選択してください
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => { onClickAdd(id,salon) }}>
                    １週間分追加する
                  </DropdownItem>
                  <DropdownItem onClick={() => { onClickDel(id) }}>
                    １週間分削除する
                  </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    );
};

/*******************************************************************************
 * シフトスケジュールを作成する
 *   Booking.jsのcomponentDidMount()で実行される(複数回コールされる事を考慮する)
 *   店舗情報salonにあるshift_monthをshift_creation_dateに変更する
 *   if ( 今日＋？週 > shift_creation_date ) シフト作成
 *   ModalShift()で作成した１週間のシフトモデルをシフトに展開する(シフト作成)
 *   shift_creation_dateはシフト作成を終了した日付とする
 *   シフトは１週間単位で作成する 作成後にshift_creation_dateを更新する
 *   シフトを作成した事をtoast表示する OKボタンを押さないとtoastが消えない
 *   シフトを作成する曜日をサロン毎に指定できる  salon.
 * 
 * onCanvasClick()、onItemClick()で表示される
 ******************************************************************************/
export const ShiftCreate = (props) => {
    console.log('ShiftCreate');
};


export default ShiftModel;
