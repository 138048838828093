/* ==============================================================================
 Guestのホーム画面
 履歴-----------------------------------------
 2019/07/04 reactstrap 対応済み

=============================================================================== */

import React, { Component } from 'react';
import { InputGroup,InputGroupText,FormGroup,Input } from 'reactstrap';

import Request from 'superagent';
import {JWToken} from './auth/GuestLogin';


class GuestHome extends Component {

	// コンストラクター
	constructor(props) {
		super(props);
		this.state = {
			customer: {id: 0, tenant_id:0, no: 550, name: '山田太郎', kana: 'ヤマダタロウ'},
		};
		console.log("GuestHome");
		this.getTable();

	}

	// 顧客の情報を取得する
	getTable = () => {
		//ajax通信する
		Request.get("/guest_cust")
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.log(err);
					console.log(res);
					console.error("顧客の情報を取得できませんでした。");
					return;
				}
				this.setState( { customer: res.body.dbData[0] } );
			}.bind(this));
	}

//-----------------------------------------------
// イベント処理関数
//-----------------------------------------------


	render() {
		return (
			<div>
				こんにちは {this.state.customer.name} さま<br/>
				{this.state.customer.point}	ポイント保有しています。			
				<form>
					<FormGroup disabled>
						<InputGroup>
							<InputGroupText>会員番号</InputGroupText>
							<Input type="text" value={this.state.customer.no} onChange={this.dayChange} readOnly={true} autoFocus={true}/>
						</InputGroup>
						<InputGroup>
							<InputGroupText>お客様名</InputGroupText>
							<Input type="text" value={this.state.customer.name} onChange={this.dayChange} readOnly={true}/>
						</InputGroup>
						<InputGroup>
							<InputGroupText>セイメイ</InputGroupText>
							<Input type="text" value={this.state.customer.kana} onChange={this.rateChange} readOnly={true}/>
						</InputGroup>
					</FormGroup>
				</form>
			</div>
		);

	}
}

export default GuestHome;
