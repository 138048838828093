/* ==============================================================================
 Star Web PRINT レシート印刷

=============================================================================== */
import { Component } from 'react';

import 'moment/locale/ja';
import moment from 'moment';
// import PropTypes from 'prop-types';

import StarWebPrintBuilder from './js/StarWebPrintBuilder';
import StarWebPrintTrader  from './js/StarWebPrintTrader';
import StarWebPrintDisplayBuilder from './js/StarWebPrintDisplayBuilder';
import StarWebPrintExtManager from './js/StarWebPrintExtManager';

import {changeYen} from '../Formatter';

var request = '';
var builder = {};
var trader  = {};
var paperWidth   = 46;

class StarReceipt extends Component {
/***
	static propTypes = {
		url:			PropTypes.string,				// 店舗情報
		width:			PropTypes.number,	// 用紙幅58mm:32文字/80mm:46文字
	};

	static defaultProps = {
		url: 'https://localhost:8001',	// BlueTooth
		width: 46
	}
***/	
	// コンストラクター
	constructor(props) {
		super(props);
		this.state = {
			width:	46,		// 58mm:32文字、80mm:46文字
			url:	'https://localhost:8001/StarWebPRNT/SendMessage',	// BlueTooth
			//  	'https://192.168.1.21/StarWebPRNT/SendMessage';	// LAN
			drawer:	true,
		};

		request = '';
	}

	connect = (url, width=46) => {			// default:Bluetooth、80mm:46文字
//		this.setState({
//			url: 	url + '/StarWebPRNT/SendMessage',
//			width:	width,
//		});
		
console.log('connect:',url);
		paperWidth = width;
		trader = new StarWebPrintTrader({url: url + '/StarWebPRNT/SendMessage'});
console.log('connected:',trader);
		
		trader.onReceive = function (response) {

	        var msg = '- onReceive -\n\n';

	        if (response.traderSuccess === 'true') {
	            msg += '印刷結果 : 成功\n';
	            return;			// 成功時は alert() 表示しない
	        }
	        else {
	            msg += '印刷結果 : 失敗\n';
	
	            msg += '状態 : [ ';
	
	            if (trader.isCoverOpen            ({traderStatus:response.traderStatus})) {msg += '\tカバーオープン,\n';}
	            if (trader.isOffLine              ({traderStatus:response.traderStatus})) {msg += '\tオフライン,\n';}
	            // if (trader.isCompulsionSwitchClose({traderStatus:response.traderStatus})) {msg += '\tCompulsionSwitchClose,\n';}
	            // if (trader.isEtbCommandExecute    ({traderStatus:response.traderStatus})) {msg += '\tEtbCommandExecute,\n';}
	            if (trader.isHighTemperatureStop  ({traderStatus:response.traderStatus})) {msg += '\t高温検知停止,\n';}
	            if (trader.isNonRecoverableError  ({traderStatus:response.traderStatus})) {msg += '\t復帰不可能エラー,\n';}
	            if (trader.isAutoCutterError      ({traderStatus:response.traderStatus})) {msg += '\tカッターエラー,\n';}
	            // if (trader.isBlackMarkError       ({traderStatus:response.traderStatus})) {msg += '\tBlackMarkError,\n';}
	            if (trader.isPaperEnd             ({traderStatus:response.traderStatus})) {msg += '\t紙無しエラー,\n';}
	            // if (trader.isPaperNearEnd         ({traderStatus:response.traderStatus})) {msg += '\tPaperNearEnd,\n';}
	
	            msg += ' ]\n';
	        }
	
	        alert(msg);
		};
		
		trader.onError = function (response) {
console.log('-- onError() --', response)
	        var msg = '- onError -\n\n';
	
	        msg += '\tStatus:' + response.status + '\n';
	        msg += '\tResponseText:' + response.responseText + '\n\n';
	        msg += 'Do you want to retry?\n';
	        var answer = window.confirm(msg);
	
	        if (answer) {
	        	this.StarReceipt();
	        } else {
	            return;
	        }
		};
console.log('StarWebPrintBuilder()');
		// レシートを作成
		builder = new StarWebPrintBuilder();
		request = builder.createInitializationElement({reset:false,print:false});
console.log('createInitializationElement()',request);

	}

	// プリンターに登録されているロゴの印刷	
	createLogo = ( num ) => {
		request += builder.createLogoElement({number:num, width:'single', height:'single'});		// Logoの印刷

	}
	
	// テキストを印刷
	createText = ( str ) => {
		request += builder.createTextElement({characterspace:0, codepage:'utf8' ,international:'japan', width:1, height:1, data: str + '\n'});

	}
	
	// テキストを２倍の文字で印刷
	createText2 = ( str ) => {
		request += builder.createTextElement({characterspace:0, codepage:'utf8' ,international:'japan', width:2, height:2, data: str + '\n'});

	}
	
	// 左寄せ、右寄せで印刷
	createTextW = ( left, right) => {
		let str = this.strLength( left, right, paperWidth );
		request += builder.createTextElement({characterspace:0, international:'japan', width:1, height:1, data: str + '\n'});

	}

	// 左寄せ、右寄せで２倍の文字で印刷
	createTextW2 = ( left, right) => {
		let str = this.strLength( left, right, paperWidth/2 );
		request += builder.createTextElement({characterspace:0, international:'japan', width:2, height:2, data: str + '\n'});

	}

	createCutPaper = () => {
	    request += builder.createCutPaperElement({feed:true});
	}

	// ドロアーオープン
	drawerOpen = () => {
			request += builder.createPeripheralElement({channel:1, on:200, off:200});
	}

	// 印刷する
	send = () => {
// console.log(request);
		trader.sendMessage({request:request});
	}

	// 漢字文字にスペースを追加して、文字数を統一する
/*	strLength = (str,len) => {
		var hankaku = new RegExp(/^[ｦ-ﾟ]*$/);
		var str1 = '';
		let l = 0;
		for ( let i= 0 ; i < str.length ; i++ ) {
			let char = str.charAt(i);
			if ( !hankaku.test(char) && encodeURI(char).length >= 4) {
				if ( l + 2 > len ) break;
				l ++;
			}
			if ( l + 1 > len ) break;
			l ++;
			if ( l <= len ) str1 += char;
		}
	    for ( ; l < len ; l++ ) { str1 += ' '; }
    
		return str1;
	}
*/
	strLength = (left,right,length) => {
		let len  = length - this.knjLength(left) - this.knjLength(right);
	    for ( let i = 0 ; i < len ; i++ ) { left += ' '; }
	    return left + right;

	}
	
	knjLength = (str) => {
	 
	  let len = 0;
	 
	  for (let i = 0; i < str.length; i++) {
	    (str[i].match(/[ -~]/)) ? len += 1 : len += 2;
	  }
	 
	  return len;
	}

	// ======================================================================================
	// スター精密  予約印刷
	// 
	// ======================================================================================
	printReserve = ( salon, visit, staffList, customerList,  techList, goodsList, techSlipList, goodsSlipList, taxRate ) => {
		// urlが空の場合は印刷しない
		if ( !salon.receipt_url ) return;

console.log(visit);
		let receipt = new StarReceipt();
		let url     = salon.receipt_url;
		let width   = salon.receipt_width;
		receipt.connect(url,width);		// レシートプリンターに接続する

		let day     = moment(visit.visit_date).format("YYYY年MM月DD日(ddd)");
		let comment = salon.comment.replace(/\\n/g, '\n');
		let staff   = staffList.find(  s => { return visit.staff_id  === s.id });
		let stylist = staff ? staff.name : "";
		let cust    = customerList.find( c => { return visit.customer_id === c.id });

		receipt.createLogo(1);										// ロゴの印刷
		
		receipt.createText2("ご 予 約 表　" );
		receipt.createText2( visit.name + ' 様');						// 顧客名の印刷
		if ( cust ) receipt.createText ("お客様番号　：" + cust.no);	// 日時の印刷
		receipt.createText ("予　約　日　：" + day);					// 日時の印刷
		receipt.createText ("予 約 時 刻 ：" + visit.visit_time.substr(0,5));	// 日時の印刷
		receipt.createText ("スタイリスト：" + stylist);			// スタイリストの印刷
//		receipt.createText ("レシート番号：" + visit.id);			// visit.idの印刷
		receipt.createText (" " );									// 改行

		// 技術項目の印刷
		let totalTech = 0;
		techSlipList.map( (s,index) => {
			totalTech +=  Number(s.price);
			let tech = techList.find(function(t) { return s.tech_id === t.id });
			let yen  = '\\' + changeYen(s.price);
			receipt.createTextW (tech.name,yen);		// 技術名、価格の印刷
			return s.price;
		});
		let techYen = '\\' + changeYen(totalTech);
		receipt.createTextW ('----技術合計----',techYen);
		receipt.createText (" " );							// 改行

		// 商品項目の印刷
		let totalGoods = 0;
		if ( goodsSlipList.length !== 0 ) {
			goodsSlipList.map( (s,index) => {
				totalGoods +=  Number(s.price);
				let goods = goodsList.find(function(g) { return s.goods_id === g.id });
				let yen   = '\\' + changeYen(s.price);
				receipt.createTextW (goods.name,yen);		// 商品名、価格の印刷
				return s.price;
			});
			let goodsYen = '\\' + changeYen(totalGoods);
			receipt.createTextW ('----商品合計----',goodsYen);
			receipt.createText (" " );							// 改行
		}

		let tax        = Math.floor((totalTech + totalGoods) * taxRate/10) * 10;
		let taxInc     = '\\' + changeYen( tax );
		let taxSales   = '\\' + changeYen( totalTech + totalGoods + tax);

		receipt.createTextW ('消費税',		taxInc);
		receipt.createTextW ('税込金額',	taxSales);

		receipt.createText  (' ');							// 改行印刷

		receipt.createText ("店舗名：" + salon.name.trim());// Salon名の印刷
		receipt.createText ("電　話：" + salon.phone);		// Salon電話番号の印刷
		receipt.createText (salon.address);					// Salon住所の印刷

		receipt.createText  (comment);						// コメント印刷
		
		receipt.createText  (' ');							// 改行印刷

		receipt.createLogo(2);								// QRコードの印刷

		receipt.createCutPaper();			// feed & Cut paper
		
		receipt.send();						// 印刷する
	}

}

/* -----------------------------------------------------------------------------
	カスタマーディスプレイ　表示
   ----------------------------------------------------------------------------- */
export class StarDisplay extends Component {

	show = (url, total)  => {
		
		let request = '';
		let display = new StarWebPrintDisplayBuilder();
		request += display.createTurnOnElement({type:'true'});
//	    request += display.createClearScreenElement({type:'all'});
	    request += display.createCursorElement({position:'home',mode:'off'});
		// ありがとうございます
        request += display.createTextElement({codepage:'shift_jis', data:'\x82\xa0\x82\xe8\x82\xaa\x82\xc6\x82\xa4\x82\xb2\x82\xb4\x82\xa2\x82\xdc\x82\xb7', binary:true});
        request += display.createReturnElement({type:'cr'});
        
        request += display.createTextElement({codepage:'shift_jis', data:'\x8d\x87\x8c\x76\x8b\xe0\x8a\x7a'+ '          '.slice(total.length) + total + '\x89\x7e', binary:true});

		this.sendDisplay(url,request);
	}

	show1 = (url, pay, change)  => {
		
		let request = '';
		let display = new StarWebPrintDisplayBuilder();
		request += display.createTurnOnElement({type:'true'});
//	    request += display.createClearScreenElement({type:'all'});
	    request += display.createCursorElement({position:'home',mode:'off'});

        request += display.createTextElement({codepage:'shift_jis', data:'\x82\xa8\x97\x61\x82\xa9\x82\xe8'+ '          '.slice(pay.length) + pay + '\x89\x7e', binary:true});
        request += display.createReturnElement({type:'cr'});
        request += display.createTextElement({codepage:'shift_jis', data:'\x82\xa8\x92\xde\x82\xe8'+ '            '.slice(change.length) + change + '\x89\x7e', binary:true});

		this.sendDisplay(url,request);
	}

	hide = (url)  => {
		let request = '';
		let display = new StarWebPrintDisplayBuilder();
	    request += display.createClearScreenElement({type:'all'});
	    request += display.createCursorElement({position:'home',mode:'off'});
		request += display.createTurnOnElement({type:'false'});
        request += display.createTextElement({codepage:'shift_jis', data:' ', binary:true});

		this.sendDisplay(url,request);
	}

	sendDisplay = (url,request) => {

	    let displayUrl = url + "/StarWebPRNT/SendDisplayExtMessage";
	
	    let manager = this.managerInit();
	    manager.write({request:request, url:displayUrl});
	
	}
	
	managerInit = () => {
		var manager = new StarWebPrintExtManager();    // for peripheral claim management
	    manager.onReceive = function(response) {
	        var msg = 'onReceive {\n';
	        msg += '\tManagerSuccess : [ ' + response.managerSuccess + ' ]\n';
	        msg += '\tManagerCode : [ '    + response.managerCode    + ' ]\n }';
	
	        console.log(msg);
	    }
	
	    manager.onError = function(args) {
	        var msg = 'onError {\n';
	        msg += '\tStatus: '      + args.status       + '\n';
	        msg += '\tResponseText:' + args.responseText + '\n }';
	
	        console.log(msg);
	    };
	
		manager.onAccessoryDisconnect     = function() {
	        console.log('event : onAccessoryDisconnect');
	    };
	    manager.onAccessoryConnectSuccess = function() {
	        console.log('event : onAccessoryConnectSuccess');
	    };
	    manager.onAccessoryConnectFailure = function() {
	        console.log('event : onAccessoryConnectFailure');
	    };
	    manager.onPrinterImpossible       = function() {
	        console.log('event : onPrinterImpossible');
	    };
	    manager.onPrinterOnline           = function() {
	        console.log('event : onPrinterOnline');
	    };
	    manager.onPrinterOffline          = function() {
	        console.log('event : onPrinterOffline');
	    };
	    manager.onPrinterPaperReady       = function() {
	        console.log('event : onPrinterPaperReady');
	    };
	    manager.onPrinterPaperNearEmpty   = function() {
	        console.log('event : onPrinterPaperNearEmpty');
	    };
	    manager.onPrinterPaperEmpty       = function() {
	        console.log('event : onPrinterPaperEmpty');
	    };
	    manager.onPrinterCoverOpen        = function() {
	        console.log('event : onPrinterCoverOpen');
	    };
	    manager.onPrinterCoverClose       = function() {
	        console.log('event : onPrinterCoverClose');
	    };
	    manager.onCashDrawerOpen          = function() {
	        console.log('event : onCashDrawerOpen');
	    };
	    manager.onCashDrawerClose         = function() {
	        console.log('event : onCashDrawerClose');
	    };
	    manager.onDisplayImpossible       = function() {
	        console.log('event : onDisplayImpossible');
	    };
	    manager.onDisplayConnect          = function() {
	        console.log('event : onDisplayConnect');
	    };
	    manager.onDisplayDisconnect       = function() {
	        console.log('event : onDisplayDisconnect');
	    };
	    manager.onWrite                   = function() {
	        console.log('event : onWrite');
	    };
	    manager.onStatusUpdate            = function(args) {
	        console.log('event : onStatusUpdate');
	    };
	    return manager;
	}

}


export default StarReceipt;
