/* ==============================================================================
 技術売上情報の編集画面

 履歴-----------------------------------------
 2019/03/17 reactstrap 対応済み

=============================================================================== */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button,ButtonToolbar,Modal,ModalHeader,ModalBody,ModalFooter,InputGroup,InputGroupText,Input,Form,FormGroup } from 'reactstrap';

import ReactDataGrid from 'react-data-grid';
import Select from 'react-select';
import Check from './Input';
import {Alert} from './auth/Login';

import './Table.css';

import { AlignLeft,AlignRight,YenFormat } from './Formatter';	// React Data Grid

import { MAX_DB_LENGTH, EDIT_UNSELECT, EDIT_ADD, EDIT_REP, EDIT_DEL } from './Define';

var editStatus = EDIT_UNSELECT;

function changeYen(num){
    return '¥' + String(num).split("").reverse().join("").match(/\d{1,3}/g).join(",").split("").reverse().join("");
}

export class TechSlipGrid extends Component {
	
	static propTypes = {
		visit: PropTypes.object.isRequired,			// 予約データ
		slipList: PropTypes.array.isRequired,		// 伝票
		techList: PropTypes.array.isRequired,		// 技術一覧
		staffList: PropTypes.array.isRequired,		// 担当者一覧
		onRowSelect:  PropTypes.func,				// 技術が選択された時にコールされる 未使用
		onRowUpdated: PropTypes.func,				// 技術伝票、施術時間、合計金額を渡して関数コール
		height: PropTypes.number
	}

	//props初期値指定
	static defaultProps = {
		onRowSelect: null,
		onRowUpdated: null,
		height: 600
	};

	// コンストラクター
	constructor(props) {
		super(props);
		this.state = {
			selectedIndexes:[0],    // react-data-grid
			rows:   [],     // [{ tenant_id: 0, visit_id: 0, tech_id: 0, staff_id: 0, price: 0 }] 各プロパティの順番はDB登録時のJSON変換で重要
			rowData: {},	// 各プロパティの順番はDB登録時のJSON変換で重要
			total: 0,
			buttonDisable: true,  // 一覧選択時の修正ボタン、削除ボタンの設定
			title: "",
			techSelect:{}	// { value: id, label: id + name },
		};
	}

	// 描画が行われる直前に一度だけ呼ばれる
	componentDidMount = () => {
//		console.log('componentDidMount',this.props.slipList);
		this.setState({ rows: this.props.slipList });
//		console.log(this.props.slipList);
	}

/****
   	// 描画が成功して、DOMにアクセス可能になる
	componentDidMount() {
    	console.log("componentDidMount",this.props.slipList);
	
	}
*****/
	// プロパティ(props)が変更された時にコールされる
	static getDerivedStateFromProps = (nextProps) => {
//		console.log('getDerivedStateFromProps nextProps',nextProps);

		let total = 0;
   		let slipList = nextProps.slipList.map(function(o){
			let row  = Object.assign({},o);
			let staff  = nextProps.staffList.find(function(s) { return o.staff_id === s.id; });
			row.staff  = staff.name;
			
			let tech   = nextProps.techList.find(function(t) { return o.tech_id === t.id; });
			row.period = tech.period;
			row.tech   = tech.name;
			total += Number(o.price); 
			return row;
		});
	
		if ( slipList.length === 0 ) {
			return( {
				total:total,
				rows: slipList,
				buttonDisable:true
			});
		} else {
			return( {
				total:total,
				rows: slipList,
				buttonDisable:false
			});
		}
//		this.onRowUodated(slip);			// ここで、propsを変更すると無限ループになる

	}


	componentDidUpdate() {
		let input = this.refs['techName'];
		input && input.focus();
	}
	
	// 追加ボタン
	onRowAdd = () => {
//		console.log('onRowAdd()',this.props.visit);
		let staff_id = this.props.visit.staff_id;
		let staff = this.props.staffList.find(function(o) { return o.id === staff_id; });
		let tech  = this.props.techList[0];
		let slip = {			// DBに登録するときにJSONに変換する。このとき順番が重要
			tenant_id:this.props.visit.tenant_id, 
			visit_id: this.props.visit.id,
			tech_id:  tech.id,
			staff_id: staff.id,
			price:    tech.price,
			period:   tech.period,
			tech:     tech.name,
			staff:    staff.no + " " + staff.name
		};

		editStatus = EDIT_ADD;
		
		let i = this.state.rows.length;
		console.log('onRowAdd',i);
		this.setState({ 
			rowData:			slip,
			selectedIndexes:	[i],
			title:				"技術 追加",
			showModal:			true,
//			selectTech:			{ value: slip.tech_id, label: slip.tech }
		});

	}

	// 修正ボタン
	onRowRep = () => {
		if ( this.props.techList.length === 0 ) {
			Alert('技術が登録されていません。技術を登録してください。');
			return;
		}

		editStatus = EDIT_REP;
		
		let i = this.state.selectedIndexes[0];
		console.log('onRowRep',i);
		let slip = Object.assign({},this.state.rows[i]);
		this.setState({ 
			rowData:			slip,
			selectedIndexes:	[i],
			title:				"技術 修正",
			showModal:			true,
//			selectTech:			{ value: slip.tech_id, label: slip.tech }
		});
	}

	// 削除ボタン
	onRowDel = () => {
		editStatus = EDIT_DEL;

		let i = this.state.selectedIndexes[0];
		let rows = this.state.rows.concat();
		rows.splice(i,1);
		this.onRowUpdated(rows);
		if ( i >= rows.length ) this.setState({ selectedIndexes: [0]});
	}


	// 登録ボタンが押された
	close = () => {
		var rows = [];
		if ( editStatus === EDIT_ADD ) {
			rows = this.state.rows.concat(this.state.rowData);
		} else {
			let i = this.state.selectedIndexes[0];
			rows = this.state.rows.concat();
			rows[i] = this.state.rowData;
		}
		this.setState( { rows: rows });
		this.setState({showModal: false });
		this.onRowUpdated(rows);
	}
	
	// キャンセル
	cancel = () => {
		this.setState({showModal: false });
	}

	// 親コンポーネントのコールバックでrowsを渡す		
	onRowUpdated = (rows) => {
		if ( this.props.onRowUpdated === null ) {
			this.setState({rows:rows});
			return;
		}
		let duration = 0;
		let total = 0;
		let slip = rows.map(function(o){
			duration += o.period;
			total    += Number(o.price);
			let s = Object.assign({},o);
			delete s.tech;					// 技術名を削除
			delete s.staff;					// 担当者名を削除
			delete s.period;				// 施術時間を削除 tech_slip に合わせる 
			return s;
		});
		this.setState({total:total});
		
		if ( this.props.onRowUpdated(slip,duration,total) ) {
			this.setState({rows:rows});
		}
	}


/* ReactDataGrid -------------------------------------------- */ 
	onCellSelected = ({ rowIdx, idx }) => {
		this.setState( {selectedIndexes: [rowIdx]} );
console.log(rowIdx);
//		this.setState({buttonDisable: false});
		if ( this.props.onRowSelect !== null) {
			this.props.onRowSelect(this.rowGetter(rowIdx));  // propsに設定された外部関数をコールする
		}
	}

	onRowDoubleClick = () => {
		this.onRowRep();
	}

	rowGetter = (rowIdx) => {
		return this.state.rows[rowIdx];
	}

/* ReactDataGrid --------------------------------------------  */

	// InputにFocusを当てると選択状態にする
	focusSelect = (event) => {
		event.target.select();
	}
	
	techChange = (event) => {
//		console.log(event.value);
		let id = Number(event.value);
		let tech = this.props.techList.find(function(o) { return o.id === id; });
		let slip = this.state.rowData;
		slip.tech_id = id;
		slip.price   = tech.price;
		slip.tech    = tech.name;			// 技術名を追加
		slip.period  = tech.period;			// 施術時間を追加

		this.setState({ rowData: slip });
	}

	staffChange = (option) => {
		let id = Number(option.value);
		let staff = this.props.staffList.find(function(o) { return o.id === id; });
		let slip = this.state.rowData;
		slip.staff_id = id;
		slip.staff    = staff.name;			// 担当者名を追加
		this.setState({ rowData:slip });
	}

	priceChange = (event) => {
		if ( Check.money(event.target.value) === false ) return;
		let slip   = Object.assign({}, this.state.rowData);
		slip.price = event.target.value;
		this.setState({ rowData:slip });
	}

//	toggle() {
	toggle = () => {
		if ( this.state.showModal === true ) {
			this.cancel();
			this.setState( { showModal: false } );
		} else {
			this.setState( { showModal: true } );
		}
	}

	
	render() {
		const columns= [
				 { key: 'tech', name: '技術名', width: 137, editable: false, resizable: true, formatter:AlignLeft },
				 { key: 'staff',name: '担当者', width: 100, editable: false, resizable: true, formatter:AlignLeft },
				 { key: 'price',name: '価格'  , 　　　　　  editable: false, resizable: true, formatter:YenFormat },
				 { key: 'period',name:'時間',   width:  70,	editable: false, resizable: true, formatter:AlignRight }
			 ];

		// 担当者のSelect設定
		let list = this.props.staffList.filter( (staff) => {		// 削除された番号は表示しない
			return staff.no < MAX_DB_LENGTH;
		});
		let staffOptions = list.map( (staff) => {
			return { value: staff.id, label:staff.no + ' ' + staff.name};
		});
		var staff = staffOptions.find( (option) => {
			return option.value === this.state.rowData.staff_id;
		});

		// 技術のSelect設定
		list = this.props.techList.filter( (tech) => {		// 削除された番号は表示しない
			return tech.no < MAX_DB_LENGTH;
		});
		let techOptions = list.map( (tech) => {
			return { value: tech.id, label:tech.no + ' ' + tech.name};
		});
		var tech = techOptions.find( (option) => {
			return option.value === this.state.rowData.tech_id;
		});

		return (
			<div className="TechSlip">
				<ButtonToolbar>
					<Button className="float-right" color="primary" onClick={this.onRowAdd}>追加</Button>
					<Button className="float-right" color="success" onClick={this.onRowRep} disabled={this.state.buttonDisable}>修正</Button>
					<Button className="float-right" color="danger"  onClick={this.onRowDel} disabled={this.state.buttonDisable}>削除</Button>
				    <div className="d-flex align-items-end">　技術合計：{changeYen(this.state.total)}円</div>
				</ButtonToolbar>

				<ReactDataGrid minHeight={this.props.height} // minWidth={420}
							rowGetter={this.rowGetter} columns={columns} rowsCount={this.state.rows.length}
							// onRowUpdated={this.handleRowUpdated}  
							onCellSelected={this.onCellSelected} onRowDoubleClick={this.onRowDoubleClick}
					rowSelection={{
						showCheckbox: false,
						enableShiftSelect: false,
						selectBy: {
							indexes: this.state.selectedIndexes
						}
					}}  />
				
				<Modal isOpen={this.state.showModal} backdrop={'static'} onClose={this.cancel} autoFocus={false}> 
					<ModalHeader toggle={this.toggle}>{this.state.title}</ModalHeader>
					<ModalBody>
						<Form>
							<FormGroup>
								<InputGroup>
									<InputGroupText>技術名</InputGroupText>
									<Select className="form-control react-select-original"  placeholder="技術" value={tech} options={techOptions} onChange={this.techChange} readOnly={this.state.readOnly} autoFocus={true}
											menuPortalTarget={document.body} menuPosition={'fixed'}   styles={ {menuPortal: provided => ({ ...provided, zIndex: 9999 }), menu: provided => ({ ...provided, zIndex: 9999 })}} />
								</InputGroup>
								<InputGroup>
									<InputGroupText>担当者</InputGroupText>
									<Select className="form-control react-select-original"  placeholder="担当者名" value={staff} options={staffOptions} onChange={this.staffChange} readOnly={this.state.readOnly}  />
								</InputGroup>
								<InputGroup>
									<InputGroupText>価　格</InputGroupText>
									<Input type="number" placeholder="金額" value={this.state.rowData.price} onChange={this.priceChange} onFocus={this.focusSelect} readOnly={this.state.readOnly}/>
								</InputGroup>
							</FormGroup>
						</Form>
					 </ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.close} disabled={this.state.closeDisable}>OK</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}

}


export default TechSlipGrid;
