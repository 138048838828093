/* ==============================================================================
 技術情報の編集画面
 
 履歴-----------------------------------------
 2019/03/04 reactstrap対応済

=============================================================================== */

import React, { Component } from 'react';
import { InputGroup,InputGroupText,FormGroup,Input,Table } from 'reactstrap';

import GridTable from './GridTable';
import Check from './Input';
import { AlignLeft,AlignNumber,AlignRight,YenFormat } from './Formatter';	// React Data Grid
import { Filters } from 'react-data-grid-addons';
import { getMasters,getRecord,addRecord,repRecords } from './Agent';
import PrintPreview from './PrintPreview';
import moment from 'moment';

import {
	MAX_DB_LENGTH,
	MAX_NAME_LENGTH,
	MAX_NICKNAME_LENGTH,
	CHECK_NO, CHECK_NAME, CHECK_DUP,
	COLOR_ERROR, COLOR_OK
} from './Define';

const masterParam	= [
	{ url: "/tech",		errMsg: "技術",		list: []	},
	{ url: "/tech_sum",	errMag: "技術集計",	list: []	}
];

const initialRow = {id: 0, tenant_id: 0, no: 0, name: "", price: 0, period: 0,  sum_id: 1, sumName: ""};

var rowProp = Object.assign({},initialRow);
var condition = 0;			// 画面の入力チェック

const {
	NumericFilter,
	AutoCompleteFilter,
//	MultiSelectFilter,
//	SingleSelectFilter
} = Filters;

class Tech extends Component {
	
	// コンストラクター
	constructor(props) {
		super(props);
		this.state = {
			tableList:  [ {id: 0, tenant_id: 0, no: 0, name: "", price: 0, period: 0, sum_id: 1, sumName: "", sumNo: 0 } ],
			optionList: [ {id: 0, name: ""} ],		// 技術集計 id, name
			rowData:      {id: 0, tenant_id: 0, no: 0, name: "", price: 0, period: 0, sum_id: 1, sumName: "", sumNo: 0 },
			noStatus: "ＯＫ",
			indexes:	null,
			searchRow:  null,
			closeButton: true,
			noColor:   '#fff0c0',
			nameColor: '#fff0c0',
			printContent: <div></div>
		};
		
		this.getTableList();
	}


	// 技術集計id で技術集計名を取得する
	lookupSum = (list,sum) => {
		for ( let i = 0; i < list.length; i++) {
			let id = list[i].sum_id;

			const found = sum.find(element => element.id === id);
			if ( found ) {
				list[i].sumName = found.name;
				list[i].sumNo   = found.no;
			} else {
				list[i].sumName = "-";
				list[i].sumNo   = 0;
			}
/*****************************************************
			list[i].sumName = "-";
			list[i].sumNo   = 0;
			for ( let j = 0; j < sum.length; j++ ) {
				if ( id === sum[j].id ) {
					list[i].sumName = sum[j].name;
					list[i].sumNo   = sum[j].no;
					break;
				}
			}
******************************************************/
		}
	}

	// 技術集計一覧を取得する
	getTableList = () => {
		getMasters(masterParam,function () {
			this.lookupSum(masterParam[0].list,masterParam[1].list);
			this.setState({ 
				tableList: masterParam[0].list,
				optionList:masterParam[1].list,
			});	
		}.bind(this));
	}

	// 技術取得
	getTable = (id) => {
		getRecord(masterParam[0],id,function(rec){
			rowProp = Object.assign({},rec);
			this.setState({rowData: rowProp });
		}.bind(this));		
	}

	rowGetter = (rowIdx) => {
		return this.state.tableList[rowIdx];
	}

	// 追加ボタンが押された
	openAdd = () => {
		rowProp = Object.assign({},initialRow);
		if ( this.state.optionList.length !== 0 )
			rowProp.sum_id = this.state.optionList[0].id;	// 技術集計は最初の集計に
		this.setState({ 
			rowData: rowProp,
			indexes: null,
		});
		condition = 0;
		this.checkParam();
		
	}

	// 修正ボタンが押された
	openRep = ( row,index ) => {
		rowProp = Object.assign({},row[index]);
		this.setState({ 
			rowData: rowProp,
			indexes: null,
		});
		condition = 0;
		this.checkParam();
	}

	// 削除ボタンが押された
	openDel = ( row,indexes ) => {
		this.setState({
			rowData:	null,				
			indexes:	indexes,
			searchRow:	row,
			closeButton: true
		});
	}

	// 追加でクローズボタンが押された
	closeAdd = () => {
		delete rowProp.sumName;
		delete rowProp.sumNo;
		masterParam[0].list = new Array(rowProp);
		addRecord(masterParam[0],function (record) {
			this.getTableList();
		}.bind(this));
	}

	// 編集でクローズボタンが押された
	closeRep = () => {
		delete rowProp.sumName;
		delete rowProp.sumNo;
		masterParam[0].list = new Array(rowProp);
		repRecords(masterParam[0], function (){
			this.getTableList();
		}.bind(this));
	}

	// 削除でクローズボタンが押された
	closeDel = () => {
		// 実際は削除せずに大きな番号を付与する
		let row = this.state.indexes.map(function(value,ondex){
			let table = this.state.searchRow[value];
			return { 
				id: table.id,
				no: table.id + MAX_DB_LENGTH,
			};
		}.bind(this));
		masterParam[0].list = row;
		repRecords(masterParam[0], function (record){
			this.getTableList();
		}.bind(this));
	}

	cancel = () => {
	}

	closeButton = (bool) => {
		this.setState({closeButton: bool });
	}

	// DBに登録されている番号か検索する
	checkNo = ( id,no ) => {
		let res = this.state.tableList.find( function (row,index){
				// eslint-disable-next-line
				if ( id == row.id ) return false;
				// eslint-disable-next-line
				if ( no == row.no ) return true;
				return false;
			});

		if ( res === undefined ) return true;
		return false;
	}
	
//-----------------------------------------------
// イベント処理関数
//-----------------------------------------------
	noChange = (event) => {

		let id    = rowProp.id;					// 編集中のid 追加時は0
		let newNo = event.target.value;     	// 入力した番号
		rowProp.no = newNo;
		
		let res = this.checkNo(id,newNo);		// 番号を検索する
		if ( res === true ) {
			this.setState( { 
				rowData: rowProp,
				noStatus: "ＯＫ" ,
			});
			condition = condition & ~CHECK_DUP;
		} else {
			this.setState( { 
				rowData: rowProp,
				noStatus: "重複" ,
			});
			condition = condition | CHECK_DUP;
		}
		
		this.checkParam();
	}

	// InputにFocusを当てると選択状態にする
	focusSelect = (event) => {
		event.target.select();
	}
	
	nameChange = (event) => {
		if ( event.target.value.length > MAX_NAME_LENGTH ) return;
		rowProp.name    = event.target.value;
		this.setState({ rowData:rowProp });

		this.checkParam();
	}

	nicknameChange = (event) => {
		if ( event.target.value.length > MAX_NICKNAME_LENGTH ) return;
		rowProp.nickname    = event.target.value;
		this.setState({ rowData:rowProp });
	}

	priceChange = (event) => {
		if ( Check.money(event.target.value) === false ) return;
		rowProp.price   = Number(event.target.value);
		this.setState({ rowData:rowProp });
	}

	periodChange = (event) => {
		if ( Check.money(event.target.value) === false ) return;
		rowProp.period  = event.target.value;
		this.setState({ rowData:rowProp });
	}

	techSumChange = (event) => {
		rowProp.sum_id= event.target.value;
		this.setState({ rowData:rowProp });
	}

	checkParam = () => {
		// 番号をチェック
		switch ( rowProp.no ) {
			case undefined:
			case 0:
			case "":
			case "0":
			case null:
				condition = condition | CHECK_NO;				// 実行ボタンを無効にする
				this.setState( { noStatus: "ＮＧ" });
				break;
			default:
				condition = condition & (~CHECK_NO);			// 実行ボタンを有効にする
				break;
		}

		// 名前をチェック
		switch ( rowProp.name ) {
			case undefined:
			case "":
			case null:
				condition = condition | CHECK_NAME;		// 実行ボタンを無効にする
				break;
			default:
				condition = condition & (~CHECK_NAME);	// 実行ボタンを有効にする
				break;
		}

		// ボタン、バックグランドカラーの設定
		if ( condition === 0) {							// 一つでもエラーがあればボタンを無効にする
			this.setState({
				closeButton: true,
				noColor:   COLOR_OK,
				nameColor: COLOR_OK,
				noStatus: "ＯＫ"
			});
		} else {
			this.setState({	closeButton: false });
			if ( (condition & (CHECK_NO | CHECK_DUP)) === 0 ) {
				this.setState({
					noColor: COLOR_OK,
					noStatus: "ＯＫ"
				});
			} else {
				this.setState({	noColor: COLOR_ERROR });
			}
	
			if ( (condition & CHECK_NAME) === 0 ) {
				this.setState({	nameColor: COLOR_OK	});
			} else {
				this.setState({	nameColor: COLOR_ERROR });
			}
		}
	}

	// 技術印刷
	printTech = () => {
		// ヘッダー
		let header = (
				<Table  size={"sm"} hover borderless >
					<tbody >
						<tr>
							<th>{"技術　"  + moment().format('YYYY年MM月DD日 HH:mm')}</th>
						</tr>
					</tbody>
				</Table>
		);

		// 技術の印刷
			const sorted = [...this.state.tableList].sort((a, b) => a.sumNo - b.sumNo);		// tableListをソートしてsortedに
			let techElement = sorted.map(function(value, index) {
			return (
				<tr key={index}>
				{ ( Number(value.no) < MAX_DB_LENGTH ) ? <td><AlignRight value={value.no} /></td> :	<td>削除</td> 	}
				  <td>{value.name}</td>
				  <td>{value.nickname}</td>
				  <td><YenFormat value={Number(value.price)} /></td>
				  <td><AlignRight value={value.period} /></td>
				  <td>{value.sumNo + ' ' + value.sumName}</td>
				</tr>
			);
		});

		// ページの処理
		let content = [];
		for ( let i = 0 ; i < techElement.length ; i += 80 ) {
			content.push(
				<section className={"sheet padding-10mm"} key={i}>
					{header}
					<Table bordered size={"sm"} hover>
						 <thead bgcolor="#ebf0f7">
							<tr >
								<th>番号    </th>
								<th>技術名  </th>
								<th>略称    </th>
								<th>料金    </th>
								<th>施術時間</th>
								<th>技術集計</th>
							</tr>
						</thead>
						<tbody bgcolor="#ffffff">
							{ techElement.slice(i , i + 80) }
						</tbody>
					</Table>
				</section>
			);
		}
		return content;
	}

	render() {

		var form = <div></div>;
		
		if ( !this.state.indexes ) {
			form =
				<form>
					<FormGroup disabled>
						<InputGroup>
							<InputGroupText>番　号</InputGroupText>
							<Input type="number" style={{backgroundColor: this.state.noColor}} placeholder="番号" value={this.state.rowData.no} onFocus={this.focusSelect} onChange={this.noChange} autoFocus={true}/>
							<InputGroupText>{this.state.noStatus}</InputGroupText>
						</InputGroup>
						<InputGroup>
							<InputGroupText>技術名</InputGroupText>
							<Input type="text" style={{backgroundColor: this.state.nameColor}} placeholder="技術名（20文字以内）" value={this.state.rowData.name} onChange={this.nameChange}/>
						</InputGroup>
						<InputGroup>
							<InputGroupText>略　称</InputGroupText>
							<Input type="text" placeholder="技術略称（4文字以内）" value={this.state.rowData.nickname} onChange={this.nicknameChange}/>
						</InputGroup>
						<InputGroup>
							<InputGroupText>価　格</InputGroupText>
							<Input type="text" placeholder="技術単価" value={this.state.rowData.price} onChange={this.priceChange} onFocus={this.focusSelect}/>
						</InputGroup>
						<InputGroup>
							<InputGroupText>時　間</InputGroupText>
							<Input type="number" placeholder="施術時間" value={this.state.rowData.period} onChange={this.periodChange} onFocus={this.focusSelect}/>
						</InputGroup>
						<InputGroup>
							<InputGroupText>集　計</InputGroupText>
							<Input type="select" placeholder="技術集計" value={this.state.rowData.sum_id} onChange={this.techSumChange}>
							{
								this.state.optionList.flatMap(function (item) {
									if ( item.no >= MAX_DB_LENGTH ) return [];
									return [<option value={item.id} key={item.id}>{item.name}</option>];
								})
							}
							</Input>
						</InputGroup>
					</FormGroup>
				</form>;
		} else {
			let rowData = this.state.indexes.map(function(element, index, array) {
	    		return Object.assign({},this.state.searchRow[element]);
			}.bind(this));
			
			// rowDataのを番号順にソート
			rowData.sort(function(a,b){
				return a.no - b.no;	
			});

			form =
				<form>
					以下の技術を削除します。<br/>
					<Table bordered hover size={"sm"} >
						 <thead bgcolor="#ebf0f7">
							<tr>
								<th><div className="text-center">番号</div></th>
								<th>技術名</th>
							</tr>
						</thead>
						<tbody>
							{
								rowData.map(function(value, index, array) {
									return (
										<tr key={index}>
											<td ><div className="text-center">{value.no  }</div></td>
											<td ><div className="text-left"  >{value.name}</div></td>
										</tr>
									);
								})
							}
						</tbody>
					</Table>
				</form>;
		}
		
		const columns = [
			{ key: 'no',     name: '番号',    width: 80,  editable: false, resizable: false,formatter:AlignNumber, filterable: true, sortable: true, filterRenderer: NumericFilter },
			{ key: 'name',   name: '技術名',  width: 250, editable: false, resizable: true, formatter:AlignLeft,   filterable: true, sortable: true	 },
			{ key: 'nickname',name: '略称',	  width:  80, editable: false, resizable: true, formatter:AlignLeft,   filterable: true, sortable: true  },
			{ key: 'price',  name: '料金',    width: 100, editable: false, resizable: true, formatter:YenFormat,   filterable: true, sortable: true, filterRenderer: NumericFilter },
			{ key: 'period', name: '施術時間',width: 100, editable: false, resizable: true, formatter:AlignRight,  filterable: true, sortable: true, filterRenderer: NumericFilter },
			{ key: 'sumNo',  name: '集計番号',width: 80,  editable: false, resizable: true, formatter:AlignLeft,   filterable: true, sortable: true, filterRenderer: NumericFilter },
			{ key: 'sumName',name: '技術集計',            editable: false, resizable: true, formatter:AlignLeft,   filterable: true, sortable: true, filterRenderer: AutoCompleteFilter }
		 ];

		return (
			<GridTable title={'技術'} form={form}
					tableRows={this.state.tableList} rowGetter={this.rowGetter} length={this.state.tableList.length} getTable={this.getTableList}
					header={<PrintPreview isPoppedOut={false} title={ "印刷" } create={this.printTech} />}
					columns={columns} 
					closeButton={this.state.closeButton}
					openAdd={this.openAdd} openRep={this.openRep} openDel={this.openDel}
					closeAdd={this.closeAdd} closeRep={this.closeRep} closeDel={this.closeDel}
					cancel={this.cancel}
			/>
		);
	}
}

export default Tech;
