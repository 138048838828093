/* ==============================================================================
 店舗の編集画面

 履歴-----------------------------------------
 2019/06/09 新規作成
 
=============================================================================== */

import React, { Component } from 'react';
import { InputGroup,InputGroupText,FormGroup,Input,Table,Container,Row,Col} from 'reactstrap';
import ReactDataGrid from 'react-data-grid';
import Request from 'superagent';
import {JWToken} from './auth/Login';
import GridTable from './GridTable';
import Check from './Input';
import { AlignLeft,AlignCenter } from './Formatter';	// React Data Grid
import { Filters } from 'react-data-grid-addons';

const MAX_DB_LENGTH = 100000;
const MAX_NAME_LENGTH = 40;
const MAX_PHONE_LENGTH = 15;
const MAX_MAIL_LENGTH = 128;
const MAX_ADDRESS_LENGTH = 100;
const CHECK_NO = 0x01, CHECK_NAME = 0x02, CHECK_DUP = 0x04;
const COLOR_ERROR = '#fff0c0', COLOR_OK = '#ffffff';

var initialRow =  {no: 0, name: "", phone: "", address: "", time_unit: 5, break_time: "01:00", stime: "09:00", etime: "19:00", workday: 0, shift_date:undefined };

var rowProp = Object.assign({},initialRow);
var condition = 0;

const {
	NumericFilter,
//	AutoCompleteFilter,
//	MultiSelectFilter,
//	SingleSelectFilter
} = Filters;

class Salon extends Component {

    // コンストラクター
    constructor(props) {
        super(props);
        this.state = { 
            rowData: Object.assign({},initialRow),
            tableList: [],
            staffList: [],
            stylistList:[],
			selectedIndexes:[], 	// react-data-grid
			indexes:	null,
			searchRow:  null,
            noStatus: "ＯＫ",
            closeButton: true,
			
            noColor: '#f0f0f0',
            nameColor: '#f0f0f0',
        };
    }

	// 描画が行われる直前に一度だけ呼ばれる
	componentDidMount() {
        this.getTableList();
        this.getStaffList();
	}

    // 店舗一覧取得
    getTableList = () => {
        //ajax通信する
        Request.get("/salon" )
            .query({ token: JWToken.getToken().token})
            .end(function(err, res) {
                if (err) {
                    console.log(err);
					console.error("店舗一覧を取得できませんでした。");
                    this.setState({rowData: initialRow });
					return;
                }
                let row = res.body.dbData.map(function( r ) {
				    if ( r.comment !== null && r.comment !== undefined ) {
						r.comment = r.comment.replace(/\\n/g,'\n');		// reactは'\n'の処理を行ってくれない？ので自分で改行した
					}
					return r;
                });
 				this.setState( { tableList: row } );
            }.bind(this));
    }

	// 店舗取得
	getTable = (id) => {
		//ajax通信する
		Request.get("/salon/" + id)
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.log(err);
					console.log(res);
					console.error("店舗を取得できませんでした。");
					return;
				}
				rowProp = Object.assign({},res.body.dbData[0]);
				if ( rowProp.comment !== null && rowProp.comment !== undefined ) {
					rowProp.comment = rowProp.comment.replace(/\\n/g,'\n');		// reactは'\n'の処理を行ってくれない？ので自分で改行した
				}
				this.setState({rowData: rowProp });
			}.bind(this));
	}

	rowGetter = (rowIdx) => {
		return this.state.tableList[rowIdx];
	}

	// 追加ボタンが押された
	openAdd = () => {
		rowProp = Object.assign({},initialRow);
		this.setState({ 
			rowData: rowProp,
			indexes: null,
		});
		condition = 0;
		this.checkParam(rowProp.id);

		this.setState({ 
			stylistList: [],
			selectedIndexes: []
		});
	}

	// 修正ボタンが押された
	openRep = ( row,index ) => {
		rowProp = Object.assign({},row[index]);
		this.setState({ 
			rowData: rowProp,
			indexes: null,
		});
		condition = 0;
		this.checkParam();

		// スタイリスト
		this.getStylistList(rowProp.id);
	}

	// 削除ボタンが押された
	openDel = ( row,indexes ) => {
		this.setState({ 
			rowData:	null,
			indexes:	indexes,
			searchRow:	row,
			closeButton: true
		});
	}

	// 登録ボタンが押された
	closeAdd = () => {
		
		Request.post('/salon')
			.send({ dbData:rowProp, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.error("店舗を追加できませんでした");
					return;
				}
				this.putStylistList(res.body.dbData[0].id);

				this.getTableList();
			}.bind(this));
	}

	// 追加でクローズボタンが押された
	closeRep = () => {

		Request.put('/salon/' + rowProp.id )
			.send({ dbData:rowProp, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.error("店舗を登録できませんでした");
					return;
				}
				this.putStylistList(rowProp.id);

				this.getTableList();
			}.bind(this));
		
	}

	// 削除でクローズボタンが押された
	closeDel = () => {
		// 実際は削除せずに大きな番号を付与する
		let row = this.state.indexes.map(function(value,ondex){
			let table = this.state.searchRow[value];
			return { 
				id: table.id,
				no: table.id + MAX_DB_LENGTH,
			};
		}.bind(this));
		Request.put('/salon/' )
			.send({ dbData:row, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.error("店舗を登録できませんでした");
					return;
				}
//				this.delStylistList(row);		// サロンを削除番号に変更してもスタイリストは削除しない。
												// サロンを削除したときは
												// FOREIGN KEY (salon_id) REFERENCES salon(id) ON DELETE CASCADE
												// でstylistも削除される

				this.getTableList();
			}.bind(this));
	}


	cancel = () => {
	}

	closeButton = (bool) => {
		this.setState({closeButton: bool });
	}


	// DBに登録されている番号か検索する
	checkNo = ( id,no ) => {
		let res = this.state.tableList.find( function (row,index){
				// eslint-disable-next-line
				if ( id == row.id ) return false;
				// eslint-disable-next-line
				if ( no == row.no ) return true;
				return false;
			});

		if ( res === undefined ) return true;
		return false;
	}
	

//-----------------------------------------------
// イベント処理関数
//-----------------------------------------------
	noChange = (event) => {

		let id    = rowProp.id;					// 編集中のid 追加時は0
		let newNo = event.target.value;     	// 入力した番号
		rowProp.no = newNo;
		
		let res = this.checkNo(id,newNo);		// 番号を検索する
		if ( res === true ) {
			this.setState( { 
				rowData: rowProp,
				noStatus: "ＯＫ" ,
			});
			condition = condition & ~CHECK_DUP;
		} else {
			this.setState( { 
				rowData: rowProp,
				noStatus: "重複" ,
			});
			condition = condition | CHECK_DUP;
		}
		
		this.checkParam();
	}

	// InputにFocusを当てると選択状態にする
	focusSelect = (event) => {
		event.target.select();
	}
	
    nameChange = (event) => {
        if ( event.target.value.length > MAX_NAME_LENGTH ) return;
        rowProp.name      = event.target.value;
        this.setState({ rowData:rowProp });

		this.checkParam();
    }


	mailChange = (event) => {
		if ( event.target.value.length > MAX_MAIL_LENGTH ) return;
		if ( Check.email(event.target.value) === false ) return;
		rowProp.mail = event.target.value;
		this.setState({ rowData:rowProp });
	}

    // 電話番号が変更された
    phoneChange = (event) => {
		if ( event.target.value.length > MAX_PHONE_LENGTH ) return;
		if ( Check.phone(event.target.value) === false ) return;
		rowProp.phone   = event.target.value;
		this.setState({ rowData:rowProp });
    }

    addressChange = (event) => {
        if ( event.target.value.length > MAX_ADDRESS_LENGTH ) return;
        rowProp.address   = event.target.value;
        this.setState({ rowData:rowProp });
    }



	commentChange = (event) => { rowProp.comment  = event.target.value; this.setState({ rowData:rowProp }); }
    stimeChange   = (event) => { rowProp.stime    = event.target.value; this.setState({ rowData:rowProp }); }
    etimeChange   = (event) => { rowProp.etime    = event.target.value; this.setState({ rowData:rowProp }); }
    break_timeChange = (event) => { rowProp.break_time = event.target.value; this.setState({ rowData:rowProp }); }
    urlChange     = (event) => { rowProp.receipt_url   = event.target.value; this.setState({ rowData:rowProp }); }
    widthChange   = (event) => { rowProp.receipt_width = event.target.value; this.setState({ rowData:rowProp }); }
    hostChange    = (event) => { rowProp.imap_host   = event.target.value; 	 this.setState({ rowData:rowProp }); }
    portChange    = (event) => { rowProp.imap_port   = event.target.value; this.setState({ rowData:rowProp }); }
    userChange    = (event) => { rowProp.imap_user   = event.target.value; this.setState({ rowData:rowProp }); }
    passwdChange  = (event) => { rowProp.imap_passwd = event.target.value; this.setState({ rowData:rowProp }); }
	
    
    // 勤務時間の単位が変更された
    y2pChange = (event) => {
        rowProp.yen2point = event.target.value;
        this.setState({ rowData: rowProp });
    }

    // 営業日が変更された
    p2yChange = (event) => {
        rowProp.point2yen = event.target.value;
        this.setState({ rowData: rowProp });
    }

    checkParam = () => {
		// 番号をチェック
		switch ( rowProp.no ) {
			case undefined:
			case "":
			case null:
				condition = condition | CHECK_NO;				// 実行ボタンを無効にする
				this.setState( { noStatus: "ＮＧ" });
				break;
			default:
				if ( Number(rowProp.no) === 0 ) {
					condition = condition | CHECK_NO;			// 実行ボタンを無効にする
					this.setState( { noStatus: "ＮＧ" });
					break;
				}
				condition = condition & (~CHECK_NO);			// 実行ボタンを有効にする
				break;
		}

		// 名前をチェック
		switch ( rowProp.name ) {
			case undefined:
			case "":
			case null:
				condition = condition | CHECK_NAME;		// 実行ボタンを無効にする
				break;
			default:
				condition = condition & (~CHECK_NAME);	// 実行ボタンを有効にする
				break;
		}

		// ボタン、バックグランドカラーの設定
		if ( condition === 0) {							// 一つでもエラーがあればボタンを無効にする
			this.setState({
				closeButton: true,
				noColor:   COLOR_OK,
				nameColor: COLOR_OK,
				noStatus: "ＯＫ"
			});
		} else {
			this.setState({	closeButton: false });
			if ( (condition & (CHECK_NO | CHECK_DUP)) === 0 ) {
				this.setState({
					noColor: COLOR_OK,
					noStatus: "ＯＫ"
				});
			} else {
				this.setState({	noColor: COLOR_ERROR });
			}
	
			if ( (condition & CHECK_NAME) === 0 ) {
				this.setState({	nameColor: COLOR_OK	});
			} else {
				this.setState({	nameColor: COLOR_ERROR });
			}
		}
	}

/* ================================================================= 
	スタイル Grid
	
	
   ================================================================= */
	// 担当者一覧を取得する
	getStaffList = () => {
		//ajax通信する
		Request.get("/staff")
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.log(err);
					console.log(res);
					console.error("担当者一覧を取得できませんでした。");
					return;
				}
				// スタッフの設定
				let list = res.body.dbData.filter( (staff) => {		// 削除された番号は表示しない
					return staff.no < MAX_DB_LENGTH;
				});
				this.setState( { staffList: list } );
			}.bind(this));
	}


	// スタイリスト一覧を取得する
	getStylistList = (salon_id) => {
		//ajax通信する
		Request.get("/stylist/" + salon_id)
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.log(err);
					console.log(res);
					console.error("スタイリスト一覧を取得できませんでした。");
					return;
				}
				let stylist = res.body.dbData;
				this.setState( { stylistList: stylist } );
				let selectedIndexes = [];
				stylist.forEach( function(stylist) {
					let index = this.state.staffList.findIndex(function( staff ) {
						return staff.id === stylist.staff_id;
					});
					if ( index !== -1 ) selectedIndexes.push( index );
				}.bind(this));
				this.setState({selectedIndexes: selectedIndexes});
			}.bind(this));
	}

	// スタイリスト一覧を登録する
	putStylistList = (salon_id) => {
		let stylistList = this.state.selectedIndexes.map(function(index){
			let stylist = Object();
			stylist.tenant_id = JWToken.getToken().tenant_id;
			stylist.salon_id  = salon_id;
			stylist.staff_id  = this.state.staffList[index].id;
			return stylist;
		}.bind(this));
		//ajax通信する
		Request.put('/stylist/' + salon_id )
			.send({ dbData:stylistList, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.error("スタイリストを登録できませんでした");
					return;
				}
			});
	}

/******
	// スタイリスト一覧を削除する ==> FOREIGN KEY (salon_id) REFERENCES salon(id) ON DELETE CASCADE;
	delStylistList = (row) => {
		let salonList = row.map((value) => { return value.id; });
		console.log(salonList);
		//ajax通信する
		Request.del('/stylist/' )
			.send({ dbData:salonList, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.error("スタイリストを削除できませんでした");
					return;
				}
			});
	}
*******/

/* ReactDataGrid -------------------------------------------- */ 

	rowStylistGetter = (rowIdx) => {
		return this.state.staffList[rowIdx];
	}

/* ReactDataGrid --------------------------------------------  */

	onRowsSelected = rows => {
		this.setState({
			selectedIndexes: this.state.selectedIndexes.concat(
				rows.map(r => r.rowIdx)
			)
		});
	};
	
	onRowsDeselected = rows => {
		let rowIndexes = rows.map(r => r.rowIdx);
		this.setState({
			selectedIndexes: this.state.selectedIndexes.filter(
				i => rowIndexes.indexOf(i) === -1
			)
		});
	};


    render = () => {

		const columns = [{ key: 'no',     name: '番号',    width: 80,  editable: false, resizable: false,formatter:AlignCenter, filterable: true, sortable: true, filterRenderer: NumericFilter },
						 { key: 'name',   name: '店舗名',  width: 200, editable: false, resizable: true, formatter:AlignLeft  , filterable: true, sortable: true },
						 { key: 'phone',  name: '電話番号',width: 140, editable: false, resizable: true, formatter:AlignLeft  , filterable: true, sortable: true },
						 { key: 'mail',   name: 'メール',  width: 200, editable: false, resizable: true, formatter:AlignLeft  , filterable: true, sortable: true },
						 { key: 'address',name: '住所',    width: 200, editable: false, resizable: true, formatter:AlignLeft  , filterable: true, sortable: true },
						 { key: 'stime',  name: '開店時刻',width: 120, editable: false, resizable: true, formatter:AlignLeft  , filterable: true, sortable: true },
						 { key: 'etime',  name: '閉店時刻',            editable: false, resizable: true, formatter:AlignLeft  , filterable: true, sortable: true}
					 ];
		const columnsStyle = [{ key: 'no',     name: '番号',    width: 50, editable: false, resizable: false, formatter:AlignCenter },
							  { key: 'name',   name: '担当者名',           editable: false, resizable: false, formatter:AlignLeft   },
						];


		var form = <div></div>;
		var size = 'lg';
		if ( !this.state.indexes ) {
			form = 
			  <Container fluid={true}>
				<Row>
				<Col xs="6">
					<form>
						<FormGroup disabled>
							<InputGroup>
								<InputGroupText style={{width:'80px'}}>番　号　</InputGroupText>
								<Input type="number" style={{backgroundColor: this.state.noColor}} placeholder="Number" value={this.state.rowData.no} onFocus={this.focusSelect} onChange={this.noChange} autoFocus={true}/>
								<InputGroupText>{this.state.noStatus}</InputGroupText>
							</InputGroup>
							<InputGroup>
								<InputGroupText style={{width:'80px'}}>店舗名　</InputGroupText>
								<Input type="text" style={{backgroundColor: this.state.nameColor}} placeholder="店舗名（40文字以内）" value={this.state.rowData.name} onChange={this.nameChange} />
							</InputGroup>
							<InputGroup>
								<InputGroupText style={{width:'80px'}}>電　話　</InputGroupText>
								<Input type="tel" placeholder="電話番号（15文字以内）" value={this.state.rowData.phone} onChange={this.phoneChange} />
							</InputGroup>
							<InputGroup>
								<InputGroupText style={{width:'80px'}}>メール　</InputGroupText>
								<Input type="email" placeholder="メールアドレス（128文字以内）" value={this.state.rowData.mail} onChange={this.mailChange} />
							</InputGroup>
							<InputGroup>
								<InputGroupText style={{width:'80px'}}>住　所　</InputGroupText>
								<Input type="text" placeholder="住所（100文字以内）" value={this.state.rowData.address} onChange={this.addressChange} />
							</InputGroup>
							<InputGroup>
								<InputGroupText style={{width:'80px'}}>開　店　</InputGroupText>
								<Input type="time" placeholder="時刻" value={this.state.rowData.stime} onChange={this.stimeChange} />
							</InputGroup>
							<InputGroup>
								<InputGroupText style={{width:'80px'}}>閉　店　</InputGroupText>
								<Input type="time" placeholder="時刻" value={this.state.rowData.etime} onChange={this.etimeChange} />
							</InputGroup>
						</FormGroup>
					</form>
				</Col>
				<Col xs="6">
					　予約管理：スタイリスト
					<ReactDataGrid rowKey="id"
						columns={columnsStyle} rowGetter={this.rowStylistGetter} rowsCount={this.state.staffList.length}
						minHeight={240} //minWidth={660} 
						enableCellSelect={true}
								// onRowUpdated={this.handleRowUpdated}
						rowSelection={{
				            showCheckbox: true,
				            enableShiftSelect: true,
				            onRowsSelected: this.onRowsSelected,
				            onRowsDeselected: this.onRowsDeselected,
				            selectBy: {
				              indexes: this.state.selectedIndexes
				            }
						}}
					/>
				</Col>
				</Row>
				<Row>
				<Col xs="6">
					<form>
						<FormGroup disabled>
							　レシート印刷
							<InputGroup>
								<InputGroupText style={{width:'80px'}}>URL　　</InputGroupText>
								<Input type="text" placeholder="URL（128文字以内）" value={this.state.rowData.receipt_url} onChange={this.urlChange} />
							</InputGroup>
							<InputGroup>
								<InputGroupText style={{width:'80px'}}>横　幅　</InputGroupText>
								<Input type="select" placeholder="幅" value={this.state.rowData.receipt_width} onChange={this.widthChange} >
									<option value={32} key={0}>{"58mm"}</option>
									<option value={46} key={1}>{"80mm"}</option>
								</Input>
							</InputGroup>
							<InputGroup>
								<InputGroupText style={{width:'80px'}}>印　刷　</InputGroupText>
								<Input type="textarea" style={{height: '80px' ,  whiteSpace: 'pre-line' }} placeholder="レシートに印刷" value={this.state.rowData.comment} onChange={this.commentChange} />
							</InputGroup>
						</FormGroup>
					</form>
				</Col>
				<Col xs="6">
					<form>
						<FormGroup disabled>
							　予約メール受信(POP3)
							<InputGroup>
								<InputGroupText style={{width:'100px'}}>ホ　ス　ト　</InputGroupText>
								<Input type="text" placeholder="URL（256文字以内）" value={this.state.rowData.imap_host} onChange={this.hostChange} />
							</InputGroup>
							<InputGroup>
								<InputGroupText style={{width:'100px'}}>ポ　ー　ト　</InputGroupText>
								<Input type="number" placeholder="URL（6文字以内）" value={this.state.rowData.imap_port} onChange={this.portChange} />
							</InputGroup>
							<InputGroup>
								<InputGroupText style={{width:'100px'}}>ユ　ー　ザ　</InputGroupText>
								<Input type="text" placeholder="URL（128文字以内）" value={this.state.rowData.imap_user} onChange={this.userChange} />
							</InputGroup>
							<InputGroup>
								<InputGroupText style={{width:'100px'}}>パスワード　</InputGroupText>
								<Input type="password" placeholder="URL（64文字以内）" value={this.state.rowData.imap_passwd} onChange={this.passwdChange} />
							</InputGroup>
						</FormGroup>
					</form>
				</Col>
				</Row>
			  </Container>;
			size = 'lg';
		} else {
			let rowData = this.state.indexes.map(function(element, index, array) {
	    		return Object.assign({},this.state.searchRow[element]);
			}.bind(this));
			
			// rowDataのを番号順にソート
			rowData.sort(function(a,b){
				return a.no - b.no;	
			});

			form =
				<form>
					以下の店舗を削除します。<br/>
					<Table bordered hover size={"sm"} >
						 <thead bgcolor="#ebf0f7">
							<tr>
								<th><div className="text-center">番号</div></th>
								<th>店舗名</th>
							</tr>
						</thead>
						<tbody>
							{
								rowData.map(function(value, index, array) {
									return (
										<tr key={index}>
											<td ><div className="text-center">{value.no  }</div></td>
											<td ><div className="text-left"  >{value.name}</div></td>
										</tr>
									);
								})
							}
						</tbody>
					</Table>
				</form>;
			size = 'sm';
		}

    return (
			<GridTable title={'店舗'} form={form}
					tableRows={this.state.tableList} rowGetter={this.rowGetter} length={this.state.tableList.length} getTable={this.getTableList}
					columns={columns} size={size}
					closeButton={this.state.closeButton}
					openAdd={this.openAdd} openRep={this.openRep} openDel={this.openDel}
					closeAdd={this.closeAdd} closeRep={this.closeRep} closeDel={this.closeDel}
					cancel={this.cancel}
			/>

    );
  }
}

export default Salon;
