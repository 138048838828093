/* ==============================================================================
 メニュー情報の編集画面

 履歴-----------------------------------------
 2021/09/26 新規作成
 
=============================================================================== */

import React, { Component } from 'react';
import { InputGroup,InputGroupText,FormGroup,Input,Table } from 'reactstrap';

// import GridTable from './GridTable';
import MenuTech  from './MenuTech';
import { AlignLeft,AlignNumber } from './Formatter';	// React Data Grid
import { Filters } from 'react-data-grid-addons';
import { getMasters,getRecord,addRecord,repRecords,delRecords } from './Agent';

import {
 MAX_NAME_LENGTH,

 CHECK_NO, CHECK_NAME, CHECK_DUP,
 COLOR_ERROR, COLOR_OK,
} from './Define';


/* Data Grid start */
import PropTypes from 'prop-types';
import ReactDataGrid from 'react-data-grid';
import { Toolbar, Data } from 'react-data-grid-addons';
import { Button,ButtonToolbar,Modal,ModalHeader,ModalBody,ModalFooter} from 'reactstrap';
import { Container,Row,Col} from 'reactstrap';
import HelpPopover from './HelpPopover';
import './Table.css';
// import './react-data-grid-header.css'

import {
	EDIT_UNSELECT, EDIT_SELECT, EDIT_ADD, EDIT_REP, EDIT_DEL, EDIT_INI
} from './Define';

/* Date grid end */




const masterParam	= [
	{ url: "/menu/",	errMag: "メニュー",	    list: []	},
	{ url: "/tech/",	errMsg: "技術",	    	list: []	}
];
const menuTechParam =  { url: "/menu_tech/",	errMag: "メニュー技術",	    list: []	};

const initialRow = {id: 0, tenant_id: 0, no: 0, name: "" };
// const initialMenuTech = { tenant_id: 0, menu_id:0, tech_id:0, price:0 };

var rowProp = Object.assign({},initialRow);
var condition = 0;			// 画面の入力チェック

const {
	NumericFilter,
//	AutoCompleteFilter,
//	MultiSelectFilter,
//	SingleSelectFilter
} = Filters;

class Menu extends Component {

	// コンストラクター
	constructor(props) {
		super(props);
		this.state = { 
		    tableList:  [ {id: 0, tenant_id:0, no:0, name: ""} ],                       // menu
			techList:   [ {id: 0, tenant_id:0, no: 0, name: "", price: 0, period: 0, sum_id: 1, sumName: ""} ],     // tech
			rowData:      {id: 0, tenant_id:0, no:0, name: ""},
			menuTech:   [ {       tenant_id:0, menu_id:0, tech_id:0, price:0 }],        // menu_tech
			noStatus: "ＯＫ",
			indexes:	null,
			searchRow:  null,
			closeButton: true,
			noColor:   '#fff0c0',
			nameColor: '#fff0c0'
		};
		
		this.getTableList();
	}

	// メニュー一覧を取得する
	getTableList = () => {
		getMasters(masterParam,function () {
			this.setState({ 
				tableList: masterParam[0].list,
				techList:  masterParam[1].list,
			});
			console.log(masterParam[0].list);
		}.bind(this));
	}

	// メニュー技術取得
	getMenuTech = (id) => {
	    menuTechParam.url = "/menu_tech/";
		getRecord(menuTechParam,id,function(rec){
			this.setState({menuTech: rec /* menuTechParam.list */ });
		}.bind(this));		
	}

    // メニュー技術update	
    onTechRowUpdated = (row) => {
		this.setState({menuTech:row });
    }

	rowGetter = (rowIdx) => {
		return this.state.tableList[rowIdx];
	}

	// 追加ボタンが押された
	openAdd = () => {
		rowProp = Object.assign({},initialRow);
		this.setState({ 
			rowData: rowProp,
			menuTech: [],
			indexes: null,
		});
		condition = 0;
		this.checkParam();
	}

	// 修正ボタンが押された
	openRep = ( row,index ) => {
		rowProp = Object.assign({},row[index]);
	    menuTechParam.url = "/menu_tech/";
		getRecord(menuTechParam,rowProp.id,function(rec){
    		this.setState({ 
    			rowData: rowProp,
    			menuTech: rec,  // menuTechParam.list,
    			indexes: null,
    		});
    		condition = 0;
    		this.checkParam();
		}.bind(this));		
	}

	// 削除ボタンが押された
	openDel = ( row,indexes ) => {
		this.setState({ 
			rowData:	null,				
			indexes:	indexes,
			searchRow:	row,
			closeButton: true
		});
	}

	// 登録ボタンが押された
	closeAdd = () => {
// console.log('closeAdd',this.state.tableList,this.state.menuTech)
		masterParam[0].list = new Array(rowProp);
		addRecord(masterParam[0],function (record) {
    		menuTechParam.list = this.state.menuTech.map( (val,index) => {
    		    val.menu_id = record[0].id;
    			return val;
		    });
    	    menuTechParam.url  = "/menu_tech/" + rowProp.id;
    		repRecords(menuTechParam,function (record) {
    			this.getTableList();
    		}.bind(this));
		}.bind(this));
	}

	// 追加でクローズボタンが押された
	closeRep = () => {
// console.log('closeRep',this.state.tableList,this.state.menuTech)
		masterParam[0].list = new Array(rowProp);
		repRecords(masterParam[0], function (){
    		menuTechParam.list = this.state.menuTech;
    	    menuTechParam.url  = "/menu_tech/" + rowProp.id;
    		repRecords(menuTechParam,function (record) {
    			this.getTableList();
    		}.bind(this));
			this.getTableList();
		}.bind(this));
	}

	// 削除でクローズボタンが押された
	closeDel = () => {
// console.log('closeDel',this.state.tableList,this.state.menuTech)
		let row = this.state.indexes.map(function(value,index){
			let table = this.state.searchRow[value];
			return { 
				id: table.id,
			};
		}.bind(this));
		masterParam[0].list = row;
		delRecords(masterParam[0], function (record){
    		menuTechParam.list = row;
    	    menuTechParam.url = "/menu_tech/";
    		delRecords(menuTechParam,function (record) {
    			this.getTableList();
    		}.bind(this));
		}.bind(this));
	}

	cancel = () => {
	}

	closeButton = (bool) => {
		this.setState({closeButton: bool });
	}

	// DBに登録されている番号か検索する
	checkNo = ( id,no ) => {
		let res = this.state.tableList.find( function (row,index){
				// eslint-disable-next-line
				if ( id == row.id ) return false;
				// eslint-disable-next-line
				if ( no == row.no ) return true;
				return false;
			});

		if ( res === undefined ) return true;
		return false;
	}
	

//-----------------------------------------------
// イベント処理関数
//-----------------------------------------------
	noChange = (event) => {

		let id    = rowProp.id;					// 編集中のid 追加時は0
		let newNo = event.target.value;     	// 入力した番号
		rowProp.no = newNo;
		
		let res = this.checkNo(id,newNo);		// 番号を検索する
		if ( res === true ) {
			this.setState( { 
				rowData: rowProp,
				noStatus: "ＯＫ" ,
			});
			condition = condition & ~CHECK_DUP;
		} else {
			this.setState( { 
				rowData: rowProp,
				noStatus: "重複" ,
			});
			condition = condition | CHECK_DUP;
		}
		
		this.checkParam();
	}

	// InputにFocusを当てると選択状態にする
	focusSelect = (event) => {
		event.target.select();
	}
	
	nameChange = (event) => {
		if ( event.target.value.length > MAX_NAME_LENGTH ) return;
		rowProp.name    = event.target.value;
		this.setState({ rowData:rowProp });

		this.checkParam();
	}


	checkParam = () => {
		// 番号をチェック
		switch ( rowProp.no ) {
			case undefined:
			case 0:
			case "":
			case "0":
			case null:
				condition = condition | CHECK_NO;				// 実行ボタンを無効にする
				this.setState( { noStatus: "ＮＧ" });
				break;
			default:
				condition = condition & (~CHECK_NO);			// 実行ボタンを有効にする
				break;
		}

		// 名前をチェック
		switch ( rowProp.name ) {
			case undefined:
			case "":
			case null:
				condition = condition | CHECK_NAME;		// 実行ボタンを無効にする
				break;
			default:
				condition = condition & (~CHECK_NAME);	// 実行ボタンを有効にする
				break;
		}

		// ボタン、バックグランドカラーの設定
		if ( condition === 0) {							// 一つでもエラーがあればボタンを無効にする
			this.setState({
				closeButton: true,
				noColor:   COLOR_OK,
				nameColor: COLOR_OK,
				noStatus: "ＯＫ"
			});
		} else {
			this.setState({	closeButton: false });
			if ( (condition & (CHECK_NO | CHECK_DUP)) === 0 ) {
				this.setState({
					noColor: COLOR_OK,
					noStatus: "ＯＫ"
				});
			} else {
				this.setState({	noColor: COLOR_ERROR });
			}
	
			if ( (condition & CHECK_NAME) === 0 ) {
				this.setState({	nameColor: COLOR_OK	});
			} else {
				this.setState({	nameColor: COLOR_ERROR });
			}
		}
	}
	

	render() {
		var form = <div></div>;
console.log('MenuTech render()')
		if ( !this.state.indexes ) {
			form = 
				<form>
					<FormGroup disabled>
						<InputGroup>
							<InputGroupText>番　号　</InputGroupText>
							<Input type="number" style={{backgroundColor: this.state.noColor}} placeholder="番号" value={this.state.rowData.no} onFocus={this.focusSelect} onChange={this.noChange} autoFocus={true}/>
							<InputGroupText>{this.state.noStatus}</InputGroupText>
						</InputGroup>
						<InputGroup>
							<InputGroupText>メニュー</InputGroupText>
							<Input type="text" style={{backgroundColor: this.state.nameColor}} placeholder="メニュー名（40文字以内）" value={this.state.rowData.name} onChange={this.nameChange}/>
						</InputGroup>
					</FormGroup>
					<MenuTech menu_id={this.state.rowData.id} onRowUpdated={this.onTechRowUpdated}
										menuList={this.state.menuTech} techList={this.state.techList} height={210} />
				</form>;
		} else {
			let rowData = this.state.indexes.map(function(element, index, array) {
	    		return Object.assign({},this.state.searchRow[element]);
			}.bind(this));
			
			// rowDataのを番号順にソート
			rowData.sort(function(a,b){
				return a.no - b.no;	
			});

			form =
				<form>
					以下のメニューを削除します。<br/>
					<Table bordered hover size={"sm"} >
						 <thead bgcolor="#ebf0f7">
							<tr>
								<th><div className="text-center">番号</div></th>
								<th>メニュー名</th>
							</tr>
						</thead>
						<tbody>
							{
								rowData.map(function(value, index, array) {
									return (
										<tr key={index}>
											<td ><div className="text-center">{value.no  }</div></td>
											<td ><div className="text-left"  >{value.name}</div></td>
										</tr>
									);
								})
							}
						</tbody>
					</Table>
				</form>;
		}

		const columns = [
			{ key: 'no',     name: '番号', width: 80,  editable: false, resizable: false, formatter:AlignNumber, filterable: true, sortable: true, filterRenderer: NumericFilter },
			{ key: 'name',   name: 'メニュー名',       editable: false, resizable: false, formatter:AlignLeft,   filterable: true, sortable: true  }
		];

		return (
			<GridTable title={'メニュー'} form={form}
					tableRows={this.state.tableList} rowGetter={this.rowGetter} length={this.state.tableList.length} getTable={this.getTableList}
					columns={columns} 
					closeButton={this.state.closeButton}
					openAdd={this.openAdd} openRep={this.openRep} openDel={this.openDel}
					closeAdd={this.closeAdd} closeRep={this.closeRep} closeDel={this.closeDel}
					cancel={this.cancel}
			/>
		);
	}
}



/* ==============================================================================
 マスター編集の一覧画面と画面のポップアップ
 
 履歴-----------------------------------------
 2019/03/11 新規作成

=============================================================================== */
/************ 先頭へ
import PropTypes from 'prop-types';
import ReactDataGrid from 'react-data-grid';
import { Toolbar, Data } from 'react-data-grid-addons';
import { Button,ButtonToolbar,Modal,ModalHeader,ModalBody,ModalFooter} from 'reactstrap';
import { Container,Row,Col} from 'reactstrap';
import HelpPopover from './HelpPopover';
import './Table.css';
// import './react-data-grid-header.css'

import {
	EDIT_UNSELECT, EDIT_SELECT, EDIT_ADD, EDIT_REP, EDIT_DEL, EDIT_INI
} from './Define';
************/

var editStatus = EDIT_UNSELECT;

var Selectors = Data.Selectors;

class GridTable extends Component {

	static propTypes = {
		title:		PropTypes.string.isRequired,	// 画面のタイトル
		form:		PropTypes.element.isRequired,	// 編集画面<FormGroup></FormGroup>
//		rowGetter:	PropTypes.func,					// 1行の取得
		length:		PropTypes.number.isRequired,	// 行数
		getTable:	PropTypes.func.isRequired,		// データの再読み込み
		header:		PropTypes.node,
		help:		PropTypes.string,				// ヘルプ表示
		help1:		PropTypes.string,				// ヘルプ表示
    	tableRows:	PropTypes.arrayOf(PropTypes.object.isRequired),		// マスターテーブル
    	onSelect:	PropTypes.func,					// 行を選択した時に呼び出す
		
	    /**
	    * グリッド上の各列を表すオブジェクトの配列。
	    * ImmutableJSオブジェクトにすることもできます
	    */
    	columns: PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.node.isRequired,	// 列の名前 default ではヘッダーセルに表示されます
			key: PropTypes.string.isRequired,	// 各列を区別するための一意のキー
			width: PropTypes.number,			// 列幅。指定しない場合は、グリッド幅と他の列の指定幅に基づいて自動的に決定されます。
			filterable: PropTypes.bool,			// 列のフィルタリングを有効にする
			filterRenderer: PropTypes.oneOfType([PropTypes.func,PropTypes.node]),		// 列のデータをフィルタリングするために使用されるコンポーネント
			resizable: PropTypes.bool,			// 列のサイズ変更を有効にする
			sortable: PropTypes.bool,			// 列の並べ替えを有効にする
			sortDescendingFirst: PropTypes.bool,// 列が最初にソートされたときに昇順ではなく降順になるように列のソート順を設定します。
			dragable: PropTypes.bool,			// 列のドラッグを有効にする
			editable: PropTypes.node,			// セル編集を有効にします。
												// 設定されていてエディタプロパティが指定されていない場合は、textinputがセルエディタとして使用されます。
			editor:    PropTypes.node,			// 列のセルが編集されているときにレンダリングされるエディタ。設定すると、列は自動的に編集可能に設定されます。
			formatter: PropTypes.oneOfType([
						PropTypes.func,			// セルコンテンツのレンダリングに使用されるフォーマッタ
						PropTypes.node]),		// セルコンテンツのレンダリングに使用されるフォーマッタ
			headerRenderer: PropTypes.node,		// 各ヘッダーセルのヘッダーレンダラー
			frozen: PropTypes.bool,				// 列が固定されているかどうかを判断します
			events: PropTypes.object			// ネイティブの反応イベントのコールバックを持つイベントオブジェクト追加することで、
												// イベントを特定の列にバインドできます。これはグリッドのデフォルトの動作を壊すことはなく、指定された列に対してのみ実行されます。
		})).isRequired,
		
		buttonAdd: PropTypes.string,
		buttonRep: PropTypes.string,
		buttonDel: PropTypes.string,
		buttonIni: PropTypes.string,

		openAdd: PropTypes.func,				// 追加モーダル画面表示
		openRep: PropTypes.func,				// 編集モーダル画面表示
		openDel: PropTypes.func,				// 削除モーダル画面表示
		openIni: PropTypes.func,				// 初期化モーダル画面表示

		closeAdd: PropTypes.func,				// 追加モーダル画面登録終了
		closeRep: PropTypes.func,				// 編集モーダル画面登録終了
		closeDel: PropTypes.func,				// 削除モーダル画面登録終了
		closeIni: PropTypes.func,				// 初期化モーダル画面登録終了
		
		cancel: PropTypes.func.isRequired,		// 画面終了
		
		closeButton: PropTypes.bool,			// モーダル画面の実行ボタン
		size:	PropTypes.string,				// モーダル画面のサイズ  例： 'lg','md','sm'
		onRowSelect: PropTypes.func,			// Visitから顧客の検索でコールされる
		height: PropTypes.number,				// グリッドの縦のサイズ
		footerButton: PropTypes.object,			// 
		footer: PropTypes.bool,					// true:実行・キャンセル、  false:確認
		showCheckbox: PropTypes.bool,			// チェックボックスを表示する／表示しない
		mstNo: PropTypes.number,				// mstNoで指定された番号にジャンプする
		canFilter: PropTypes.bool,				// フィルタリングを
	}

	//props初期値指定
	static defaultProps = {
		buttonAdd: '追加',
		buttonRep: '修正',
		buttonDel: '削除',
		buttonIni: null,
		openAdd:   null,
		openRep:   null,
		openDel:   null,
		openIni:   null,
		closeAdd:  null,
		closeRep:  null,
		closeDel:  null,
		closeIni:  null,
		size: 'md',
		onRowUpdated: null,
		onRowSelect: null,
		title: 'メニュー',
		height: 150,			// ヘッダー height
		header:null,
		help:null,
		help1:null,
		onSelect:null,
		footerButton:null,
		footer:true,			// 実行・キャンセル
		showCheckbox:true,		// チェックボックスを表示する／表示しない
		mstNo: 0,
		canFilter: false,						// フィルタリングはDefault無効
	};

	// コンストラクター
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,			// 編集画面の表示・非表示
			repDisable: true,			// 一覧選択時の修正ボタンの設定
			delDisable: true,			// 一覧選択時の削除ボタンの設定
			selectedIndexes: [],		// 選択した行
			subTitle: "",				// モーダルタイトル
			width:  0,					// ウィンドウのサイズ
			height: 0,					// ウィンドウのサイズ
//			form: <div></div>,			// 追加、変更、削除のフォーム
			// react-data-grid で使用している 
			rows: [],					// react
			filters: {},
			sortColumn: null,
			sortDirection: null,
			scrollToRowIndex: 0,		// 指定した行にジャンプする
		};
		
	}


	componentDidUpdate = (prevProps, prevState) => {
//		console.log('componentDidUpdate');
		if ( prevProps.tableRows !== this.props.tableRows ) {
			this.setState({ rows: this.props.tableRows });
		}
		if ( this.props.length !== 0 ) {
			if ( this.state.selectedIndexes.length === 0 ) {
				// mstNoで指定した番号に移動する
				let index = 0;
				let mstNo = this.props.mstNo;
				if ( mstNo !== 0 ) {
					index = this.state.rows.findIndex( row => {
						return (row.no === mstNo); 
					});
					if ( index === -1 ) return;
					if ( prevState.scrollToRowIndex !== index ) {
						this.setState({
							scrollToRowIndex:index,		// 指定された行にスクロールする
							selectedIndexes: [index]
						});		// 行を選択する
					}
					if (this.props.onSelect !== null )  this.props.onSelect([this.props.tableRows[index]]);
					editStatus = EDIT_SELECT;// 選択状態にする
				} 
			}
		}
//		else {
//			this.setState( {
//				selectedIndexes: [0],			// 行を選択する
//				repDisable: false,				// 編集ボタンを有効にする
//				delDisable: false,				// 削除ボタンを有効にする
//			});				// Cellが選択されたら修正、削除は可能
//		}
//		let row = this.rowGetter(0);
//		if (this.props.onSelect !== null && row )  this.props.onSelect([row]);
	}

	componentDidMount = () => {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);

//		console.log(this.refs);
		if ( this.props.canFilter ) 
			this.refs.grid.onToggleFilter();		// 起動時に検索可能になる
			console.log(this.refs);
	}
	
	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}
	
	updateWindowDimensions = () => {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

/* ReactDataGrid nextProps----------------------------------- */ 
	handleRowUpdated = (e) => {
		//merge updated row with current row and rerender by setting state
		var rows = this.state.tableList;
		Object.assign(rows[e.rowIdx], e.updated);
		this.setState({rows:rows});
	}

	// フィルター
	getRows = () => {
    	return Selectors.getRows(this.state);	// rows,filters,sortColumn,sortDirection,scrollToRowIndex を参照している
	}

	getSize = () => {
    	return this.getRows().length;		
//		return this.state.tableList.length;
	}
	
	rowGetter = (rowIdx) => {
		const rows = this.getRows();
    	return rows[rowIdx];
//		return this.state.	tableList[rowIdx];
	}

	// 選択されている行を取得する
	rowsGetter = ( indexes ) => {
		let rows = this.getRows();
		let selected = indexes.map(index=>rows[index]);
		return selected;
	}

	handleGridSort = (sortColumn, sortDirection) => {
		this.setState({
			sortColumn: sortColumn,
			sortDirection: sortDirection,
    		selectedIndexes: [],			// 選択した行
			scrollToRowIndex:0,				// 先頭行に移動
			repDisable: true,				// 編集ボタンを無効にする
			delDisable: true,				// 削除ボタンを無効にする

		});
		if (this.props.onSelect !== null)  this.props.onSelect([]);
	}


	getValidFilterValues(rows, columnId) {
		return rows
			.map(r => r[columnId])
			.filter((item, i, a) => {
				return i === a.indexOf(item);
			});
	}

	handleFilterChange = (filter) => {
	    let newFilters = Object.assign({}, this.state.filters);
    	if (filter.filterTerm) {
    		newFilters[filter.column.key] = filter;
    	} else {
    		delete newFilters[filter.column.key];
    	}
    	this.setState({
    		filters: newFilters,
    		selectedIndexes: [],		// 選択した行
			scrollToRowIndex:0,			// 先頭行に移動
			repDisable: true,			// 編集ボタンを無効にする
			delDisable: true,			// 削除ボタンを無効にする
    	});
		if (this.props.onSelect !== null)  this.props.onSelect([]);
	}

	onClearFilters = () => {
    	this.setState({ 
    		filters: {},
    		selectedIndexes: [],		// 選択した行
			scrollToRowIndex:0,			// 先頭行に移動
			repDisable: true,			// 編集ボタンを無効にする
			delDisable: true,			// 削除ボタンを無効にする
    	});
		this.props.getTable();		// 再読み込み
		if (this.props.onSelect !== null)  this.props.onSelect([]);
	}


/* ReactDataGrid --------------------------------------------  */

/* ---------------------------------------------
	状態遷移
------------------------------------------------ */
	// 追加ボタンが押された
	openAdd = () => {
		// eslint-disable-next-line
		switch(editStatus) {
			case EDIT_UNSELECT:
			case EDIT_SELECT:
				editStatus = EDIT_ADD;
				this.props.openAdd(this.getRows(),this.state.selectedIndexes[0]);	// 追加画面表の準備（親）
				this.setState({
					subTitle: "　" + this.props.buttonAdd,	// モーダル画面のサブタイトル
					showModal:true,							// 画面を表示する
				});	
				break;
			case EDIT_ADD:
			case EDIT_REP:
			case EDIT_DEL:
				break;
		}
	}

	// 修正ボタンが押された
	openRep = () => {
		// eslint-disable-next-line
		switch(editStatus) {
			case EDIT_UNSELECT:
				break;
			case EDIT_SELECT:
			    
				editStatus = EDIT_REP;
				if ( this.state.selectedIndexes.length !== 1 ) break;
				if ( this.props.buttonRep !== null ) {
					this.props.openRep(this.getRows(),this.state.selectedIndexes[0]);	// 編集画面表の準備（親）
					this.setState({
						subTitle: "　" + this.props.buttonRep,	// モーダル画面のサブタイトル
						showModal:true							// 画面を表示する
					});
				}
				break;
			case EDIT_ADD:
			case EDIT_REP:
			case EDIT_DEL:
				break;
		}
	}

	// 削除ボタンが押された
	openDel = () => {
		console.log('openDel() editStatus=',editStatus );
		// eslint-disable-next-line
		switch(editStatus) {
			case EDIT_UNSELECT:
				break;
			case EDIT_SELECT:
				editStatus = EDIT_DEL;
				let form = this.props.openDel(this.getRows(),this.state.selectedIndexes);	// 削除画面表の準備（親）
				this.setState({
					subTitle: "　" + this.props.buttonDel,		// モーダル画面のサブタイトル
					form: form,									// 追加画面
					showModal:true								// 画面を表示する
				});	
				break;
			case EDIT_ADD:
			case EDIT_REP:
			case EDIT_DEL:
				break;
		}
	}

	// 初期化ボタンが押された
	openIni = () => {
		console.log('openIni() editStatus=',editStatus );
		editStatus = EDIT_INI;
		if ( this.props.openIni ) this.props.openIni(this.getRows(),this.state.selectedIndexes);	// 削除画面表の準備（親）
		this.setState({
			subTitle: "　" + this.props.buttonIni,		// モーダル画面のサブタイトル
			showModal:true								// 画面を表示する
		});	
	}

	// 登録ボタンが押された
	close = () => {
		// eslint-disable-next-line
		switch(editStatus) {
			case EDIT_UNSELECT:
			case EDIT_SELECT:
				break;
			case EDIT_ADD:
				this.props.closeAdd(this.state.selectedIndexes[0]);
				editStatus = EDIT_SELECT;
				break;
			case EDIT_REP:
				this.props.closeRep(this.state.selectedIndexes[0]);
				editStatus = EDIT_SELECT;
				break;
			case EDIT_DEL:
				this.props.closeDel(this.state.selectedIndexes);
				this.setState({
					selectedIndexes:[],
					repDisable: true,			// 編集ボタンを無効にする
					delDisable: true,			// 削除ボタンを無効にする
				});
				editStatus = EDIT_UNSELECT;
				if ( this.props.onSelect !== null)  this.props.onSelect([this.rowGetter(0)]);
				break;
			case EDIT_INI:
				if ( this.props.closeIni ) this.props.closeIni();
				break;
		}
		// Request.put()より先に実行されてしまう
		this.setState({showModal: false });
	}

	// キャンセルボタンが押された
	cancel = () => {
		// eslint-disable-next-line
		switch(editStatus) {
			case EDIT_UNSELECT:
			case EDIT_SELECT:
				break;
			case EDIT_ADD:
			case EDIT_REP:
			case EDIT_DEL:
				this.props.cancel();
				editStatus = EDIT_SELECT;
				break;
		}
		this.setState({showModal: false });
	}


	toggle = () => {
		if ( this.state.showModal === true ) {
			this.cancel();
			this.setState( { showModal: false } );
		} else {
			this.setState( { showModal: true } );
		}
	}



// 行の選択
	onCellSelected = ({ rowIdx, idx }) => {
		this.setState( {selectedIndexes: [rowIdx],			// 行を選択する
						repDisable: false,					// 修正ボタンを有効にする
						delDisable: false,					// 削除ボタンを有効にする
		});				// Cellが選択されたら修正、削除は可能
		editStatus = EDIT_SELECT;
		if (this.props.onSelect    !== null)  this.props.onSelect([this.rowGetter(rowIdx)]);
		if (this.props.onRowSelect !== null)  this.props.onRowSelect(this.rowGetter(rowIdx));
	}

	onRowDoubleClick = (index) => {
		this.setState( {selectedIndexes: [index],			// 行を選択する
						repDisable: false,					// 修正ボタンを有効にする
						delDisable: false,					// 削除ボタンを有効にする
		});				// Cellが選択されたら修正、削除は可能
		editStatus = EDIT_SELECT;
		this.openRep();								// 修正ボタンが押された
	}

/* ============================================================ */
/* 2021/01/23 追加												*/
	onRowsSelected = rows =>{
		let idx = this.state.selectedIndexes.concat( rows.map(r => r.rowIdx) );
		let rep = false;
		let del = false;
		if ( idx.length === 0 ) {
			rep = true;
			del = true;
			editStatus = EDIT_UNSELECT;
		} else if ( idx.length === 1 ) {
			rep = false;
			del = false;
			editStatus = EDIT_SELECT;
		} else if ( idx.length > 1) {
			rep = true;
			del = false;
			editStatus = EDIT_SELECT;
		}
		if ( this.props.onSelect !== null ) this.props.onSelect(this.rowsGetter(idx));
	    this.setState({
	    	selectedIndexes: idx,
			repDisable: 	 rep,
			delDisable:		 del,
	    });
	}

	onRowsDeselected = rows => {
		let rowIndexes = rows.map(r => r.rowIdx);
		let idx = this.state.selectedIndexes.filter( i => rowIndexes.indexOf(i) === -1 );
		let rep = false;
		let del = false;
		if ( idx.length === 0 ) {
			rep = true;
			del = true;
		} else if ( idx.length === 1 ) {
			rep = false;
			del = false;
		} else if ( idx.length > 1) {
			rep = true;
			del = false;
		}
		if ( this.props.onSelect !== null ) this.props.onSelect(this.rowsGetter(idx));
		this.setState({
			selectedIndexes: idx,
			repDisable: rep,
			delDisable: del,
		});
	};
/* 2021/01/23=================================================== */	

	render() {
		let searchCount = this.getSize();
		let selectCount = this.state.selectedIndexes.length;
		let height = this.state.height - this.props.height;
		if ( height < 100 ) height = 100;
		
		return (
			<div>
				<ButtonToolbar>
					<Button color="info" onClick={this.props.getTable}>{this.props.title}</Button>{'　'}
					{ this.props.buttonAdd && <Button color="primary" onClick={this.openAdd}>{this.props.buttonAdd}</Button> }
					{ this.props.buttonRep && <Button color="success" onClick={this.openRep} disabled={this.state.repDisable}>{this.props.buttonRep}</Button> }
					{ this.props.buttonDel && <Button color="danger"  onClick={this.openDel} disabled={this.state.delDisable}>{this.props.buttonDel}</Button> }
					{ this.props.buttonIni && <Button color="warning" onClick={this.openIni} disabled={this.state.iniDisable}>{this.props.buttonIni}</Button> } {'　'}
					{ this.props.header}
					{ this.props.help && <HelpPopover id='GridTable' help={this.props.help} /> }
				</ButtonToolbar>
	
				<ReactDataGrid ref="grid" enableCellSelect={true} enableDragAndDrop={false} minHeight={ height } //minWidth={660} 
					rowGetter={this.rowGetter}  columns={this.props.columns} rowsCount={this.getSize()}
					onCellSelected={this.onCellSelected} onRowDoubleClick={this.onRowDoubleClick}
					onGridSort={this.handleGridSort}
					scrollToRowIndex={this.state.scrollToRowIndex}
					enableFilter={true}
					onAddFilter={this.handleFilterChange}
					onClearFilters={this.onClearFilters}
					getValidFilterValues={columnKey => this.getValidFilterValues(this.props.tableRows, columnKey)}
					toolbar={<Toolbar enableFilter filterRowsButtonText={'検索'} >{selectCount+'/'+searchCount+'件'}</Toolbar>}
					rowSelection={{
						showCheckbox: this.props.showCheckbox,
						enableShiftSelect: false,
			            onRowsSelected: this.onRowsSelected,
			            onRowsDeselected: this.onRowsDeselected,
						selectBy: {
							indexes: this.state.selectedIndexes
						}
					}}  
				/>
				<Modal isOpen={this.state.showModal} backdrop={'static'} size={this.props.size} style={{ width: '99%'}} autoFocus={false}>
					<ModalHeader toggle={this.toggle} >
						<Container >
					      <Row>
					        <Col>{this.props.title}</Col>
					        <Col>{this.state.subTitle}</Col>
					        <Col>{ this.props.help1 && <HelpPopover id='GridTable' help={this.props.help1} /> }</Col>
					      </Row>
						</Container>
					</ModalHeader>
					<ModalBody focus="true" >
						{this.props.form}
					 </ModalBody>
					<ModalFooter>
					{ this.props.footerButton ? this.props.footerButton : '' }
					{ this.props.footer ? <div>
						<Button color="warning" onClick={this.cancel}>キャンセル</Button>
						<Button color="primary" onClick={this.close} disabled={!this.props.closeButton}>実行</Button> 
						</div> :
						<Button color="primary" onClick={this.close} disabled={!this.props.closeButton}>終了</Button>
					}
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}

export default Menu
;
