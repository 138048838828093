/* ==============================================================================
 テナント情報の編集画面

 履歴-----------------------------------------
 2019/03/07 reactstrap 対応済み
 
=============================================================================== */

import React, { Component } from 'react';
import { InputGroup,InputGroupText,FormGroup,Input,Table } from 'reactstrap';

import Request from 'superagent';
import {JWToken,Alert} from './auth/Login';
import GridTable from './GridTable';
import Check from './Input';
import { AlignLeft,AlignCenter } from './Formatter';	// React Data Grid
import { Filters } from 'react-data-grid-addons';
import Zip2Addr from './ZipAddr';

import {
	MAX_COMPANY_LENGTH,
	MAX_NAME_LENGTH,
	MAX_MAIL_LENGTH,
	MAX_PHONE_LENGTH,
	MAX_ZIPCODE_LENGTH,
	MAX_PREF_LENGTH,
	MAX_CITY_LENGTH,
	MAX_SUBURB_LENGTH,
	MAX_ADDRESS1_LENGTH,
	MAX_ADDRESS2_LENGTH,
} from './Define';

const {
	NumericFilter,
//	AutoCompleteFilter,
//	MultiSelectFilter,
//	SingleSelectFilter
} = Filters;

const initialRow = {id: 0, name: "", first_name: "", last_name: "", first_kana: "", last_kana: "",
					mail: "", phone: "", zipcode: "", address1: "", address2: "", 
					username: "", password: "",begin_month:1, begin_day:1, month:1 };	// usernameはメールアドレスとする
var rowProp = Object.assign({},initialRow);		// rowPropはモーダル編集画面で使用している
var conform = false;							// 画面の入力確認

/* =============================================================================
 * 請求先の情報表示
 * <Tenant master={tenant Object} result={callBack(tenant,confirm)} />
 * ============================================================================= */
class Tenant extends Component {

	// コンストラクター
	constructor(props) {
		super(props);
		this.state = {  
			tableList: [ Object.assign({},initialRow) ],
			rowData:  Object.assign({},initialRow),
			title: "テナント編集",
//			address: [],						// 郵便番号から住所を取得した時の格納エリア
			indexes:	null,
			searchRow:  null,
//			closeButton: true,					// 実行ボタンの有効/無効 -> conform (renderでsetState()しないように変更)
			noColor: '#e9ecef',
			nameColor: '#e9ecef'
		};
		
		this.getTableList();

	}

	// テナント一覧を取得する
	getTableList = () => {
		let token = JWToken.getToken().token;
		//ajax通信する
		Request.get("/tenant")
			.query( { token: token } )
			.end(function(err, res) {
				if (err) {
					console.log(err);
					console.log(res);
					console.error("テナント一覧を取得できませんでした。");
					return;
				}
				this.setState( { tableList: res.body.dbData } );
			}.bind(this));
	}

	// テナント取得
	getTable = (id) => {
		//ajax通信する
		Request.get("/tenant/" + id)
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.log(err);
					console.log(res);
					console.error("テナントを取得できませんでした。");
					this.setState({rowData: rowProp });
				} else {
					this.setState({rowData: res.body.dbData[0] });
				}
			}.bind(this));
	}

	// 店舗を作成する
	postSalon = (id,name) => {
		let salon = {
		    id:			0,				/* キー         */
		    tenant_id:	id,				/* テナントID   */
		    no:			1,  			/* 番号         */
		    name:		name,			/* 店舗名       */
		    mail:		"",				/* メールアドレス */
		    phone:		"",				/* 電話番号     */
		    address1:	"",				/* 住所         */
		    time_unit:	5,				/* 給与計算時間単位 １分、５分、１０分、１５分、３０分、６０分 削除。全て１分単位とする */    
		    break_time:	"01:00",		/* 休憩時間     */
		    stime:		"09:00",		/* 開店時間 削除。StaffSceduleに変更する*/
		    etime:		"19:00",		/* 閉店時間 削除。StaffSceduleに変更する*/
		    workday:	0x79,            /* 営業日(週) 土:0x64、金:0x32、木:0x16、水:0x08、火:0x04、月:0x02、日:0x01 削除。StaffSceduleに変更する */
		};
		//ajax通信する
		Request.post('/salon/' + id )
			.send({ dbData:salon, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.error("店舗を登録できませんでした");
					return;
				}
				this.getTableList();
			}.bind(this));
	}



	rowGetter = (rowIdx) => {
		return this.state.tableList[rowIdx];
	}

	// 追加ボタンが押された
	openAdd = () => {
		rowProp = Object.assign({},initialRow);
		this.setState({ 
			rowData: rowProp,
			indexes: null,
		});
	}

	// 修正ボタンが押された
	openRep = (  row,index ) => {
		rowProp = Object.assign({},row[index]);
		this.setState({ 
			rowData: rowProp,
			indexes: null,
		});
	}

	// 削除ボタンが押された
	openDel = ( row,indexes ) => {
		console.log('openDel',row,indexes);
		this.setState({ 
			rowData:	null,				
			indexes:	indexes,
			searchRow:	row,
//			closeButton: true			// // 実行ボタンの有効/無効 -> conform (renderでsetState()しないように変更)
		});
		conform = true;
	}

	// 登録ボタンが押された
	closeAdd = () => {
		if ( this.checkParam(this.state.rowData.username) !== undefined ) {
			// Alert表示 同じログイン名がすでに使用されています Popover?
			Alert("ユーザー名は使われています");
			return;
		}
		Request.post('/tenant')
			.send({ dbData:rowProp, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.error("テナントを追加できませんでした");
					return;
				}
				let tenant = res.body.rows[0];
				this.postSalon(tenant.id,"店舗1");
				this.getTableList();
			}.bind(this));
	}

	// 変更でクローズボタンが押された
	closeRep = () => {
		let tenant = this.checkParam(this.state.rowData.username);
		if ( tenant !== undefined ) {
			if ( tenant.id !== rowProp.id ) {
				// Alert表示 同じログイン名がすでに使用されています Popover?
				Alert("ユーザー名は既に使用されています");
				return;
			}
		}
		Request.put('/tenant/' + rowProp.id )
			.send({ dbData:rowProp, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.error("テナントを登録できませんでした");
					return;
				}
				this.getTableList();
			}.bind(this));
	}

	// 削除でOKボタンが押された
	closeDel = () => {

		let row = this.state.indexes.map(function(value,index){
			let table = this.state.searchRow[value];
			return { 
				id: table.id,
			};
		}.bind(this));
		Request.del('/tenant/' )
			.send({ dbData:row, token: JWToken.getToken().token } )
			.end(function(err, res){
				if ( err ) {
					console.log(err);
					console.log(res);
					console.error("テナントの削除に失敗しました");
					return;
				}
				this.getTableList();
			}.bind(this));
	}


	cancel = () => {
	}

/*
	closeButton = (bool) => {
		this.setState({closeButton: bool });
	}
*/

	// DBに登録されているログイン名か検索する
	checkParam = ( name ) => {
		// username がすでに登録されているかチェックする
		let res = this.state.tableList.find( function (row,index){
				if ( name === row.username ) return true;
				return false;
			});

		return res;								// 同一名がある
	}
	

//-----------------------------------------------
// イベント処理関数
//-----------------------------------------------

//	nameChange = (event) => { rowProp.name    = event.target.value;	this.setState({ rowData:rowProp }); }

/*	phoneChange = (event) => {
		if ( Check.phone(event.target.value) === false ) return;
		rowProp.phone   = event.target.value;
		this.setState({ rowData:rowProp });
	}
*/
    usernameChange = (event) => { rowProp.username  = event.target.value; this.setState({ rowData:rowProp }); }
    passwordChange = (event) => { rowProp.password  = event.target.value; this.setState({ rowData:rowProp }); }
    beginMonthChange = (event) => { rowProp.begin_month= event.target.value; this.setState({ rowData:rowProp }); }
    beginDayChange   = (event) => { rowProp.begin_day  = event.target.value; this.setState({ rowData:rowProp }); }
    monthChange      = (event) => { rowProp.month      = event.target.value; this.setState({ rowData:rowProp }); }

	nameChange = (event) => {
		if ( event.target.value.length > MAX_COMPANY_LENGTH ) return;
		rowProp.name   = event.target.value;
		this.setState({ rowData:rowProp });
	}
	
	firstNameChange = (event) => {
		if ( event.target.value.length > MAX_NAME_LENGTH ) return;
		rowProp.first_name    = event.target.value;
		this.setState({ rowData:rowProp });
	}
	lastNameChange = (event) => {
		if ( event.target.value.length > MAX_NAME_LENGTH ) return;
		rowProp.last_name    = event.target.value;
		this.setState({ rowData:rowProp });
	}
	firstKanaChange = (event) => {
		if ( event.target.value.length > MAX_NAME_LENGTH ) return;
		rowProp.first_kana    = event.target.value;
		this.setState({ rowData:rowProp });
	}
	lastKanaChange = (event) => {
		if ( event.target.value.length > MAX_NAME_LENGTH ) return;
		rowProp.last_kana    = event.target.value;
		this.setState({ rowData:rowProp });
	}
	phoneChange = (event) => {
		if ( event.target.value.length > MAX_PHONE_LENGTH ) return;
		if ( Check.phone(event.target.value) === false ) return;
		rowProp.phone   = event.target.value;
		this.setState({ rowData:rowProp });
	}
	zipcodeChange = (event) => {
		if ( event.target.value.length > MAX_ZIPCODE_LENGTH ) return;
		if ( Check.postCode(event.target.value) === false ) return;
		rowProp.zipcode= event.target.value;
		this.setState({ rowData:rowProp });
	}
	prefChange = (event) => {
		if ( event.target.value.length > MAX_PREF_LENGTH ) return;
		rowProp.pref = event.target.value;
		this.setState({ rowData:rowProp });
	}
	cityChange = (event) => {
		if ( event.target.value.length > MAX_CITY_LENGTH ) return;
		rowProp.city = event.target.value;
		this.setState({ rowData:rowProp });
	}
	suburbChange = (event) => {
		if ( event.target.value.length > MAX_SUBURB_LENGTH ) return;
		rowProp.suburb = event.target.value;
		this.setState({ rowData:rowProp });
	}
	address1Change = (event) => {
		if ( event.target.value.length > MAX_ADDRESS1_LENGTH ) return;
		rowProp.address1 = event.target.value;
		this.setState({ rowData:rowProp });
	}
	address2Change = (event) => {
		if ( event.target.value.length > MAX_ADDRESS2_LENGTH ) return;
		rowProp.address2 = event.target.value;
		this.setState({ rowData:rowProp });
	}
	mailChange = (event) => {
		if ( event.target.value.length > MAX_MAIL_LENGTH ) return;
		if ( Check.email(event.target.value) === false ) return;
		rowProp.mail = event.target.value;
		this.setState({ rowData:rowProp });
	}


    // 勤務時間の単位が変更された
    unitChange = (event) => {
        if ( this.state.readOnly ) return;
        rowProp.time_unit = event.target.value;
        this.setState({ rowData:rowProp });
    }

	// 郵便番号から住所を取得する Zip2Addrのコールバック関数
	setAddress = (pref, city, suburb) => {
		rowProp.pref   = pref;
		rowProp.city   = city;
		rowProp.suburb = suburb;
		this.setState({rowData:rowProp});
	}

	render() {
//		console.log(this.state.rowData);

		var form = <div></div>;
		if ( !this.state.indexes ) {
			let company = 'OK';
			if ( !this.state.rowData.name  ) company = '省略不可';

			let name = 'OK';
			if ( !this.state.rowData.last_name || !this.state.rowData.first_name ) name = '省略不可';
	
			let kana = 'OK';
			if ( !this.state.rowData.last_kana || !this.state.rowData.first_kana )   kana = '省略不可';
			else if ( Check.isZenKatakana(this.state.rowData.last_kana)  === false ) kana = '全角カナのみ';
			else if ( Check.isZenKatakana(this.state.rowData.first_kana) === false ) kana = '全角カナのみ';
	
			let phone  = 'OK';
			let tel = this.state.rowData.phone.replace( /-/g, '');		// '-'を削除
			if ( !tel ) { 
				phone = '省略不可';
			} else if (!tel.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/)) {
				phone = '不正';
			}
	
			let mail  = 'OK';
			var regexp = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
			if ( !this.state.rowData.mail  ) {
				mail = '省略不可';
			} else if ( !regexp.test(this.state.rowData.mail) ) mail = '不正アドレス';
	
			let zip  = 'OK';
			if ( !this.state.rowData.zipcode ) {
				zip = '省略不可';
			} else {
				let zipcode = this.state.rowData.zipcode.replace( /-/g, '');		// '-'を削除
				if ( zipcode.length < 7  ) {
					zip = '不正';
				}
			}
			
			let pref  = 'OK';
			if ( !this.state.rowData.pref ) pref = '省略不可';
			let city  = 'OK';
			if ( !this.state.rowData.city ) city = '省略不可';
			let suburb  = 'OK';
			if ( !this.state.rowData.suburb ) suburb = '省略不可';
			let addr1  = 'OK';
			if ( !this.state.rowData.address1 ) addr1 = '省略不可';
//			let addr2  = 'OK';
//			if ( !this.state.rowData.address2 ) addr2 = '省略不可';

			// Buttonの 有効/無効 を設定
			if ( name === 'OK' && kana === 'OK' && phone === 'OK' && mail === 'OK' && zip === 'OK' && pref ==='OK' && city ==='OK' && suburb ==='OK' && addr1 === 'OK' ) {
//				this.closeButton(true);
				conform = true;
			} else {
//				this.closeButton(false);
				conform = false;
			}
	
			// ドロップダウン
			let month = [];
			for ( let i = 0 ; i < 12 ;  ) month[i] = ++i;
			let day = [];
			for ( let i = 0 ; i < 31 ;  ) day[i] = ++i;
	
			form = 
				<form>
					<FormGroup>
	                    <InputGroup>
	                        <InputGroupText>会社名　</InputGroupText>
	                        <Input type="text" placeholder="会社名" value={this.state.rowData.name}  onChange={this.nameChange} autoFocus={true}/>
	                        <InputGroupText style={{width:'90px'}}>{company}</InputGroupText>
	                    </InputGroup>
	                    <InputGroup>
	                        <InputGroupText>お名前　</InputGroupText>
	                        <Input type="text" placeholder="姓" value={this.state.rowData.last_name}  onChange={this.lastNameChange} />
	                        <Input type="text" placeholder="名" value={this.state.rowData.first_name} onChange={this.firstNameChange} />
	                        <InputGroupText style={{width:'90px'}}>{name}</InputGroupText>
	                    </InputGroup>
	                    <InputGroup>
	                        <InputGroupText>カタカナ</InputGroupText>
	                        <Input type="text" placeholder="セイ" value={this.state.rowData.last_kana}  onChange={this.lastKanaChange} />
	                        <Input type="text" placeholder="メイ" value={this.state.rowData.first_kana} onChange={this.firstKanaChange} />
	                        <InputGroupText style={{width:'90px'}}>{kana}</InputGroupText>
	                    </InputGroup>
	                    <InputGroup>
	                        <InputGroupText>電話番号</InputGroupText>
	                        <Input type="phone" placeholder="電話番号（15文字以内）" value={this.state.rowData.phone} onChange={this.phoneChange} />
	                        <InputGroupText style={{width:'90px'}}>{phone}</InputGroupText>
	                    </InputGroup>
	                    <InputGroup>
	                        <InputGroupText>ｅメール</InputGroupText>
	                        <Input type="mail" placeholder="email address" value={this.state.rowData.mail} onChange={this.mailChange} />
	                        <InputGroupText style={{width:'90px'}}>{mail}</InputGroupText>
	                    </InputGroup>
						<InputGroup>
							<InputGroupText>郵便番号</InputGroupText>
							<Input type="text" placeholder="郵便番号" value={this.state.rowData.zipcode} onChange={this.zipcodeChange} />
							<Zip2Addr zipcode={this.state.rowData.zipcode} callBack={this.setAddress}/>
	                        <InputGroupText style={{width:'90px'}}>{zip}</InputGroupText>
						</InputGroup>
						<InputGroup>
							<InputGroupText>都道府県</InputGroupText>
							<Input type="text" placeholder="都道府県名" value={this.state.rowData.pref} onChange={this.prefChange} />
	                        <InputGroupText style={{width:'90px'}}>{pref}</InputGroupText>
						</InputGroup>
						<InputGroup>
							<InputGroupText>市区町村</InputGroupText>
							<Input type="text" placeholder="市区町村名" value={this.state.rowData.city} onChange={this.cityChange} />
	                        <InputGroupText style={{width:'90px'}}>{city}</InputGroupText>
						</InputGroup>
						<InputGroup>
							<InputGroupText>町　域　</InputGroupText>
							<Input type="text" placeholder="町域名" value={this.state.rowData.suburb} onChange={this.suburbChange} />
	                        <InputGroupText style={{width:'90px'}}>{suburb}</InputGroupText>
						</InputGroup>
						<InputGroup>
							<InputGroupText>住所　１</InputGroupText>
							<Input type="text" placeholder="丁目、番地、号など" value={this.state.rowData.address1} onChange={this.address1Change} />
	                        <InputGroupText style={{width:'90px'}}>{addr1}</InputGroupText>
						</InputGroup>
						<InputGroup>
							<InputGroupText>住所　２</InputGroupText>
							<Input type="text" placeholder="ビル名、階層、室など" value={this.state.rowData.address2} onChange={this.address2Change} />
{ /*	                        <InputGroupText style={{width:'90px'}}>{addr2}</InputGroupText> */}
						</InputGroup>
	
	
	                    <InputGroup>
	                        <InputGroupText>期首は</InputGroupText>
	                        <Input type="select" placeholder="月" value={this.state.rowData.begin_month} onChange={this.beginMonthChange} >
								{	
									month.map(function(n){	return 	<option value={n} key={n}>{n+"月"}</option>	})
								}
							</Input>
	                        <Input type="select" placeholder="日" value={this.state.rowData.begin_day}   onChange={this.beginDayChange} >
								{	
									day.map(function(n){	return 	<option value={n} key={n}>{n+"日"}</option>	})
								}
							</Input>
	                        <InputGroupText>を</InputGroupText>
	                        <Input type="select" placeholder="月" value={this.state.rowData.month}       onChange={this.monthChange} >
								{	
									month.map(function(n){	return 	<option value={n} key={n}>{n+"月"}</option>	})
								}
							</Input>
	                        <InputGroupText style={{width:'90px'}}>度とする</InputGroupText>
	                    </InputGroup>
	                    <InputGroup>
	                        <InputGroupText>ユーザー名</InputGroupText>
	                        <Input type="mail" placeholder="ユーザー名" value={this.state.rowData.username} onChange={this.usernameChange} />
	                    </InputGroup>
	                    <InputGroup>
	                        <InputGroupText>パスワード</InputGroupText>
	                        <Input type="text" placeholder="パスワード" value={this.state.rowData.password} onChange={this.passwordChange} />
	                    </InputGroup>
	 				</FormGroup>
				</form>;
		} else {
			let rowData = this.state.indexes.map(function(element, index, array) {
	    		return Object.assign({},this.state.searchRow[element]);
			}.bind(this));
			
			// rowDataのを番号順にソート
			rowData.sort(function(a,b){
				return a.no - b.no;	
			});

			form =
				<form>
					以下のテナントを削除します。<br/>
					<Table bordered hover size={"sm"} >
						 <thead bgcolor="#ebf0f7">
							<tr>
								<th><div className="text-center">番号</div></th>
								<th>テナント名</th>
							</tr>
						</thead>
						<tbody>
							{
								rowData.map(function(value, index, array) {
									return (
										<tr key={index}>
											<td ><div className="text-center">{value.id  }</div></td>
											<td ><div className="text-left"  >{value.name}</div></td>
										</tr>
									);
								})
							}
						</tbody>
					</Table>
				</form>;
		}

		const columns = [{ key: 'id',       	name: '番号',       width: 40,  editable: false, resizable: false, formatter:AlignCenter,filterable: true, sortable: true, filterRenderer: NumericFilter    },
						 { key: 'name',     	name: 'テナント名', width: 200, editable: false, resizable: true, formatter:AlignLeft, filterable: true, sortable: true },
						 { key: 'last_name',	name: '姓', 		width: 60,  editable: false, resizable: true, formatter:AlignLeft, filterable: true, sortable: true },
						 { key: 'first_name',	name: '名', 		width: 60,  editable: false, resizable: true, formatter:AlignLeft, filterable: true, sortable: true },
						 { key: 'phone',    	name: '電話番号',   width: 120, editable: false, resizable: true, formatter:AlignLeft, filterable: true, sortable: true },
						 { key: 'mail',    		name: 'メール',     width: 120, editable: false, resizable: true, formatter:AlignLeft, filterable: true, sortable: true },
						 { key: 'zipcode',    	name: '郵便番号',   width: 120, editable: false, resizable: true, formatter:AlignLeft, filterable: true, sortable: true },
						 { key: 'pref',  		name: '都道府県',	width: 120, editable: false, resizable: true, formatter:AlignLeft, filterable: true, sortable: true },
						 { key: 'city', 		name: '市区町村',	width: 120, editable: false, resizable: true, formatter:AlignLeft, filterable: true, sortable: true },
						 { key: 'suburb',	 	name: '町域',		width: 120, editable: false, resizable: true, formatter:AlignLeft, filterable: true, sortable: true },
						 { key: 'address1',  	name: '丁目 番地',	width: 200, editable: false, resizable: true, formatter:AlignLeft, filterable: true, sortable: true },
						 { key: 'address2',  	name: 'ビル名など', width: 200, editable: false, resizable: true, formatter:AlignLeft, filterable: true, sortable: true },
						 { key: 'username', 	name: 'ログイン名', width: 120, editable: false, resizable: true, formatter:AlignLeft, filterable: true, sortable: true },
						 { key: 'password', 	name: 'パスワード',             editable: false, resizable: true, formatter:AlignLeft, filterable: true, sortable: true }
					 ];

		return (
			<div>
				<GridTable title={'テナント編集'} form={form}
						tableRows={this.state.tableList} rowGetter={this.rowGetter} length={this.state.tableList.length} getTable={this.getTableList}
						columns={columns} 
						closeButton={conform}
						openAdd={this.openAdd} openRep={this.openRep} openDel={this.openDel}
						closeAdd={this.closeAdd} closeRep={this.closeRep} closeDel={this.closeDel}
						cancel={this.cancel}
				/>
			</div>
		);

	}
}

export default Tenant;
