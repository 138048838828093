/* ==============================================================================
  棚卸画面 表示　モジュール

DB table R  : visit、tech_slip、goods_slip
		 R  : staff、customer、tech、goods
		 
 group: id,title,<staff>
 staff: id,no+name
 salesax: startDate, rate
 
 item:  id,group,   title,start_time,end_time,<visit>
 visit: id,staff_id,name, start_time,duration,visit_state,customer_id
 
=============================================================================== */

import React, {useState,useEffect} from 'react';

// import ReactDataGrid from 'react-data-grid';
import {FormGroup,InputGroup,InputGroupText,Input,Table } from 'reactstrap';
import GridTable  from './GridTable';
import { AlignLeft,AlignCenter,YenFormat } from './Formatter';	// React Data Grid
import { Filters } from 'react-data-grid-addons';

import {JWToken} from './auth/Login';
import { getMasters,addRecord,delRecords } from './Agent';
import Check from './Input';
import PrintPreview from './PrintPreview';

import './Table.css';

import { MAX_DB_LENGTH } from './Define';

// const EDIT_UNSELECT = 0, EDIT_ADD = 2, EDIT_REP = 3, EDIT_DEL = 4;

const {
	NumericFilter,
	AutoCompleteFilter,
//	MultiSelectFilter,
//	SingleSelectFilter
} = Filters;

const masterParam	= [
	{ url: "/stock/",		errMsg: "商品棚卸",	list: []	},
	{ url: "/supplier/",	errMag: "仕入先",	list: []	}
];



const Stock = (props) => {
	const {
		salon_id=null,
		salonName=null,
	} = props;

	const defaultStock = {
		tenant_id:   0,     			/* テナントID   */
	    salon_id:    0,     			/* 来店情報ID   */
	    id:			 0,     			/* 商品ID		*/
		no:			 0,					/* 商品番号		*/
		name:		 0,					/* 商品名		*/
	    price:       0,					/* 販売価格     */
	    cost:        0,					/* 仕入値       */
	    stock:       0,					/* 在庫数       */
	    just:        0,					/* 適在庫数     */
	    supplier_id: 0,					/* 仕入先ID     */
	    stocktake:   0,					/* 棚卸数		*/
	};

	const [row,   setRow]   = useState(defaultStock);
	const [stockList,setStockList] = useState([defaultStock]);
	const [headerHeight, setheaderHeight]  = useState(150);
	const [buttons, setButtons] = useState({add:null,rep:null,del:null,ini:null});
	const [form, setForm] = useState(<div></div>);

	useEffect(() => {
		getTableList();
	// eslint-disable-next-line
	},[salon_id]);

	// useStateでStateを書き換えた直後にそのStateを参照すると古い値を参照してしまう。
	// useEffectでStateが書き換わるまで待つ。
	useEffect(() => {
		setForm(repForm);
	// eslint-disable-next-line
	},[row]);


	// InputにFocusを当てると選択状態にする
	const focusSelect = (event) => {
		event.target.select();
	}

/* -----------------------------------------------------------
	Stockの読み込み
------------------------------------------------------------- */
	// 商品一覧を取得する
	const getTableList = () => {
		
		masterParam[0].url = "/stock/";
		if ( salon_id ) {
			setButtons({add:null,rep:'修正',del: null, ini:'初期化'});
//			setButtonAdd(null);			
//			setButtonRep('修正');			
//			setButtonDel('初期化');			
			masterParam[0].url = "/stock/" + salon_id;
			setheaderHeight(300);									// ヘッダー height
		}

		getMasters(masterParam,function () {
			lookupSupplire(masterParam[0].list,masterParam[1].list);
			setStockList( masterParam[0].list.filter((v)=> v.no < MAX_DB_LENGTH) );	
		});
	};

	// 仕入先id で仕入先名を取得する
	const lookupSupplire = (list,supplier) => {
		for ( let i = 0; i < list.length; i++) {
			if ( list[i].no >= MAX_DB_LENGTH ) continue;
//			if ( list[i].stocktake === null ) list[i].stocktake = '0';
			let id = list[i].supplier_id;
			list[i].supplierName = "-";
			for ( let j = 0; j < supplier.length; j++ ) {
				if ( id === supplier[j].id ) {
					list[i].supplierName = supplier[j].name;
					break;
				}
			}
		}
	};

	const rowGetter = (rowIdx) => {
		return stockList[rowIdx];
	};


	// 追加ボタンが押された
	const openAdd = (list,index) => {
		setRow(list[index]);
	};

	// 修正ボタンが押された
	const openRep = ( list,index ) => {
		setRow(list[index]);
	};

	// 削除ボタンが押された
	const openDel = ( row,indexes ) => {
		setRow(row[indexes[0]]);
	};

	// 初期化ボタンが押された
	const openIni = ( row,indexes ) => {
		setForm(delForm);
	};

	// 追加でクローズボタンが押された
	const closeAdd = () => {
		let rowStock = {
			tenant_id:	JWToken.getToken().tenant_id,
			salon_id:	salon_id,
			goods_id:	row.id,
			stocktake:	row.stocktake,
		};
		masterParam[0].list= [rowStock];
		addRecord(masterParam[0],function (record) {
			getTableList();
		});
	};

	// 追加でクローズボタンが押された
	const closeRep = () => {
		let rowStock = {
			tenant_id:	JWToken.getToken().tenant_id,
			salon_id:	salon_id,
			goods_id:	row.id,
			stocktake:	row.stocktake,
		};
		masterParam[0].list= [rowStock];
		addRecord(masterParam[0],function (record) {
			getTableList();
		});
	};

	// 削除でクローズボタンが押された
	const closeDel = () => {
		masterParam[0].list = [];
		masterParam[0].url  = "/stock/" + salon_id;
		delRecords(masterParam[0], function (record){
			getTableList();
		});
	};

	// 初期化でクローズボタンが押された
	const closeIni = () => {
		masterParam[0].list = [];
		masterParam[0].url  = "/stock/" + salon_id;
		delRecords(masterParam[0], function (record){
			getTableList();
		});
	};

	const cancel = () => {
		
	}
	
	const stockChange = (event)  => {
		if ( Check.money(event.target.value) === false ) return;
		let stock =  Object.assign({},row);
		stock.stocktake   = event.target.value;
		setRow(stock);
	};

	/* 印刷 */
	const createChart = () => {
		let pages = [];			// 1ページ目は日別の集計と担当者別の集計

		const createPage = (chart) => {
			return (
				<section className={"sheet padding-10mm"}>
					<Table striped bordered size={"sm"} hover>
						<tbody >
							<tr>
								<th>{ "商品棚卸　"  } { salon_id && salonName }</th>
							</tr>
						</tbody>
					</Table>
					<Table bordered hover >
						 <thead bgcolor="#ebf0f7">
							<tr key={'header'}>
								<th>番号</th>
								<th>商品名</th>
								<th>販売価格</th>
								<th>仕入価格</th>
								<th>在庫数</th>
								<th>棚卸資産</th>
								<th>仕入先</th>
							</tr>
						</thead>
						<tbody>
							{ chart }
						</tbody>
					</Table>
				</section>
			);
		};

		let totalStock = 0;
		let totalTake  = 0;
		let goodsTable = stockList.filter((v)=>v.stocktake).map(function(value, index) {
			totalStock += Number(value.stocktake);
			totalTake  += value.stocktake * value.cost;
			return (
					<tr key={index}>
					  <td>{value.no  }</td>
					  <td>{value.name}</td>
					  <td><YenFormat  value={Number(value.price) } /></td>
					  <td><YenFormat  value={Number(value.cost)  } /></td>
					  <td><YenFormat  value={Number(value.stocktake)} /></td>
					  <td><YenFormat mark='¥' value={value.stocktake * value.cost} /></td>
					  <td>{value.supplierName}</td>
					</tr>
			);
		});
		goodsTable.push(
					<tr className="font-weight-bold">
						<td colSpan="4" ><div className="text-center">合　　計</div></td>
						<td><YenFormat  value={totalStock} /></td>
						<td><YenFormat mark='¥' value={totalTake} /></td>
						<td></td>
					</tr>
		);

		for ( let i = 0 ; i < goodsTable.length ; i += 50 ) {
			pages.push(createPage( goodsTable.slice(i,i+50)));
		}

		return(
			<div>
				{ pages }
			</div>
			);
	};

	const delForm = 
		<form>
			商品棚卸を初期化します。<br/>
		</form>;


	const repForm =
		<form>
			<FormGroup disabled>
				<InputGroup>
					<InputGroupText>在庫数</InputGroupText>
					<Input type="number" placeholder="在庫数" value={row.stocktake} onFocus={focusSelect} onChange={stockChange} autoFocus={true}/>
				</InputGroup>
				<InputGroup>
					<InputGroupText>番　号</InputGroupText>
					<Input type="number" placeholder="番号" value={row.no} readOnly={true}/>
				</InputGroup>
				<InputGroup>
					<InputGroupText>商品名</InputGroupText>
					<Input type="text" placeholder="商品名（40文字以内）" value={row.name} readOnly={true} />
				</InputGroup>
				<InputGroup>
					<InputGroupText>価　格</InputGroupText>
					<Input type="number" placeholder="商品単価" value={row.price} readOnly={true} />
				</InputGroup>
				<InputGroup>
					<InputGroupText>仕入値</InputGroupText>
					<Input type="number" placeholder="仕入単価" value={row.cost} readOnly={true} />
				</InputGroup>
				<InputGroup>
					<InputGroupText>仕入先</InputGroupText>
					<Input type="text" placeholder="仕入先" value={row.supplierName} readOnly={true} >
					</Input>
				</InputGroup>
			</FormGroup>
		</form>;

//	var form = state === EDIT_DEL ? delForm : repForm;

	const columns = [
		{ key: 'no',    	name: '番号',    width: 80,  editable: false, resizable: false,formatter:AlignCenter, filterable: true, sortable: true, filterRenderer: NumericFilter },
		{ key: 'name',  	name: '商品名',  width: 200, editable: false, resizable: true, formatter:AlignLeft,   filterable: true, sortable: true  },
		{ key: 'price', 	name: '販売価格',width: 100, editable: false, resizable: true, formatter:YenFormat,   filterable: true, sortable: true, filterRenderer: NumericFilter },
		{ key: 'cost',  	name: '仕入価格',width: 100, editable: false, resizable: true, formatter:YenFormat,   filterable: true, sortable: true, filterRenderer: NumericFilter },
		{ key: 'stocktake',	name: '在庫数',  width: 100, editable: false, resizable: true, formatter:YenFormat,   filterable: true, sortable: true, filterRenderer: NumericFilter },
		{ key: 'supplierName',name: '仕入先',            editable: false, resizable: true, formatter:AlignLeft,   filterable: true, sortable: true, filterRenderer: AutoCompleteFilter }
	];

	return (
		<GridTable title={'商品'} form={form} height={headerHeight}
				tableRows={stockList} rowGetter={rowGetter} length={stockList.length} getTable={getTableList} 
				columns={columns} showCheckbox={false} help={help}
				header={<PrintPreview isPoppedOut={false} title={ "商品棚卸表 " + salonName} create={createChart} />}
				closeButton={true} cancel={cancel}
				buttonAdd={buttons.add} buttonRep={buttons.rep} buttonDel={buttons.del} buttonIni={buttons.ini}
				openAdd={openAdd}   openRep={openRep}   openDel={openDel}   openIni={openIni}
				closeAdd={closeAdd} closeRep={closeRep} closeDel={closeDel} closeIni={closeIni}
		/>
	);

};

const help = 
	"##### 概要\n" +
	"商品・材料の棚卸を行います。 " +
	"商品や材料の在庫数を入力して、仕入単価との掛け算で固定資産を計算します。\n " +
	"在庫数の入力は各店舗ごとに行います。 " + 
	"「予約管理」画面の「店舗集計」＞「商品棚卸」で、画面を表示します。  \n " +
	"全店舗の合計は「集計表」＞「商品棚卸」で開きます。この画面では修正や初期化は行えません。  \n " +
	"##### 修正ボタン\n" +
	"修正したい商品に合わせてから修正ボタンを押すと、商品在庫の修正画面を表示します。\n " +
	"###### 「商品 修正」画面\n" +
	"##### 初期化ボタン︎   \n" +
	"店舗内の在庫数を全てクリアします。  \n" +
	"棚卸を実施する時に最初に在庫数をクリアしておきます。\n " + 
	"##### 印刷ボタン︎   \n" +
	"棚卸の結果を印刷します。\n " + 
	"";



export default Stock;