import React, { Component /*, useSelector */ } from 'react';
// import ReactDOM from 'react-dom'; react-dom V18 変更点
import { createRoot } from 'react-dom/client';
import { HashRouter,Route, Routes,BrowserRouter, Navigate } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootswatch/dist/cerulean/bootstrap.css'; // Added this :boom:
// import 'bootswatch/dist/yeti/bootstrap.min.css'; // Added this :boom:
import { JWToken /* ,GotoLogin */ } from './auth/Login';
import './Table.css';

// import AppNavBar from './AppNavBar';  ./auth/UserOnly.js に移動
import Booking  from './Booking';
import Customer from './Customer';
// import DayChart from './DayChart';
import DaySlip  from './DaySlip';
import MonthChart from './MonthChart';
import YearChart from './YearChart';
import Stock     from './Stock';
import WorkHour  from './WorkHour';
import ShiftModel from './ShiftModel';

import Tenant   from './Tenant';
import Salon    from './Salon';
import Staff    from './Staff';
import Tech     from './Tech';
import Menu     from './Menu';
import TechSum  from './TechSum';
import Goods    from './Goods';
import Supplier from './Supplier';
import DayOffItem  from './DayOffItem';       // 休暇理由
import AccountItem from './AccountItem';      // 勘定科目
import SalesTax from './SalesTax';
import Point    from './Point';
import Credit   from './Credit';
import Settings from './Settings';      // テナントの設定
import Payment  from './Payment';       // 支払い情報
// import Test     from './LINE/test';     // LINE テスト
// import Reserve  from './Reserve';       // 顧客のネット予約   未使用
// import Excuse   from './Excuse';     // スタッフの勤怠管理 未使用
import Convert  from './Convert';       // 旧DBのコンバート
import Login, { GuestWebApp, GuestReceipt, GuestPrice, GuestBook, GuestCustomer, GuestRegister, GuestWorkHour }      from './auth/Login';
import AppNavBar    from './AppNavBar';
import {GuestNavBar} from './auth/Login';

// import UserOnly   from './auth/UserOnly';
import GuestLogin from './auth/GuestLogin';
// import GuestOnly  from './auth/GuestOnly';

import GuestHome    from './GuestHome';
// import GuestRecord  from './GuestRecord';
// import GuestSet     from './GuestSet';
/*****
import About from './About';
import Art   from './Art';
import Goods from './Goods';
******/
import News   from './News';

class About extends Component {
    render() {
        return <div>About</div>;
    }
}

class Signup extends Component {
    render() {
        console.log(process.env);
        return  <div>Signup</div>;
    }
}

const GotoInit = () => {
  return <Navigate to={'/'}  replace={true} />
}

/*************************************************
var createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;
class GuestHome extends Component {
  constructor(props) {
		super(props);

 		this.state = {
            image_src: ""
    };
        
    		this.handleChangeFile = this.handleChangeFile.bind(this);

  }

  handleChangeFile(e) {
    console.log(e);
    // ①イベントからfileの配列を受け取る
    var files = e.target.files;

    // ②createObjectURLで、files[0]を読み込む
    var image_url = createObjectURL(files[0]);

    // ③setStateする！
    this.setState({image_src: image_url});
  }

  handleClick(e) {
        this.refs.file.getDOMNode().click();
  }
  
  render() {
      return(
// ここにGuestのログイン画面や、登録画面を作る
      <div>Web予約 {this.props.match.params.tenant} {/ * http://client-s2neko.c9users.io:8080/#/guest/atoz * /}
            { console.log(this.props.match) }
          <div>
              <input type="file" ref="file" onChange={this.handleChangeFile} />
              <img src={this.state.image_src} alt="default value" />
              <button onClick={this.handleClick} type="button">投稿する</button>
          </div>

      </div>
      );
  }
}
*************************************************/

const LoginRoutes = () => {
  routers =  (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/"  element={<AppNavBar/>}/>
{/*          <Route path="/LINE/Test" element={<Test/>}/>  */}
        </Routes>
      </BrowserRouter>
  
      <HashRouter>
        <Routes>
{/*
          <Route path="/"           element={<Login/>}/>
          <Route path="/login"      element={<Login/>}/>
          <Route path="/signup"     element={<Signup/>}/>
          <Route path="/guest/:tenant"  element={<GuestLogin/>}/>
          <Route path="/news"       element={<News/>}/>
*/}          
          <Route path="/booking"     element={<Booking/>}/>
          <Route path="/customer"    element={<Customer/>}/>
          <Route path="/daySlip"     element={<DaySlip/>}/>
          <Route path="/monthChart"  element={<MonthChart/>}/>
          <Route path="/yearChart"   element={<YearChart/>}/>
          <Route path="/stock"       element={<Stock/>}/>
          <Route path="/workHour"    element={<WorkHour/>}/>
          <Route path="/shiftModel"  element={<ShiftModel/>}/>
          <Route path="/about"       element={<About/>}/>
          <Route path="/tenant"      element={<Tenant/>}/>
          <Route path="/salon"       element={<Salon/>}/>
          <Route path="/salesTax"    element={<SalesTax/>}/>
          <Route path="/Point"       element={<Point/>}/>
          <Route path="/credit"      element={<Credit/>}/>
          <Route path="/staff"       element={<Staff/>}/>
          <Route path="/tech"        element={<Tech/>}/>
          <Route path="/techSum"     element={<TechSum/>}/>
          <Route path="/menu"        element={<Menu/>}/>
          <Route path="/goods"       element={<Goods/>}/>
          <Route path="/supplier"    element={<Supplier/>}/>
          <Route path="/dayoffitem"  element={<DayOffItem/>}/>
          <Route path="/accountitem" element={<AccountItem/>}/>
          <Route path="/settings"    element={<Settings/>}/>
          <Route path="/payment"     element={<Payment/>}/>
  {/*     <Route path="/reserve"     element={<Reserve/>}/> */}
  {/*     <Route path="/excuse"      element={<Excuse/>}/> */}
          <Route path="/convert"     element={<Convert/>}/>
          <Route path="/GuestHome"   element={<GuestHome/>}/>
  { /*    <Route path="/GuestRecord" element={<GuestRecord/>}/>
          <Route path="/GuestSet"    element={<GuestSet/>}/> */ }
        </Routes>
      </HashRouter>
    </div>
  );
  
  console.log('index.js: Login');
  
//  const container = document.getElementById('root');
//  const root = createRoot(container); // createRoot(container!) if you use TypeScript
  root.render(routers);
  
};


var routers = (
  
  <div>
    <BrowserRouter>
      <Routes>
        <Route path="/"  element={<GuestNavBar/>}/>
{/*        <Route path="/LINE/Test" element={<Test/>}/> */}
      </Routes>
    </BrowserRouter>
    <HashRouter>
      <Routes>
        <Route path="/"               element={<Login/>}/>
        <Route path="/login"          element={<Login/>}/>
        <Route path="/signup"         element={<Signup/>}/>
        <Route path="/guest/:tenant"  element={<GuestLogin/>}/>
        <Route path="/news"           element={<News/>}/>
        <Route path="/GuestHome"      element={<GuestHome/>}/>
        <Route path="/guest/webapp"   element={<GuestWebApp/>}/>
        <Route path="/guest/receipt"  element={<GuestReceipt/>}/>
        <Route path="/guest/price"    element={<GuestPrice/>}/>
        <Route path="/guest/book"     element={<GuestBook/>}/>
        <Route path="/guest/customer" element={<GuestCustomer/>}/>
        <Route path="/guest/register" element={<GuestRegister/>}/>
        <Route path="/guest/workhour" element={<GuestWorkHour/>}/>
        <Route path="*" element={<GotoInit/>}/>
      </Routes>
    </HashRouter>
  </div>
);


// ReactDOM.render( <App />, document.getElementById('root') );
// ReactDOM.render( routes, document.getElementById('root'));


console.log('index.js: Guest');
JWToken.clearToken();
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(routers);
// GotoLogin();



export default LoginRoutes;
