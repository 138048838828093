
/* ==============================================================================
 Webメニュー技術情報の編集画面

 履歴-----------------------------------------
 2019/03/17 reactstrap 対応済み


=============================================================================== */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button,ButtonToolbar,Modal,ModalHeader,ModalBody,ModalFooter,InputGroup,InputGroupText,Input,Form,FormGroup } from 'reactstrap';

import ReactDataGrid from 'react-data-grid';
import Select from 'react-select';
import Check from './Input';
import {Alert} from './auth/Login';

import './Table.css';

import { AlignLeft,YenFormat } from './Formatter';	// React Data Grid

import { MAX_DB_LENGTH } from './Define';

function changeYen(num){
    return '¥' + String(num).split("").reverse().join("").match(/\d{1,3}/g).join(",").split("").reverse().join("");
}

const initialRow = { tenant_id: 0, menu_id:0, tech_id:0, price:0 };

export class MenuTech extends Component {
	
	static propTypes = {
		menu_id: PropTypes.number.isRequired,		// menu_id
		menuList: PropTypes.object.isRequired,		// メニュー技術
		techList:	PropTypes.array.isRequired,		// 技術一覧
		onRowUpdated: PropTypes.func,				// 技術伝票、施術時間、合計金額を渡して関数コール
		height: PropTypes.number
	}

	//props初期値指定
	static defaultProps = {
		onRowSelect: null,
		onRowUpdated: null,
		height: 600
	};

	// コンストラクター
	constructor(props) {
		super(props);
		this.state = {
			selectedIndexes:[0],    					// react-data-grid
			rows:   [Object.assign({},initialRow)],     // [{ tenant_id: 0, meni_id: 0, tech_id: 0, price: 0 }] 各プロパティの順番はDB登録時のJSON変換で重要
			rowData: Object.assign({},initialRow),		// 各プロパティの順番はDB登録時のJSON変換で重要
			total: 0,
			buttonDisable: true,  // 一覧選択時の修正ボタン、削除ボタンの設定
			title: "",
			techSelect:{}	// { value: id, label: id + name },
		};
	}

	// 描画が行われる直前に一度だけ呼ばれる
	componentDidMount = () => {
console.log(this.props.menu_id,this.props.menuList);
		let list = this.props.menuList.filter(function(value) {
			return value.id === this.props.menu_id;
		}.bind(this));
		this.setState({ rows: list });
	}


	// プロパティ(props)が変更された時にコールされる
	static getDerivedStateFromProps = (nextProps) => {
		console.log('getDerivedStateFromProps nextProps',nextProps);

		let total = 0;
   		let menuList = nextProps.menuList.map(function(o){
			let row  = Object.assign({},o);
			let tech   = nextProps.techList.find(function(t) { return o.tech_id === t.id; });
//			row.period = tech.period;
			row.tech   = tech.name;
			total += Number(o.price); 
			return row;
		});
	
		if ( menuList.length === 0 ) {
			return( {
				total:total,
				rows: menuList,
				buttonDisable:true
			})
		} else {
			return( {
				total:total,
				rows: menuList,
				buttonDisable:false
			});
		}
	}

	componentDidUpdate() {
		let input = this.refs['techName'];
		input && input.focus();
	}
	
	// 追加ボタン
	onRowAdd = () => {
//		console.log('onRowAdd()',this.props.menuList);
		if ( this.props.techList.length === 0 ) {
			Alert('技術が登録されていません。技術を登録してください。');
			return;
		}

		let tech  = this.props.techList[0];
console.log(this.props.menuList);
		let menuTech = {			// DBに登録するときにJSONに変換する。このとき順番が重要
			tenant_id:0, 
			menu_id:  this.props.menu_id,
			tech_id:  tech.id,
			price:    tech.price,
			tech:	  tech.name,
		};

		let i = this.state.rows.length;
		this.setState({ 
			rowData:			menuTech,
			selectedIndexes:	[i],
			title:				"技術 追加",
			showModal:			true,
		});

	}

	// 修正ボタン
	onRowRep = () => {
		let i = this.state.selectedIndexes[0];
//		console.log('onRowRep',i);
		let menuTech = Object.assign({},this.state.rows[i]);
		this.setState({ 
			rowData:			menuTech,
			selectedIndexes:	[i],
			title:				"技術 修正",
			showModal:			true,
		});
	}

	// 削除ボタン
	onRowDel = () => {
		let i = this.state.selectedIndexes[0];
		let rows = this.state.rows.concat();
		rows.splice(i,1);
		this.onRowUpdated(rows);

	}


	// 親コンポーネントのコールバックでrowsを渡す		
	onRowUpdated = (rows) => {
		if ( this.props.onRowUpdated === null ) {
			this.setState({rows:rows});
			return;
		}
		let duration = 0;
		let total = 0;
		let tech = rows.map(function(o){
			duration += o.period;
			total    += Number(o.price);
			let s = Object.assign({},o);
			delete s.tech;					// 技術名を削除
			return s;
		});
		this.setState({total:total});
		
		if ( this.props.onRowUpdated(tech,duration,total) ) {
			this.setState({rows:rows});
		}
	}


/* ReactDataGrid -------------------------------------------- */ 
	onCellSelected = ({ rowIdx, idx }) => {
		this.setState( {selectedIndexes: [rowIdx]} );
// console.log(rowIdx);
//		this.setState({buttonDisable: false});
		if ( this.props.onRowSelect !== null) {
			this.props.onRowSelect(this.rowGetter(rowIdx));  // propsに設定された外部関数をコールする
		}
	}

	onRowDoubleClick = () => {
		this.onRowRep();
	}

	rowGetter = (rowIdx) => {
		return this.state.rows[rowIdx];
	}

/* ReactDataGrid --------------------------------------------  */

	// InputにFocusを当てると選択状態にする
	focusSelect = (event) => {
		event.target.select();
	}
	
	techChange = (event) => {
//		console.log(event.value);
		let id = Number(event.value);
		let tech     = this.props.techList.find(function(o) { return o.id === id; });
		let menuTech = this.state.rowData;
		menuTech.tech_id = id;
		menuTech.price   = tech.price;
		menuTech.tech    = tech.name;			// 技術名を追加
//		menuTech.period  = tech.period;			// 施術時間を追加

		this.setState({ rowData: menuTech });
	}

	priceChange = (event) => {
		if ( Check.money(event.target.value) === false ) return;
		let menuTech   = Object.assign({}, this.state.rowData);
		menuTech.price = event.target.value;
		this.setState({ rowData:menuTech });
	}

	// 登録ボタンが押された
	close = () => {
		let tech_id = this.state.rowData.tech_id;
		let tech = this.props.techList.find(function(o) { return o.id === tech_id; });
		let i = this.state.selectedIndexes[0];
		var rows = this.state.rows.concat();
		rows[i] = this.state.rowData;
		rows[i].tech = tech.name;
		this.setState( { rows: rows });
		this.setState({showModal: false });
console.log(rows);
		this.onRowUpdated(rows);

	}
	
	// キャンセル
	cancel = () => {
		this.setState({showModal: false });
	}

//	toggle() {
	toggle = () => {
		if ( this.state.showModal === true ) {
			this.cancel();
			this.setState( { showModal: false } );
		} else {
			this.setState( { showModal: true } );
		}
	}

	
	render() {
		const columns= [
				 { key: 'tech', name: '技術名', width: 230, editable: false, resizable: true, formatter:AlignLeft },
				 { key: 'price',name: '価格'  , 　　　　　  editable: false, resizable: true, formatter:YenFormat },
			 ];

		// 技術のSelect設定
		let list = this.props.techList.filter( (tech) => {		// 削除された番号は表示しない
			return tech.no < MAX_DB_LENGTH;
		});
		let techOptions = list.map( (tech) => {
			return { value: tech.id, label:tech.no + ' ' + tech.name};
		});
		var tech = techOptions.find( (option) => {
			return option.value === this.state.rowData.tech_id;
		});

		return (
			<div className="MenuTech">
				<ButtonToolbar>
					<Button className="float-right" color="primary" onClick={this.onRowAdd}>追加</Button>
					<Button className="float-right" color="success" onClick={this.onRowRep} disabled={this.state.buttonDisable}>修正</Button>
					<Button className="float-right" color="danger"  onClick={this.onRowDel} disabled={this.state.buttonDisable}>削除</Button>
				    <div className="d-flex align-items-end">　技術合計：{changeYen(this.state.total)}円</div>
				</ButtonToolbar>

				<ReactDataGrid enableCellSelect={true} minHeight={this.props.height} // minWidth={420} 
							rowGetter={this.rowGetter} columns={columns} rowsCount={this.state.rows.length}
							// onRowUpdated={this.handleRowUpdated}
							onCellSelected={this.onCellSelected} onRowDoubleClick={this.onRowDoubleClick}
					rowSelection={{
						showCheckbox: false,
						enableShiftSelect: false,
						selectBy: {
							indexes: this.state.selectedIndexes
						}
					}}  />
				
				<Modal isOpen={this.state.showModal} backdrop={'static'} onClose={this.cancel} autoFocus={false}> 
					<ModalHeader toggle={this.toggle}>{this.state.title}</ModalHeader>
					<ModalBody>
						<Form>
							<FormGroup>
								<InputGroup>
									<InputGroupText>技術名</InputGroupText>
									<Select className="form-control react-select-original"  placeholder="技術" value={tech} options={techOptions} onChange={this.techChange} readOnly={this.state.readOnly} autoFocus={true}
											menuPortalTarget={document.body} menuPosition={'fixed'}   styles={ {menuPortal: provided => ({ ...provided, zIndex: 9999 }), menu: provided => ({ ...provided, zIndex: 9999 })}} />
								</InputGroup>
								<InputGroup>
									<InputGroupText>価　格</InputGroupText>
									<Input type="number" placeholder="金額" value={this.state.rowData.price} onChange={this.priceChange} onFocus={this.focusSelect} readOnly={this.state.readOnly}/>
								</InputGroup>
							</FormGroup>
						</Form>
					 </ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.close} disabled={this.state.closeDisable}>OK</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}

}


export default MenuTech;
