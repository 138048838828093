/* ==============================================================================
 印刷画面のウィンドウ  ポップアップモジュール
=============================================================================== */

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Popout from 'react-popout';
import { Button } from 'reactstrap';

const PrintPreview = ({
    button = "印刷",
    disabled = false,
    isPoppedOut: initialIsPoppedOut = false,
    title = "印刷",
    url = "A4.html",
    contents = null,
    create = null,
}) => {
    const [isPoppedOut, setIsPoppedOut] = useState(initialIsPoppedOut);
    const [generatedContents, setGeneratedContents] = useState(null);

    useEffect(() => {
        setIsPoppedOut(initialIsPoppedOut);
    }, [initialIsPoppedOut]);

    const popout = useCallback(() => {
        if (create) {
            setGeneratedContents(create());
        }

        setIsPoppedOut(true);
    }, [create]);

    const popoutClosed = useCallback(() => {
        setIsPoppedOut(false);
    }, []);
    

    return (
        <div>
            {isPoppedOut && (contents || create ) ? (
                <Popout
                    containerID={"printroot"}
                    url={url}
                    title={title}
                    onClosing={popoutClosed}
                    options={{ width: 840, height: 600 }}
                >
                    <div id="printroot">
                    {contents}
                    {generatedContents}
                    </div>
                </Popout>): null
                
            }
            {/* <!-- これは親画面に表示される --> */}
            <Button onClick={popout} disabled={disabled}>{button}</Button>
        </div>
    );
};

PrintPreview.propTypes = {
    button: PropTypes.string,
    disabled: PropTypes.bool,
    isPoppedOut: PropTypes.bool,
    title: PropTypes.string,
    url: PropTypes.string,
    contents: PropTypes.element, // 予め用意されたコンテンツ
    create: PropTypes.func, // ボタンが押されたときにコンテンツを作成する
};

export default PrintPreview;
