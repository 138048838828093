/* ==============================================================================
 月次勤怠表の表示・印刷画面

=============================================================================== */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PrintPreview from './PrintPreview';
import { InputGroup,InputGroupText,FormGroup,Table,Container,Row,Button } from 'reactstrap';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/ja';

import Request from 'superagent';
import {JWToken,Alert} from './auth/Login';

import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import "react-datepicker/dist/react-datepicker.css";

import { Date2Format,time2int,int2time } from './Formatter';	// React Data Grid

import './Printer.css';		// ページ番号の印刷

// font size 12px に設定した時
// const minimumTebleHeight = 61;
// const rowHeight  = 17;
// const pageHeight = 1043 - 14;			// A4 サイズ

// 日次集計表を作成するには、会計情報、技術会計、商品会計、税金情報、カードが読み終わってないといけない
const ITEM_MONTH	= 0x01;
const ITEM_TENANT   = 0x02;
const ITEM_ALL		= ITEM_MONTH | ITEM_TENANT ;  // | ITEM_TECH | ITEM_GOODS;

const MSEC_OF_DAY     = (24*60*60*1000);

// タイムゾーン
const TIMEZONE = 'Asia/Tokyo';

// マスターのreadに使用する
const tenantParam = { url: "/tenant/",      	errMsg: "テナント",		list: [],	item: ITEM_TENANT };
const monthParam  = { url: "/workhour/month/",	errMsg: "月次勤務表",	list: [],	item: ITEM_MONTH  };

var	item_flag = 0;	// ITEM_XXX DBが読込まれた事を確認する。 ITEM_ALLになったか？


class MonthRoster extends Component {
	// propTypes
	static propTypes = {
		viewDate:	PropTypes.string,		// 表示日
		changeDate: PropTypes.func,			// ReactCalenderTimeline の日付を変更する

	}

	static defaultProps = {
		viewDate: moment().tz(TIMEZONE).format('YYYY-MM-DD'),
		changeDate: null,
	}

	// コンストラクター
	constructor(props) {
		super(props);
		this.state = {  
			creditTable:  ( <div></div> ),
			start_day: this.props.viewDate,
			end_day: moment().tz(TIMEZONE).add(1,'month').add(-1,'day').format('YYYY-MM-DD'),
			chart: ( <section className="sheet padding-10mm">A4 Seet </section> ),
			staffTotal:[],
			preWeek:  0,		// 開始日より何日前か
			preStart: 0,		// スタート日の日数(1970-01-01からの日数)
			preEnd:   0			// エンドの日数    (1970-01-01からの日数)
		};

		moment.locale('ja');
		moment.tz.setDefault(TIMEZONE);		// timezoneをJSTに変更する

		registerLocale('ja', ja);		// react-datepicker の日本語表記
	}

	// ComponentがDOMツリーに追加される前に一度だけ呼ばれる。
	componentDidMount = () => {
		console.log("componentDidMount");
		this.setState({start_day: this.props.viewDate });

		item_flag = 0;				// 
		tenantParam.url     = "/tenant/" + JWToken.getToken().tenant_id;
		this.getTenant(tenantParam);	// テナント情報の読み込み（月次会計情報、担当者の読み込み)
	}
	
	getAll = ( start,end ) => {
		let startWeek = tenantParam.list[0].start_week;
		let dayOfWeek = moment(start).day();
		let preWeek   = startWeek <= dayOfWeek ? dayOfWeek - startWeek : dayOfWeek + 7 - startWeek ;		// 週40,44時間を計算する為に週単位に計算する
// console.log(startWeek,dayOfWeek,preWeek);
		start  = moment(start).add( -preWeek, 'days' ).format("YYYY-MM-DD");
		let preStart = moment(start).valueOf() / MSEC_OF_DAY;	// 1970-01-01からの日数
		let preEnd   = moment(end  ).valueOf() / MSEC_OF_DAY;	// 1970-01-01からの日数
		this.setState({
			preWeek:  preWeek,		// 開始日より何日前か
			preStart: preStart,		// スタート日の日数(1970-01-01からの日数)
			preEnd:   preEnd		// エンドの日数    (1970-01-01からの日数)
		});

		monthParam.url		= "/workhour/month/"	+ start + '_' + end;
		item_flag = ITEM_TENANT;	// テナントは再読み込みしない
		this.getList(monthParam);	// 月次会計情報
	}

// ======================================================================================
// DB 入出力 関数
// ======================================================================================

	getTenant = (param) => {
		//ajax通信する
		Request.get(param.url)
			.query( { token: JWToken.getToken().token } )
			.then( res => {
				param.list = res.body.dbData;
				item_flag |= param.item;				// ビットを1に

				// 月次の期首はテナントから読み取る
				let start = moment().tz(TIMEZONE);
				if ( start.get('date') < param.list[0].labor_day ) start.add(-1,'month');
				start.set('date', param.list[0].labor_day);
				let startDay = start.format('YYYY-MM-DD');
				let endDay = start.add(1,'month').add(-1,'days').format('YYYY-MM-DD');
				this.setState({ start_day: startDay,
								end_day:   endDay
				});
				
				this.getAll(startDay,endDay);
			})
			.catch( err => {
				console.error(param.errMsg +"を取得できませんでした。");
				console.log(err,err.status);
				if ( err & err.status === 403 ) {
					Alert('再ログインしてください');
					JWToken.clearToken();
				}
			});
	}

/* -----------------------------------------------------------
	マスターの取得
------------------------------------------------------------- */
	getList = (param) => {
		//ajax通信する
		Request.get(param.url)
			.query( { token: JWToken.getToken().token } )
			.then( res => {
				param.list = res.body.dbData;
				this.getInitialTable(param.item);		// Chartの作成
			})
			.catch( err => {
				console.error(param.errMsg +"を取得できませんでした。");
				console.log(err,err.status);
				if ( err & err.status === 403 ) {
					Alert('再ログインしてください');
					JWToken.clearToken();
				}
			});
	}

	getInitialTable = (flag) => {
		item_flag |= flag;
		if ( item_flag !== ITEM_ALL ) return;		// マスターがすべて読み込まれたか？

		this.createChart();
	}



// ======================================================================================
// 画面入力 関数
// ======================================================================================

	// 日時が変更された
	dayChange = (date) => {
//		console.log(event.target.value);
		let start = moment(date).format('YYYY-MM-DD');
		let end   = moment(start).add(1,'month').add(-1,'days').format('YYYY-MM-DD');
		this.setState({ start_day: start,
						end_day:   end
		});

		//指定された日付でファイルを読み込む
		this.getAll(start,end);
	}

	decMonth = () => {
		let start = moment(this.state.start_day).add(-1,'month').format('YYYY-MM-DD');
		let end   = moment(start).add(1,'month').add(-1,'days'  ).format('YYYY-MM-DD');
		this.setState({ start_day: start,
						end_day:   end
		});

		//指定された日付でファイルを読み込む
		this.getAll(start,end);
	}

	incMonth = () => {
		let start = moment(this.state.start_day).add(1,'month').format('YYYY-MM-DD');
		let end   = moment(start).add(1,'month').add(-1,'days').format('YYYY-MM-DD');
		this.setState({ start_day: start,
						end_day:   end
		});

		//指定された日付でファイルを読み込む
		this.getAll(start,end);
	}

// ======================================================================================
// テーブル作成
// ======================================================================================

	clearMonth = () => {
		let roster = {
			date:		'',
			punchStart:'',
			punchQuit:	'',
			start:		null,
			quit:		null,
			break_time:	null,
			type:		'',
			kinmu:		null,
			syotei:		null,
			syoteiGai:	null,
			houteiGai:	null,
			shinya:		null,
			kyuujitsu:	null,
			h60Gai:		null
		};
		let month = [];
		let start = this.state.preStart;
		let end   = this.state.preEnd;
		for ( let i = start ; i <= end ; i++ ) {
			roster.date = moment(i*MSEC_OF_DAY).format('YYYY-MM-DD');
			month.push(Object.assign({},roster));
		}
		return month;
	}

	// rosterから １ヶ月の勤務表を作成する。
	createChart = () => {
		let staff_id = 0;
		let staff_name = '';
		let total = { };		// 担当者毎の合計
		let staffTable = [];	// ページ毎の印刷
		let staffTotal = [];	// 画面表示
		let month = [];			// １ヶ月分のテーブル
		
		if ( monthParam.list.length ) {
			// workhour のループ
			monthParam.list.forEach( (workhour, index) => {
				let	date = moment(workhour.work_date  ).valueOf() / MSEC_OF_DAY;
				let idx  = date - this.state.preStart;
				// 担当者が変わった場合は前担当者のページを作成する
				if ( staff_id !== workhour.staff_id ) {
					if ( staff_id !== 0 ) {
						[month,total] = this.totalMonth(month,staff_name);
console.log(month,total,staff_name);
						// 前担当者１名分の印刷イメージの作成
						staffTable.push ( this.createPage(month,total) );
	
						// 前担当者の画面表示用 月間トータル
						staffTotal.push( this.createMonthTotal(total) );
					}
					// 次の担当者を設定るす
					staff_id = workhour.staff_id;
					staff_name = workhour.staff;
					month = this.clearMonth();
					this.clearTotal(total,workhour);
				} 
				month[idx] = this.getOverTime(workhour);
			});
			[month,total] = this.totalMonth(month,staff_name);
console.log(month,total,staff_name);
			// 担当者１名分の印刷イメージの作成
			staffTable.push ( this.createPage(month,total) );
			// 担当者別 月間トータル
			staffTotal.push( this.createMonthTotal(total) );
		}
		
		let display = (
			<div>
				<Table bordered hover size={"sm"}>
					<thead bgcolor="#ebf0f7">
						<tr>
							<th>担当者</th>
							<th>勤務　</th>
							<th>定時　</th>
{/*							<th>所定外</th> */ }
							<th>残業　</th>
							<th>深夜　</th>
							<th>休日　</th>
							<th>60h超 </th>
						</tr>
					</thead>
					<tbody>
						{ staffTotal }
					</tbody>
				</Table>
			</div>
		);
		this.setState({ staffTotal: display });
		
		let print =	(
			<div id="printroot" >
				{ staffTable }
			</div>
		);

		this.setState( { chart: print } );
	}

// ======================================================================================
// 画面表示の作成
// ======================================================================================

	// 画面表示のための合計値を初期化する
	clearTotal = ( total,workhour ) => {
		total.id    = workhour.staff_id;
		total.name  = workhour.staff;
		total.kinmu     = 0;
		total.syotei    = 0;
		total.syoteiGai = 0;
		total.houteiGai = 0;
		total.shinya    = 0;
		total.kyuujitsu = 0;
		total.h60Gai    = 0;
		total.count     = 0;
	}


	createMonthTotal = (total) => {
		return(
			<tr key={total.id} className="text-center">
				<td>{total.name}</td>
				<td>{int2time(total.kinmu)}</td>
				<td>{int2time(total.syotei + total.syoteiGai)}</td>
{ /*
				<td>{int2time(total.syotei)}</td>
				<td>{int2time(total.syoteiGai)}</td>
  */ }
				<td>{int2time(total.houteiGai)}</td>
				<td>{int2time(total.shinya)}</td>
				<td>{int2time(total.kyuujitsu)}</td>
				<td>{int2time(total.h60Gai)}</td>
			</tr>
		);
	}

// ======================================================================================
// 印刷するページの作成
// ======================================================================================

	totalMonth = (month,name) => {
		let total = {
			kinmu:		0,
			syotei:		0,
			syoteiGai:	0,
			houteiGai:	0,
			shinya:		0,
			kyuujitsu:	0,
			h60Gai:		0,
			count:		0
		};
		let weekOntimeLimit = tenantParam.list[0].after_work * 60;	// １週間の定時の上限時間(40h or 44h)
		let weekOntime = 0;					// １週間の定時
		let monthOvertime = 0;				// １ヶ月の残業時間

		let line = month.map((d,index) => {
			// 1週間に40/44時間勤務のチェック
			if ( 0 === index % 7 ) weekOntime = 0;
			weekOntime += d.syotei + d.syoteiGai;
			if ( weekOntime > weekOntimeLimit ) {
				let timeOver = weekOntime - weekOntimeLimit;
				d.houteiGai += timeOver;
				d.syoteiGai -= timeOver;
				if ( d.syoteiGai < 0 ) {
					d.syotei += d.syoteiGai;
					d.syoteiGai = 0;
				}
				weekOntime = weekOntimeLimit;
			}
			
			// １ヶ月に60時間以上の残業は割増料金50%
			if ( index >= this.state.preWeek ) {
				monthOvertime += d.houteiGai + d.shinya + d.kyuujitsu;
				if ( monthOvertime > 60*60 ) {
					d.h60Gai = monthOvertime - 60*60;
					monthOvertime = 60*60;
				}

				total.kinmu		+= d.kinmu;
				total.syotei	+= d.syotei;
				total.syoteiGai += d.syoteiGai;
				total.houteiGai += d.houteiGai;
				total.shinya	+= d.shinya;
				total.kyuujitsu += d.kyuujitsu;
				total.h60Gai	+= d.h60Gai;
				if ( d.kinmu ) total.count++;
			}

			return d;
		});
		total.name = name;
		return [line,total];	
	}
	
	createPrintDays = (month) => {
		let line = month.map((d,index) => {
			return (
				<tr key={d.date} className={ index < this.state.preWeek ? "font-weight-light text-muted" : "font-weight-normal" }>
					<td><Date2Format value={d.date}/></td>
{ /* 					<td>{int2time(d.start) +  (d.punchStart ? ' ('+d.punchStart+')' : '')}</td> */}
{ /*					<td>{int2time(d.quit)  +  (d.punchQuit  ? ' ('+d.punchQuit +')' : '')}</td> */}
 					<td>{int2time(d.start) }</td>
					<td>{int2time(d.quit)  }</td>
					<td>{int2time(d.break_time)}</td>
					<td>{d.name}</td>
					<td>{int2time(d.syotei + d.syoteiGai)}</td>
					<td>{int2time(d.kinmu)}</td>
{/*
					<td>{int2time(d.syotei)}</td>
					<td>{int2time(d.syoteiGai)}</td>
 */}
					<td>{int2time(d.houteiGai)}</td>
					<td>{int2time(d.shinya)}</td>
					<td>{int2time(d.kyuujitsu)}</td>
					<td>{int2time(d.h60Gai)}</td>
				</tr>
			);
		});
		if ( this.state.preWeek ) {
			line.splice(this.state.preWeek,0,
				<tr bgcolor="#ebf0f7" className="text-center" key="head1">
					<th>月日</th>
					<th>出勤</th>
					<th>退勤</th>
					<th>休憩</th>
					<th>勤怠届</th>
					<th>定時</th>
					<th>勤務</th>
	{ /*
					<th>所定</th>
					<th>所定外</th>
	*/}
					<th>残業</th>
					<th>深夜</th>
					<th>休日</th>
					<th>60h超</th>
				</tr>
			);
		}

		return line;
	}
	
	// 担当者の１ページを作成する
	createPage = (month,total) => {
		return(
			<section className={"sheet padding-10mm"} key={total.id}>
				<Table striped bordered size={"sm"} hover>
					<tbody >
						<tr>
							<th>{ "月次集計表　　" + total.name + "　　" + this.state.start_day + " 〜 " + this.state.end_day }</th>
						</tr>
					</tbody>
				</Table>
				<Table bordered hover>
					 <thead bgcolor="#ebf0f7" className="text-center">
						<tr key="head1">
							<th>月日</th>
							<th>出勤</th>
							<th>退勤</th>
							<th>休憩</th>
							<th>勤怠届</th>
							<th>定時</th>
							<th>勤務</th>
{ /*
							<th>所定</th>
							<th>所定外</th>
*/}
							<th>残業</th>
							<th>深夜</th>
							<th>休日</th>
							<th>60h超</th>
						</tr>
					</thead>
					<tbody >
						{ this.createPrintDays(month) }
						<tr className="font-weight-bold" key="tail2">
							<td className="text-right">{month.length-this.state.preWeek}日間</td>
							<td colSpan="2"  className="text-right">出勤日数 {total.count}日</td>
							<td></td>
							<td className="text-center">合計</td>
							<td>{int2time(total.syotei + total.syoteiGai)}   </td>
							<td>{int2time(total.kinmu)}    </td>
{/*
							<td>{int2time(total.syotei)}   </td>
							<td>{int2time(total.syoteiGai)}</td>
 */}
							<td>{int2time(total.houteiGai)}</td>
							<td>{int2time(total.shinya)}  </td>
							<td>{int2time(total.kyuujitsu)}</td>
							<td>{int2time(total.h60Gai)}    </td>
						</tr>
					</tbody>
				</Table>
			</section>
		);
	}

	getOverTime = (workhour) => {
		const h05  = time2int('05:00');
//		const h08  = time2int('08:00');							// 2021-05-26 法定労働時間を所定労働時間に変更
		const h22  = time2int('22:00');
		const week = [1,2,4,8,16,32,64,128];
		
		let open   = time2int(tenantParam.list[0].start_time);	// 始業時刻 開店
		let close  = time2int(tenantParam.list[0].quit_time );	// 就業時刻 閉店
		let recess = time2int(tenantParam.list[0].break_time);	// 休憩時間
		const h08  = close - open - recess;						// 2021-05-26 法定労働時間を所定労働時間に変更

		let dayOfWeek  = week[moment(workhour.work_date).day()];
		let start      = time2int(workhour.start);
		let quit       = time2int(workhour.quit );
		let break_time = time2int(workhour.break_time );

		if ( start !== null && quit !== null && break_time !== null ) {
			let kinmu     = quit - start - break_time;													// 就業時間
			let syotei    = Math.min( h08, Math.min(quit,close) - Math.max(open,start) - break_time);	// 所定内
			if ( start > close || quit  < open ) syotei = 0;
			let kyuujitsu = ( tenantParam.list[0].day_off & dayOfWeek ) ? syotei : 0;			// 休日出勤
			let overtime  = Math.max(kinmu,syotei) - syotei;									// 所定時間外
			let shinya    = ( start < h05 ) ? Math.min(h05,quit) - start : 0   +  ( quit > h22 ) ? quit - Math.max(h22,start) : 0 ;			// 深夜残業
			let houteiGai = Math.max(kinmu,h08) - h08 - shinya;								// 法定外
			let syoteiGai = overtime - houteiGai - shinya;									// 所定外
			return {
				date:		workhour.work_date,
				name:		workhour.name,
				punchStart: workhour.punch_start !== null ? workhour.punch_start : '',
				punchQuit:	workhour.punch_quit  !== null ? workhour.punch_quit  : '',
				start:		start,
				quit:		quit,
				break_time:	break_time,
				type:		workhour.name,
				kinmu:		kinmu,
				syotei:		syotei,
				syoteiGai:	syoteiGai,
				houteiGai:	houteiGai,
				shinya:		shinya,
				kyuujitsu:	kyuujitsu,
				h60Gai:		null,
			};
		}
		let roster = {
			date:		workhour.work_date,
			name:		workhour.name,
			punchStart: workhour.punch_start !== null ? workhour.punch_start : '',
			punchQuit:	workhour.punch_quit  !== null ? workhour.punch_quit  : '',
			start:		start,
			quit:		quit,
			break_time:	break_time,
			type:		workhour.name,
			kinmu:		null,
			syotei:		null,
			syoteiGai:	null,
			houteiGai:	null,
			shinya:		null,
			kyuujitsu:	null,
			h60Gai:		null
		};
		return roster;
	}

	render() {
		var summaryDate = new Date(this.state.start_day);
		return (
			<div className="DayChart" >

				<Container>
					<Row>
						<form>
							<FormGroup disabled>
								<InputGroup>
									<InputGroupText>日付</InputGroupText>
									<Button onClick={this.decMonth} autoFocus={true} >◀︎</Button>
								    <DatePicker className="form-control" selected={summaryDate} onChange={this.dayChange} locale="ja" dateFormat="yyyy-MM-dd"/>
									<InputGroupText>{' 〜 ' + moment(this.state.end_day).format('YYYY/MM/DD')}</InputGroupText>
									<Button onClick={this.incMonth} >▶︎</Button>
								</InputGroup>
							</FormGroup>
						</form>
					</Row>
				</Container>
				
				{ this.state.staffTotal }
				<PrintPreview isPoppedOut={false} url="A4.html" title={ "月次勤務集計表　"} contents={this.state.chart} />
			</div>
		);

	}
}

export default MonthRoster;
