/* ==============================================================================
 Modal画面のHookモジュール

 履歴-----------------------------------------
 2021/07/04 追加
 
=============================================================================== */

import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const ModalHook = (props) => {
	const {
		isOpen	 = false,
//		size	 = 'sm',			// 'sm'、'lg'
		title    = 'Title',
		subTitle = '',
		icons	 = '',				// 右寄せでiconなどを表示する。
		body	 = 'empty',			// 本体のフォーム
		footer	 = '',				// フッターに表示するボタンなど。「キャンセル」、「登録」は表示済み
		maxWidth = '750px',			// モーダルダイヤログの横幅を指定する
		onCancel,
	} = props;

// console.log(maxWidth);	
	return (
		<div>
			<Modal isOpen={isOpen} backdrop={'static'} style={{maxWidth: maxWidth }} autoFocus={false} toggle={onCancel}> 
				<ModalHeader toggle={onCancel}>
					<table width="100%" ><tbody><tr>
						<td>{title}</td>
						<td className="h6">{subTitle}</td>
						<td align="right" >{icons}   </td>
					</tr></tbody></table>
				</ModalHeader>
				<ModalBody>
					{ body }
				</ModalBody>
				<ModalFooter>
					{ footer }
				</ModalFooter>
			</Modal>
		</div>
	);
};

export const FooterOk = (props) => {
	const {
		footer,
		onClose,
		disabled = false,
	} = props;
	return(
		<div>
			{ footer }
			<Button color="primary" onClick={onClose} disabled={disabled}>OK</Button>
		</div>
	);
};

export const FooterOkCancel = (props) => {
	const {
		footer,
		onClose,
		onCancel,
		disabled = false,
	} = props;
	return(
		<div>
			{ footer }
			<Button color="warning" onClick={onCancel}>キャンセル</Button>　
			<Button color="primary" onClick={onClose} disabled={disabled}>登録</Button>
		</div>
	);
};

export const FooterOkCancelDelete = (props) => {
	const {
		footer,
		onClose,
		onCancel,
		onDelete,
		disabled = false,
	} = props;
	return(
		<div>
			{ footer }
			<Button color="danger"  onClick={onDelete}>削除</Button>&thinsp;
			<Button color="warning" onClick={onCancel}>キャンセル</Button>&thinsp;
			<Button color="primary" onClick={onClose} disabled={disabled}>登録</Button>
		</div>
	);
};



export default ModalHook;

