import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Request from 'superagent';
// import { Redirect } from 'react-router-dom';
import GuestOnly from './GuestOnly';

var storeToken = {
  isLoggedIn: false,
  token: "",
  tenant_id: 0,
  customer_id: 0
};

export var JWToken = {
    setToken: function(res) {
      storeToken.isLoggedIn = true;
      storeToken.token       = res.token;        // Object.assign({},token); オブジェクトをコピーしておきたい
      storeToken.tenant_id   = res.tenant_id;    // Object.assign({},token); オブジェクトをコピーしておきたい
      storeToken.customer_id = res.customer_id;
//      console.log('setToken');
//      console.log( storeToken );
      return;
    },
    getToken: function() {
      return storeToken;
    },
    clearToken: function() {
      storeToken.isLoggedIn = false;
      storeToken.token = "";
      storeToken.tenant_id = 0;
      storeToken.customer_id = 0;
      return;
    }  
};


export default class GuestLogin extends Component {
//  static contextTypes = {
//    router: PropTypes.object.isRequired
//  }


  // コンストラクター
  constructor(props) {
    super(props);
    this.emailRef = React.createRef();
    this.state = {  isLogin: false,
                    username: "",
                    password: "",         // 削除時は各項目を編集付加に設
                    buttonDisable: true  // 一覧選択時の修正ボタン、削除ボタンの設定
                  };
                  
        this.close = this.close.bind(this);
        this.usernameChange = this.usernameChange.bind(this);           
        this.passwordChange = this.passwordChange.bind(this);           
    
    }

  	// 描画が行われる直前に呼ばれる
  	componentDidMount() {
      // カーソルを移動する
//      let node = ReactDOM.findDOMNode(this.refs.emailInput);
      let node = this.emailRef.current;
      if ( node ) node.focus();

  	}
    
    usernameChange(event) {  this.setState({ username: event.target.value}); }
    passwordChange(event) {  this.setState({ password: event.target.value}); }
    
    close() {
console.log(this.props.match.params);
        var req = {
          tenant:   this.props.match.params.tenant,
          username: this.state.username.trim(),
          password: this.state.password.trim()
        };
        console.log(req);
        Request.post('/guest/' +  this.props.match.params.tenant )
          .send(req)
          .end(function(err, res){
            if (err) {
                console.log(err);
                alert("認証エラー");
                return;
            }
            if ( res.body.success === false ) {
              alert("認証エラー");
              return;
            }
            console.log(res.body);
            // ここで、JWTを受け取り、ローカルストレージに登録する
            JWToken.setToken(res.body);
            
            this.setState( { isLogin: true });
//            this.context.router.replace('/booking');
          }.bind(this));

    }

    render() {
      if ( JWToken.getToken().isLoggedIn === true ) {
        console.log("GuestLogin: ログイン済");
//        return (<Redirect push to={'/GuestHome'} />);
        return (<GuestOnly /> );
      }
      return (
        <Modal isOpen={true}> 
          <ModalHeader>ログイン</ModalHeader>
          <ModalBody>
             <Form>
              <FormGroup>
                <Label>メールアドレス</Label>
                <Input type="email" ref={this.emailRef} placeholder="Mail Address" onChange={this.usernameChange} />
              </FormGroup>
              <FormGroup>
                <Label>パスワード　　</Label>
                <Input type="password" ref="password" placeholder="Password" onChange={this.passwordChange} />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="info" onClick={this.close} className="btn btn-default">ログイン</Button>
          </ModalFooter>
        </Modal>

      );
    }
}

