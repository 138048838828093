/* =============================================================================
	React Data Grid 用の Formatter
   ============================================================================= */


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MAX_DB_LENGTH } from './Define';

/* -----------------------------------------------------------------------------
	左寄せ
   ----------------------------------------------------------------------------- */


export const AlignLeft = ({ value = '' }) => {
  return (
    <div className="text-left">{value}</div>
  );
};

AlignLeft.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};


/* -----------------------------------------------------------------------------
	右寄せ
   ----------------------------------------------------------------------------- */

export const AlignRight = ({ value = '' }) => {
  return (
    <div className="text-right">{value}</div>
  );
};

AlignRight.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

/* -----------------------------------------------------------------------------
	中央寄せ
   ----------------------------------------------------------------------------- */
export const AlignCenter = ({ value = '' }) => {
  return (
    <div className="text-center">{value}</div>
  );
};

AlignCenter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

/* -----------------------------------------------------------------------------
	textarea
   ----------------------------------------------------------------------------- */
   

export const TextArea = ({ value = '' }) => {
  const paragraphs = value.split('\n').map((paragraph, index) => (
    <p key={index}>{paragraph}</p>
  ));

  return (
    <div className="text-left">{paragraphs}</div>
  );
};

TextArea.propTypes = {
  value: PropTypes.string
};

/* -----------------------------------------------------------------------------
	番号表示  削除した番号
   ----------------------------------------------------------------------------- */

export const AlignNumber = ({ value = '' }) => {
  const isWithinMaxLength = Number(value) < MAX_DB_LENGTH;
  return (
    <div className="text-center">
      {isWithinMaxLength ? value : '削除'}
    </div>
  );
};

AlignNumber.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};


/* -----------------------------------------------------------------------------
	金額表示
   ----------------------------------------------------------------------------- */

export const changeYen = (num) => {
	if ( num === null ) return '';
	if ( num === 0 )    return '0';
	if ( isNaN(num) )   return '';
	if ( num < 0 ) {
		let yen = Math.round(num);
		return '-' + String(yen).split("").reverse().join("").match(/\d{1,3}/g).join(",").split("").reverse().join("");
	}
	let yen = Math.round(num);
    return String(yen).split("").reverse().join("").match(/\d{1,3}/g).join(",").split("").reverse().join("");
}

export const YenFormat = ({ value = 0, mark = '' }) => {
  return (
    <div className="text-right">{mark + changeYen(value)}</div>
  );
};

YenFormat.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mark: PropTypes.string,
};


/* -----------------------------------------------------------------------------
	年月日を表示する
   ----------------------------------------------------------------------------- */
export class DateFormat extends Component {
	static propTypes= {
		value: PropTypes.string
	}
	render(){
		if ( this.props.value === undefined ) return <div></div>;
		if ( this.props.value === null ) return <div></div>;
		if ( this.props.value === ""   ) return <div></div>;
		let d = new Date(this.props.value);
		if ( d === "" ) return <div>"--/--"</div>;
		let str = d.getFullYear() + "-" + ("0" + (d.getMonth()+1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
		return <div>{str}</div>;
	}	
}

/* -----------------------------------------------------------------------------
	月日(曜日)を表示する
   ----------------------------------------------------------------------------- */
export class Date2Format extends Component {
	static propTypes= {
		value: PropTypes.string
	}
	render(){
		if ( this.props.value === undefined ) return <div></div>;
		if ( this.props.value === null ) return <div></div>;
		if ( this.props.value === ""   ) return <div></div>;
		const week = ["日","月","火","水","木","金","土"];
		let d = new Date(this.props.value);
		if ( d === "" ) return <div>"--/--"</div>;
		let str = ("0" + (d.getMonth()+1)).slice(-2) + "/" + ("0" + d.getDate()).slice(-2) + " " + week[d.getDay()];
		return <div>{str}</div>;
	}	
}

/* -----------------------------------------------------------------------------
	時:分を表示する
   ----------------------------------------------------------------------------- */
export class TimeFormat extends Component {
	static propTypes= {
		value: PropTypes.string
	}
	render(){
		if ( !this.props.value  ) return <div></div>;
		let d = new Date(this.props.value);
		if ( !d ) return <div>"--:--"</div>;
		let str = ("0" + (d.getHours())).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) ;
		return <div>{str}</div>;
	}	
}

/* -----------------------------------------------------------------------------
	時:分を表示する
   ----------------------------------------------------------------------------- */
export class TimeHMFormat extends Component {
	static propTypes= {
		value: PropTypes.string
	}
	render(){
		if ( ! this.props.value ) return <div></div>;
		let array = this.props.value.split(':');
		array.splice( 2, 1 ) ;
		return <div className="text-center">{array.join(':')}</div>;
	}	
}

/* -----------------------------------------------------------------------------
	曜日を表示する
   ----------------------------------------------------------------------------- */
export class DayOfWeekFormat extends Component {
	static propTypes= {
		value: PropTypes.number
	}
	render(){
		if ( this.props.value === undefined ) return <div></div>;
		if ( this.props.value === null ) return <div></div>;
		if ( this.props.value === ""   ) return <div></div>;
		const week = ["日","月","火","水","木","金","土"];
		return <div>{week[this.props.value]}</div>;
	}	
}


/* -----------------------------------------------------------------------------
	曜日を表示する
   ----------------------------------------------------------------------------- */
export function time2int(time) {
	if ( !time ) return null;
    let t = time.split(':');
    return Number(t[0]) * 60 + Number(t[1]);
}

export function int2time(i) {
	if ( ! i ) return '';
	let h = parseInt(i / 60, 10 );
    return [ h < 10 ? '0' + h : h  , ( '00' + i % 60).slice(-2) ].join(":");
}

